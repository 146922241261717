import { getUrl } from "../components/pdfDisplay/utils";

export function loadPaymentScript(user, data) {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
        let options = {
            key: process.env.RAZORPAY_API_KEY,
            description: 'Quyva Credit Transaction',
            callback_url: getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/payment-verification`
            ),
            prefill: {
                name: user.username,
                email: user.email,
            },
            readonly: { email: true },
            ...data,
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    script.onerror = () => {
        console.error('Razorpay script failed to load.');
    };
    document.body.appendChild(script);
}
