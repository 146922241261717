import React from 'react';

export const NoInteractionView = ({ viewText }) => {
    return (
        <div className='relative'>
            <div className='fixed inset-0 flex items-center justify-center bg-transparent z-50 backdrop-filter backdrop-blur'>
                <div className='w-fit h-fit p-4 bg-backgrounds-white rounded-xl flex flex-col justify-center items-center shadow-md border border-backgrounds-primary100'>
                    <div className='w-fit h-fit p-3 flex items-center justify-center bg-opacity-100 shadow-inner rounded-xl mb-2'>
                        <div className='w-3 h-3 bg-backgrounds-primary100 rounded-full animate-ping mx-2 shadow'></div>
                        <div className='w-3 h-3 bg-backgrounds-primary200 rounded-full animate-ping mx-2 shadow'></div>
                        <div className='w-3 h-3 bg-backgrounds-primary300 rounded-full animate-ping mx-2 shadow'></div>
                        <div className='w-3 h-3 bg-backgrounds-primary400 rounded-full animate-ping mx-2 shadow'></div>
                    </div>
                    <div className='bg-opacity-100 mt-2 text-texts-secondary300 drop-shadow'>
                        {viewText}
                    </div>
                </div>
            </div>
        </div>
    );
};
