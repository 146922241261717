// Function to get random word of desired length.
export const generateRandomWord = (length) => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    let randomWord = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * alphabet.length);
        randomWord += alphabet[randomIndex];
    }

    return randomWord;
};
