import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUrl } from '../components/pdfDisplay/utils';

const AdminContext = createContext();

export const useAdmin = () => {
    return useContext(AdminContext);
};

export const AdminProvider = ({ children }) => {
    const [adminList, setAdminList] = useState([]);

    useEffect(() => {
        const fetchAdminList = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('current'));
                const encodedToken = encodeURIComponent(user.token);
                const response = await fetch(
                    getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/AdminList`),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodedToken,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                setAdminList(result); // result is already an array of emails
            } catch (error) {
                console.error('Error fetching admin list:', error);
                setAdminList([]);
            }
        };

        fetchAdminList();
    }, []);

    return (
        <AdminContext.Provider value={adminList}>
            {children}
        </AdminContext.Provider>
    );
};
