export const getIncreasinglyRandomInt = (min, max) => {
    const increment = 2; // Adjust the increment value as needed
    let lastValue = min;

    return function () {
        lastValue += Math.random() * increment;
        if (lastValue > max) {
            lastValue = min + (lastValue - max);
        }
        return Math.floor(lastValue);
    };
};
