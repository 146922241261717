import React, { useRef, useState } from 'react';
import { faM, faPaperPlane, faRotate } from '@fortawesome/free-solid-svg-icons';

import { Button, Input } from '../reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ChatActions = ({
    query,
    fineTunedMode,
    repoName,
    setQuery,
    handleFineTune,
    handleSubmit,
    handleSubmitMultiple,
    handleRegenerate,
    currentQnA,
    isGenerating,
    fileToChat,
    isDatabase,
}) => {
    const buttonRef = useRef(null);
    const [disableRegenerate, setDisableRegenerate] = useState(false);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            buttonRef?.current?.click(); // Programmatically click the button
        }
    };

    return (
        <div className='w-full mx-3'>
            <div className='flex items-center justify-between'>
                <div className='w-full bg-backgrounds-white border border-1 rounded-xl'>
                    <Input
                        className='px-4 h-fit w-full'
                        value={query}
                        type='text'
                        placeholder={
                            !fineTunedMode
                                ? `Ask question from ${
                                      fileToChat === '' ||
                                      fileToChat === undefined
                                          ? repoName
                                          : fileToChat
                                  }'s model here`
                                : `Ask question from fine tuned model of ${repoName}'s here`
                        }
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {isDatabase == null &&
                (fileToChat === '' || fileToChat === undefined) ? (
                    <div className='flex items-center justify-between ml-1'>
                        {/* <button
                        className={`flex font-semibold items-center w-10 h-10 justify-center border rounded-full shadow ${
                            query.length === 0
                                ? 'text-texts-secondary300 cursor-not-allowed'
                                : 'hover:scale-110 hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary500'
                        }`}
                        type='submit'
                        title='Send question'
                        disabled={query.length === 0}
                        onClick={
                            fineTunedMode
                                ? handleFineTune
                                : () => {
                                      handleSubmit(false);
                                  }
                        }
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button> */}

                        <Button
                            ref={buttonRef}
                            className={`w-9 h-9 ml-2`}
                            hoverText='Send question and get single answer'
                            disabled={query.length === 0 || isGenerating}
                            onClick={
                                fineTunedMode
                                    ? handleFineTune
                                    : async () => {
                                          await handleSubmit(false);
                                          setDisableRegenerate(false);
                                          setQuery('');
                                      }
                            }
                            icon={faPaperPlane}
                            type={'success'}
                        />

                        {/* <div className='flex items-center justify-center relative'>
                            <Button
                                className={`w-9 h-9 ml-2`}
                                onClick={
                                    fineTunedMode
                                        ? handleFineTune
                                        : async (e) => {
                                              e.stopPropagation();
                                              await handleSubmitMultiple(false);
                                              setDisableRegenerate(false);
                                              setQuery('');
                                          }
                                }
                                hoverText='Send question and get multiple answers'
                                disabled={query.length === 0 || isGenerating}
                                icon={faPaperPlane}
                                type={'info'}
                            />
                            <FontAwesomeIcon
                                icon={faM}
                                fontSize={8}
                                title='Send question and get multiple answers'
                                className={`absolute p-0.5 right-1 bottom-1.5 drop-shadow-sm bg-backgrounds-slate  ${
                                    query.length === 0 || isGenerating
                                        ? 'text-icons-tertiary200 cursor-not-allowed'
                                        : 'text-icons-primary cursor-pointer'
                                }  rounded-full`}
                            />
                        </div> */}

                        <Button
                            className={`w-9 h-9 ml-2`}
                            hoverText='Regenerate answer'
                            disabled={
                                currentQnA.length === 0 ||
                                disableRegenerate ||
                                isGenerating ||
                                query.length !== 0
                            }
                            onClick={async () => {
                                await handleSubmit(true);
                                setDisableRegenerate(true);
                            }}
                            icon={faRotate}
                            type={'info'}
                        />
                    </div>
                ) : (
                    <div className='flex items-center justify-between ml-2'>
                        {/* <button
                        className={`flex font-semibold items-center w-10 h-10 justify-center border rounded-full shadow ${
                            query.length === 0
                                ? 'text-texts-secondary300 cursor-not-allowed'
                                : 'hover:scale-110 hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary500'
                        }`}
                        type='submit'
                        title='Send question'
                        disabled={query.length === 0}
                        onClick={
                            fineTunedMode
                                ? handleFineTune
                                : () => {
                                      handleSubmit(false);
                                  }
                        }
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button> */}

                        <Button
                            ref={buttonRef}
                            className={`w-9 h-9 ml-1 mr-1`}
                            hoverText='Send question and get single answer'
                            disabled={query.length === 0 || isGenerating}
                            onClick={
                                fineTunedMode
                                    ? handleFineTune
                                    : async () => {
                                          await handleSubmit(false);
                                          setDisableRegenerate(false);
                                          setQuery('');
                                      }
                            }
                            icon={faPaperPlane}
                            type={'success'}
                        />

                        <Button
                            className={`w-9 h-9 ml-1 mr-1`}
                            hoverText='Regenerate answer'
                            disabled={
                                currentQnA.length === 0 ||
                                disableRegenerate ||
                                isGenerating ||
                                query.length !== 0
                            }
                            onClick={async () => {
                                await handleSubmit(true);
                                setDisableRegenerate(true);
                            }}
                            icon={faRotate}
                            type={'info'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
