import ReactPaginate from 'react-paginate';
import React, { useEffect, useState } from 'react';
import './pagination.css';

export const Pagination = ({
    activeId,
    itemsCount,
    itemsPerPage,
    handlePageChange,
    previousLabel,
    nextLabel,
}) => {
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    // Set page count based on itemsPerPage and itemsCount.
    useEffect(() => {
        setPageCount(Math.ceil(itemsCount / itemsPerPage));
    }, [itemsPerPage, itemsCount]);

    // Reset page on question change.
    useEffect(() => {
        setOffset(0);
    }, [activeId]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % itemsCount;
        handlePageChange(newOffset);
        setOffset(newOffset);
    };

    return (
        <ReactPaginate
            nextLabel={nextLabel}
            previousLabel={previousLabel}
            onClick={handlePageClick}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            forcePage={offset}
            pageCount={pageCount}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='previous'
            previousLinkClassName='page-link'
            nextClassName='next'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
        />
    );
};
