import React, { useEffect, useState } from 'react';
import { Input } from '../reusableComponents/input';
import { Button, Dropdown, NoInteractionView } from '../reusableComponents';
import { faSave, faShare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { isEmailValid } from '../../utils';
import { UserAccessOptions } from '../../constants/userAccessOptions';
import { UserListComponent } from './userListComponent';
import { getUrl } from '../pdfDisplay/utils';
export const ShareRepository = ({
    user,
    repositoryData,
    setViewSharingModal,
}) => {
    const [newUserEmail, setNewUserEmail] = useState('');
    const [userList, setUserList] = useState([]);
    const [addedUserList, setAddedUserList] = useState([]);
    const [accessRights, setAccessRights] = useState(UserAccessOptions.CHAT);
    const [enableSharing, setEnableSharing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const encodedToken = encodeURIComponent(user.token);
    async function getSharedRepos(repoid) {
        try {
            setIsLoading(true);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getSharedRepos/${repoid}`
                ),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            const usersData = result;

            // sort user list in ascending order
            usersData.sort((a, b) => a.email.localeCompare(b.email));

            setUserList(usersData);
            setIsLoading(false);
            console.log('Shared Repositories:', result);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getSharedRepos(repositoryData.repoid);

        return () => {};
    }, []);

    if (isLoading || isSharing) {
        return (
            <NoInteractionView
                viewText={
                    isSharing
                        ? 'Sharing repository ...'
                        : 'Loading shared list ...'
                }
            />
        );
    }

    const addNewUser = () => {
        const newEmail = newUserEmail.toLowerCase();
        if (newUserEmail === repositoryData.email) {
            alert('Listed email is the owner. No sharing required');
            return;
        }

        // Check if user already exist in either of the list
        const combinedUserList = [...userList, ...addedUserList];
        for (let index = 0; index < combinedUserList.length; index++) {
            const element = combinedUserList[index];
            if (element.email.toLowerCase() === newEmail) {
                alert('User already present in shared user list');
                return;
            }
        }

        if (user.email.toLowerCase() === newEmail) {
            alert("Can't share with self!");
            return;
        }

        const newUserData = {
            email: newEmail,
            accessRights: accessRights,
        };

        const newRecentList = [newUserData, ...addedUserList];
        setAddedUserList(newRecentList);
        setNewUserEmail('');
        setEnableSharing(true);
    };

    const dropDownList = Object.keys(UserAccessOptions).map((data, index) => {
        return {
            key: index,
            label: data,
            disabled: false,
            selected: accessRights === UserAccessOptions[data],
            action: () => {
                if (data && accessRights !== UserAccessOptions[data]) {
                    setAccessRights(UserAccessOptions[data]);
                }
            },
        };
    });

    const handleUpdateShared = async (updatedData) => {
        try {
            const encodedToken = encodeURIComponent(user.token);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/updateSharedRepos`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                    body: JSON.stringify(updatedData),
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            console.log(result); // Log the response from the server
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const shareUpdatedData = async () => {
        setIsSharing(true);
        const updatedData = {
            repoId: repositoryData.repoid,
            originUser: user.email,
            data: [...addedUserList, ...userList],
        };

        // Call to backend for sharing updated user data
        handleUpdateShared(updatedData);
        setIsSharing(false);
    };

    return (
        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col p-2 overflow-y-auto'>
            <div className='flex border-b-2 border-white justify-center items-center pt-3 pb-4'>
                <Input
                    type={'email'}
                    placeholder={'Email'}
                    value={newUserEmail}
                    className={'mr-3 w-full truncate'}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                />
                <Dropdown
                    dropDownList={dropDownList}
                    showButtonText={true}
                    buttonText={accessRights}
                    buttonHoverText={'Change access rights of user'}
                    textColor={
                        accessRights === UserAccessOptions.ADMIN
                            ? 'backgrounds-settings100'
                            : 'icons-primary'
                    }
                    backgroundColor={
                        accessRights === UserAccessOptions.ADMIN
                            ? 'backgrounds-settings100'
                            : 'icons-primary'
                    }
                    buttonClassName={'mr-3 ml-3 w-24'}
                    listClassName={''}
                    disabled={false}
                />
                <Button
                    className=''
                    text={'Add'}
                    onClick={(event) => {
                        addNewUser();
                        event.stopPropagation();
                    }}
                    disabled={!isEmailValid(newUserEmail)}
                    hoverText={'Add new user to repository shared list'}
                    icon={faUserPlus}
                    type={'success'}
                />
            </div>

            {addedUserList && addedUserList.length !== 0 && (
                <div className='border-b-2 border-white'>
                    <div className='flex justify-between items-center font-semibold mt-2 px-2 py-2 text-texts-secondary300'>
                        <div>New users</div>
                        <div className='mr-2'>Actions</div>
                    </div>

                    <div className='max-h-48 overflow-auto w-full'>
                        <UserListComponent
                            userList={addedUserList}
                            stateAction={setAddedUserList}
                            enableSharing={enableSharing}
                            setEnableSharing={setEnableSharing}
                        />
                    </div>
                </div>
            )}

            <div>
                <div className='flex justify-between items-center font-semibold mt-2 px-2 py-2 text-texts-secondary300'>
                    <div>User with access</div>
                    <div className='mr-2'>Actions</div>
                </div>

                <div className='max-h-52 overflow-auto w-full'>
                    <UserListComponent
                        userList={userList}
                        stateAction={setUserList}
                        enableSharing={enableSharing}
                        setEnableSharing={setEnableSharing}
                    />
                </div>
            </div>

            <div className='flex border-t-2 border-white pt-2 justify-end items-center'>
                <Button
                    className='whitespace-nowrap overflow-hidden text-ellipsis w-32'
                    text={addedUserList.length ? 'Share' : 'Update'}
                    onClick={async (event) => {
                        await shareUpdatedData();
                        setViewSharingModal(false);
                        event.stopPropagation();
                    }}
                    hoverText={'Share repository with updated data'}
                    icon={addedUserList.length ? faShare : faSave}
                    type={'success'}
                    disabled={!enableSharing}
                />
            </div>
        </div>
    );
};
