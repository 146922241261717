import React, { useState, useEffect } from 'react';
import {
    GoogleChartEditor,
    GoogleChartWrapper,
    GoogleViz,
    Chart,
} from 'react-google-charts';
import '../styles';
import { ChartTypes, DataTypes } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const NoChartDataView = () => {
    return (
        <div className='flex flex-col w-full h-1/2 justify-center items-center text-lg absolute top-44 left-0 z-10'>
            <FontAwesomeIcon
                icon={faBoxOpen}
                className='mb-1 text-texts-secondary300'
                size='3x'
            />
            <div className='text-texts-secondary300'>No Chart Data</div>
            <div className='text-texts-secondary300 text-sm'>
                Select field to add chat data
            </div>
        </div>
    );
};

export const GraphEditorView = ({
    chartData,
    showGraphModal,
    setShowGraphModal,
    title,
    onUpdateChart,
}) => {
    const [chartEditor, setChartEditor] = useState();
    const [chartWrapper, setChartWrapper] = useState();
    const [google, setGoogle] = useState();
    const [localChartData, setLocalChartsData] = useState(chartData);

    const defaultVAxis = {
        minValue: null,
        maxValue: null,
        viewWindow: null,
        viewWindowMode: null,
        useFormatFromData: true,
        title: 'Y axis',
    };

    const defaultHAxis = {
        viewWindow: {
            max: null,
            min: null,
        },
        minValue: null,
        maxValue: null,
        useFormatFromData: true,
        title: 'X axis',
    };

    const options = {
        title: title,
        vAxis: defaultVAxis,
        hAxis: defaultHAxis,
        width: '100%',
        height: '100%',
    };

    useEffect(() => {
        if (chartData.chart === ChartTypes.GEO_CHART) {
            if (
                chartData.data &&
                Array.isArray(chartData.data) &&
                chartData.data[1].length !== 2
            ) {
                alert(
                    'This chart can have two selected fields, 1st is string (country name) and 2nd selected field is number'
                );
            } else if (typeof chartData.data[1][0] !== DataTypes.STRING) {
                alert(
                    "This chart's 1st selected fields should be string (country name)"
                );
            } else if (typeof chartData.data[1][1] !== DataTypes.NUMBER) {
                alert("This chart's 2st selected field should be a number");
            }
        }

        return () => {};
    }, [chartData.chart]);

    useEffect(() => {
        if (chartData) {
            const newChartData = { ...chartData };
            if (newChartData.options) {
                if (!newChartData.options.hAxis) {
                    newChartData.options.hAxis = defaultHAxis;
                }
                if (!newChartData.options.vAxis) {
                    newChartData.options.vAxis = defaultVAxis;
                }
            } else {
                newChartData.options = options;
            }

            setLocalChartsData(newChartData);
        }

        return () => {};
    }, [chartData]);

    if (showGraphModal) {
        if (!chartWrapper || !google || !chartEditor) {
            return;
        }

        chartEditor.openDialog(chartWrapper);

        const chartOptions = chartWrapper.getOptions();

        chartOptions.width = '100%';
        chartOptions.height = '100%';
        chartOptions.vAxis = chartOptions.vAxis
            ? chartOptions.vAxis
            : defaultVAxis;
        chartOptions.hAxis = chartOptions.hAxis
            ? chartOptions.hAxis
            : defaultHAxis;

        const updatedChartOptions = { ...chartOptions };
        chartWrapper.setOptions(updatedChartOptions);

        google.visualization.events.addListener(chartEditor, 'ok', () => {
            const newChartWrapper = chartEditor.getChartWrapper();

            newChartWrapper.draw();

            const newChartOptions = newChartWrapper.getOptions();
            newChartOptions.width = '100%';
            newChartOptions.height = '100%';
            newChartOptions.vAxis = defaultVAxis;
            newChartOptions.hAxis = defaultHAxis;

            const updatedChartOptions = { ...newChartOptions };
            newChartWrapper.setOptions(updatedChartOptions);

            const newChartType = newChartWrapper.getChartType();

            // console.log('Chart type changed to ', newChartType);
            // console.log('Chart options changed to ', newChartOptions);

            const updatedChartData = {
                chart: newChartType,
                options: updatedChartOptions,
            };
            onUpdateChart(updatedChartData);

            setShowGraphModal(false);
        });

        google.visualization.events.addListener(chartEditor, 'cancel', () => {
            chartEditor.closeDialog(chartWrapper);
            setShowGraphModal(false);
        });
    }

    if (localChartData) {
        return (
            <div
                className={`w-full ${
                    localChartData.chart === ChartTypes.TABLE &&
                    localChartData.data &&
                    localChartData.data.length !== 0
                        ? 'h-1/3'
                        : 'h-full'
                }`}
            >
                {!localChartData.data || localChartData.data.length === 0 ? (
                    <NoChartDataView />
                ) : (
                    <></>
                )}

                <Chart
                    chartType={localChartData.chart}
                    width='100%'
                    height='100%'
                    data={localChartData.data}
                    options={localChartData.options}
                    chartPackages={['corechart', 'controls', 'charteditor']}
                    getChartEditor={({ chartEditor, chartWrapper, google }) => {
                        setChartEditor(chartEditor);
                        setChartWrapper(chartWrapper);
                        setGoogle(google);
                    }}
                />
            </div>
        );
    } else {
        return <></>;
    }
};
