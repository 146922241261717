export const validatePassword = (password) => {
    const minLength = 8;
    const isValidLength = password.length >= minLength;
    if (!isValidLength) {
        return {
            isValid: false,
            error: `Password must be at least ${minLength} characters long.`,
        };
    }
    return { isValid: true, error: null };
};
