import React, { useState, useEffect } from 'react';
import { DataTypes } from '../constants';
import { toSentenceCase } from '../utils';
import Toggle from '../../reusableComponents/Toggle';
import { Input } from '../../reusableComponents';

const maxTitleLength = 60;

const buildFieldData = (parent, schema, fieldsData) => {
    const schemaKeys = Object.keys(schema);
    let data = null;
    const parentData = fieldsData.get(parent);
    const isParentMultiple =
        parentData && parentData.isSelfMultiple
            ? parentData.isSelfMultiple
            : false;

    schemaKeys.forEach((field) => {
        if (
            typeof schema[field] === 'object' &&
            !Array.isArray(schema[field]) &&
            schema[field] !== null
        ) {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: false,
                isSelectable: false,
            };
            fieldsData.set(field, data);
            buildFieldData(field, schema[field], fieldsData);
        } else if (
            typeof schema[field] === 'object' &&
            Array.isArray(schema[field]) &&
            typeof schema[field][0] === 'object'
        ) {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: true,
                isSelectable: false,
            };
            fieldsData.set(field, data);
            buildFieldData(field, schema[field][0], fieldsData);
        } else if (
            (typeof schema[field] === 'object' &&
                Array.isArray(schema[field]) &&
                typeof schema[field][0] === 'string') ||
            typeof schema[field][0] === 'number'
        ) {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: true,
                isSelectable: true,
                isDisabled: false,
                type:
                    typeof schema[field][0] === 'string'
                        ? DataTypes.STRING
                        : DataTypes.NUMBER,
            };
            fieldsData.set(field, data);
        } else {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: false,
                isSelectable: true,
                isDisabled: false,
                type:
                    typeof schema[field] === 'string'
                        ? DataTypes.STRING
                        : DataTypes.NUMBER,
            };
            fieldsData.set(field, data);
        }
    });
};

const FieldComponent = ({
    field,
    fieldsData,
    reportTitleField,
    setReportTitleField,
}) => {
    const data = fieldsData.get(field);
    if (data.isSelfMultiple || data.isParentMultiple) return <></>;

    return (
        <div className='flex flex-col justify-center items-start'>
            <label
                className={
                    data.isDisabled
                        ? 'cursor-not-allowed border-l-2 py-1'
                        : 'cursor-pointer border-l-2 py-1 flex items-center'
                }
                title={
                    data.isDisabled
                        ? 'Disabled as a "Multiple" type field from other category selected'
                        : 'Select the field'
                }
            >
                <span className='text-texts-secondary300 mr-1'>-</span>
                {/* Checkbox */}
                {data.isSelectable ? (
                    <input
                        type='radio'
                        name='reportTitleField'
                        value={field}
                        disabled={data.isDisabled}
                        checked={field === reportTitleField}
                        onChange={() => {
                            setReportTitleField(field);
                        }}
                        className='mr-2'
                    />
                ) : (
                    <></>
                )}

                {/* Field text */}

                <span
                    className={
                        data.isSelectable ? 'text-slate-600' : 'font-semi-bold'
                    }
                >
                    {toSentenceCase(field)}
                </span>

                {/* Type text */}
                {data.isSelectable ? (
                    <span className='ml-2 text-texts-secondary300'>
                        {data.type.toUpperCase()}
                    </span>
                ) : (
                    <></>
                )}

                {/* Multiple text */}
                {data.isSelfMultiple ? (
                    <span className='ml-2 text-backgrounds-primary400'>
                        Multiple
                    </span>
                ) : (
                    <></>
                )}
            </label>
        </div>
    );
};

const FieldsStructure = ({
    schema,
    fieldsData,
    reportTitleField,
    setReportTitleField,
}) => {
    if (fieldsData.size === 0) {
        return <></>;
    }

    const schemaKeys = Object.keys(schema);

    return schemaKeys.map((field) => {
        if (
            typeof schema[field] === 'object' &&
            !Array.isArray(schema[field]) &&
            schema[field] !== null
        ) {
            return (
                <div className='flex flex-col justify-center items-start'>
                    <FieldComponent
                        field={field}
                        fieldsData={fieldsData}
                        reportTitleField={reportTitleField}
                        setReportTitleField={setReportTitleField}
                    />

                    <div className='mb-2 ml-4'>
                        <FieldsStructure
                            schema={schema[field]}
                            fieldsData={fieldsData}
                            reportTitleField={reportTitleField}
                            setReportTitleField={setReportTitleField}
                        />
                    </div>
                </div>
            );
        } else if (
            typeof schema[field] === 'object' &&
            Array.isArray(schema[field]) &&
            typeof schema[field][0] === 'object'
        ) {
            return (
                <div className='flex flex-col justify-center items-start'>
                    <FieldComponent
                        field={field}
                        fieldsData={fieldsData}
                        reportTitleField={reportTitleField}
                        setReportTitleField={setReportTitleField}
                    />

                    <div className='mb-2 ml-4'>
                        <FieldsStructure
                            schema={schema[field][0]}
                            fieldsData={fieldsData}
                            reportTitleField={reportTitleField}
                            setReportTitleField={setReportTitleField}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <FieldComponent
                    field={field}
                    fieldsData={fieldsData}
                    reportTitleField={reportTitleField}
                    setReportTitleField={setReportTitleField}
                />
            );
        }
    });
};

export const ReportTitleFieldsViewer = ({
    schema,
    reportTitleField,
    setReportTitleField,
    useCustomReportTitle,
    setUseCustomReportTitle,
    defaultReportTitle,
}) => {
    const [updatedSchema, setUpdatedSchema] = useState(schema);
    const [fieldsData, setFieldsData] = useState(new Map());
    const [firstFieldsData, setFirstFieldsData] = useState(new Map());

    useEffect(() => {
        if (schema && reportTitleField) {
            const newSchema = {
                default: {
                    fileName: 'string',
                },
                ...schema,
            };

            const completeFieldsData = new Map(fieldsData);

            buildFieldData(null, newSchema, completeFieldsData);

            setUpdatedSchema(newSchema);
            setFirstFieldsData(completeFieldsData);
        }

        return () => {};
    }, [schema, reportTitleField]);

    useEffect(() => {
        if (schema && reportTitleField) {
            if (firstFieldsData.size) {
                const completeFieldsData = new Map(firstFieldsData);
                buildFieldData(null, updatedSchema, completeFieldsData);

                setFieldsData(completeFieldsData);
            }
        }

        return () => {};
    }, [firstFieldsData]);

    const toggleUseCustomText = () => {
        if (useCustomReportTitle) {
            setReportTitleField(defaultReportTitle);
        } else {
            setReportTitleField('Report');
        }
        setUseCustomReportTitle(!useCustomReportTitle);
    };

    const onChangeReportTitle = (title) => {
        setReportTitleField(title);
    };

    return (
        <div className='w-[50vw] h-full flex flex-col justify-start items-start p-4'>
            <div
                className='mb-5'
                title='Toggle between custom text or field selection for the report title'
            >
                <Toggle
                    toggleText={'Custom Title'}
                    toggleTextClassName={`w-fit mr-4 cursor-help font-semibold text-texts-secondary300`}
                    disabled={false}
                    checked={useCustomReportTitle}
                    onChange={toggleUseCustomText}
                />
            </div>
            <div className='w-full flex justify-between items-center font-semibold text-texts-secondary300'>
                <div>
                    {useCustomReportTitle
                        ? 'Enter the report title'
                        : 'Select a field for report title'}
                </div>
            </div>
            <div className='w-full p-4'>
                {useCustomReportTitle ? (
                    <div className='w-full flex justify-between items-center'>
                        <div className=' w-[100px] text-texts-secondary300 mr-3 truncate'>
                            Report Title
                        </div>
                        <Input
                            className={
                                'w-[380px] text-texts-secondary300 truncate'
                            }
                            value={reportTitleField}
                            type={'text'}
                            onChange={(e) =>
                                onChangeReportTitle(e.target.value)
                            }
                            maxLength={maxTitleLength}
                        />
                    </div>
                ) : (
                    <FieldsStructure
                        schema={updatedSchema}
                        fieldsData={fieldsData}
                        reportTitleField={reportTitleField}
                        setReportTitleField={setReportTitleField}
                    />
                )}
            </div>
        </div>
    );
};
