import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { LoadingView } from './reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getUrl } from './pdfDisplay/utils';

const RenderTablesData = ({ tablesData }) => {
    const totalTables = tablesData.length;

    const modifiedTablesData = tablesData.map((data, index) => ({
        'sNo.': `${index + 1}.`,
        ...data,
    }));

    const tableColumns = [
        {
            title: 'Index',
            dataIndex: 'sNo.',
            key: 'sNo.',
        },
        {
            title: 'Table Name',
            dataIndex: 'table_name',
            key: 'table_name',
        },
        {
            title: 'Number of Rows',
            dataIndex: 'row_count',
            key: 'row_count',
        },
    ];

    return (
        <div className='border p-1 rounded-xl'>
            <div className='mb-2 font-semibold text-lg'>
                Total Tables: {totalTables}
            </div>
            <Table
                pagination={{
                    defaultPageSize: 5,
                    disabled: tablesData.length <= 5,
                }}
                dataSource={modifiedTablesData}
                columns={tableColumns}
            />
        </div>
    );
};

export const RepoStatsDB = ({ repoName ,email}) => {
    const [tablesData, setTablesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getDatabaseStats = async () => {
        try {
            setIsLoading(true);
            const apiUrl = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/database-stats?repo_name=${repoName}&user_email=${email}`);
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setTablesData(data.tables);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
            setErrorMessage(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getDatabaseStats();
    }, [repoName]);

    if (isLoading) {
        return (
            <div className='w-full h-96 max-h-96 flex justify-center items-center'>
                <LoadingView loadingText={'Loading database stats ...'} />
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className='w-full p-3 h-96 max-h-96 overflow-auto flex flex-col justify-center items-center text-buttons-alert400 font-semibold'>
                <FontAwesomeIcon icon={faExclamationTriangle} size='2x' />
                <div className='mt-2'>{errorMessage}</div>
            </div>
        );
    }

    return (
        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col px-6 py-6 rounded-xl bg-backgrounds-slate'>
            <RenderTablesData tablesData={tablesData} />
        </div>
    );
};
