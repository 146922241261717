import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faTimes,
    faUserPlus,
    faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

import { appLogo, companyLogo } from './Constants.js';
import { Button } from '../../components/reusableComponents';
import { useLocation, useNavigate } from 'react-router';
import { MENUITEMS } from '../../enums.js';
import { getUrl } from '../../components/pdfDisplay/utils.js';

const Navbar = ({ menuItems }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const useSecuredConnection = process.env.REACT_APP_USE_SECURED_CONNECTION;
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const handleNavigation = (url, ref) => {
        if (url) {
            if (url.startsWith('/')) {
                const host = process.env.REACT_APP_HOST;
                const port = process.env.REACT_APP_PORT;
                if (useSecuredConnection) {
                    window.open(getUrl(`http://${host}${url}`), '_self');
                } else {
                    window.open(
                        getUrl(`http://${host}:${port}${url}`),
                        '_self'
                    );
                }
            } else {
                window.open(url, '_blank');
            }
        } else {
            if (location.pathname !== '/') {
                navigate('/');
            } else if (ref && ref.current) {
                window.scrollTo({
                    top: ref.current.offsetTop,
                    behavior: 'smooth',
                });
            }
        }
    };

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    return (
        <nav className='bg-backgrounds-white shadow-lg px-4 py-2 flex justify-between items-center sm:px-8 md:px-16 lg:px-24 fixed top-0 left-0 w-full z-50'>
            <div className='flex items-center justify-between w-full md:w-auto'>
                <button className='md:hidden' onClick={toggleMobileMenu}>
                    <FontAwesomeIcon
                        icon={showMobileMenu ? faTimes : faBars}
                        className='text-backgrounds-primary300 text-2xl'
                    />
                </button>
                {showMobileMenu && (
                    <div className='md:hidden fixed top-0 left-0 w-screen h-screen bg-backgrounds-secondary bg-opacity-90 flex justify-center items-center'>
                        <div className='flex flex-col items-center'>
                            <button
                                className='text-texts-secondary text-2xl mb-4'
                                onClick={toggleMobileMenu}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            {menuItems.map((item, index) => (
                                <button
                                    key={index}
                                    className='font-bold text-texts-secondary300 mb-2 hover:text-backgrounds-primary400'
                                    onClick={() => {
                                        handleNavigation(null, item.ref);
                                        toggleMobileMenu();
                                    }}
                                >
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
                <div className='hidden md:flex items-center'>
                    <button
                        onClick={() =>
                            handleNavigation('https://www.mtree.co.in/')
                        }
                        className='flex justify-center items-center'
                    >
                        <img
                            src={companyLogo}
                            alt='company logo'
                            className='w-10 h-auto md:w-12 lg:w-14'
                            draggable='false'
                        />
                    </button>
                    <div className='border-l border-texts-secondary300 h-6 md:h-8 lg:h-10 mx-2 hidden md:flex'></div>
                    <button onClick={() => handleNavigation('/')}>
                        <img
                            src={appLogo}
                            alt='app logo'
                            className='w-10 h-auto md:w-12 lg:w-14'
                            draggable='false'
                        />
                    </button>
                </div>
                <div className='flex md:hidden justify-center w-full'>
                    <button onClick={() => handleNavigation('/')}>
                        <img
                            src={appLogo}
                            alt='Logo2'
                            className='w-10 h-auto md:w-12 lg:w-14'
                            draggable='false'
                        />
                    </button>
                </div>
            </div>

            <div className='hidden md:flex flex-grow justify-left'>
                {menuItems.map((item, index) => (
                    <button
                        key={index}
                        className='font-bold mx-2 lg:mx-4 hover:text-backgrounds-primary400'
                        onClick={() => {
                            if (item.name === MENUITEMS.HOME) {
                                handleNavigation('/');
                            } else {
                                handleNavigation(null, item.ref);
                            }
                        }}
                    >
                        {item.name}
                    </button>
                ))}
            </div>

            <div className='flex'>
                <div className='md:hidden flex items-center'>
                    <Button
                        className='font-bold hover:text-backgrounds-primary400 mr-1 drop-shadow-sm text-backgrounds-primary300'
                        onClick={() => handleNavigation('/signup')}
                        icon={faUserPlus}
                    />
                    <Button
                        className='font-bold hover:text-backgrounds-primary400  drop-shadow-sm text-backgrounds-primary300'
                        onClick={() => handleNavigation('/login')}
                        icon={faSignInAlt}
                    />
                </div>

                <div className='hidden md:flex items-center'>
                    <Button
                        className='font-semibold text-texts-primary px-4 py-2 rounded-xl bg-backgrounds-primary300 border-backgrounds-white border-2 flex items-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-primary300 hover:text-backgrounds-white ml-2 lg:ml-4'
                        onClick={() => handleNavigation('/signup')}
                        icon={faUserPlus}
                        text='Sign Up'
                        hoverText={'Sign up'}
                    />
                    <Button
                        className='font-semibold text-texts-primary px-4 py-2 rounded-xl bg-backgrounds-primary300 border-backgrounds-white border-2 flex items-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-primary300 hover:text-backgrounds-white ml-2 lg:ml-4'
                        onClick={() => handleNavigation('/login')}
                        icon={faSignInAlt}
                        text='Log In'
                        hoverText={'Log In'}
                    />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
