import {
    faFile,
    faFileCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const FileListViewerSqllite = ({ selectedFiles }) => {
    return (
        <div className='border rounded-xl bg-backgrounds-white'>
            <div className='font-semibold border-b py-2 px-3 rounded-t-xl'>
                <FontAwesomeIcon
                    icon={faFile}
                    className='mr-2 text-texts-secondary300'
                />
                Selected File
            </div>
            <ul className='text-texts-secondary300 p-3 max-h-28 overflow-auto'>
                {selectedFiles && selectedFiles.length ? (
                    selectedFiles.map((file, index) => (
                        <li key={index}>
                            {index + 1}. {file.name}
                        </li>
                    ))
                ) : (
                    <div className='flex justify-center items-center text-texts-secondary300'>
                        <FontAwesomeIcon
                            icon={faFileCircleExclamation}
                            className='mr-2 text-icons-secondary300'
                        />
                        No file selected
                    </div>
                )}
            </ul>
        </div>
    );
};
