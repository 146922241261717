import React, { useEffect, useState } from 'react';
import {
    faAdd,
    faFile,
    faIcons,
    faStop,
    faTrash,
    faTriangleExclamation,
    faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { Table } from 'antd';
import {
    Button,
    Dropdown,
    DropdownListType,
    Input,
} from '../reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileIcon, getFileIconColor, isStringInArray } from '../../utils';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { useRef } from 'react';

const pageSize = 6;

const columns = [
    {
        title: (
            <span className='font-semibold text-texts-secondary300'>
                <FontAwesomeIcon icon={faFile} className='mr-2' />
                File Name
            </span>
        ),
        dataIndex: 'file',
        key: 'file',
        sorter: (a, b) => {
            return a['fileName']
                .toString()
                .localeCompare(b['fileName'].toString());
        },
    },
    {
        title: (
            <span className='font-semibold text-texts-secondary300'>
                <FontAwesomeIcon icon={faIcons} className='mr-2' />
                Product Category
            </span>
        ),
        dataIndex: 'productCategory',
        key: 'productCategory',
        width: 250,
    },
    {
        title: (
            <span className='font-semibold text-texts-secondary300'>
                <FontAwesomeIcon icon={faProductHunt} className='mr-2' />
                Products
            </span>
        ),
        dataIndex: 'products',
        key: 'products',
        width: 400,
    },
    {
        title: (
            <span className='font-semibold text-texts-secondary300'>
                <FontAwesomeIcon icon={faStop} className='mr-2' />
                Actions
            </span>
        ),
        dataIndex: 'actions',
        key: 'actions',
        width: 180,
    },
];

const handleAddProductCategory = (
    newProductCategory,
    productCategoriesAndProducts,
    setProductCategoriesAndProducts,
    setNewCategory
) => {
    if (newProductCategory && newProductCategory.trim()) {
        const productCategories = productCategoriesAndProducts.map(
            (data) => data.productCategory
        );

        if (!isStringInArray(productCategories, newProductCategory)) {
            const newData = {
                productCategory: newProductCategory,
                products: [],
            };
            const newProductCategoriesAndProducts = [
                newData,
                ...productCategoriesAndProducts,
            ];
            setProductCategoriesAndProducts(newProductCategoriesAndProducts);
            setNewCategory('');
        } else {
            alert('Product category already exists in list!');
            return;
        }
    } else {
        alert('Please provide a new product category!');
        return;
    }
};

const handleAddProduct = (
    newProduct,
    productCategory,
    productCategoriesAndProducts,
    setProductCategoriesAndProducts,
    setNewProduct
) => {
    if (newProduct && newProduct.trim()) {
        const products = productCategoriesAndProducts.find(
            (data) => data.productCategory === productCategory
        ).products;

        if (!isStringInArray(products, newProduct)) {
            const newProductCategoriesAndProducts = [
                ...productCategoriesAndProducts,
            ];
            newProductCategoriesAndProducts.forEach((data) => {
                if (data.productCategory === productCategory) {
                    data.products.unshift(newProduct);
                }
            });
            setProductCategoriesAndProducts(newProductCategoriesAndProducts);
            setNewProduct('');
        } else {
            alert(`Product already exists for ${productCategory} category!`);
            return;
        }
    } else {
        alert('Please provide a new product category!');
        return;
    }
};

const handleProductCategoryChange = (
    fileId,
    productCategory,
    fileProductMappings,
    setFileProductMappings
) => {
    const productMappingString = JSON.stringify(fileProductMappings);
    const updatedProductMapping = JSON.parse(productMappingString);

    updatedProductMapping[fileId] = {
        documentId: fileId,
        productCategory: productCategory,
        products: [],
    };

    setFileProductMappings(updatedProductMapping);
};

const handleProductChange = (
    fileId,
    product,
    fileProductMappings,
    setFileProductMappings
) => {
    const productMappingString = JSON.stringify(fileProductMappings);
    const updatedProductMapping = JSON.parse(productMappingString);

    let products = updatedProductMapping[fileId]['products'];
    if (!products) {
        updatedProductMapping[fileId]['products'] = [];
        products = updatedProductMapping[fileId]['products'];
    }

    const foundIndex = products.indexOf(product);
    if (foundIndex > -1) {
        // Already present then remove
        products.splice(foundIndex, 1);
    } else {
        // Not found then add
        products.push(product);
    }

    setFileProductMappings(updatedProductMapping);
};

const handleRemoveMapping = (
    fileId,
    fileProductMappings,
    setFileProductMappings
) => {
    const productMappingString = JSON.stringify(fileProductMappings);
    const updatedProductMapping = JSON.parse(productMappingString);

    delete updatedProductMapping[fileId];
    setFileProductMappings(updatedProductMapping);
};

const getProductCategory = (
    fileId,
    fileProductMappings,
    productCategoriesAndProducts,
    setFileProductMappings
) => {
    const selectedProductCategory = fileProductMappings[fileId]
        ? fileProductMappings[fileId].productCategory
        : null;

    const productCategories = [];

    if (productCategoriesAndProducts && productCategoriesAndProducts.length) {
        productCategoriesAndProducts
            .sort((a, b) =>
                a['productCategory']
                    .toString()
                    .localeCompare(b['productCategory'].toString())
            )
            .forEach((productDetail, index) => {
                const productCategoryData = {
                    key: index,
                    label: productDetail.productCategory,
                    icon: productDetail.icon,
                    iconColor: productDetail.icon,
                    selected:
                        productDetail.productCategory ===
                        selectedProductCategory,
                    action: () => {
                        handleProductCategoryChange(
                            fileId,
                            productDetail.productCategory,
                            fileProductMappings,
                            setFileProductMappings
                        );
                    },
                };
                productCategories.push(productCategoryData);
            });
        return productCategories;
    }
};

const getProducts = (
    repoId,
    fileId,
    productDetailsMap,
    selectedProductCategory,
    selectedProducts,
    fileProductMappings,
    setFileProductMappings,
    productRepoMappings
) => {
    const products = productDetailsMap.get(selectedProductCategory);
    if (products && products.length) {
        const productsData = products
            .sort((a, b) => a.toString().localeCompare(b.toString()))
            .map((product, index) => {
                const mappingKey = `${selectedProductCategory}-${product}`;
                const isMappedToAnotherRepo =
                    productRepoMappings[mappingKey] &&
                    productRepoMappings[mappingKey] !== repoId;
                return {
                    key: index,
                    label: product,
                    icon: isMappedToAnotherRepo ? faTriangleExclamation : null,
                    iconColor: null,
                    selected: !!selectedProducts.find(
                        (sProduct) => sProduct === product
                    ),
                    action: () => {
                        handleProductChange(
                            fileId,
                            product,
                            fileProductMappings,
                            setFileProductMappings
                        );
                    },
                    disabled: isMappedToAnotherRepo,
                };
            });
        return productsData;
    }
};

const handleKeyDown = (event, buttonRef) => {
    if (event.key === 'Enter') {
        buttonRef?.current?.click(); // Programmatically click the button
    }
};

const AddProductCategory = ({
    newCategory,
    setNewCategory,
    productCategoriesAndProducts,
    setProductCategoriesAndProducts,
    isSmallScreen,
}) => {
    const buttonRef = useRef(null);

    return (
        <div className='w-full flex pb-1 items-center'>
            <Input
                placeholder={'New Category Name'}
                type={'text'}
                value={newCategory}
                onChange={(e) => {
                    const category = e.target.value;
                    setNewCategory(category);
                }}
                autoFocus={true}
                onKeyDown={(event) => handleKeyDown(event, buttonRef)}
            />
            <Button
                ref={buttonRef}
                text={!isSmallScreen ? 'Add' : null}
                className='ml-2 text-icons-primary'
                onClick={async (event) => {
                    handleAddProductCategory(
                        newCategory,
                        productCategoriesAndProducts,
                        setProductCategoriesAndProducts,
                        setNewCategory
                    );
                    event.stopPropagation();
                }}
                hoverText={'Add new product category'}
                icon={faAdd}
                type={'success'}
                disabled={!newCategory}
            />
        </div>
    );
};

const AddProduct = ({
    newProduct,
    setNewProduct,
    selectedProductCategory,
    productCategoriesAndProducts,
    setProductCategoriesAndProducts,
    isSmallScreen,
}) => {
    const buttonRef = useRef(null);

    return (
        <div className='flex pb-1 items-center'>
            <Input
                placeholder={'New Product Name'}
                type={'text'}
                value={newProduct}
                onChange={(e) => {
                    const product = e.target.value;
                    setNewProduct(product);
                }}
                autoFocus={true}
                onKeyDown={(event) => handleKeyDown(event, buttonRef)}
            />
            <Button
                ref={buttonRef}
                text={!isSmallScreen ? 'Add' : null}
                className='ml-2 text-icons-primary'
                onClick={async (event) => {
                    handleAddProduct(
                        newProduct,
                        selectedProductCategory,
                        productCategoriesAndProducts,
                        setProductCategoriesAndProducts,
                        setNewProduct
                    );
                    event.stopPropagation();
                }}
                hoverText={'Add new product'}
                icon={faAdd}
                type={'success'}
                disabled={!newProduct}
            />
        </div>
    );
};

export const FileList = ({
    repoId,
    productCategoriesAndProducts,
    fileProductMappings,
    setProductCategoriesAndProducts,
    setFileProductMappings,
    allReposFilesData,
    productRepoMappings,
    isSmallScreen,
}) => {
    const [filesData, setFilesData] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [newProduct, setNewProduct] = useState('');

    useEffect(() => {
        if (allReposFilesData && repoId) {
            const repoData = allReposFilesData.get(repoId);
            if (repoData) {
                const files = repoData.filesData;
                if (files) {
                    setFilesData(files);
                }
            }
        }

        return () => {};
    }, [allReposFilesData, repoId]);

    const productDetailsMapData = new Map();

    if (productCategoriesAndProducts && productCategoriesAndProducts.length) {
        productCategoriesAndProducts.forEach((productDetail, index) => {
            productDetailsMapData.set(
                productDetail.productCategory,
                productDetail.products
            );
        });
    }

    const mappingDataSource = filesData.map((file, index) => {
        const mapping = fileProductMappings[file.document_id];
        let selectedProductCategory = null;
        let selectedProducts = null;

        if (mapping) {
            selectedProductCategory = mapping.productCategory;
            selectedProducts = mapping.products;
        }

        const categoryDropDownList = getProductCategory(
            file.document_id,
            fileProductMappings,
            productCategoriesAndProducts,
            setFileProductMappings
        );

        const productDropDownList = getProducts(
            repoId,
            file.document_id,
            productDetailsMapData,
            selectedProductCategory,
            selectedProducts,
            fileProductMappings,
            setFileProductMappings,
            productRepoMappings
        );

        const selectProductString = selectedProducts
            ? selectedProducts
                  .sort((a, b) => a.toString().localeCompare(b.toString()))
                  .toString()
                  .replace(/,/g, ', ')
            : [];

        return {
            key: index,
            fileName: file.name,
            file: (
                <span className='text-texts-secondary300'>
                    <FontAwesomeIcon
                        icon={getFileIcon(file.name)}
                        color={getFileIconColor(file.name)}
                        className='mr-2'
                    />
                    {file.name}
                </span>
            ),
            productCategory: (
                <div className='w-full flex justify-between items-center'>
                    {selectedProductCategory && (
                        <div className='mr-2 text-texts-secondary300'>
                            {selectedProductCategory}
                        </div>
                    )}
                    <Dropdown
                        dropDownList={categoryDropDownList}
                        showButtonText={true}
                        buttonText={
                            isSmallScreen ? (
                                <FontAwesomeIcon icon={faPencil} />
                            ) : selectedProductCategory ? (
                                'Edit'
                            ) : (
                                'Select Category'
                            )
                        }
                        listTitleText={
                            <AddProductCategory
                                newCategory={newCategory}
                                setNewCategory={setNewCategory}
                                productCategoriesAndProducts={
                                    productCategoriesAndProducts
                                }
                                setProductCategoriesAndProducts={
                                    setProductCategoriesAndProducts
                                }
                                isSmallScreen={isSmallScreen}
                            />
                        }
                        buttonHoverText={'Select Product Category'}
                        buttonClassName={'text-texts-tertiary400 rounded-xl'}
                        disabled={false}
                        placement={isSmallScreen ? 'bottom' : 'bottomLeft'}
                    />
                </div>
            ),
            products: (
                <div className='w-full flex justify-between items-center'>
                    {selectedProducts && selectedProducts.length !== 0 ? (
                        <div className='mr-2 text-texts-secondary300'>
                            {selectProductString}
                        </div>
                    ) : selectedProducts && selectedProducts.length === 0 ? (
                        <div className='mr-2 text-texts-secondary300'>
                            <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className='mr-2 text-texts-red400'
                            />
                            No Product Selected
                        </div>
                    ) : (
                        <></>
                    )}
                    <Dropdown
                        dropDownList={productDropDownList}
                        showButtonText={true}
                        buttonText={
                            isSmallScreen ? (
                                <FontAwesomeIcon icon={faPencil} />
                            ) : selectedProducts && selectedProducts.length ? (
                                'Edit'
                            ) : (
                                'Select Products'
                            )
                        }
                        listTitleText={
                            <AddProduct
                                newProduct={newProduct}
                                setNewProduct={setNewProduct}
                                selectedProductCategory={
                                    selectedProductCategory
                                }
                                productCategoriesAndProducts={
                                    productCategoriesAndProducts
                                }
                                setProductCategoriesAndProducts={
                                    setProductCategoriesAndProducts
                                }
                                isSmallScreen={isSmallScreen}
                            />
                        }
                        buttonHoverText={
                            selectedProducts && selectedProducts.length
                                ? 'Select or Unselect Products'
                                : 'Select Products'
                        }
                        buttonClassName={'text-texts-tertiary400 rounded-xl'}
                        disabled={!selectedProductCategory}
                        listType={DropdownListType.CHECKBOX}
                        closeOnListAction={false}
                        placement={isSmallScreen ? 'bottom' : 'bottomRight'}
                    />
                </div>
            ),
            actions: (
                <div className='w-full flex justify-start items-center'>
                    <Button
                        text={!isSmallScreen ? 'Remove Mapping' : null}
                        className='text-icons-primary'
                        onClick={async (event) => {
                            handleRemoveMapping(
                                file.document_id,
                                fileProductMappings,
                                setFileProductMappings
                            );
                            event.stopPropagation();
                        }}
                        hoverText={
                            fileProductMappings[file.document_id]
                                ? `Remove product mappings from '${file.name}' file`
                                : 'Disabled as no product mapping assigned'
                        }
                        icon={faTrash}
                        type={'success'}
                        disabled={!fileProductMappings[file.document_id]}
                    />
                </div>
            ),
        };
    });

    return (
        <div className={isSmallScreen ? 'w-fit' : 'w-full'}>
            <Table
                dataSource={mappingDataSource}
                columns={columns}
                pagination={
                    filesData.length <= pageSize
                        ? false
                        : { pageSize: pageSize }
                }
                bordered
            />
        </div>
    );
};
