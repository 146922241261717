import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { appName } from './Constants';
import { LandingPageButton } from '../../components/reusableComponents';
import { getUrl } from '../../components/pdfDisplay/utils';

const Layout = () => {
    const useSecuredConnection = process.env.REACT_APP_USE_SECURED_CONNECTION;
    const handleClick = (url) => {
        if (url.charAt(0) === '/') {
            const host = process.env.REACT_APP_HOST;
            const port = process.env.REACT_APP_PORT;
            console.log(`${host}:${port}`);
            if (useSecuredConnection) {
                window.open(getUrl(`http://${host}${url}`), '_self');
            } else {
                window.open(getUrl(`http://${host}:${port}${url}`), '_self');
            }
        } else {
            window.location.href = url;
        }
    };

    return (
        <div className='flex flex-col mt-16 sm:mt-24 md:mt-24 md:flex-row md:mx-8 lg:mx-20 rounded-xl bg-backgrounds-primary100'>
            <div className='flex flex-col items-center justify-center p-8 sm:p-12 md:p-16 lg:p-20 w-full md:w-1/2 rounded-xl'>
                <div className='mb-6 md:mb-8 text-center'>
                    <h1 className='text-2xl md:text-3xl lg:text-4xl font-bold text-texts-secondary'>
                        {appName}
                    </h1>
                </div>
                <div className='mb-8 md:mb-12 text-center text-texts-secondary'>
                    <p className='text-sm md:text-base lg:text-lg'>
                        Engage in dynamic discussions while seamlessly
                        integrating multiple PDF and DOCX files directly into
                        the conversation. Whether it's referencing specific
                        sections or analyzing content collaboratively, users can
                        effortlessly share and interact with various documents
                        without interruption.
                    </p>
                </div>
                <LandingPageButton
                    className='bg-backgrounds-primary300 border-backgrounds-white border-2 flex items-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary200 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 md:p-3 rounded-lg mb-6 md:mb-8 text-texts-primary font-bold mr-2'
                    onClick={() => handleClick('/signup')}
                    text='Start Trial Today'
                    icon={faArrowRight}
                />
            </div>
            <div className='flex justify-center items-center p-8 sm:p-12 md:p-16 lg:p-20 rounded-xl'>
                <img
                    src={process.env.PUBLIC_URL + '/LandingPage1.png'}
                    alt='LP'
                    className='rounded-lg w-full h-auto max-w-full max-h-96 object-cover'
                    draggable='false'
                />
            </div>
        </div>
    );
};

export default Layout;
