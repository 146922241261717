import React, { useEffect, useState } from 'react';
import { SingleNotificationsView } from './singleNotificationView';
import axios from 'axios';
import { LoadingView } from '../reusableComponents';
import { TaskProgressType } from '../../constants/taskProgressTypes';
import { NoNotificationView } from './noNotificationView';
import { getUrl } from '../pdfDisplay/utils';

const getNotificationHistory = async (user) => {
    try {
        if (!user) {
            throw new Error('User data missing!');
        }
        if (user && !user.email) {
            throw new Error('User email missing!');
        }
        if (user && !user.token) {
            throw new Error('Invalid user!');
        }
        
        const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getNotificationHistory?email=${user.email}`);

        const encodedToken = encodeURIComponent(user.token);
        const response = await axios.get(dynamicUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: encodedToken,
            },
        });

        if (response) {
            const notificationHistory = response.data.notificationHistory;
            if (notificationHistory) {
                const formattedHistory = notificationHistory.map(
                    (notification) => {
                        const row = {
                            id: notification.id,
                            userMail: notification.user_mail,
                            repoId: notification.repo_id,
                            repoName: notification.repo_name,
                            process: notification.process,
                            progress: notification.progress,
                            percentageCompletion:
                                notification.progress ===
                                TaskProgressType.COMPLETED
                                    ? 100
                                    : 0,
                            message: notification.message,
                            timeStamp: notification.timestamp,
                        };
                        return row;
                    }
                );
                return formattedHistory;
            } else {
                return [];
            }
        }
    } catch (error) {
        alert('Error: ', error.message);
        return [];
    }
};

const createId = (message) => {
    const userMail = message.userMail ? message.userMail : message.user_mail;
    const repoName = message.repoName ? message.repoName : message.repo_name;
    const process = message.process;
    const id = `${userMail}-${repoName}-${process}`;
    return id;
};

const getUpdatedNotification = (notifications) => {
    const messageMap = new Map();
    notifications.forEach((notification) => {
        const key = createId(notification);
        messageMap.set(key, notification);
    });

    return [...messageMap.values()];
};

const NotificationsComponent = ({
    socket,
    notifications,
    sortNotification,
    reactKey,
}) => {
    if (sortNotification) {
        const key = sortNotification['key'];
        return notifications
            .sort((a, b) => {
                if (sortNotification.descending) {
                    return b[key].localeCompare(a[key]);
                } else {
                    return a[key].localeCompare(b[key]);
                }
            })
            .map((notification, index) => (
                <SingleNotificationsView
                    socket={socket}
                    notification={notification}
                    key={reactKey + index}
                />
            ));
    }
    return notifications.map((notification, index) => (
        <SingleNotificationsView
            socket={socket}
            notification={notification}
            key={reactKey + index}
        />
    ));
};

const HistoryComponent = ({ history, isLoading, sortNotification }) => {
    if (isLoading) {
        return (
            <div className='w-full h-40 flex justify-center items-center'>
                <LoadingView
                    loadingText={'Loading notification history'}
                    inLine={false}
                />
            </div>
        );
    }

    if (sortNotification) {
        const key = sortNotification['key'];
        return history
            .sort((a, b) => {
                if (sortNotification.descending) {
                    return b[key].localeCompare(a[key]);
                } else {
                    return a[key].localeCompare(b[key]);
                }
            })
            .map((notification, index) => (
                <SingleNotificationsView
                    notification={notification}
                    key={'history' + index}
                />
            ));
    }

    return history.map((notification, index) => (
        <SingleNotificationsView notification={notification} key={index} />
    ));
};

export const NotificationsView = ({
    socket,
    notifications,
    showNotifications,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));

    const [notificationHistory, setNotificationHistory] = useState([]);
    const [onGoingProcessNotifications, setOnGoingProcessNotifications] =
        useState([]);
    const [finishedProcesses, setFinishedProcesses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const notificationStartRef = React.createRef();

    const scrollToLatestNotification = (notificationStartRef) => {
        notificationStartRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const fetchNotificationHistory = async (user) => {
        if (notificationHistory.length === 0) {
            setIsLoading(true);
        }
        const history = await getNotificationHistory(user);
        setFinishedProcesses([]);
        setNotificationHistory(history);

        setIsLoading(false);
    };

    useEffect(() => {
        if (showNotifications) {
            scrollToLatestNotification(notificationStartRef);
        }

        return () => {};
    }, [notifications]);

    useEffect(() => {
        if (notifications.length) {
            // For Ongoing notification
            const onGoingProcesses = [];
            const updatedNotifications = getUpdatedNotification(notifications);
            updatedNotifications.forEach((notification) => {
                if (notification.progress === TaskProgressType.PROCESSING) {
                    onGoingProcesses.push(notification);
                }
            });
            setOnGoingProcessNotifications(onGoingProcesses);

            // For Finished notification
            const lastNotification = notifications[notifications.length - 1];
            if (
                lastNotification.progress &&
                lastNotification.progress !== TaskProgressType.PROCESSING &&
                lastNotification.progress !== TaskProgressType.STARTED
            ) {
                const newFinishedProcesses = [...finishedProcesses];
                newFinishedProcesses.push(lastNotification);
                setFinishedProcesses(newFinishedProcesses);
            }
        }
    }, [notifications]);

    useEffect(() => {
        if (showNotifications) {
            (async () => {
                await fetchNotificationHistory(user);
            })();
        }
    }, [showNotifications]);

    if (
        !isLoading &&
        notifications.length === 0 &&
        notificationHistory.length === 0
    ) {
        return <NoNotificationView />;
    }

    return (
        <div
            className={`${
                window.innerWidth < 1024
                    ? 'w-[90%] max-w-[17rem] min-h-[20rem] max-h-[28rem] overflow-auto'
                    : 'w-[26rem] min-h-52 max-h-[30rem] overflow-auto'
            } border rounded-xl shadow-inner p-3 bg-backgrounds-slate`}
        >
            {/* Reference div to auto scroll to it on new notification received */}
            <div ref={notificationStartRef} className='mb-1' />

            <NotificationsComponent
                socket={socket}
                notifications={onGoingProcessNotifications}
                sortNotification={{ key: 'repoName' }}
                reactKey={'onGoingProcess'}
            />

            <NotificationsComponent
                socket={socket}
                notifications={finishedProcesses}
                sortNotification={{ key: 'repoName' }}
                reactKey={'finishedProcess'}
            />

            <HistoryComponent
                history={notificationHistory}
                sortNotification={{ key: 'timeStamp', descending: true }}
                isLoading={isLoading}
            />
        </div>
    );
};
