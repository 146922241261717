import { getFileFormat } from './getFileFormat';

export const getFileIconColor = (fileName) => {
    const fileFormat = getFileFormat(fileName).toLowerCase();
    if (fileFormat === 'pdf') return 'red';
    else if (fileFormat === 'doc' || fileFormat === 'docx') return '#38bdf8';
    else if (
        fileFormat === 'txt' ||
        fileFormat === 'csv' ||
        fileFormat === 'py'
    )
        return '#22c55d';
    else if (fileFormat === 'json') return '#ffd602';
    else if (fileFormat === 'db') return 'gold';
    else return 'texts-secondary';
};
