import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const NoNotificationView = () => {
    return (
        <div className='w-[36rem] h-40 flex flex-col justify-center items-center border rounded-xl shadow-inner'>
            <FontAwesomeIcon
                icon={faBoxOpen}
                size='2x'
                className='mb-2 drop-shadow text-texts-secondary300'
            />
            <div className='text-texts-secondary300 font-semibold text-sm'>
                No Notification
            </div>
        </div>
    );
};
