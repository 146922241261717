// import logo from './images/QuyvaLogo.png';

// App info.
export const appName = 'Quyva';
export const appURL = 'http://quyva.mtree.co.in/';
export const contactEmail = 'quyva@mtree.co.in';
export const contactNumber = '';
// export const appLogo = logo;
export const appLogo = `${process.env.PUBLIC_URL}/QuyvaLogo.png`;
export const companyLogo = `${process.env.PUBLIC_URL}/mtree-logo.png`;
export const appVersion = '1.0.0';
