import React from 'react';
import { Input } from '../../reusableComponents';

const maxTitleLength = 20;

export const ReportHeader = ({
    reportHeaderName,
    AppData,
    CompanyData,
    templateName,
    layoutName,
    enableEditing,
    setReportHeaderName,
    iconClassName,
}) => {
    return (
        <div className='px-12 pb-1'>
            <div
                className='border-b px-2 py-4'
                style={{
                    width: '100%',
                    height: '80px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#e0f2fe',
                    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                }}
            >
                {/* Branding */}
                <div
                    style={{
                        width: 'width: 33.333333%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                    }}
                >
                    <img
                        style={{ width: '45px' }}
                        className={iconClassName}
                        src={AppData.appLogo}
                        alt='camera'
                        draggable='false'
                    />

                    <div
                        className='font-sans'
                        style={{
                            color: 'rgb(100 116 139)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <span className='font-semibold mr-2 text-xl'>
                                {AppData.appName}
                            </span>
                            <span className='text-xs'>
                                v{AppData.appVersion}
                            </span>
                        </div>
                        <div className='text-xs'>
                            © {CompanyData.CompanyDetails.NAME}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        width: '33.333333%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {/* Report Title */}
                    {enableEditing ? (
                        <Input
                            value={reportHeaderName}
                            type={'text'}
                            className={
                                'w-full h-10 py-0.5 font-semibold text-texts-secondary300'
                            }
                            onChange={(e) => {
                                setReportHeaderName(e.target.value);
                            }}
                            maxLength={maxTitleLength}
                            hoverText={`Edit report header title`}
                        />
                    ) : (
                        <div
                            className='text-xl font-semibold drop-shadow-sm break-words truncate'
                            style={{
                                color: 'rgb(100 116 139)',
                                maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            title={reportHeaderName}
                        >
                            {reportHeaderName}
                        </div>
                    )}

                    {/* Powered by */}
                    <div
                        className='text-xs mt-1'
                        style={{
                            color: 'rgb(100 116 139)',
                        }}
                    >
                        <span>Powered by</span>
                        <span className='ml-1'>
                            {CompanyData.CompanyDetails.POWERED_BY}
                        </span>
                    </div>
                </div>

                {/* Other Details */}
                <div
                    style={{
                        width: '33.333333%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'end',
                    }}
                >
                    <div
                        className='break-words pb-0.5'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '13rem',
                        }}
                    >
                        <span
                            className='text-xs mr-2'
                            style={{ color: 'rgb(148 163 184)' }}
                        >
                            Layout:
                        </span>
                        <span
                            className='text-xs font-semibold break-words'
                            style={{
                                color: 'rgb(100 116 139)',
                            }}
                        >
                            {layoutName}
                        </span>
                    </div>

                    <div
                        className='break-words pb-0.5'
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '13rem',
                        }}
                    >
                        <span
                            className='text-xs mr-2'
                            style={{ color: 'rgb(148 163 184)' }}
                        >
                            Template:
                        </span>
                        <span
                            className='text-xs font-semibold break-words'
                            style={{ color: 'rgb(100 116 139)' }}
                        >
                            {templateName}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReportHeader.defaultProps = {
    iconClassName: 'mr-1',
};
