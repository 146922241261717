import React, { useState } from 'react';
import {
    faCheckCircle,
    faCircleXmark,
    faClose,
    faFileExport,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InputModal = ({ parameters, onSubmit, onCancel }) => {
    const uniqueParameters = Array.from(
        new Set(parameters.map((param) => param.name))
    ).map((name) => parameters.find((param) => param.name === name));

    const [inputs, setInputs] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        const allFilled = uniqueParameters.every((param) => inputs[param.name]);
        if (!allFilled) {
            alert('The values are mandatory to fill for extraction');
            return;
        }
        onSubmit(inputs);
    };

    return (
        <div className='fixed top-0 left-0 min-h-screen w-screen flex justify-center items-center bg-backgrounds-black bg-opacity-40 z-50 border backdrop-filter backdrop-blur-sm overflow-auto'>
            <div className='relative flex flex-col w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] bg-backgrounds-white p-6 rounded-xl shadow-lg overflow-y-auto max-h-[90vh]'>
                <Button
                    icon={faClose}
                    type={'danger'}
                    title='Close'
                    onClick={() => onCancel()}
                    className={'absolute top-4 right-5'}
                />
                <div className='w-full mb-4 text-texts-secondary300'>
                    <div className='flex items-center font-semibold text-lg'>
                        <FontAwesomeIcon
                            icon={faFileExport}
                            className='mr-2 text-texts-tertiary'
                        />
                        Enter Extraction Parameters
                    </div>
                </div>
                <div className='w-full bg-backgrounds-slate border rounded-xl overflow-y-auto p-4'>
                    {uniqueParameters.map((param) => (
                        <div className='mb-4' key={param.name}>
                            <label
                                className='block text-gray-700 text-sm font-bold mb-2'
                                htmlFor={`param-${param.name}`}
                            >
                                {param.label}
                            </label>
                            <input
                                className='bg-backgrounds-white w-full p-2.5 rounded-xl border hover:border-backgrounds-primary300 focus:outline-backgrounds-primary200'
                                type='text'
                                placeholder={`Enter ${param.name}`}
                                name={param.name}
                                value={inputs[param.name] || ''}
                                onChange={handleChange}
                            />
                        </div>
                    ))}
                </div>
                <div className='flex items-center justify-center mt-4'>
                    <div className='flex items-center justify-between w-full'>
                        <Button
                            className='w-1/3 min-w-[120px]'
                            type={'danger'}
                            onClick={onCancel}
                            icon={faCircleXmark}
                            text={'Cancel'}
                            hoverText={'Cancel the above action'}
                        />
                        <Button
                            className={`w-1/3 min-w-[120px]`}
                            type='success'
                            title='Submit parameters'
                            onClick={handleSubmit}
                            icon={faCheckCircle}
                            text={'Confirm'}
                            hoverText={'Accept the above action'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
