import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LayoutDesigner } from './layoutDesigner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMobileAlt,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { getUrl } from '../pdfDisplay/utils';

const ReportLayoutsDesignerView = ({
    user,
    selectedLayout,
    selectedTemplate,
}) => {
    const [layout, setLayout] = useState([]);
    const [templateData, setTemplateData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    const handleSave = async (layoutData) => {
        try {
            setIsSaving(true);
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}`
            );

            const data = {
                email: user.email,
                templateName: selectedTemplate.filename,
                layoutName: selectedLayout.name,
                layoutData: layoutData,
            };

            const encodedToken = encodeURIComponent(user.token);

            const response = await axios.post(
                `${dynamicUrl}/saveLayout`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                }
            );

            if (response) {
                console.log(response.data.message);
                setLayout(layoutData);
            }

            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
            console.error('Error saving layout:', error);
        }
    };

    const getLayoutData = async () => {
        try {
            setIsLoading(true);
            setLoadingText('Loading Layout Data...');

            const email = user.email;
            const layoutName = selectedLayout.name;
            const templateName = selectedTemplate.filename;
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getLayoutData?email=${email}&templateName=${templateName}&layoutName=${layoutName}`
            );

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                const layoutData = response.data;

                if (layoutData) {
                    setLayout(layoutData);
                }
            }

            setIsLoading(false);
            setLoadingText('');
        } catch (error) {
            alert(error.message);
            setIsLoading(false);
            setLoadingText('');
        }
    };

    const getTemplateData = async () => {
        try {
            setLoadingText('Loading Template Data...');
            setIsLoading(true);

            const email = user.email;
            const templateName = selectedTemplate.filename;
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getTemplateData?email=${email}&templateName=${templateName}`
            );

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response && response.data.templateData) {
                const templateData = response.data.templateData;
                setTemplateData(templateData);
            }

            setIsLoading(false);
            setLoadingText('');
        } catch (error) {
            alert(error.message);
            setIsLoading(false);
            setLoadingText('');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (selectedLayout) {
                await getLayoutData();
                await getTemplateData();
            }
        })();

        return () => {};
    }, [selectedLayout]);

    if (isMobile) {
        return (
            <div className='bg-backgrounds-slate w-full h-full flex items-center justify-center'>
                <div className='flex flex-col items-center text-center text-texts-secondary300 p-4'>
                    <FontAwesomeIcon
                        icon={faMobileAlt}
                        className='w-16 h-16 mb-4 text-icons-primary100'
                    />
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className='w-12 h-12 mb-2 text-icons-tertiary200'
                    />
                    <h1 className='text-xl font-semibold'>
                        Page not available on small screen mode
                    </h1>
                    <p className='text-lg mt-2'>
                        Please switch to a larger screen to access this content.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <LayoutDesigner
            layout={layout}
            templateData={templateData}
            selectedTemplate={selectedTemplate}
            selectedLayout={selectedLayout}
            isLoading={isLoading}
            loadingText={loadingText}
            isSaving={isSaving}
            onSaveLayout={(layout) => {
                handleSave(layout);
            }}
        />
    );
};

export default ReportLayoutsDesignerView;
