// Function to get all root level unique keys
export const getRootLevelFields = (data, rootKeys = new Set()) => {
    for (const key in data) {
        if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
                if (typeof item === 'object') {
                    getRootLevelFields(item, rootKeys);
                } else if (typeof item === 'string') {
                    rootKeys.add(key);
                }
            });
        } else if (typeof data[key] === 'object') {
            getRootLevelFields(data[key], rootKeys);
        } else {
            rootKeys.add(key);
        }
    }

    return Array.from(rootKeys);
};
