import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Brand } from './brand';
import { Input } from './reusableComponents';
import { PoweredByInfo } from './powereByComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRightToBracket,
    faEye,
    faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import googleLogo from '../images/google2.png';
import { useAuth } from '../context/AuthContext';
import {
    GoogleLogin,
    useGoogleLogin,
    getAuthResponse,
} from '@react-oauth/google';
import { getEmailPattern, isEmailValid } from '../utils';
import { getUrl } from './pdfDisplay/utils';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const { login, authenticated } = useAuth();
    console.log(authenticated);
    const googleLogin = useGoogleLogin({
        onSuccess: (credentialResponse) => {
            async function sendData() {
                console.log(credentialResponse);
                
                const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/google_login`);
                const response = await fetch(dynamicUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(credentialResponse),
                    credentials: 'include',
                });

                if (response.ok) {
                    const userData = await response.json();
                    console.log('Login successful', userData);
                    login(userData);
                } else {
                    const errorData = await response.json();
                    console.error('Login failed', errorData);
                    alert(`Login failed: ${errorData.message}`);
                }
            }
            sendData();
        },
        onError: () => {
            console.log('Login Failed');
        },
        flow: 'auth-code',
    });

    const Finalize = async (e) => {
        e.preventDefault();
        try {
            if (!email && !password) {
                setEmailValid(false);
                setPasswordValid(false);
            }
            if (!email) {
                alert('Email address field is empty!');
                setEmailValid(false);
                return;
            } else {
                setEmailValid(true);
            }
            if (isEmailValid(email)) {
                setEmailValid(true);
            } else {
                alert('Please enter a valid email address!');
                setEmailValid(false);
                return;
            }

            if (!password) {
                alert('Password field is empty!');
                setPasswordValid(false);
                return;
            } else {
                setPasswordValid(true);
            }

            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_login`);
            const response = await fetch(dynamicUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include',
            });

            if (response.ok) {
                const userData = await response.json();
                console.log('Login successful', userData);
                login(userData);
                // localStorage.setItem('current', JSON.stringify(userData));
                // navigate('/chats');

                // Consider using React Router for navigation
                //history.push('/chats');
            } else {
                const errorData = await response.json();
                console.error('Login failed', errorData);
                alert(`Login failed: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error during login', error);
            alert('An error occurred during login. Please try again.');
        }
    };

    return (
        <div className='flex items-center justify-center min-h-screen bg-gradient-primary'>
            <div className='w-full max-w-[500px] flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                <div
                    className='mb-8 w-full w-3/5 mx-auto'
                    onClick={() => {
                        if (path !== '/login') {
                            navigate('/login');
                        }
                    }}
                >
                    <Brand iconClassName={'w-20 h-20 mr-3'} />
                </div>
                <div className='flex flex-col text-sm w-full'>
                    <form onSubmit={Finalize}>
                        <Input
                            className={`mb-5 rounded-xl border p-3 w-full hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm ${
                                !emailValid && 'border-red-500'
                            }`}
                            type='email'
                            placeholder='Email id'
                            pattern={getEmailPattern().source}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className='relative w-full mb-5'>
                            <Input
                                className={`rounded-xl border p-3 w-full hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm ${
                                    !passwordValid && 'border-red-500'
                                }`}
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {password && (
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer font-semibold text-texts-secondary300'
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                />
                            )}
                        </div>
                        <button
                            className='flex w-full font-semibold items-center justify-center bg-buttons-primary border border-1 shadow-inner hover:bg-gradient-buttons hover:border-buttons-tertiary hover:text-texts-primary p-3 mt-2 rounded-lg'
                            type='submit'
                            // onClick={Finalize}
                        >
                            <FontAwesomeIcon
                                icon={faRightToBracket}
                                className='mr-1.5 drop-shadow-sm text-texts-primary'
                            />
                            <span className='drop-shadow-sm text-texts-primary'>
                                Login
                            </span>
                        </button>
                    </form>
                    <div style={{ marginTop: 15 }}>
                        <button
                            className='w-full rounded-lg border px-3 py-2.5 mt-1  flex justify-center items-center text-texts-secondary200 shadow-inner'
                            onClick={() => googleLogin()}
                        >
                            <span>
                                <img
                                    className='max-w-3 max-h-3 mr-2'
                                    src={googleLogo}
                                    alt='camera'
                                    draggable='false'
                                />
                            </span>
                            Sign in with Google
                        </button>
                    </div>
                </div>

                <div className='mt-4 p-1 flex justify-between text-sm font-semibold text-texts-tertiary'>
                    <a href='/forgot-password'>Forgot password?</a>
                    <Link to='/signup'>Sign up</Link>
                </div>

                <div className='w-full flex justify-center items-center mt-8'>
                    <PoweredByInfo />
                </div>
            </div>
        </div>
    );
};

export default Login;
