import React, { useEffect, useState } from 'react';
import { Button, LoadingView } from '../reusableComponents';
import { faFolder, faSave, faShare } from '@fortawesome/free-solid-svg-icons';
import { getUrl } from '../pdfDisplay/utils';
import axios from 'axios';
import { Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileList } from './fileMappingList';

const repoMappingData = [
    {
        productCategory: 'productCategory1',
        product: 'product1',
        repoId: 'repoId1',
    },
    {
        productCategory: 'productCategory1',
        product: 'product2',
        repoId: 'repoId2',
    },
];

const fetchProductCategoriesAndProducts = async (user) => {
    try {
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/get-user-product-categories-and-products/${user.email}`
        );
        const encodedToken = encodeURIComponent(user.token);
        const response = await axios.get(dynamicUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${encodedToken}`,
            },
        });

        const data = await response.data.userProductCategoriesAndProducts;
        if (data) {
            return data;
        }
        return [];
    } catch (error) {
        throw new Error(error);
    }
};

const fetchFileProductMappings = async (user, repoId) => {
    try {
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/get-file-product-mappings/${repoId}`
        );
        const encodedToken = encodeURIComponent(user.token);
        const response = await axios.get(dynamicUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${encodedToken}`,
            },
        });

        const data = await response.data.repoFileProductMappings;
        if (data) {
            return data;
        }
        return [];
    } catch (error) {
        throw new Error(error);
    }
};

const saveFileProductMappings = async (
    user,
    setIsLoading,
    setLoadingText,
    repoId,
    fileProductMappings,
    productCategoriesAndProducts,
    getRepoProductMappings
) => {
    try {
        setIsLoading(true);
        const encodedToken = encodeURIComponent(user.token);

        setLoadingText(`Updating Product Category And Products ...`);
        const productsUpdateDynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/update-product-categories-and-products`
        );

        const productsUpdateResponse = await axios.post(
            productsUpdateDynamicUrl,
            {
                user_id: user.email,
                userProductCategoriesAndProducts: productCategoriesAndProducts,
            },

            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${encodedToken}`,
                },
            }
        );

        setLoadingText(`Saving Product Mappings ...`);
        const fileProductMappingData = Object.values(fileProductMappings);
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/update-file-product-mappings`
        );
        const saveMappingResponse = await axios.post(
            dynamicUrl,
            {
                repo_id: repoId,
                mappings: fileProductMappingData,
                token: user.token,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${encodedToken}`,
                },
            }
        );

        await getRepoProductMappings();

        setIsLoading(false);
        setLoadingText('');
    } catch (error) {
        setIsLoading(false);
        setLoadingText('');
        alert(error.message);
    }
};

const RepoMappingList = ({
    selectedRepo,
    productCategoriesAndProducts,
    fileProductMappings,
    setProductCategoriesAndProducts,
    setFileProductMappings,
    allReposFilesData,
    productRepoMappings,
    isSmallScreen,
}) => {
    let mappedFiles = 0;
    let totalFiles = 0;

    Object.keys(fileProductMappings).forEach((fileId) => {
        if (fileProductMappings[fileId].products.length) {
            mappedFiles++;
        }
    });

    if (allReposFilesData && allReposFilesData.get(selectedRepo.repoid)) {
        totalFiles = allReposFilesData.get(selectedRepo.repoid).filesData
            .length;
    }

    return (
        <div className='w-full flex flex-col'>
            {totalFiles !== 0 && (
                <div className='w-full flex items-center justify-center p-2 border-b'>
                    <div className='text-texts-secondary300 mx-1'>
                        {mappedFiles === 0 ? (
                            <div className='font-semibold'>No File Mapped</div>
                        ) : (
                            <>
                                <span className='font-bold mr-1.5'>
                                    {mappedFiles}/{totalFiles}
                                </span>
                                {mappedFiles > 1 ? 'Files' : 'File'} Mapped With
                                Product(s)
                            </>
                        )}
                    </div>
                </div>
            )}

            <div className='w-full flex items-center justify-between'>
                <FileList
                    repoId={selectedRepo.repoid}
                    productCategoriesAndProducts={productCategoriesAndProducts}
                    setProductCategoriesAndProducts={
                        setProductCategoriesAndProducts
                    }
                    fileProductMappings={fileProductMappings}
                    setFileProductMappings={setFileProductMappings}
                    allReposFilesData={allReposFilesData}
                    productRepoMappings={productRepoMappings}
                    isSmallScreen={isSmallScreen}
                />
            </div>
        </div>
    );
};

// Main Component
export const RepoProductMappings = ({
    user,
    selectedRepo,
    sharedRepos,
    getRepoFilesData,
    allReposFilesData,
    productRepoMappings,
    getRepoProductMappings,
    isSmallScreen,
}) => {
    const [fileProductMappings, setFileProductMappings] = useState({});
    const [productCategoriesAndProducts, setProductCategoriesAndProducts] =
        useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [isShared, setIsShared] = useState(false);

    useEffect(() => {
        if (
            user &&
            user.email &&
            user.token &&
            selectedRepo &&
            selectedRepo.repoid
        ) {
            const init = async (user, repoId) => {
                try {
                    setIsLoading(true);

                    // Product Category and Products
                    setLoadingText(
                        'Loading Product Categories And Products ...'
                    );
                    const categoryAndProductData =
                        await fetchProductCategoriesAndProducts(user);
                    setProductCategoriesAndProducts(categoryAndProductData);

                    // Files data in repos
                    setLoadingText('Loading Files Data ...');
                    await getRepoFilesData(repoId, false);

                    // File and product mappings
                    setLoadingText(`Fetching Product Mappings ...`);
                    const mappingData = await fetchFileProductMappings(
                        user,
                        repoId
                    );
                    const mappings = {};
                    mappingData.forEach((mappingData) => {
                        mappings[mappingData.documentId] = { ...mappingData };
                    });
                    setFileProductMappings(mappings);

                    setIsLoading(false);
                    setLoadingText('');
                } catch (error) {
                    setIsLoading(false);
                    setLoadingText('');
                    alert(error.message);
                }
            };

            for (let index = 0; index < sharedRepos.length; index++) {
                const repo = sharedRepos[index];
                if (repo.repoid === selectedRepo.repoid) {
                    setIsShared(true);
                    break;
                }
            }

            init(user, selectedRepo.repoid);
        }

        return () => {};
    }, [selectedRepo, sharedRepos]);

    if (isLoading) {
        return (
            <div className='w-[70vw] h-[79vh] flex justify-center items-center'>
                <LoadingView loadingText={loadingText} inLine={false} />
            </div>
        );
    }

    let isSaveDisabled = false;
    const mappedFiles = Object.keys(fileProductMappings);
    for (let index = 0; index < mappedFiles.length; index++) {
        const mappingData = fileProductMappings[mappedFiles[index]];
        if (mappingData && mappingData.products.length === 0) {
            isSaveDisabled = true;
            break;
        }
    }

    return (
        <div
            className={
                isSmallScreen
                    ? 'w-[100vw] h-[100vh] flex flex-col justify-center'
                    : 'w-[70vw] h-[79vh] flex flex-col justify-center'
            }
        >
            {/* Header */}
            <div className='flex w-full min-h-fit h-[6vh] justify-between items-center py-2 px-3 bg-backgrounds-primary100 shadow'>
                <div className='flex justify-center items-center font-semibold text-texts-secondary300'>
                    <FontAwesomeIcon
                        icon={faFolder}
                        className='text-texts-tertiary200 mr-2'
                    />
                    {selectedRepo.name}
                    {isShared && (
                        <div className='font-semibold ml-4 flex items-center'>
                            <Tag className='text-texts-tertiary400 bg-backgrounds-secondary'>
                                <FontAwesomeIcon
                                    icon={faShare}
                                    className='mr-1'
                                />
                                Shared
                            </Tag>
                        </div>
                    )}
                </div>

                <div className='flex justify-center items-center'>
                    <Button
                        text={isSmallScreen ? null : 'Save'}
                        className='ml-2 text-icons-primary'
                        onClick={async (event) => {
                            if (selectedRepo && selectedRepo.repoid) {
                                await saveFileProductMappings(
                                    user,
                                    setIsLoading,
                                    setLoadingText,
                                    selectedRepo.repoid,
                                    fileProductMappings,
                                    productCategoriesAndProducts,
                                    getRepoProductMappings
                                );
                            }

                            event.stopPropagation();
                        }}
                        hoverText={
                            isSaveDisabled
                                ? 'A file is mapped with Product Category but not Products'
                                : 'Save all the mapping changes'
                        }
                        icon={faSave}
                        type={'success'}
                        disabled={isSaveDisabled}
                    />
                </div>
            </div>
            {/* Mapping List */}
            <div
                className={
                    isSmallScreen
                        ? 'w-full h-full overflow-y-auto'
                        : 'w-full h-[73vh] overflow-y-auto'
                }
            >
                <RepoMappingList
                    selectedRepo={selectedRepo}
                    productCategoriesAndProducts={productCategoriesAndProducts}
                    setProductCategoriesAndProducts={
                        setProductCategoriesAndProducts
                    }
                    fileProductMappings={fileProductMappings}
                    setFileProductMappings={setFileProductMappings}
                    allReposFilesData={allReposFilesData}
                    productRepoMappings={productRepoMappings}
                    isSmallScreen={isSmallScreen}
                />
            </div>
        </div>
    );
};
