import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getUrl } from '../components/pdfDisplay/utils';

// Create the context
export const DefaultPromptContext = createContext();

// Create a provider component
export const DefaultPromptProvider = ({ children }) => {
    const [defaultPromptValue, setDefaultPromptValue] = useState('');

    // Function to fetch the default prompt value from the backend
    const fetchDefaultPrompt = async () => {
        try {
            const response = await axios.get(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_default_prompt_value`
                )
            );
            setDefaultPromptValue(response.data.default_value);
        } catch (error) {
            alert('Error fetching default prompt value:', error);
        }
    };

    useEffect(() => {
        fetchDefaultPrompt();
    }, []);

    return (
        <DefaultPromptContext.Provider value={defaultPromptValue}>
            {children}
        </DefaultPromptContext.Provider>
    );
};
