import React, { useEffect, useState } from 'react';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import ReactGridLayout from 'react-grid-layout';
import { ReportCard } from './reportCard';
import { pageWidth } from '../constants';

const minCardHeight = 2;

export const ReportViewer = ({ layout, enableEditing }) => {
    const [completeLayout, setCompleteLayout] = useState(layout);
    const [metaData, setMetaData] = useState(new Map());
    const [selectedFieldsData, setSelectedFieldsData] = useState(new Map());
    const [chartsData, setChartsData] = useState(new Map());
    const [layoutData, setLayoutData] = useState([]);

    useEffect(() => {
        if (layout) {
            setCompleteLayout(layout);
        }
    }, [layout]);

    useEffect(() => {
        if (completeLayout) {
            const newMetaData = new Map();
            const newSelectedFieldsData = new Map();
            const newChartsData = new Map();

            const newLayoutData = [];

            layout.forEach((data) => {
                const id = data.layoutData.i;

                newMetaData.set(id, data.metaData);
                newSelectedFieldsData.set(id, data.selectedFields);
                newChartsData.set(id, data.chartData);

                newLayoutData.push(data.layoutData);
            });

            setMetaData(newMetaData);
            setSelectedFieldsData(newSelectedFieldsData);
            setChartsData(newChartsData);

            setLayoutData(newLayoutData);
        }
    }, [completeLayout]);

    // Function to remove a card from view.
    const removeCard = (id) => {
        const copyMetaData = new Map(metaData);
        copyMetaData.delete(id);
        setMetaData(copyMetaData);

        const copySelectedFieldsData = new Map(selectedFieldsData);
        copySelectedFieldsData.delete(id);
        setSelectedFieldsData(copySelectedFieldsData);

        const copyChartsData = new Map(chartsData);
        copyChartsData.delete(id);
        setChartsData(copyChartsData);

        const newLayoutData = layoutData.filter((data) => data.i !== id);
        setLayoutData(newLayoutData);
    };

    // console.log('###############################################');
    // console.log('Layout', layout);
    // console.log('Meta Data', metaData);
    // console.log('Layout Data', layoutData);
    // console.log('Selected Fields Data', selectedFieldsData);
    // console.log('chart Data', chartsData);

    const cards = React.useMemo(() => {
        return layoutData.map((layout, idx) => (
            <div
                key={layout.i}
                data-grid={{
                    x: layout.x,
                    y: layout.y,
                    w: layout.w,
                    h: layout.h,
                    minW: layout.minW,
                    minH: minCardHeight,
                    isDraggable: enableEditing,
                    isResizable: enableEditing,
                }}
                className={`z-10 select-none bg-backgrounds-white ${
                    enableEditing ? 'cursor-move' : ''
                } `}
            >
                <ReportCard
                    layout={layout}
                    metaData={metaData}
                    chartsData={chartsData}
                    enableEditing={enableEditing}
                    removeCard={removeCard}
                />
            </div>
        ));
    }, [layoutData, enableEditing, metaData, chartsData]);

    return (
        <ReactGridLayout
            className='layout'
            rowHeight={40}
            width={pageWidth}
            cols={12}
            containerPadding={[50, 5]}
            resizeHandles={['se', 'sw']}
            onLayoutChange={(layout) => setLayoutData(layout)}
        >
            {cards}
        </ReactGridLayout>
    );
};
