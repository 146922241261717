export function base64ToBlob(base64, type = 'application/octet-stream') {
    try {
        // Check if base64 string has a valid data URL prefix and remove it
        if (base64.startsWith('data:')) {
            const parts = base64.split(',');
            if (parts.length !== 2) {
                throw new Error('Invalid base64 string format');
            }
            // Decode the base64 part (after the comma)
            base64 = parts[1];
        }

        // Ensure the base64 string is correctly padded
        const padding = base64.length % 4;
        if (padding > 0) {
            base64 += '='.repeat(4 - padding); // Add padding if necessary
        }

        // Decode base64 string to binary
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type });
    } catch (error) {
        console.log(error);
    }
}
