import React, { useEffect, useState } from "react";
import { Card, Button, Badge, Switch, Modal, message } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { loadPaymentScript } from '../utils/loadPaymentScript';
import { getUrl } from './pdfDisplay/utils';
import { usePlanData } from '../hooks/usePlanData';
import { calculateYearlyPrice, getPlanPriority, SUBSCRIPTION_PLANS, YEARLY_DISCOUNT_PERCENTAGE } from '../utils/planUtils';
import { Currency } from '../constants/currency';
import { LoadingView } from './reusableComponents';

const PricingPageOnLogin =  ()  => {
    const { plans, error } = usePlanData();
    const [planName , setPlanName]= useState(SUBSCRIPTION_PLANS.FREE)
    const [isYearly, setIsYearly] = useState(true);
    const { user } = useAuth();
    const [showDowngradeModal, setShowDowngradeModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

    useEffect(() => {
      if (error) {
        message.error(error);
      }
    }, [error]);

    useEffect(() => {
      const fetchPlanName = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/current-plan/${encodeURIComponent(user.email)}`));
          setPlanName(response.data.name);
        } catch (error) {
          console.error('Error fetching plan:', error);
          message.error('Failed to fetch current plan details');
          setPlanName(SUBSCRIPTION_PLANS.FREE);
        } finally {
          setIsLoading(false);
        }
      };
      fetchPlanName();
    }, [user]);

  const handleCheckout = async (amount) => {
    try {
      if (!amount) {
        throw new Error('Please enter a valid amount.');
      }
      setIsCheckoutLoading(true);
      const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/create-checkout-session`);
      const authToken = encodeURIComponent(user.token);
      const response = await axios.post(
          dynamicUrl,
          {
              amount: amount,
              currency: user.currency,
          },
          {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: authToken,
              },
          }
      );
      if (response) {
          let data = {
              amount: response.data.amount,
              order_id: response.data.id,
              currency: response.data.currency,
          };
          loadPaymentScript(user, data);
      }
    } catch (err) {
      console.error('Error processing payment:', err);
      message.error(err.message || 'Payment failed. Please try again.');
    } finally {
      setIsCheckoutLoading(false);
    }
  };

  const handlePlanSelection = (plan, amount) => {
    try {
      if (getPlanPriority(plan.name) < getPlanPriority(planName)) {
        setSelectedPlan(plan);
        setShowDowngradeModal(true);
      } else {
        handleCheckout(amount);
      }
    } catch (error) {
      console.error('Error selecting plan:', error);
      message.error('Failed to process plan selection');
    }
  };

  const handleDowngradeConfirm = async () => {
    try {
      const amount = isYearly 
        ? (user.currency === Currency.USD.key 
          ? calculateYearlyPrice(selectedPlan.price, selectedPlan.name) 
          : calculateYearlyPrice(selectedPlan.price, selectedPlan.name) * 100)
        : (user.currency === Currency.USD.key 
          ? selectedPlan.price 
          : selectedPlan.price * 100);
      
      await handleCheckout(amount);
      setShowDowngradeModal(false);
    } catch (error) {
      console.error('Error confirming downgrade:', error);
      message.error('Failed to process downgrade request');
    }
  };

  return (
    <div className='flex flex-col items-center max-w-[1440px] mx-auto px-2'>
      {isLoading ? (
        <div className="flex justify-center items-center w-full py-8">
          <LoadingView loadingText="Loading subscription plans..." />
        </div>
      ) : (
        <>
          <div className="mt-8 pt-4 flex items-center justify-center gap-6">
            <span className={`${!isYearly ? 'font-bold' : 'text-gray-500'} text-sm`}>Monthly</span>
            <Switch
              checked={isYearly}
              onChange={setIsYearly}
              className="bg-backgrounds-primary300"
            />
            <span className={`${isYearly ? 'font-bold' : 'text-gray-500'} text-sm`}>
              Yearly <span className="text-green-500">(Save {YEARLY_DISCOUNT_PERCENTAGE}%)</span>
            </span>
          </div>
          
          <div className='flex flex-wrap justify-center items-stretch gap-4 p-4 w-full'>
            {plans.map((plan, index) => (
              <Badge.Ribbon
                key={index}
                text={plan.name === planName ? "Current" : plan.is_most_popular ? "Most Popular" : ""}
                color={plan.name === planName ? "yellow" : plan.is_most_popular ? "blue" : "white"}
              >
                <Card
                  className="w-64 h-[420px] rounded-xl shadow-md hover:shadow-lg transition duration-200 flex flex-col flex-shrink-0"
                  title={plan.name}
                  headStyle={{
                    backgroundColor: plan.is_most_popular ? "#e0f7ff" : "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: '8px',
                  }}
                  bodyStyle={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '12px',
                  }}
                >
                  <div className="text-center mb-1">
                    <div className="text-2xl font-semibold">
                      {Currency[user.currency].symbol}
                      {plan.name === SUBSCRIPTION_PLANS.FREE 
                        ? 0 
                        : (isYearly 
                          ? (user.currency === Currency.USD.key 
                            ? calculateYearlyPrice(plan.price, plan.name).toFixed(2)
                            : (calculateYearlyPrice(plan.price, plan.name) * 100).toFixed(2))
                          : (user.currency === Currency.USD.key 
                            ? plan.price.toFixed(2)
                            : (plan.price * 100).toFixed(2))
                        )}
                    </div>
                    <div className="text-xs text-gray-500">
                      {plan.name === SUBSCRIPTION_PLANS.FREE 
                        ? "forever" 
                        : (isYearly 
                          ? `${Currency[user.currency].symbol}${
                              user.currency === Currency.USD.key
                                ? (calculateYearlyPrice(plan.price, plan.name) / 12).toFixed(2)
                                : (calculateYearlyPrice(plan.price, plan.name) / 12 * 100).toFixed(2)
                            }/mo, billed yearly`
                          : "per month"
                        )}
                    </div>
                  </div>
                  <div className="text-center text-xs text-gray-500 mb-3">
                    {plan.description}
                  </div>
                  <ul className="mb-4 text-sm space-y-1">
                    {plan.features.map((feature, i) => (
                      <li
                        key={i}
                        className={`flex items-start ${feature === "" ? "invisible " : "gap-2"}`}
                      >
                        <span className="text-green-500 mt-0.5">
                          <CheckOutlined style={{ fontSize: '12px' }} />
                        </span>
                        <span className="text-gray-800 text-sm">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  {plan.name !== SUBSCRIPTION_PLANS.FREE && <Button
                    type="primary"
                    onClick={() => handlePlanSelection(plan, isYearly 
                        ? user.currency === Currency.USD.key 
                            ? calculateYearlyPrice(plan.price, plan.name) 
                            : calculateYearlyPrice(plan.price, plan.name) * 100 
                        : user.currency === Currency.USD.key 
                            ? plan.price 
                            : plan.price * 100)}
                    disabled={isCheckoutLoading || plan.name === planName}
                    className={`w-full font-semibold text-sm px-3 py-1.5 rounded-lg ${
                      plan.name === planName 
                        ? 'bg-gray-200 cursor-not-allowed' 
                        : 'bg-backgrounds-primary300 border-backgrounds-white border-2 flex items-center hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-primary300 hover:text-backgrounds-white'
                    }`}
                  >
                    {plan.name === planName 
                      ? "Current Plan"
                      : getPlanPriority(plan.name) < getPlanPriority(planName)
                        ? `Downgrade to ${plan.name}`
                        : `Upgrade to ${plan.name}`}
                  </Button>}
                </Card>
              </Badge.Ribbon>
            ))}
          </div>
        </>
      )}

      <Modal
        title="Downgrade Confirmation"
        open={showDowngradeModal}
        onOk={handleDowngradeConfirm}
        onCancel={() => setShowDowngradeModal(false)}
        okText="Confirm Downgrade"
        cancelText="Cancel"
      >
        <p>Your downgrade to {selectedPlan?.name} plan will take effect at the end of your current billing period. Until then, you'll continue to have access to your current plan's features.</p>
      </Modal>
    </div>
  );
} ;

export default PricingPageOnLogin;
