import React, { useEffect, useState } from 'react';
import { Button, ConfirmModal, ProgressBar } from '../reusableComponents';
import { TaskProgressType } from '../../constants/taskProgressTypes';
import {
    faBell,
    faCancel,
    faClose,
    faFileExport,
    faFileUpload,
    faFolderPlus,
    faExclamationTriangle,
    faCircleXmark,
    faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { ProcessType } from '../../constants/processTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationsColors } from '../../styles/notificationsColors';
import { convertUTCToLocal } from '../../utils';

// Status structure
//{
// message: 'Processing!';
// percentageCompletion: 60;
// process: 'file upload';
// progress: 'processing';
// repoId: '3';
// repoName: 'Gama Repo';
// statusCode: 200;
// timeStamp: '2024-05-14T21:46:23.463Z';
// userMail: 'shubham.singh@mtree.co.in';
//}

// time formatting options
const timeFormatOptions = {
    // weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
    // timeZoneName: 'short',
};

export const SingleNotificationsView = ({ socket, notification, key }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // Date time formatting
    let date = null;
    let time = null;
    const validDateTime = new Date(notification.timeStamp);
    if (validDateTime) {
        const localDateTime = convertUTCToLocal(
            validDateTime,
            timeFormatOptions
        );
        const localTimeString = localDateTime.toString();
        const timeDateData = localTimeString.split(',');
        const year = timeDateData[1];

        const dayMonthData = timeDateData[0].split(' ');
        const day = dayMonthData[1];
        const month = dayMonthData[0];

        const formattedDate = `${day} ${month} ${year}`;
        const formattedTime = timeDateData[2];

        if (formattedDate) {
            date = formattedDate;
        }

        if (formattedTime) {
            time = formattedTime;
        }
    }

    // Notification status formatting
    let status = '';
    let iconColor = NotificationsColors.SUCCESS;
    let textIcon = faCircleCheck;
    if (
        notification.progress === TaskProgressType.PROCESSING ||
        notification.progress === TaskProgressType.STARTED
    ) {
        status = 'active';
        iconColor = NotificationsColors.ONGOING;
        textIcon = null;
    } else if (notification.progress === TaskProgressType.FAILED) {
        status = 'exception';
        iconColor = NotificationsColors.FAIL;
        textIcon = faCircleXmark;
    } else if (notification.progress === TaskProgressType.CANCELLING) {
        status = 'active';
        iconColor = NotificationsColors.CANCELLING;
        textIcon = faCancel;
    } else if (notification.progress === TaskProgressType.CANCELLED) {
        status = 'exception';
        iconColor = NotificationsColors.CANCELLED;
        textIcon = faCancel;
    } else if (notification.progress === TaskProgressType.WARNING) {
        iconColor = NotificationsColors.WARNING;
        textIcon = faExclamationTriangle;
    }

    // Notification icon formatting
    let icon = faBell;
    if (notification.process === ProcessType.EXTRACTION) {
        icon = faFileExport;
    } else if (notification.process === ProcessType.FILE_UPLOAD) {
        icon = faFileUpload;
    } else if (notification.process === ProcessType.REPO_CREATION) {
        icon = faFolderPlus;
    }

    return (
        <div
            key={key}
            className={`w-full min-h-16 flex flex-col md:flex-row items-start md:items-center p-2 text-texts-secondary300 border shadow-sm rounded-xl mb-1.5 bg-backgrounds-white`}
        >
            <div className='h-full w-full md:w-1/6 flex justify-start md:justify-center items-center mb-2 md:mb-0'>
                <FontAwesomeIcon
                    icon={icon}
                    color={iconColor}
                    size='2x'
                    className='drop-shadow'
                />
            </div>
            <div className='w-full md:w-5/6 px-2'>
                <div className='w-full flex flex-col md:flex-row justify-between'>
                    <div className='w-full md:w-1/2 font-semibold truncate'>
                        {notification.repoName}
                    </div>
                    <div className='w-full md:w-1/2 flex justify-start md:justify-end items-center text-sm mt-1 md:mt-0'>
                        <div className='keep-all pr-1 border-r'>{date}</div>
                        <div className='keep-all ml-1'>{time}</div>
                    </div>
                </div>

                {!showDetails ? (
                    <div className='w-full flex justify-between mt-2 md:mt-0'>
                        <div className='capitalize'>
                            {`${notification.process} ${notification.progress}`}
                            <FontAwesomeIcon
                                color={iconColor}
                                icon={textIcon}
                                className='ml-1'
                            />
                        </div>
                        <div
                            className='cursor-pointer text-texts-tertiary font-semibold'
                            onClick={(e) => {
                                setShowDetails(true);
                                e.stopPropagation();
                            }}
                        >
                            Details
                        </div>
                    </div>
                ) : (
                    <div className='w-full flex justify-between items-start mt-2 md:mt-0 break-words'>
                        <div>{notification.message}</div>
                        <div
                            className='cursor-pointer text-texts-tertiary font-semibold'
                            onClick={(e) => {
                                setShowDetails(false);
                                e.stopPropagation();
                            }}
                        >
                            Hide
                        </div>
                    </div>
                )}

                {notification.progress &&
                    notification.progress.toLowerCase() ===
                        TaskProgressType.PROCESSING && (
                        <div className='flex items-center justify-between mt-2 md:mt-0'>
                            {notification.percentageCompletion && (
                                <ProgressBar
                                    percentage={
                                        notification.percentageCompletion
                                    }
                                    status={status}
                                    size={'small'}
                                    allowStrokeColor={false}
                                />
                            )}

                            {!notification.percentageCompletion && <div></div>}

                            <Button
                                className={`ml-1 w-[24px] h-[24px]`}
                                onClick={() => {
                                    setShowConfirmModal(true);
                                }}
                                hoverText={`Cancel ${notification.process} on ${notification.repoName}`}
                                icon={faClose}
                                type={'danger'}
                            />
                        </div>
                    )}
            </div>

            {showConfirmModal && (
                <ConfirmModal
                    modalText={`Are you sure you want to cancel ${notification.process} on ${notification.repoName}?`}
                    onConfirm={() => {
                        socket.emit('cancelTask', {
                            userId: notification.userId,
                            repoId: notification.repoId,
                        });
                        setShowConfirmModal(false);
                    }}
                    onCancel={() => {
                        setShowConfirmModal(false);
                    }}
                />
            )}
        </div>
    );
};
