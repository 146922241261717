import React, { useState } from 'react';
import { Button, Input } from '../reusableComponents';
import { Checkbox } from 'antd';
import {
    faAdd,
    faExclamationTriangle,
    faSave,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const addSubfield = (subfields, setSubfields) => {
    setSubfields([
        ...subfields,
        '', // Add subfield as a string
    ]);
};

const deleteSubfields = (
    subfields,
    selectedSubfields,
    setSubfields,
    setSelectedSubfields
) => {
    const updatedSubfields = subfields.filter(
        (_, index) => !selectedSubfields.includes(index)
    );
    setSubfields(updatedSubfields);
    setSelectedSubfields([]); // Clear selection after deletion
};

const handleSubfieldChange = (index, value, subfields, setSubfields) => {
    const updatedSubfields = [...subfields];
    updatedSubfields[index] = value; // Update the string directly
    setSubfields(updatedSubfields);
};

const handleSave = (subfields, onSave) => {
    if (subfields.some((subfield) => subfield.trim() === '')) {
        alert('Subfield names cannot be empty');
        return;
    }
    onSave(subfields); // Pass the updated array of strings back to the parent component
};

const handleSelectSubfield = (
    index,
    selectedSubfields,
    setSelectedSubfields
) => {
    setSelectedSubfields((prevSelected) =>
        prevSelected.includes(index)
            ? prevSelected.filter((i) => i !== index)
            : [...prevSelected, index]
    );
};

const SubfieldsInfo = ({ fieldName, initialSubfields, onSave }) => {
    const [subfields, setSubfields] = useState(initialSubfields || []);
    const [selectedSubfields, setSelectedSubfields] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);

    return (
        <div>
            <div className='flex justify-between items-center m-4'>
                <h2 className='font-bold text-lg'>{`${fieldName}'s Subfields`}</h2>
                <div className='flex items-center'>
                    <Button
                        icon={faAdd}
                        onClick={() => addSubfield(subfields, setSubfields)}
                        text={'Add Subfield'}
                        type={'success'}
                    />
                    <Button
                        onClick={() =>
                            deleteSubfields(
                                subfields,
                                selectedSubfields,
                                setSubfields,
                                setSelectedSubfields
                            )
                        }
                        text={'Delete Subfield(s)'}
                        icon={faTrash}
                        type={'danger'}
                        disabled={selectedSubfields.length === 0} // Disable delete if no subfield is selected
                        className='ml-2'
                    />
                    <Button
                        onClick={() => handleSave(subfields, onSave)}
                        text='Save'
                        type='success'
                        icon={faSave}
                        className='ml-2'
                    />
                </div>
            </div>

            {subfields.length === 0 ? (
                <div className='flex flex-col items-center text-center text-gray-700 p-4'>
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className='mb-2'
                    />
                    <p className='mt-2'>No subfield data available</p>
                </div>
            ) : (
                <div className='grid grid-cols-3 gap-4 m-4'>
                    {subfields.map((subfield, index) => (
                        <div
                            key={index}
                            className='flex items-center space-x-2'
                        >
                            <Checkbox
                                checked={selectedSubfields.includes(index)}
                                onChange={() =>
                                    handleSelectSubfield(
                                        index,
                                        selectedSubfields,
                                        setSelectedSubfields
                                    )
                                }
                            />
                            {editingIndex === index ? (
                                <Input
                                    value={subfield}
                                    onChange={(e) =>
                                        handleSubfieldChange(
                                            index,
                                            e.target.value,
                                            subfields,
                                            setSubfields
                                        )
                                    }
                                    onBlur={() => setEditingIndex(null)} // Stop editing on blur
                                    autoFocus
                                    className='truncate' // Add class to handle truncation
                                    placeholder={
                                        subfield.trim() === ''
                                            ? `Subfield ${index + 1}`
                                            : ''
                                    } // Set placeholder conditionally
                                    onFocus={() =>
                                        handleSubfieldChange(
                                            index,
                                            '',
                                            subfields,
                                            setSubfields
                                        )
                                    } // Clear placeholder on focus
                                />
                            ) : (
                                <div
                                    onClick={() => setEditingIndex(index)}
                                    className='cursor-pointer p-2 border rounded-md hover:border-blue-500 w-full'
                                >
                                    {subfield || `Subfield ${index + 1}`}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SubfieldsInfo;
