import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, TextArea, Button } from '../reusableComponents';
import { getEmailPattern, isEmailValid } from '../../utils';
import {
    faBriefcase,
    faBuilding,
    faEarth,
    faEnvelope,
    faMapPin,
    faPaperPlane,
    faPhone,
    faUserTie,
    faCheckCircle,
    faLeftLong,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoInteractionView } from '../reusableComponents';
import { CompanyDetails } from '../../Constants';
import { getUrl } from '../pdfDisplay/utils';

export const ContactUs = () => {
    const user = JSON.parse(localStorage.getItem('current'));
    const [name, setName] = useState(user?.username || '');
    const [email, setEmail] = useState(user?.email || '');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); // Success message state

    const navigate = useNavigate();

    const isValidForm = () => {
        if (!name) {
            alert('Name is missing');
            return false;
        }
        if (!email) {
            alert('Email is missing');
            return false;
        }
        if (!isEmailValid(email)) {
            alert('Email is invalid');
            return false;
        }
        if (!message) {
            alert('Message is missing');
            return false;
        }
        return true;
    };

    const handleSubmitEmail = async () => {
        if (!isValidForm()) {
            return;
        }
        try {
            const formData = {
                name,
                email,
                phone,
                company: companyName,
                message,
            };

            const encodedToken = encodeURIComponent(user?.token || '');
            setLoading(true);
            const response = await fetch(
                getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/submit-form`),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (response.ok) {
                const result = await response.json();
                console.log('API response:', result);
                setLoading(false);
                setMessage('');
                setCompanyName('');
                setPhone('');
                setSuccessMessage(
                    'Thank you for the query. Our team will contact you shortly!'
                ); // Set the success message
            } else {
                console.error(
                    'API error:',
                    response.status,
                    response.statusText
                );
                setLoading(false);
                setSuccessMessage('Could not send the message');
            }
        } catch (error) {
            setLoading(false);
            console.error('API error:', error.message);
            setSuccessMessage('Could not send the message');
        }
    };

    const sendMessage = () => {
        handleSubmitEmail();
    };

    if (isLoading) {
        return <NoInteractionView viewText={'Sending ...'} />;
    }

    return (
        <div className='flex sm:items-start lg:items-center justify-center w-full min-h-screen bg-backgrounds-primary300 animated-background'>
            <div className='flex flex-col md:flex-row w-full h-fit max-w-screen-lg lg:rounded-xl overflow-hidden shadow-lg'>
                {/* Contact us section */}
                <div className='flex flex-col w-full md:w-1/2 bg-backgrounds-primary100 p-6 lg:rounded-l-xl justify-center'>
                    <div className='text-xl font-semibold text-texts-secondary300 mb-4'>
                        <FontAwesomeIcon icon={faEnvelope} className='mr-2' />
                        Contact Us
                    </div>
                    {successMessage ? (
                        <>
                            <div className='flex flex-col items-center text-texts-secondary p-4 rounded-lg mb-4 justify-center h-full'>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className='text-green-500 text-3xl mb-2'
                                />
                                <div className='text-center'>
                                    {successMessage}
                                </div>
                            </div>
                            <div className='flex w-full justify-center items-center mt-4'>
                                <Button
                                    onClick={() => navigate(-1)}
                                    text='Previous Page'
                                    icon={faLeftLong}
                                    hoverText='Return to the previous page'
                                    className='my-2'
                                    type='warning'
                                    disabled={false}
                                />
                            </div>
                        </>
                    ) : (
                        <div className='flex flex-col text-sm w-full'>
                            <Input
                                type='text'
                                className='my-2'
                                placeholder='Name *'
                                value={name}
                                disabled={!!user} // Disable if user is logged in
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Input
                                type='email'
                                className='my-2'
                                pattern={getEmailPattern().source}
                                onInvalid={(e) =>
                                    e.target.setCustomValidity(
                                        'Please enter a valid email address'
                                    )
                                }
                                onInput={(e) => e.target.setCustomValidity('')}
                                placeholder='Email *'
                                value={email}
                                disabled={!!user} // Disable if user is logged in
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className='flex justify-between items-center'>
                                <Input
                                    type='number'
                                    className='my-2 mr-1'
                                    placeholder='Phone'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <Input
                                    type='text'
                                    className='my-2 ml-1'
                                    placeholder='Company Name'
                                    value={companyName}
                                    onChange={(e) =>
                                        setCompanyName(e.target.value)
                                    }
                                />
                            </div>

                            <div className='h-56'>
                                <TextArea
                                    className='my-2 h-48 max-h-48 min-h-48'
                                    placeholder='Write your message *'
                                    rows={8}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    resize={'none'}
                                />
                            </div>

                            <div className='flex w-full justify-between items-center mt-1'>
                                <Button
                                    onClick={() => navigate(-1)}
                                    text='Previous Page'
                                    icon={faLeftLong}
                                    hoverText='Return to the previous page'
                                    className='my-2 mr-1'
                                    type='warning'
                                    disabled={false}
                                />
                                <Button
                                    onClick={sendMessage}
                                    text='Send Message'
                                    icon={faPaperPlane}
                                    hoverText='Send message directly to us'
                                    className='my-2 ml-1'
                                    type='success'
                                    disabled={false}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className='flex flex-col w-full md:w-1/2 bg-backgrounds-secondaryChat p-6 overflow-y-auto lg:rounded-r-xl'>
                    <div className='pt-2 pb-5 mb-5 border-b-2 border-backgrounds-secondary'>
                        <div className='text-xl font-semibold mb-4'>
                            <FontAwesomeIcon
                                icon={faBriefcase}
                                className='mr-2'
                            />
                            Our Details
                        </div>
                        {CompanyDetails.NAME && (
                            <div className='my-2'>
                                <FontAwesomeIcon
                                    icon={faBuilding}
                                    className='mr-2'
                                />
                                {CompanyDetails.NAME}
                            </div>
                        )}
                        {CompanyDetails.ADDRESS && (
                            <div className='my-2'>
                                <FontAwesomeIcon
                                    icon={faMapPin}
                                    className='mr-2'
                                />
                                {CompanyDetails.ADDRESS}
                            </div>
                        )}
                        {CompanyDetails.PHONE && (
                            <div className='my-2'>
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    className='mr-2'
                                />
                                {CompanyDetails.PHONE}
                            </div>
                        )}
                        {CompanyDetails.EMAIL && (
                            <div className='my-2'>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className='mr-2'
                                />
                                {CompanyDetails.EMAIL}
                            </div>
                        )}
                        {CompanyDetails.WEBSITE && (
                            <div className='my-2'>
                                <FontAwesomeIcon
                                    icon={faEarth}
                                    className='mr-2'
                                />
                                {CompanyDetails.WEBSITE}
                            </div>
                        )}
                    </div>
                    <div className='py-2'>
                        <div className='text-xl font-semibold mb-4'>
                            <FontAwesomeIcon
                                icon={faUserTie}
                                className='mr-2'
                            />
                            About Us
                        </div>
                        {CompanyDetails.ABOUT_US_TITLE && (
                            <div className='my-2 font-semibold'>
                                {CompanyDetails.ABOUT_US_TITLE}
                            </div>
                        )}
                        {CompanyDetails.ABOUT_US_DESCRIPTION && (
                            <div className='my-2 overflow-auto lg:h-32 md:h-32 sm:h-fit'>
                                {CompanyDetails.ABOUT_US_DESCRIPTION}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
