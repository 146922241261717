import React, { useEffect, useState, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IMAGES } from '../../enums';
import { LandingPageButton } from '../../components/reusableComponents';
import { getUrl } from '../../components/pdfDisplay/utils';

const Step = ({ name, number, description, isActive, onClick }) => {
    return (
        <div className={`mb-6 w-full`} onClick={onClick}>
            <div
                className={`ml-4 font-semibold text-lg md:text-xl mb-2 ${
                    isActive ? 'text-texts-tertiary' : 'text-texts-secondary'
                }`}
            >
                {name}
            </div>
            <div className='ml-4 text-texts-secondary300 text-sm md:text-lg'>
                {description}
            </div>
            <div className='border-b border-texts-secondary w-full ml-4 mt-4'></div>
        </div>
    );
};

const ChatSteps = forwardRef((props, ref) => {
    const [selectedStep, setSelectedStep] = useState(1);
    const steps = [
        {
            number: 1,
            name: 'Step 1: Create a Repository',
            description:
                'Create a repository and upload multiple PDF files to it.',
        },
        {
            number: 2,
            name: 'Step 2: Chat with PDF',
            description:
                'Chat with the PDF by asking your questions and receiving answers.',
        },
        {
            number: 3,
            name: 'Step 3: Check Source of Answer',
            description:
                'Easily check the source of answers highlighted in the PDF.',
        },
    ];

    const handleStepClick = (stepNumber) => {
        setSelectedStep(stepNumber);
    };

    const handleClick = (url) => {
        const host = process.env.REACT_APP_HOST;
        const port = process.env.REACT_APP_PORT;
        const useSecuredConnection =
            process.env.REACT_APP_USE_SECURED_CONNECTION;
        if (useSecuredConnection) {
            window.open(getUrl(`http://${host}${url}`), '_self');
        } else {
            // Construct the full URL with the path
            window.open(getUrl(`http://${host}:${port}${url}`), '_self');
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedStep((prevStep) => (prevStep === 3 ? 1 : prevStep + 1));
        }, 10000); // 10 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div
            ref={ref}
            className='mt-8 mx-4 md:mx-8 lg:mx-20 rounded-lg shadow-md bg-backgrounds-primary100'
        >
            <h1 className='font-bold text-2xl md:text-3xl text-texts-secondary mb-8 text-center pt-5'>
                How to chat with PDF online?
            </h1>
            <div style={{ position: 'relative' }}>
                <div
                    className='w-px h-full bg-texts-backgrounds-secondary hidden lg:block'
                    style={{
                        position: 'absolute',
                        marginLeft: 43,
                        zIndex: 1,
                    }}
                />
                <div className='flex flex-col lg:flex-row items-center text-texts-secondary'>
                    <div className='w-full lg:w-1/2 p-4'>
                        {steps.map((step) => (
                            <div className='flex' key={step.number}>
                                <div className='flex flex-col items-center'>
                                    <div
                                        className={`w-6 h-6 rounded-full mb-2 lg:mb-0 ${
                                            selectedStep === step.number
                                                ? 'bg-backgrounds-primary200'
                                                : 'bg-icons-tertiary100'
                                        }`}
                                        style={{
                                            zIndex: 5,
                                        }}
                                        onClick={() =>
                                            handleStepClick(step.number)
                                        }
                                    />
                                </div>
                                <Step
                                    name={step.name}
                                    number={step.number}
                                    description={step.description}
                                    isActive={selectedStep === step.number}
                                    onClick={() => handleStepClick(step.number)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className='w-full lg:w-1/2 flex justify-center items-center pt-10 pb-10'>
                        <div
                            className='bg-backgrounds-primary200'
                            style={{
                                padding: 10,
                                borderRadius: 10,
                                margin: 30,
                            }}
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    `/ChatSteps/${
                                        selectedStep === 1
                                            ? IMAGES.CHAT_STEP_1
                                            : selectedStep === 2
                                            ? IMAGES.CHAT_STEP_2
                                            : IMAGES.CHAT_STEP_3
                                    }`
                                }
                                alt='Step'
                                className='max-w-full max-h-full rounded-xl'
                                draggable='false'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center px-3 py-5'>
                <LandingPageButton
                    className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-full md:w-40 h-12 text-texts-primary font-bold'
                    onClick={() => handleClick('/signup')}
                    text='Start Today'
                    icon={faArrowRight}
                />
            </div>
        </div>
    );
});

export default ChatSteps;
