import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretRight,
    faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { appLogo, appName } from '../Constants';
import { Button } from './reusableComponents';
import { useAuth } from '../context/AuthContext';

export const CollapsedSideBar = ({
    setIsRepoViewExpanded,
    title,
    icon,
    iconColor,
}) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    return (
        <div className='flex flex-col justify-between items-center w-full h-full border border-2 overflow-none py-5 bg-backgrounds-slate rounded-xl z-10'>
            <div className='flex flex-col justify-center items-center'>
                <img
                    className='w-10 h-10 mx-auto mb-2'
                    src={appLogo}
                    alt={appName}
                    draggable='false'
                />
                <Button
                    icon={faCaretRight}
                    onClick={() => setIsRepoViewExpanded(true)}
                    hoverText={'Expand and show repositories view'}
                    type={'warning'}
                />
            </div>

            <div
                className='w-80'
                style={{
                    transform: 'rotate(90deg)',
                }}
            >
                <div
                    className='flex justify-center items-center text-texts-secondary300 break-keep'
                    style={{
                        transform: 'rotate(180deg)',
                        letterSpacing: '1px',
                    }}
                >
                    <FontAwesomeIcon
                        icon={icon}
                        //color={iconColor}
                        className={`mr-2 text-${iconColor}`}
                    />
                    <div className='break-keep font-semibold'>{title}</div>
                </div>
            </div>

            <Button
                icon={faRightFromBracket}
                onClick={() => {
                    // localStorage.clear();
                    // navigate('/login');
                    logout();
                }}
                hoverText={'Log out from current user'}
                type={'danger'}
            />
        </div>
    );
};
