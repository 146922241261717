import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRight,
    faFileCode,
    faFileLines,
    faFilePen,
    faHandPointLeft,
    faArrowDown,
    faHandPointUp,
    faMobileAlt,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

const BeforeSelectView = () => {
    const isSmallScreen = window.innerWidth < 1024;
    const [isVerySmallScreen, setIsVerySmallScreen] = useState(
        window.innerWidth <= 1024
    );

    useEffect(() => {
        const handleResize = () => {
            setIsVerySmallScreen(window.innerWidth <= 1024);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isVerySmallScreen) {
        return (
            <div className='bg-backgrounds-slate w-full h-full flex items-center justify-center'>
                <div className='flex flex-col items-center text-center text-texts-secondary p-4'>
                    <FontAwesomeIcon
                        icon={faMobileAlt}
                        className='w-16 h-16 mb-4 text-icons-primary100'
                    />
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className='w-12 h-12 mb-2 text-icons-tertiary200'
                    />
                    <h1 className='text-xl font-semibold'>
                        Page not available on small screen mode
                    </h1>
                    <p className='text-lg mt-2'>
                        Please switch to a larger screen to access this content.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className='bg-backgrounds-slate w-full h-full rounded-xl flex items-center justify-center'>
            {!isSmallScreen ? (
                <div className='w-full h-full flex flex-col items-center'>
                    <div className='flex h-4/6 w-full items-center justify-center text-texts-secondary300'>
                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <FontAwesomeIcon
                                icon={faFileCode}
                                className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary100'
                            />
                            <div>Expand Layout Collection</div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className='w-[5vw] h-[5vw] m-[3vw] drop-shadow text-icons-tertiary100'
                        />

                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <FontAwesomeIcon
                                icon={faFileLines}
                                className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary100'
                            />
                            <div>Select Layout File</div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className='w-[5vw] h-[5vw] m-[3vw] drop-shadow text-icons-tertiary100'
                        />

                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <FontAwesomeIcon
                                icon={faFilePen}
                                className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary100'
                            />
                            <div>Design/Edit Layout</div>
                        </div>
                    </div>
                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-20'>
                        <FontAwesomeIcon
                            icon={faHandPointLeft}
                            className='mr-3 text-icons-primary'
                        />
                        <div>
                            Select a layout file from the Collection to start
                            designing/editing report layout
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col items-center'>
                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-20 pl-2 pr-2'>
                        <FontAwesomeIcon
                            icon={faHandPointUp}
                            className='mr-3 text-icons-primary'
                        />
                        <div className='text-center'>
                            Select a layout file from the Collection to start
                            designing/editing report layout
                        </div>
                    </div>

                    <div className='flex flex-col items-center justify-center h-4/6 text-texts-secondary300 mt-10'>
                        <div className='flex flex-col items-center justify-center mb-6 drop-shadow'>
                            <FontAwesomeIcon
                                icon={faFileCode}
                                className='w-20 h-20 md:w-24 md:h-24 text-icons-primary100'
                            />
                            <div className='text-base md:text-lg'>
                                Expand Layout Collection
                            </div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowDown}
                            className='w-10 h-10 md:w-12 md:h-12 my-4 drop-shadow text-icons-tertiary100'
                        />
                        <div className='flex flex-col items-center justify-center mb-6 drop-shadow'>
                            <FontAwesomeIcon
                                icon={faFileLines}
                                className='w-20 h-20 md:w-24 md:h-24 text-icons-tertiary'
                            />
                            <div className='text-base md:text-lg'>
                                Select Layout File
                            </div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowDown}
                            className='w-10 h-10 md:w-12 md:h-12 my-4 drop-shadow text-icons-tertiary100'
                        />
                        <div className='flex flex-col items-center justify-center drop-shadow'>
                            <FontAwesomeIcon
                                icon={faFilePen}
                                className='w-20 h-20 md:w-24 md:h-24 text-icons-primary100'
                            />
                            <div className='text-base md:text-lg'>
                                Design/Edit Layout
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BeforeSelectView;
