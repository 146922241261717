import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input } from './reusableComponents';
import { Brand } from './brand';
import { PoweredByInfo } from './powereByComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { getUrl } from './pdfDisplay/utils';

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [validToken, setValidToken] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        if (token) {
            setToken(token);
        } else {
            setValidToken(false);
            console.error('Token not found in URL');
        }
    }, [location]);

    const handleChangePassword = async () => {
        try {
            if (!password || !confirmPassword) {
                alert('Password fields cannot be empty!');
                return;
            }

            if (password !== confirmPassword) {
                alert('Passwords do not match!');
                return;
            }
            
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/reset`);
            const response = await fetch(dynamicUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newPassword: password, token }),
            });

            if (response.ok) {
                alert('Password changed successfully!');
                navigate('/login');
            } else {
                const errorData = await response.json();
                console.error('Error changing password', errorData);
                alert(`Error: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error changing password', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className='flex items-center justify-center min-h-screen animated-background'>
            <div className='w-full max-w-[500px] flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                <div className='mb-8 w-full lg:w-3/5 mx-auto'>
                    <Brand iconClassName={'w-20 h-20 mr-3'} />
                </div>
                {validToken ? (
                    <>
                        <h2 className='text-xl font-bold mb-4 text-center text-black-500'>
                            Change Password
                        </h2>
                        <div className='text-sm font-semibold mb-4 text-center text-backgrounds-primary400'>
                            Enter your new password below.
                        </div>
                        <div className='flex flex-col text-sm w-full'>
                            <Input
                                className='mb-5 rounded-xl border p-3 w-full hover:outline-none focus:outline-backgrounds-primary200 hover:border-backgrounds-primary300 shadow-sm'
                                type='password'
                                placeholder='New Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Input
                                className='mb-5 rounded-xl border p-3 w-full hover:outline-none focus:outline-backgrounds-primary200 hover:border-backgrounds-primary300 shadow-sm'
                                type='password'
                                placeholder='Confirm New Password'
                                value={confirmPassword}
                                onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                }
                            />
                            <button
                                className='flex w-full font-semibold items-center justify-center text-backgrounds-primary500 bg-gradient-to-r from-lime-100 via-backgrounds-primary100 to-sky-100 border border-1 shadow-inner hover:bg-gradient-to-r hover:from-lime-200 hover:via-backgrounds-primary400 hover:to-sky-200 hover:border-backgrounds-primary300 hover:text-white p-3 mt-2 rounded-lg'
                                type='submit'
                                onClick={handleChangePassword}
                            >
                                <FontAwesomeIcon
                                    icon={faKey}
                                    className='mr-1.5'
                                />
                                <span className='drop-shadow-sm'>
                                    {' '}
                                    Change Password{' '}
                                </span>
                            </button>
                        </div>
                    </>
                ) : (
                    <div className='text-center text-buttons-alert400 font-semibold'>
                        The reset password link has expired or is invalid.
                    </div>
                )}
                <div className='mt-5 p-1 text-center text-sm font-semibold text-backgrounds-primary400'>
                    <Link to='/login'>Back to Login</Link>
                </div>
                <div className='w-full flex justify-center items-center mt-8'>
                    <PoweredByInfo />
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
