import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Table, Card } from 'antd';
import { Button } from './reusableComponents'; // Import Button component from reusableComponents
import { generateUniqueNumber } from '../utils'; // Import generateUniqueNumber function from utils
import { getUrl } from './pdfDisplay/utils';

const columns = [
    // {
    //     title: 'S No.',
    //     dataIndex: 'serialNumber',
    //     key: 'serialNumber',
    // },
    {
        title: 'Template Name',
        dataIndex: 'templateName',
        key: 'templateName',
        sorter: (a, b) => a.templateName.localeCompare(b.templateName),
    },
    {
        title: 'Model Name',
        dataIndex: 'modelName',
        key: 'modelName',
        sorter: (a, b) => a.modelName.localeCompare(b.modelName),
    },
    {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.timestamp.localeCompare(b.timestamp),
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
    },
];

export const ExtractDownloadView = ({
    downloadList,
    setShowReportModal,
    setWorkOnTemplate,
    email,
    folderName,
}) => {
    if (downloadList.length === 0) return <></>;

    const handleDownloadCardClick = async (email, folderName, templateName) => {
        try {
            const uniqueNumber = generateUniqueNumber();
            
            const response = await fetch(
              getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/redownloadfile_excell?email=${email}&foldername=${folderName}&uid=${uniqueNumber}&templatename=${templateName}`)
            );
            const disposition = response.headers.get('Content-Disposition');

            if (disposition && disposition.includes('attachment')) {
                const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = fileNameRegex.exec(disposition);
                let filename =
                    matches != null && matches[1] ? matches[1] : 'file.xlsx';
                const originalString = filename;
                const modifiedString = originalString.substring(
                    1,
                    originalString.length - 1
                );

                filename = modifiedString;
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleViewReportCardClick = (templateName) => {
        // Execute actions to view report
        setWorkOnTemplate(templateName);
        setShowReportModal(true);
    };

    // Function to render cards for smaller screens
    const renderCards = (email,folderName) => {
        return downloadList.map((item, index) => (
            <Card key={index} title={item.templateName}>
                <p>
                    <span className='font-semibold'>Model Name:</span>{' '}
                    {item.modelName}
                </p>
                <p className='pt-1'>
                    <span className='font-semibold'>Timestamp:</span>{' '}
                    {item.timestamp}
                </p>
                <div className='flex items-center justify-between pt-3'>
                    <Button
                        text={'Download Data'}
                        className={'m-0'}
                        hoverText={`Download extracted data from ${item.templateName} template`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDownloadCardClick(
                                email,
                                folderName,
                                item.templateName
                            );
                        }}
                        type={'success'}
                    />
                    <Button
                        text={'View Report'}
                        className={'ml-2'}
                        hoverText={`View report for ${item.templateName} template`}
                        // Add onClick handler for viewing report
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleViewReportCardClick(item.templateName);
                        }}
                        type={'success'}
                    />
                </div>
            </Card>
        ));
    };

    return (
        <div className='border rounded-xl bg-backgrounds-white'>
            {/* Title Section */}
            <div className='w-full flex justify-between items-center border-b p-3 rounded-t-xl bg-backgrounds-white'>
                <div className='mx-1 flex justify-between items-center'>
                    <FontAwesomeIcon
                        icon={faFileExport}
                        className='text-texts-tertiary mr-1.5'
                    />
                    <div className='text-texts-secondary300 font-semibold'>
                        Extracted Data
                    </div>
                </div>
            </div>
            {/* Content */}
            <div className='overflow-x-auto max-w-full'>
                {/* Conditionally render Table or Cards based on screen size */}
                {window.innerWidth <= 1024 ? (
                    <div className='pt-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg-backgrounds-secondary'>
                        {renderCards(email,folderName)}
                    </div>
                ) : (
                    <Table
                        pagination={{
                            defaultPageSize: 3,
                            disabled: downloadList.length <= 3,
                        }}
                        dataSource={downloadList}
                        columns={columns}
                    />
                )}
            </div>
        </div>
    );
};
