import React, { useState, useEffect } from 'react';
import { DataTypes } from '../constants';
import { toSentenceCase } from '../utils';

const hasAnotherMultipleSelectedField = (
    fieldData,
    selectedFieldsData,
    fieldsData
) => {
    const found = selectedFieldsData.find((data) => {
        const anotherFieldData = fieldsData.get(data.field);
        if (anotherFieldData.parent === fieldData.parent) {
            if (fieldData.isSelfMultiple && anotherFieldData.isSelfMultiple) {
                return true;
            }
        } else {
            if (fieldData.isSelfMultiple && anotherFieldData.isSelfMultiple) {
                return true;
            }
            if (
                fieldData.isParentMultiple &&
                anotherFieldData.isParentMultiple
            ) {
                return true;
            }
            if (fieldData.isSelfMultiple && anotherFieldData.isParentMultiple) {
                return true;
            }
            if (fieldData.isParentMultiple && anotherFieldData.isSelfMultiple) {
                return true;
            }
        }
        return false;
    });

    if (found) return true;
    return false;
};

const isDisabled = (field, selectedFieldsData, fieldsData) => {
    const isSelected = !!selectedFieldsData.find(
        (data) => data.field === field
    );
    if (isSelected) {
        return false;
    }

    const data = fieldsData.get(field);

    if (
        data &&
        hasAnotherMultipleSelectedField(data, selectedFieldsData, fieldsData)
    ) {
        return true;
    }

    return false;
};

const buildFieldData = (parent, schema, selectedFieldsData, fieldsData) => {
    const schemaKeys = Object.keys(schema);
    let data = null;
    const parentData = fieldsData.get(parent);
    const isParentMultiple =
        parentData && parentData.isSelfMultiple
            ? parentData.isSelfMultiple
            : false;
    schemaKeys.forEach((field) => {
        if (
            typeof schema[field] === 'object' &&
            !Array.isArray(schema[field]) &&
            schema[field] !== null
        ) {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: false,
                isSelectable: false,
            };
            fieldsData.set(field, data);
            buildFieldData(
                field,
                schema[field],
                selectedFieldsData,
                fieldsData
            );
        } else if (
            typeof schema[field] === 'object' &&
            Array.isArray(schema[field]) &&
            typeof schema[field][0] === 'object'
        ) {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: true,
                isSelectable: false,
            };
            fieldsData.set(field, data);
            buildFieldData(
                field,
                schema[field][0],
                selectedFieldsData,
                fieldsData
            );
        } else if (
            (typeof schema[field] === 'object' &&
                Array.isArray(schema[field]) &&
                typeof schema[field][0] === 'string') ||
            typeof schema[field][0] === 'number'
        ) {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: true,
                isSelectable: true,
                isDisabled: isDisabled(field, selectedFieldsData, fieldsData),
                isSelected: !!selectedFieldsData.find(
                    (data) => data.field === field
                ),
                type:
                    typeof schema[field][0] === 'string'
                        ? DataTypes.STRING
                        : DataTypes.NUMBER,
            };
            fieldsData.set(field, data);
        } else {
            data = {
                parent: parent,
                isParentMultiple: isParentMultiple,
                isSelfMultiple: false,
                isSelectable: true,
                isDisabled: isDisabled(field, selectedFieldsData, fieldsData),
                isSelected: !!selectedFieldsData.find(
                    (data) => data.field === field
                ),
                type:
                    typeof schema[field] === 'string'
                        ? DataTypes.STRING
                        : DataTypes.NUMBER,
            };
            fieldsData.set(field, data);
        }
    });
};

const handleCheckBoxChange = (
    keyData,
    selectedFieldsData,
    setSelectedFieldsData
) => {
    const found = selectedFieldsData.find(
        (data) => data.field === keyData.field
    );
    if (found) {
        const filteredKeyData = selectedFieldsData.filter(
            (data) => data.field !== keyData.field
        );
        setSelectedFieldsData(filteredKeyData);
    } else {
        setSelectedFieldsData([...selectedFieldsData, keyData]);
    }
};

const FieldComponent = ({
    field,
    fieldsData,
    selectedFieldsData,
    setSelectedFieldsData,
    handleCheckBoxChange,
}) => {
    const data = fieldsData.get(field);
    return (
        <div className='flex flex-col justify-center items-start'>
            <label
                className={
                    data.isDisabled
                        ? 'cursor-not-allowed border-l-2 py-1'
                        : 'cursor-pointer border-l-2 py-1'
                }
                title={
                    data.isDisabled
                        ? 'Disabled as a "Multiple" type field from other category selected'
                        : 'Select the field'
                }
            >
                <span className='text-texts-secondary300 mr-1'>-</span>
                {/* Checkbox */}
                {data.isSelectable ? (
                    <input
                        type='checkbox'
                        name='selectedKey'
                        value={field}
                        disabled={data.isDisabled}
                        checked={data.isSelected}
                        onChange={() => {
                            const keyData = {
                                field: field,
                                type: data.type,
                            };
                            handleCheckBoxChange(
                                keyData,
                                selectedFieldsData,
                                setSelectedFieldsData
                            );
                        }}
                        className='mr-2'
                    />
                ) : (
                    <></>
                )}

                {/* Field text */}

                <span
                    className={
                        data.isSelectable ? 'text-slate-600' : 'font-semi-bold'
                    }
                >
                    {toSentenceCase(field)}
                </span>

                {/* Type text */}
                {data.isSelectable ? (
                    <span className='ml-2 text-texts-secondary300'>
                        {data.type.toUpperCase()}
                    </span>
                ) : (
                    <></>
                )}

                {/* Multiple text */}
                {data.isSelfMultiple ? (
                    <span className='ml-2 text-backgrounds-primary400'>
                        Multiple
                    </span>
                ) : (
                    <></>
                )}
            </label>
        </div>
    );
};

const FieldsStructure = ({
    schema,
    fieldsData,
    selectedFieldsData,
    setSelectedFieldsData,
}) => {
    if (fieldsData.size === 0) {
        return <></>;
    }

    const schemaKeys = Object.keys(schema);

    return schemaKeys.map((field) => {
        if (
            typeof schema[field] === 'object' &&
            !Array.isArray(schema[field]) &&
            schema[field] !== null
        ) {
            return (
                <div className='flex flex-col justify-center items-start'>
                    <FieldComponent
                        field={field}
                        fieldsData={fieldsData}
                        selectedFieldsData={selectedFieldsData}
                        setSelectedFieldsData={setSelectedFieldsData}
                        handleCheckBoxChange={handleCheckBoxChange}
                    />

                    <div className='mb-2 ml-4'>
                        <FieldsStructure
                            schema={schema[field]}
                            fieldsData={fieldsData}
                            selectedFieldsData={selectedFieldsData}
                            setSelectedFieldsData={setSelectedFieldsData}
                        />
                    </div>
                </div>
            );
        } else if (
            typeof schema[field] === 'object' &&
            Array.isArray(schema[field]) &&
            typeof schema[field][0] === 'object'
        ) {
            return (
                <div className='flex flex-col justify-center items-start'>
                    <FieldComponent
                        field={field}
                        fieldsData={fieldsData}
                        selectedFieldsData={selectedFieldsData}
                        setSelectedFieldsData={setSelectedFieldsData}
                        handleCheckBoxChange={handleCheckBoxChange}
                    />

                    <div className='mb-2 ml-4'>
                        <FieldsStructure
                            schema={schema[field][0]}
                            fieldsData={fieldsData}
                            selectedFieldsData={selectedFieldsData}
                            setSelectedFieldsData={setSelectedFieldsData}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <FieldComponent
                    field={field}
                    fieldsData={fieldsData}
                    selectedFieldsData={selectedFieldsData}
                    setSelectedFieldsData={setSelectedFieldsData}
                    handleCheckBoxChange={handleCheckBoxChange}
                />
            );
        }
    });
};

export const TemplateSchemaViewer = ({
    schema,
    selectedFieldsData,
    setSelectedFieldsData,
}) => {
    const [fieldsData, setFieldsData] = useState(new Map());
    const [firstFieldsData, setFirstFieldsData] = useState(new Map());

    useEffect(() => {
        if (schema && selectedFieldsData) {
            const completeFieldsData = new Map(fieldsData);
            buildFieldData(
                null,
                schema,
                selectedFieldsData,
                completeFieldsData
            );

            setFirstFieldsData(completeFieldsData);
        }

        return () => {};
    }, [schema, selectedFieldsData]);

    useEffect(() => {
        if (schema && selectedFieldsData) {
            if (firstFieldsData.size) {
                const completeFieldsData = new Map(firstFieldsData);
                buildFieldData(
                    null,
                    schema,
                    selectedFieldsData,
                    completeFieldsData
                );

                setFieldsData(completeFieldsData);
            }
        }

        return () => {};
    }, [firstFieldsData]);

    return (
        <div className='w-full h-full flex flex-col justify-start items-start p-2'>
            <div className='font-semibold text-texts-secondary300 text-lg mb-2'>
                Template structure
            </div>
            <div className='w-full'>
                <FieldsStructure
                    schema={schema}
                    fieldsData={fieldsData}
                    selectedFieldsData={selectedFieldsData}
                    setSelectedFieldsData={setSelectedFieldsData}
                />
            </div>
        </div>
    );
};
