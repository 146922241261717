import React from 'react';
import { CompanyDetails, appLogo, appName, appVersion } from '../Constants';
import { useNavigate } from 'react-router';
import { useAuth } from '../context/AuthContext';
import { AuthStatus } from '../constants/authStatus';

export const Brand = ({ iconClassName }) => {
    const navigate = useNavigate();
    const { authenticated } = useAuth();
    return (
        <div
            className='flex w-full items-center justify-start'
            onClick={() => {
                if (authenticated === AuthStatus.AUTHENTICATED) {
                    navigate('/chats');
                }
            }}
        >
            <img
                className={iconClassName}
                src={appLogo}
                alt='camera'
                draggable='false'
            />
            <div className='font-sans text-texts-secondary300 '>
                <span className='text-xl font-semibold mr-2 truncate'>
                    {appName}
                </span>
                <span className='text-xs truncate'>v{appVersion}</span>

                <div className='text-xs whitespace-normal flex flex-center items-center break-words'>
                    © {CompanyDetails.NAME}
                </div>
            </div>
        </div>
    );
};

Brand.defaultProps = {
    iconClassName: 'w-16 h-16 mr-3',
};
