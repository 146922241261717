import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const DragDropFile = ({ onDrop, onDragOver, icon, text }) => {
    const boxText = text
        ? text
        : "Drag and drop a file here or click on 'Choose Files' button to select file";
    return (
        <div
            className='flex justify-center items-center min-h-[25vh] bg-backgrounds-white rounded-xl my-4 border-2 border-dashed border-backgrounds-primary300'
            onDrop={onDrop}
            onDragOver={onDragOver}
        >
            <div className='text-xl flex flex-col justify-center truncate'>
                <FontAwesomeIcon
                    icon={icon ? icon : faFileArrowDown}
                    className='mr-2 mb-3 text-texts-tertiary'
                    size='3x'
                />
                <div className='p-3 truncate'>{boxText}</div>
            </div>
        </div>
    );
};
