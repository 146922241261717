export const SUBSCRIPTION_PLANS = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  ENTERPRISE: 'ENTERPRISE'
}; 

export const PlanPriority = Object.freeze({
  FREE: 0,
  BASIC: 1,
  PRO: 2,
  ENTERPRISE: 3
});

export const YEARLY_DISCOUNT_PERCENTAGE = 17;

export const calculateYearlyPrice = (monthlyPrice, planName) => {
  if (planName === SUBSCRIPTION_PLANS.FREE) return 0;
  const yearlyDiscount = 0.17;
  return Math.round(monthlyPrice * 12 * (1 - yearlyDiscount));
};

export const getPlanPriority = (planName) => {
  return PlanPriority[planName.toUpperCase()] || 0;
}; 