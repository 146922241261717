import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoInteractionView, Spinner } from './reusableComponents';
import { PoweredByInfo } from './powereByComponent';
import { useAuth } from '../context/AuthContext';
import { getUrl } from './pdfDisplay/utils';
// import { handleLogout } from './userDetailAndAction';

const VerifyEmailBlockerPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const { login, logout } = useAuth();
    const user = JSON.parse(localStorage.getItem('current'));
    console.log(user);
    const sendVerificationEmail = async () => {
        try {
            setLoading(true);
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/send_mail?email=${user.email}`);
            const authToken = encodeURIComponent(user.token); // Replace with your actual authentication token
            const headers = {
                Authorization: authToken,
                'Content-Type': 'application/json',
            };
            const response = await fetch(dynamicUrl, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setLoading(false);
                setEmailSent(true);
            } else {
                setLoading(false);
                setEmailSent(false);
                alert('Failed to send verification mail');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setEmailSent(false);
            alert('An error occurred while sending the verification mail');
        }
    };
    useEffect(() => {
        if (emailSent) {
            setTimeout(() => {
                alert('Email Verification mail sent successfully');
                setEmailSent(false);
            }, 5);
        }
    }, [emailSent]);
    return (
        <div className='flex items-center justify-center min-h-screen animated-background'>
            {loading && <NoInteractionView viewText={'Sending ...'} />}
            <div className='w-full md:w-1/2 max-w-[500px] flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                <div className='text-center'>
                    <h2 className='font-bold text-xl text-texts-secondary300'>
                        Verify your email address
                    </h2>
                    <p className='pt-5 text-texts-secondary200'>{user.email}</p>
                    <p className='text-sm text-texts-secondary300 mr-10 ml-10 mb-10 mt-3 text-texts-secondary300'>
                        Please click on the link in the email we just sent you
                        to confirm your email address
                    </p>
                </div>
                <div className='flex flex-col text-sm w-full'>
                    {/* Resend Email button */}
                    <button
                        className='flex w-full font-semibold items-center justify-center text-backgrounds-primary500 bg-gradient-to-r from-lime-100 via-backgrounds-primary100 to-sky-100 border border-1 shadow-inner hover:bg-gradient-to-r hover:from-lime-200 hover:via-backgrounds-primary400 hover:to-sky-200 hover:border-backgrounds-primary300 hover:text-white p-3 mt-2 rounded-lg'
                        type='submit'
                        onClick={sendVerificationEmail}
                    >
                        <span className='drop-shadow-sm'>Resend Email</span>
                    </button>

                    {/* Logout button */}
                    <button
                        className='mt-3'
                        onClick={() => {
                            // handleLogout(navigate);
                            logout();
                        }}
                    >
                        <span className='text-texts-secondary300'>
                            Back to Sign In
                        </span>
                    </button>
                </div>
                {/* Powered By Info */}
                <div className='w-full flex justify-center items-center mt-8'>
                    <PoweredByInfo />
                </div>
            </div>
        </div>
    );
};

export default VerifyEmailBlockerPage;
