import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faExclamationTriangle,
    faFolderPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    DragDropFile,
    FileListViewerSqllite,
} from './reusableComponents';
import { vectorStores } from '../constants/vectorStore';
import { hasDuplicateNames, isValidName } from '../utils';
import { Select } from 'antd';
import { isMobile } from 'react-device-detect';
import { getUrl } from './pdfDisplay/utils';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';

const { Option } = Select;

const Addnewrepo_sqlite = (props) => {
    const reponame = props.reponame;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const [repoDetails, setRepoDetails] = useState([]);
    const [activeVectorStore, setActiveVectorStore] = useState(
        vectorStores.CHROMA
    );
    const [isDatabaseRepo, setIsDatabaseRepo] = useState(false);
    const vectorStoresList = [
        {
            key: 0,
            label: vectorStores.CHROMA,
            selected: activeVectorStore === vectorStores.CHROMA,
            action: () => {
                setActiveVectorStore(vectorStores.CHROMA);
            },
        },
        {
            key: 1,
            label: vectorStores.FAISS,
            selected: activeVectorStore === vectorStores.FAISS,
            action: () => {
                setActiveVectorStore(vectorStores.FAISS);
            },
        },
    ];

    const user = JSON.parse(localStorage.getItem('current'));
    console.log(user);

    const [summary, setSummary] = useState(false);

    useEffect(() => {
        setError('');
    }, [reponame]);

    const call = async () => {
        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/repocount/${user.email}`);

            const response = await fetch(dynamicUrl);
            const data = await response.json();
            console.log(data.count);
            return data.count;
        } catch (error) {
            console.log(error);
        }
    };

    function isNameFound(searchString) {
        for (let i = 0; i < repoDetails.length; i++) {
            if (repoDetails[i].name === searchString) {
                return true;
            }
        }
        return false;
    }

    const repos = async () => {
        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getAllChatsRepos/${user.email}`);
            const authToken = encodeURIComponent(user.token); // Replace with your actual authentication token

            const headers = {
                Authorization: authToken,
                'Content-Type': 'application/json',
            };

            const response = await fetch(dynamicUrl, {
                method: 'GET',
                headers: headers,
            });

            const data = await response.json();
            console.log(data);
            console.log(`the number of repos is ${data.Normal.length}`);
            setRepoDetails(data.Normal);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        repos();
    }, []);

    // Handle file selection
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        setError('');
        validateFiles(files);
    };

    //generate unique number
    function generateUniqueNumber() {
        const min = 10000; // Minimum value (inclusive)
        const max = 99999; // Maximum value (inclusive)

        // Generate a random number between min and max
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        // Convert the number to a string
        const uniqueNumber = randomNumber.toString();

        return uniqueNumber;
    }

    // Handle file drop
    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
        setError('');
        validateFiles(files);
    };

    // Prevent default behavior for file drop
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Validate file types
    const validateFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop();

            // Check if file extension is not .db
            if (fileExtension.toLowerCase() !== 'db') {
                setError('Only SQLite .db files are allowed.');
                return;
            }

            if (hasDuplicateNames(files)) {
                setError('Duplicate file name found.');
                return;
            }
        }
    };

    // Handle form submission
    const handleSubmit_sqllite = async (event) => {
        event.preventDefault();
        setError('');

        if (reponame.length === 0) {
            setError('Please enter a valid repository name!');
            return;
        }

        if (selectedFiles.length === 0) {
            setError('Please select a file to upload!');
            return;
        }

        const isValidNameData = isValidName(reponame, false);
        if (!isValidNameData.isValid) {
            setError(isValidNameData.error);
            return;
        }

        const isFound = isNameFound(reponame);
        if (isFound) {
            setError('Repository with this name already exists!');
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file, index) => {
            formData.append(`file`, file);
        });

        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}`);

            const formData = new FormData();
            formData.append('reponame', props.reponame);
            formData.append('email', user.email);
            formData.append('repotype', 'sqllite');
            selectedFiles.forEach((file) => {
                formData.append('sqlite_file', file);
            });
            const encodedToken = encodeURIComponent(user.token);
            props.setAddNewMode(false);

            const status = {
                message: 'Starting database repository creation',
                percentageCompletion: 0,
                process: ProcessType.REPO_CREATION,
                progress: TaskProgressType.STARTED,
                repoId: null,
                repoName: reponame,
                statusCode: 200,
                timeStamp: new Date(),
                userMail: user.email,
            };

            props.socket.emit('statusUpdate', status);
            await axios
                .post(`${dynamicUrl}/add_sqlite_repo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: encodedToken,
                    },
                    onUploadProgress: (progressEvent) => {
                        const fileUploadPercentage =
                            (progressEvent.loaded * 100) / progressEvent.total;
                        const percentCompleted = Math.round(
                            fileUploadPercentage / 10
                        );

                        const status = {
                            message:
                                'Uploading db file for repository creation',
                            percentageCompletion: percentCompleted,
                            process: ProcessType.REPO_CREATION,
                            progress: TaskProgressType.PROCESSING,
                            repoId: null,
                            repoName: reponame,
                            statusCode: 200,
                            timeStamp: new Date(),
                            userMail: user.email,
                        };

                        props.socket.emit('statusUpdate', status);
                    },
                })
                .then((response) => {
                    if (response.data.message_alert) {
                        // If there is a message_alert, display an alert
                        alert(response.data.message_alert);
                    } else {
                        // If there is a regular message, log it or handle it as needed
                        console.log(response.data.message);
                    }
                });

            console.log('Files uploaded successfully!');
            await props.getAllReposData();
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // The server responded with a 400 status code
                const errorMessage = error.response.data.error;
                alert(`${errorMessage}`);
            } else {
                // Handle other errors
                alert(error.message);
            }

            // Handle error
            props.setAddNewMode(false);
        }
    };

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setSummary(checked);
    };

    const handleChange = (value) => {
        // Log the selected value
        console.log('Selected value:', value);

        // Update selected value state

        // Check if the selected value is 'database' and update isDatabase state accordingly
        setIsDatabaseRepo(value === 'database');
    };

    return (
        <>
            <form onSubmit={handleSubmit_sqllite}>
                <input
                    title='Choose db file'
                    type='file'
                    className='bg-backgrounds-white w-full p-2 rounded-xl border cursor-pointer'
                    onChange={handleFileSelect}
                />
                {!isMobile && (
                    <DragDropFile
                        text={
                            "Drag and drop a file here or click on 'Choose File' button to upload file"
                        }
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                    />
                )}

                <div>
                    <FileListViewerSqllite selectedFiles={selectedFiles} />
                </div>
                {error && (
                    <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className='mr-2'
                        />
                        {error}
                    </div>
                )}
                <div className='flex items-center justify-center mt-4'>
                    <div className='flex items-center justify-between w-full'>
                        <Button
                            type='danger'
                            icon={faCircleXmark}
                            text={'Cancel'}
                            tile={'Cancel and close the modal'}
                            className='w-1/3'
                            onClick={() => {
                                props.setAddNewMode(false);
                            }}
                        />

                        <button
                            className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                !reponame || error || selectedFiles.length === 0
                                    ? 'text-texts-secondary300 cursor-not-allowed'
                                    : `hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400`
                            }`}
                            type='submit'
                            title='Upload file and add new repository'
                            disabled={
                                !reponame || error || selectedFiles.length === 0
                            }
                        >
                            <FontAwesomeIcon
                                icon={faFolderPlus}
                                className='mr-2'
                            />
                            Add
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Addnewrepo_sqlite;
