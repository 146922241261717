// BrandGuidelines.jsx
import React, { forwardRef } from 'react';
import {
    appLogo,
    appName,
    companyLogo,
    contactEmail,
    contactNumber,
} from '../Constants';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { useMenuItems } from '../menuItems';

const BrandGuidelines = forwardRef(() => {
    const { menuItems } = useMenuItems();
    return (
        <>
            <Navbar menuItems={menuItems} />
            <div className='mt-24 mx-0 md:mx-8 lg:mx-20 p-8'>
                <h1 className='font-bold text-3xl mb-8 text-center'>
                    Brand Guidelines
                </h1>
                <div className='text-texts-secondary mb-6'>
                    <p>
                        Welcome to {appName}! Our brand is important to us, and
                        we want to ensure that it is represented consistently
                        and effectively across all channels. These brand
                        guidelines outline how to use our logo, colors, fonts,
                        and other brand assets.
                    </p>
                    <h2 className='font-semibold mt-4'>1. Logo Usage</h2>
                    <p>
                        Our logo is the primary visual representation of our
                        brand. When using our logo, please adhere to the
                        following guidelines:
                        <ul className='list-disc list-inside pl-4'>
                            <li>
                                Use the logo in its original colors whenever
                                possible.
                            </li>
                            <li>
                                Do not alter the logo in any way, including
                                resizing, rotating, or distorting it.
                            </li>
                            <li>
                                Maintain clear space around the logo to ensure
                                visibility and legibility.
                            </li>
                            <li>
                                Do not place the logo on busy backgrounds or use
                                conflicting colors that may diminish its impact.
                            </li>
                        </ul>
                    </p>
                    <h2 className='font-semibold mt-4'>2. Color Palette</h2>
                    <p>
                        Our brand colors are an essential part of our identity.
                        When using our brand colors, please ensure consistency
                        across all materials to maintain brand integrity.
                    </p>
                    <h2 className='font-semibold mt-4'>3. Typography</h2>
                    <p>
                        Our brand typography is clean, modern, and easy to read.
                        The primary font for headings and titles is "Public
                        Sans", while the backgrounds-secondary font for body
                        text is "sans-serif."
                    </p>
                    <h2 className='font-semibold mt-4'>4. Photography</h2>
                    <p>
                        When selecting imagery for our brand, choose
                        high-quality photos that align with our brand values and
                        aesthetics. Avoid using stock photos that appear generic
                        or unprofessional. Kindly do not use the logo without
                        our consent and for unethical purposes.
                    </p>
                    <h2 className='font-semibold mt-4'>5. Voice and Tone</h2>
                    <p>
                        Our brand voice is friendly, informative, and
                        straightforward. When communicating with our audience,
                        strive for clarity and authenticity while maintaining a
                        positive tone.
                    </p>
                    <h2 className='font-semibold mt-4'>6. Contact Us</h2>
                    <p>
                        If you have any questions or need further assistance
                        with our brand guidelines, please contact us
                        {contactNumber ? `${contactNumber} or` : ''}{' '}
                        {contactEmail}.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
});

export default BrandGuidelines;
