import React, { useState, useEffect } from 'react';
import {
    faArrowRight,
    faCaretLeft,
    faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Input } from '../reusableComponents';

export const PdfActions = ({ pageNumber, totalPageNumbers, setPageNumber }) => {
    const [goToPage, setGoToPage] = useState('');
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1024);
        };

        // Initial check on mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='w-full flex justify-end items-center'>
            {!isSmallScreen && (
                <div className='mr-3 flex justify-center items-center'>
                    <div className='w-20'>
                        <Input
                            value={goToPage}
                            placeholder={'Page'}
                            type={'number'}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setGoToPage(parseInt(e.target.value));
                                } else {
                                    setGoToPage('');
                                }
                            }}
                            className={'h-8 rounded-none rounded-l-full'}
                            disabled={!totalPageNumbers}
                        />
                    </div>

                    <Button
                        type={'info'}
                        onClick={() => {
                            setPageNumber(goToPage);
                            setGoToPage('');
                        }}
                        icon={faArrowRight}
                        hoverText={'Go to page number'}
                        className={'rounded-none rounded-r-full'}
                        disabled={goToPage < 1 || goToPage > totalPageNumbers}
                    />
                </div>
            )}

            <div className='w-fit flex items-center justify-center'>
                <Button
                    type={'warning'}
                    onClick={previousPage}
                    icon={faCaretLeft}
                    hoverText={'Previous Page'}
                    disabled={pageNumber <= 1}
                />

                <div className='text-sm mx-2 text-texts-secondary300 font-semibold text-nowrap'>
                    Page {pageNumber || (totalPageNumbers ? 1 : '--')} /{' '}
                    {totalPageNumbers || '--'}
                </div>

                <Button
                    type={'warning'}
                    onClick={nextPage}
                    icon={faCaretRight}
                    hoverText={'Next Page'}
                    disabled={pageNumber >= totalPageNumbers}
                />
            </div>
        </div>
    );
};
