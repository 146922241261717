import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { LoadingView } from '../reusableComponents';
import { getFileFormat } from '../../utils';

export const LoadingComponent = ({ fileName }) => {
    return (
        <div className='flex w-full h-[60vh] justify-center items-center text-texts-secondary300'>
            <LoadingView loadingText={`Loading ${fileName}`} />
        </div>
    );
};

export const LoadingPageComponent = ({ pageNumber }) => {
    return (
        <div className='flex w-full h-[60vh] justify-center items-center text-texts-secondary300'>
            loadingText={`Loading page number ${pageNumber}`}
        </div>
    );
};

export const NoDataComponent = ({ fileName }) => {
    let message = '';
    if (fileName) {
        const fileExtension = getFileFormat(fileName).toUpperCase();
        message = `No ${fileExtension} file specified`;
    } else {
        message = 'No file specified';
    }

    return (
        <div className='flex w-full h-[60vh] justify-center items-center text-backgrounds-settings500'>
            <FontAwesomeIcon icon={faFileCircleXmark} className='mr-1' />
            <div className='font-semibold'>{message}</div>
        </div>
    );
};

export const ErrorDataComponent = ({ fileName }) => {
    return (
        <div className='flex w-full h-[60vh] justify-center items-center text-buttons-alert400'>
            <FontAwesomeIcon icon={faFileCircleXmark} className='mr-1' />
            <div className='font-semibold'>Failed to load {fileName}</div>
        </div>
    );
};
