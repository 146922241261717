import React, { useState } from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import {
    faBoxOpen,
    faCaretDown,
    faCaretUp,
    faChartSimple,
    faCheck,
    faClipboardList,
    faClose,
    faListCheck,
    faPieChart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from '../../reusableComponents';
import { GraphEditorView } from './graphEditorView';
import { TemplateSchemaViewer } from './templateSchemaViewer';
import { ChartTypes, DataTypes } from '../constants';
import Toggle from '../../reusableComponents/Toggle';
import { toSentenceCase } from '../utils';

const maxTitleLength = 60;

const updateFieldPosition = (
    field,
    position,
    selectedFields,
    setSelectedFieldsData,
    updatedSelectedFields
) => {
    const index = selectedFields.findIndex((data) => data.field === field);

    const interchangeIndex = index + position;
    const newSelectedFieldsData = [...selectedFields];
    const temp = newSelectedFieldsData[index];
    newSelectedFieldsData[index] = newSelectedFieldsData[interchangeIndex];
    newSelectedFieldsData[interchangeIndex] = temp;

    setSelectedFieldsData(newSelectedFieldsData);
    updatedSelectedFields(newSelectedFieldsData);
};

const DataSourcesComponent = ({
    dataSources,
    onChangeFieldType,
    setSelectedFieldsData,
    updateSelectedFields,
}) => {
    const sources = dataSources ? dataSources : [];

    if (sources.length === 0) {
        return (
            <div className='flex flex-col w-full h-full justify-center items-center text-lg'>
                <FontAwesomeIcon
                    icon={faBoxOpen}
                    className='mb-1 text-texts-secondary300'
                    size='3x'
                />
                <div className='text-texts-secondary300'>No Field Selected</div>
            </div>
        );
    }

    const dataTypesData = Object.values(DataTypes);
    const dataTypesDropdown = dataTypesData.map((item, index) => {
        return (
            <option id={`option ${index}`} value={item}>
                {item.toUpperCase()}
            </option>
        );
    });

    return sources.map((source, index) => {
        return (
            <div className='w-full flex justify-start items-center mb-3'>
                <Input
                    value={toSentenceCase(source.field)}
                    placeholder={toSentenceCase(source.field)}
                    type={'string'}
                    onChange={() => {}}
                    className={'w-[350px] mr-2'}
                    disabled={true}
                />
                <select
                    id={index}
                    className='block w-26 p-2 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300 text-texts-secondary300'
                    value={source.type}
                    onChange={(e) =>
                        onChangeFieldType(source.field, e.target.value)
                    }
                >
                    {dataTypesDropdown}
                </select>

                <div className='flex ml-1'>
                    <Button
                        className='ml-1'
                        onClick={(event) => {
                            updateFieldPosition(
                                source.field,
                                -1,
                                sources,
                                setSelectedFieldsData,
                                updateSelectedFields
                            );
                            event.stopPropagation();
                        }}
                        hoverText={`Move field up. Changes field position in chart`}
                        icon={faCaretUp}
                        type={'warning'}
                        disabled={index === 0}
                    />
                    <Button
                        className='ml-1'
                        onClick={(event) => {
                            updateFieldPosition(
                                source.field,
                                1,
                                sources,
                                setSelectedFieldsData,
                                updateSelectedFields
                            );
                            event.stopPropagation();
                        }}
                        hoverText={`Move field down. Changes field position in chart`}
                        icon={faCaretDown}
                        type={'warning'}
                        disabled={index === sources.length - 1}
                    />
                </div>
            </div>
        );
    });
};

export const CardOptions = ({
    metaData,
    templateData,
    selectedFields,
    chartData,
    updateSelectedFields,
    onChangeCardName,
    toggleCardNameView,
    toggleUseRowFormat,
    onChangeFieldType,
    onUpdateChart,
}) => {
    const [showGraphModal, setShowGraphModal] = useState(false);
    const [showTemplateSchema, setShowTemplateSchema] = useState(false);
    const [previousSelectedFieldsData, setPreviousSelectedFieldsData] =
        useState(selectedFields);
    const [selectedFieldsData, setSelectedFieldsData] =
        useState(selectedFields);

    return (
        <div className='flex flex-col w-[85vw] h-[79vh]'>
            <div className='flex w-full items-center p-3 border-b-2 border-b-white mb-2'>
                <div className=' w-[100px] font-semibold text-texts-secondary300 mr-3 truncate'>
                    Card Title
                </div>
                <Input
                    className={'w-[380px] text-texts-secondary300 truncate'}
                    value={metaData ? metaData.name : 'Untitled Card'}
                    type={'string'}
                    onChange={(e) => onChangeCardName(e.target.value)}
                    maxLength={maxTitleLength}
                />
                <div
                    className='w-fit'
                    title={'Hides card title from the actual report'}
                >
                    <Toggle
                        toggleText={'Hide'}
                        toggleTextClassName={`w-full mx-2 cursor-pointer font-semibold text-texts-secondary300`}
                        disabled={false}
                        checked={metaData ? metaData.isCardNameHidden : false}
                        onChange={toggleCardNameView}
                    />
                </div>
            </div>

            <div className='flex justify-between items-start w-[85vw] h-[70vh] p-3 bg-backgrounds-slate'>
                <ReflexContainer orientation='vertical'>
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={350}
                        size={500}
                        maxSize={700}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {/* Data Field Section */}
                            <div className='w-full h-full flex flex-col justify-start items-start'>
                                <div className='w-full flex justify-between items-center border-b border-b-white pr-2 pb-2'>
                                    <div className='font-semibold text-texts-secondary300 '>
                                        <FontAwesomeIcon
                                            icon={
                                                showTemplateSchema
                                                    ? faClipboardList
                                                    : faListCheck
                                            }
                                            className='mr-2'
                                        />
                                        <span>
                                            {showTemplateSchema
                                                ? 'Modify Fields'
                                                : 'Selected Fields'}
                                        </span>
                                    </div>
                                    <div>
                                        {showTemplateSchema ? (
                                            <div className='w-full flex justify-between items-center'>
                                                <Button
                                                    text={'Cancel'}
                                                    icon={faClose}
                                                    className={'mr-2'}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedFieldsData(
                                                            previousSelectedFieldsData
                                                        );
                                                        setShowTemplateSchema(
                                                            false
                                                        );
                                                    }}
                                                    hoverText={'Edit Card'}
                                                    type={'danger'}
                                                />
                                                <Button
                                                    text={'Update'}
                                                    icon={faCheck}
                                                    className={''}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        updateSelectedFields(
                                                            selectedFieldsData
                                                        );
                                                        setPreviousSelectedFieldsData(
                                                            selectedFieldsData
                                                        );
                                                        setShowTemplateSchema(
                                                            false
                                                        );
                                                    }}
                                                    hoverText={'Remove Card'}
                                                    type={'success'}
                                                />
                                            </div>
                                        ) : (
                                            <Button
                                                text={'Modify Fields'}
                                                icon={faClipboardList}
                                                className={''}
                                                onClick={() => {
                                                    setShowTemplateSchema(true);
                                                }}
                                                hoverText={
                                                    'Modify selected fields'
                                                }
                                                type={'success'}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className='w-full h-[70vh] flex flex-col justify-start items-center p-2 overflow-auto'>
                                    {showTemplateSchema ? (
                                        <TemplateSchemaViewer
                                            schema={templateData}
                                            selectedFieldsData={
                                                selectedFieldsData
                                            }
                                            setSelectedFieldsData={
                                                setSelectedFieldsData
                                            }
                                        />
                                    ) : (
                                        <DataSourcesComponent
                                            dataSources={selectedFieldsData}
                                            onChangeFieldType={
                                                onChangeFieldType
                                            }
                                            setSelectedFieldsData={
                                                setSelectedFieldsData
                                            }
                                            updateSelectedFields={
                                                updateSelectedFields
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </ReflexElement>

                    <ReflexSplitter
                        style={{
                            border: 0,
                            background: 'white',
                            width: '4px',
                            zIndex: 10,
                            height: '100%',
                        }}
                    />

                    <ReflexElement className='right-pane ml-0.5'>
                        <div className='pane-content w-full h-full bg-transparent'>
                            {/* Graph Section */}
                            <div className='w-full h-full flex flex-col justify-start items-start'>
                                <div className='w-full flex justify-between items-center border-b border-b-white pb-2 pl-4'>
                                    <div className='font-semibold text-texts-secondary300 '>
                                        <FontAwesomeIcon
                                            icon={faChartSimple}
                                            className='mr-2'
                                        />
                                        <span>Chart</span>
                                    </div>
                                    {chartData.chart === ChartTypes.TABLE && (
                                        <div
                                            className='w-fit'
                                            title='Each data is shown in separate row with its title.'
                                        >
                                            <Toggle
                                                toggleText={'Use Row Format'}
                                                toggleTextClassName={
                                                    'w-full mx-2 cursor-default font-semibold text-texts-secondary300'
                                                }
                                                checked={
                                                    chartData
                                                        ? chartData.useRowFormat
                                                        : false
                                                }
                                                onChange={toggleUseRowFormat}
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <Button
                                            text={'Update Chart'}
                                            icon={faPieChart}
                                            className={''}
                                            onClick={() => {
                                                setShowGraphModal(true);
                                            }}
                                            hoverText={'Select Chart'}
                                            type={'success'}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`w-full h-full flex justify-center ${
                                        chartData.chart === ChartTypes.TABLE
                                            ? 'items-start'
                                            : 'items-center'
                                    } ${
                                        chartData.useRowFormat
                                            ? 'row-table'
                                            : ''
                                    } p-2 `}
                                >
                                    <GraphEditorView
                                        chartData={chartData}
                                        showGraphModal={showGraphModal}
                                        setShowGraphModal={setShowGraphModal}
                                        title={metaData ? metaData.name : ''}
                                        onUpdateChart={onUpdateChart}
                                    />
                                </div>
                            </div>
                        </div>
                    </ReflexElement>
                </ReflexContainer>
            </div>
        </div>
    );
};
