import React from 'react';
import { Link } from 'react-router-dom';
import { Brand } from './brand';
import { PoweredByInfo } from './powereByComponent';

const ConfirmationPage = () => {
    return (
        <div className='flex items-center justify-center min-h-screen bg-gradient-primary'>
            <div className='w-full max-w-[500px] flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                <div className='mb-8 w-full lg:w-3/5 mx-auto'>
                    <Brand iconClassName={'w-20 h-20 mr-3'} />
                </div>
                <h2 className='text-xl font-semibold mb-4 text-center text-texts-secondary'>
                    Sent Successfully!
                </h2>
                <div className='text-sm mb-4 text-center text-texts-secondary300'>
                    The link to generate a new password has been successfully
                    sent to your email address. Kindly check your mail to
                    recover your account.
                </div>
                <div className='mt-5 p-1 text-center text-sm font-semibold text-texts-tertiary'>
                    <Link to='/login'>Back to Login</Link>
                </div>
                <div className='w-full flex justify-center items-center mt-8'>
                    <PoweredByInfo />
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPage;
