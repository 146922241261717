import React, { useState, useEffect } from 'react';
import { ConfirmModal, InputModal } from './reusableComponents';
import axios from 'axios';
import { generateUniqueNumber } from '../utils';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';
import { getUrl } from './pdfDisplay/utils';

async function handleExcelFileDownload(email, folderName, templateName) {
    const uniqueId = generateUniqueNumber();

    const response = await fetch(
        getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${
            process.env.REACT_APP_FAST_API_PORT
        }/redownloadfile_excell?email=${email}&foldername=${folderName}&uid=${toString(
            uniqueId
        )}&templatename=${templateName}`)
    );
    const disposition = response.headers.get('Content-Disposition');

    if (disposition && disposition.includes('attachment')) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(disposition);
        let filename = matches != null && matches[1] ? matches[1] : 'file.xlsx';
        const originalString = filename;
        const modifiedString = originalString.substring(
            1,
            originalString.length - 1
        );

        filename = modifiedString;
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        const data = await response.json();
        alert(data.message);
    }
}

const updateActiveTemplate = async (user, activeTemplate) => {
    try {
        await axios.post(
            getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/updatetemplate`),
            {
                id: user.email,
                csvname: activeTemplate.filename,
            }
        );
    } catch (error) {
        console.error('Error:', error);
    }
};

const Extraction = (props) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const { setExtractMode, activeTemplate } = props;
    const [showModal, setShowModal] = useState(true);
    const [showInputModal, setShowInputModal] = useState(false);
    const [parameters, setParameters] = useState([]);
    const [inputs, setInputs] = useState({});

    const fetchParameters = async () => {
        try {
            const response = await axios.post(
                getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get-parameters`),
                {
                    email: user.email,
                    template_name: activeTemplate.filename,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );

            const data = response.data;
            const params = data.parameters || [];
            console.log('Fetched Parameters:', params); // Log the parameters

            setParameters(params);

            const initialInputs = params.reduce((acc, param) => {
                acc[param.name] = '';
                return acc;
            }, {});
            setInputs(initialInputs);

            return params;
        } catch (error) {
            console.error(
                'Error fetching parameters:',
                error.response?.data?.error || error.message
            );
            return [];
        }
    };
    const extractInformation = async (inputValues = {}) => {
        setExtractMode(false);

        await updateActiveTemplate(user, activeTemplate);
        const token = user.token;

        const status = {
            message: `Starting extraction process on ${props.repo.name}`,
            percentageCompletion: 0,
            process: ProcessType.EXTRACTION,
            progress: TaskProgressType.STARTED,
            repoId: props.repo.repoid,
            repoName: props.repo.name,
            statusCode: 200,
            timeStamp: new Date(),
            userMail: user.email,
        };
        props.socket.emit('statusUpdate', status);
        
        const bodyData = new URLSearchParams();
        bodyData.append('email', props.repo.email);
        bodyData.append('user_email', user.email);
        bodyData.append('repo_name', props.repo.name);
        bodyData.append('template_name', props.activeTemplate.filename);
        bodyData.append('repoid', props.repo.repoid);
        bodyData.append('db_extraction', '0');
        bodyData.append('parameters', JSON.stringify(inputValues));
        await fetch(
            getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/extract-information`),
            {
                method: 'POST',
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded;charset=UTF-8',
                    Authorization: `Bearer ${token}`,
                },
                body: bodyData.toString(),
            }
        )
            .then(async (response) => {
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || errorData.detail);
                }

                const contentDispositionHeader = response.headers.get(
                    'Content-Disposition'
                );
                if (!contentDispositionHeader) {
                    throw new Error(
                        'Error occurred while downloading excel file'
                    );
                }

                let filename = contentDispositionHeader.split('filename=')[1];
                const originalString = filename;
                const modifiedString = originalString.substring(
                    1,
                    originalString.length - 1
                );
                filename = modifiedString;

                return response.blob().then((blob) => ({ filename, blob }));
            })
            .then(async (data) => {
                if (data.detail) {
                    throw new Error(data.detail);
                }
                const url = window.URL.createObjectURL(data.blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = data.filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                await handleExcelFileDownload(
                    props.repo.email,
                    props.repo.name,
                    props.activeTemplate.filename
                );
            })
            .then(async () => {
                await props.fetchDownloadList(props.repo.email);
            })
            .catch(async (error) => {
                await props.fetchDownloadList(props.repo.email);
                const errorMessage =
                    error.message || 'Error occurred while running extraction';
                alert(`Error: ${errorMessage}`);
            });
    };

    const handleConfirm = async () => {
        setShowModal(false);
        if (activeTemplate.filename.startsWith('default')) {
            await extractInformation();
        } else {
            const params = await fetchParameters();
            if (params.length > 0) {
                setShowInputModal(true);
            } else {
                await extractInformation();
            }
        }
    };

    const handleInputModalSubmit = async (inputValues) => {
        setShowInputModal(false);
        await extractInformation(inputValues);
    };

    return (
        <div>
            {showModal && (
                <ConfirmModal
                    modalText={`Extract '${props.repo.name}' repository's information?`}
                    onConfirm={handleConfirm}
                    onCancel={() => setExtractMode(false)}
                />
            )}
            {showInputModal && (
                <InputModal
                    parameters={parameters}
                    onSubmit={handleInputModalSubmit}
                    onCancel={() => setExtractMode(false)}
                />
            )}
        </div>
    );
};

export default Extraction;
