import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const NoDataView = ({ text, icon, iconColor }) => {
    return (
        <div className='h-3/4 w-full flex flex-col text-texts-secondary300 justify-center items-center'>
            <FontAwesomeIcon
                icon={icon ? icon : faDatabase}
                //color={iconColor ? iconColor : ''}
                className={`w-[9vw] h-[9vw] mb-6 drop-shadow text-${iconColor}`}
            />
            <div className='text-xl'>{text ? text : 'No data to view'}</div>
        </div>
    );
};
