import React from 'react';
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InformationModal = ({ modalText }) => {
    return (
        <div className='relative'>
            <div className='fixed top-0 left-0 min-h-screen w-screen flex justify-center items-center bg-backgrounds-slate bg-opacity-20 z-50 backdrop-filter backdrop-blur-sm'>
                <div className='w-1/4 min-w-[300px] max-w-[25%] relative bg-backgrounds-slate p-4 rounded-xl shadow-lg flex-col items-center border sm:mx-2'>
                    <div className='absolute -top-8 left-1/2 transform -translate-x-1/2 bg-backgrounds-slate rounded-full p-3 shadow-md'>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className='text-texts-tertiary text-3xl'
                        />
                    </div>

                    <div className='flex items-center justify-center w-full mb-4 p-4 border rounded-xl bg-backgrounds-white h-auto'>
                        <div className='text-lg md:text-xl font-semibold text-texts-tertiary break-words'>
                            {modalText}
                        </div>
                    </div>

                    <div className='flex justify-center w-full'>
                        <Button
                            className='w-1/3 min-w-[120px]'
                            type={'success'}
                            onClick={() => {
                                window.location.reload(); // Refreshes the page
                            }}
                            icon={faCheckCircle}
                            text={'OK'}
                            hoverText={'Refresh the page'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
