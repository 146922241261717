import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, DragDropFile, NoInteractionView } from './reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClipboardCheck,
    faExclamationTriangle,
    faFile,
    faFileCircleExclamation,
    faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import { ipAddress } from '../Constants';
import { isMobile } from 'react-device-detect';
import { getUrl } from './pdfDisplay/utils';

const Evaluate = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [questions, setquestions] = useState([]);
    //const repo_to_chat = {email:'rk@gmail.com', foldername:'v-sir-2files202307241171624'}
    //repo_to_chat = {repo_to_chat} fine_tuned_mode={fine_tuned_mode} finetunedata = {finetunedata}
    //const{repo_to_chat,fine_tuned_mode , finetunedata} = props
    const { repo_to_chat, fine_tuned_mode, finetunedata } = props;
    console.log(repo_to_chat);
    console.log(fine_tuned_mode);
    console.log(finetunedata);
    const readTextFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const contents = event.target.result;
                const lines = contents.split('\n');
                resolve(lines);
            };
            reader.onerror = (event) => {
                reject(event.target.error);
            };
            reader.readAsText(file);
        });
    };

    // Handle file selection
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setError('');
        validateFile(file);
    };

    // Handle file drop
    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
        setError('');
        validateFile(file);
    };

    // Prevent default behavior for file drop
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Validate file type
    const validateFile = (file) => {
        if (!file || file.type !== 'text/csv') {
            setError('Only csv files are allowed.');
        }
    };

    function generateUniqueString() {
        // Generate a unique number
        const uniqueNumber = Math.floor(Math.random() * 1000000);

        // Get the current date and time
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Construct the unique string using date and unique number
        const uniqueString = `${year}${month}${day}${hours}${minutes}${seconds}_${uniqueNumber}`;

        return uniqueString;
    }

    const handleSubmit2 = async () => {
        try {
            if (!selectedFile) {
                console.log('no file selected ');
                return;
            }
            setIsLoading(true);
            const tques = await readTextFile(selectedFile);
            console.log(tques);
            setquestions(tques);
            const t_name = generateUniqueString();
            
            const response = await fetch(
                getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/write_to_file2`),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        questions: JSON.stringify(tques),
                        email: repo_to_chat.email,
                        foldername: repo_to_chat.foldername,
                        filename: t_name,
                    }).toString(),
                }
            );

            console.log(response);

            const anchor = document.createElement('a');
            anchor.href = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/download2?filename=${t_name}`); // Replace with your FastAPI download URL
            anchor.download = `${t_name}.csv`; // Replace with the desired filename
            anchor.click();

            setIsLoading(false);
            console.log('handle submit called');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleSubmit = async () => {
        try {
            if (!selectedFile) {
                console.log('No file selected');
                return;
            }

            setIsLoading(true);
            const output_filename = generateUniqueString();
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('output_filename', output_filename); // Replace with your desired output filename
            formData.append('email', repo_to_chat.email); // Replace with your email
            formData.append('foldername', repo_to_chat.name); // Replace with your foldername

            const response = await axios.post(
                getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/process-csv`),
                formData
            );

            console.log(response);

            if (response.status >= 200 && response.status < 300) {
                const anchor = document.createElement('a');
                anchor.href = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/download2?filename=${output_filename}`); // Replace with your FastAPI download URL
                anchor.download = `${output_filename}.csv`; // Replace with the desired filename
                anchor.click();
            } else {
                alert('csv file cannot be generated');
            }

            setIsLoading(false);
            console.log('Handle submit called');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleSubmit_finetune = async () => {
        try {
            console.log('finetuned function called');
            if (!selectedFile) {
                console.log('No file selected');
                return;
            }

            setIsLoading(true);
            const output_filename = generateUniqueString();
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('output_filename', output_filename); // Replace with your desired output filename
            formData.append('email', repo_to_chat.email); // Replace with your email
            formData.append('foldername', repo_to_chat.foldername);
            formData.append('model', finetunedata.modelid); // Replace with your foldername

            const response = await axios.post(
                getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/process-csv-finetune`),
                formData
            );

            console.log(response);

            if (response.status >= 200 && response.status < 300) {
                const anchor = document.createElement('a');
                anchor.href = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/download2?filename=${output_filename}`); // Replace with your FastAPI download URL
                anchor.download = `${output_filename}.csv`; // Replace with the desired filename
                anchor.click();
            } else {
                alert('csv file cannot be generated');
            }

            setIsLoading(false);
            console.log('Handle submit called');
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const modelText = fine_tuned_mode
        ? `Fine tuned model of ${repo_to_chat.name}`
        : `${repo_to_chat.name}`;

    //responsiveness related
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='relative w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col border px-6 py-6 shadow-md rounded-xl bg-backgrounds-slate'>
            {isLoading && (
                <NoInteractionView
                    viewText={'Waiting for the file to download ...'}
                />
            )}

            <div className='w-full mb-6 text-texts-secondary300'>
                <div className='flex items-center font-semibold mb-1'>
                    <FontAwesomeIcon icon={faClipboardCheck} className='mr-2' />
                    Batch & Eval
                </div>
                <p>{`* Upload your file which contains questions and answers from ${modelText} repository and get them evaluated.`}</p>
            </div>

            <div className='flex flex-col w-full'>
                <form onSubmit={handleSubmit}>
                    <input
                        type='file'
                        className='bg-backgrounds-white w-full p-2 rounded-xl text-texts-secondary300 border cursor-pointer'
                        onChange={handleFileSelect}
                    />
                    {!isMobile && (
                        <DragDropFile
                            text={
                                "Drag and drop a file here or click on 'Choose Files' button to select one"
                            }
                            onDrop={handleFileDrop}
                            onDragOver={handleDragOver}
                        />
                    )}

                    <div className='pt-4'>
                        <div className='border rounded-xl bg-backgrounds-white'>
                            <div className='font-semibold border-b py-2 px-3 rounded-t-xl'>
                                <FontAwesomeIcon
                                    icon={faFile}
                                    className='mr-2 text-texts-secondary300'
                                />
                                Selected File
                            </div>
                            <div className='text-texts-secondary300 p-3'>
                                {selectedFile ? (
                                    selectedFile.name
                                ) : (
                                    <div className='flex justify-center items-center text-texts-secondary300'>
                                        <FontAwesomeIcon
                                            icon={faFileCircleExclamation}
                                            className='mr-2 text-texts-primary100'
                                        />
                                        No file selected
                                    </div>
                                )}
                            </div>
                        </div>

                        {error && (
                            <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className='mr-2'
                                />
                                {error}
                            </div>
                        )}
                    </div>
                </form>
            </div>

            <div className='flex w-full items-center justify-between mt-6'>
                <Button
                    text={props.cancelText}
                    title={props.cancelText}
                    className='w-1/3'
                    onClick={props.onCancel}
                    icon={props.cancelIcon}
                    type={'danger'}
                />

                <Button
                    text={'Upload'}
                    title={'Upload file'}
                    className='w-1/3'
                    onClick={
                        fine_tuned_mode ? handleSubmit_finetune : handleSubmit
                    }
                    icon={faFileUpload}
                    type={'success'}
                    disabled={error || !selectedFile}
                />
            </div>
        </div>
    );
};

export default Evaluate;
