import React, { useState } from 'react';
import { useEffect } from 'react';
import { camelToTitleCase, getFileFormat } from '../utils';
import { Table } from 'antd';
import { LoadingView } from './reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getUrl } from './pdfDisplay/utils';

const RenderFilesData = ({ filesData, filesListData }) => {
    if (filesListData) {
        filesListData.filesData.forEach((data) => {
            // Getting file extension
            const fileExtension = getFileFormat(data.name);
            // Getting file name without extension
            const fileName = data.name.replace(`.${fileExtension}`, '');
            filesData.forEach((anotherData) => {
                // Getting file extension
                const fileExtension = getFileFormat(anotherData.fileName);
                // Getting file name without extension
                const anotherFileName = anotherData.fileName.replace(
                    `.${fileExtension}`,
                    ''
                );
                if (fileName === anotherFileName) {
                    anotherData.fileName = data.name;
                }
            });
        });
    }

    const modifiedFilesData = [...filesData].map((data, index) => {
        return { 'sNo.': `${index + 1}.`, ...data };
    });

    const fileKeysData = Object.keys(modifiedFilesData[0]);

    const columns = [];

    fileKeysData.forEach((key) => {
        const columnData = {
            title: camelToTitleCase(key),
            dataIndex: key,
            key: key,
        };
        columns.push(columnData);
    });

    return (
        <div className='border p-1 rounded-xl'>
            <Table
                pagination={{
                    defaultPageSize: 3,
                    disabled: filesData.length <= 3,
                }}
                dataSource={modifiedFilesData}
                columns={columns}
            />
        </div>
    );
};

const RenderList = ({ repository, filesListData }) => {
    const keysData = Object.keys(repository);

    return keysData.map((key) => {
        return (
            <div className='min-w-96 w-full'>
                {Array.isArray(repository[key]) ? (
                    <div className='w-full h-fit p-2.5 border-b border-b'>
                        <div className='w-full font-semibold text-texts-secondary300 mb-2.5'>
                            {camelToTitleCase(key)}
                        </div>
                        <div className='my-1 w-full'>
                            <RenderFilesData
                                filesData={repository[key]}
                                filesListData={filesListData}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='w-full h-fit p-2.5 flex items-start justify-between border-b border-b'>
                        <div className='w-6/12 font-semibold text-texts-secondary300'>
                            {camelToTitleCase(key)}
                        </div>
                        <div className='w-6/12'>{repository[key]}</div>
                    </div>
                )}
            </div>
        );
    });
};

export const RepositoryStats = ({ repository, filesListData, mode }) => {
    const user = JSON.parse(localStorage.getItem('current'));

    const [repoStat, setRepoStat] = useState({});
    const [repoList, setRepoList] = useState(filesListData);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getRepoFilesData = async (repoId) => {
        try {
            setIsLoading(true);
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_alldocs/${repoId}`);

            const encodedToken = encodeURIComponent(user.token);
            const response = await fetch(dynamicUrl, {
                method: 'GET', // or 'POST' or other HTTP method
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            const data = await response.json();

            const repoData = {
                filesData: data,
            };

            setRepoList(repoData);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };
    
    const getRepoStats = async () => {
        try {
            setIsLoading(true);
            const apiUrl = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/folder-stats?folder_name=${repository.name}&user=${repository.email}&mode=${mode}`);
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            // console.log('Folder Stats:', data);
            setRepoStat(data);
            // console.log(data.filesData);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
            setErrorMessage(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await getRepoStats();
        }

        fetchData();
    }, [repository]);

    useEffect(() => {
        async function fetchData() {
            if (!repoList) {
                await getRepoFilesData(repository.repoid);
            }
        }

        fetchData();
    }, [repoList]);

    if (isLoading) {
        return (
            <div className='w-full h-96 max-h-96 flex justify-center items-center'>
                <LoadingView loadingText={'Loading stats ...'} />
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className='w-full p-3 h-96 max-h-96 overflow-auto flex flex-col justify-center items-center text-buttons-alert400 font-semibold'>
                <FontAwesomeIcon icon={faExclamationTriangle} size='2x' />
                <div className='mt-2'>{errorMessage}</div>
            </div>
        );
    }

    return (
        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col px-6 py-6 rounded-xl bg-backgrounds-slate'>
            <RenderList repository={repoStat} filesListData={repoList} />
        </div>
    );
};
