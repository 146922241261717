import React, { useEffect, useState } from 'react';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import axios from 'axios';
import { LoadingView } from '../../reusableComponents';
import { CombinedReportsViewer } from './combinedReportsViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { getUrl } from '../../pdfDisplay/utils';

const getRepoFilesData = async (user, repoId) => {
    try {
        const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_alldocs/${repoId}`);

        const encodedToken = encodeURIComponent(user.token);
        const response = await axios.get(dynamicUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: encodedToken,
            },
        });

        if (response && response.data) {
            return response.data;
        }
        return [];
    } catch (error) {
        console.log(error);
    }
};

export const ReportContainer = ({
    extractionRepo,
    layouts,
    layoutName,
    template,
    reportHeaderName,
    setReportHeaderName,
    reportTitleData,
    AppData,
    CompanyData,
    enableEditing,
    isLoading,
    loadingText,
    pdfRef,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const [completeLayouts, setCompleteLayouts] = useState(layouts);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        (async () => {
            if (user && extractionRepo.repoid) {
                const list = await getRepoFilesData(
                    user,
                    extractionRepo.repoid
                );

                // sorting list as data will be in file name sorted order.
                const sortedList = list.sort((a, b) => {
                    if (a.name && b.name) {
                        return a.name.localeCompare(b.name);
                    }
                    return 0;
                });

                setFileList(sortedList);
            }
        })();
    }, []);

    useEffect(() => {
        if (layouts) {
            setCompleteLayouts(layouts);
        }
    }, [layouts]);

    if (isLoading) {
        return (
            <div className='w-full h-full flex justify-center items-center'>
                <LoadingView loadingText={loadingText} inLine={false} />
            </div>
        );
    }

    if (layouts.length === 0 || !layoutName) {
        return (
            <div
                id='report'
                ref={pdfRef}
                className={`w-[795px] h-[1032px] bg-backgrounds-white shadow-xl py-12`}
            >
                <div className='flex flex-col w-full h-1/2 justify-center items-center text-lg'>
                    <FontAwesomeIcon
                        icon={faBoxOpen}
                        className='mb-1 text-texts-secondary300'
                        size='3x'
                    />
                    <div className='text-texts-secondary300'>
                        Design a layout for this Template in Report Designer
                        Section
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            id='report'
            ref={pdfRef}
            className={`w-[795px] h-fit bg-backgrounds-white shadow-xl py-12`}
        >
            <CombinedReportsViewer
                layouts={completeLayouts}
                enableEditing={enableEditing}
                fileList={fileList}
                reportHeaderName={reportHeaderName}
                reportTitleData={reportTitleData}
                AppData={AppData}
                CompanyData={CompanyData}
                templateName={template}
                layoutName={layoutName}
                setReportHeaderName={setReportHeaderName}
            />
        </div>
    );
};
