import React, { useEffect, useState } from 'react';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import {
    Button,
    ConfirmModal,
    Container,
    LoadingView,
    Modal,
} from '../../reusableComponents';
import {
    faAdd,
    faAngleRight,
    faBoxes,
    faFileLines,
    faGears,
    faHandPointUp,
    faPencil,
    faSave,
} from '@fortawesome/free-solid-svg-icons';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { generateUniqueId } from '../../../utils';
import {
    generateRandomChartData,
    getFileNameWithoutExt,
    getRootLevelFields,
    toSentenceCase,
} from '../utils';
import { CardOptions } from './cardOptions';
import ReactGridLayout from 'react-grid-layout';
import { Card } from './card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartTypes, DataTypes } from '../constants';
import { ReportTitleFieldsViewer } from './reportTitleFieldsViewer';

const cardSize = 4;
const maxUnits = 8;
const minCardHeight = 3;
const defaultReportTitle = 'fileName';

const NoCardsView = () => {
    return (
        <div className='flex flex-col w-full h-[80vh] items-center justify-center'>
            <div className='flex items-center justify-center text-texts-secondary300 text-xl'>
                <h1>Add layout cards from "Add card" section</h1>
                <FontAwesomeIcon
                    icon={faHandPointUp}
                    className='ml-3 text-icons-primary'
                />
            </div>
            <div className='flex h-2/3 w-full items-center justify-center text-texts-secondary300'>
                <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon
                        icon={faBoxes}
                        //color='gold'
                        className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary'
                    />
                    <div>No Layout Cards</div>
                </div>
            </div>
        </div>
    );
};

const scrollToCard = (cardRef) => {
    cardRef.current?.scrollIntoView({ behavior: 'smooth' });
};

export const LayoutDesigner = ({
    layout,
    templateData,
    selectedTemplate,
    selectedLayout,
    onSaveLayout,
    isLoading,
    loadingText,
    isSaving,
}) => {
    const [completeLayout, setCompleteLayout] = useState(layout);
    const [activeCard, setActiveCard] = useState(null);
    const [metaData, setMetaData] = useState(new Map());
    const [layoutData, setLayoutData] = useState([]);
    const [selectedFieldsData, setSelectedFieldsData] = useState(new Map());
    const [chartsData, setChartsData] = useState(new Map());
    const [viewCardOptions, setViewCardOptions] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [newCardId, setNewCardId] = useState(null);
    const [movingCardId, setMovingCardId] = useState(null);
    const [flash, setFlash] = useState(false);
    const [reportTitleField, setReportTitleField] =
        useState(defaultReportTitle);
    const [viewReportTitleOptions, setViewReportTitleOptions] = useState(false);
    const [useCustomReportTitle, setUseCustomReportTitle] = useState(false);

    // Creating card reference
    const newCardRef = React.createRef();
    const movingCardRef = React.createRef();

    useEffect(() => {
        if (flash) {
            setTimeout(() => {
                setFlash(false);
            }, 3000); // Stop flashing after 3 second
        }
    }, [flash]);

    useEffect(() => {
        if (newCardRef) {
            scrollToCard(newCardRef);
        }

        return () => {};
    }, [newCardRef, newCardId]);

    useEffect(() => {
        if (movingCardRef) {
            scrollToCard(movingCardRef);
        }

        return () => {};
    }, [movingCardRef, movingCardId]);

    useEffect(() => {
        if (layout && templateData) {
            setCompleteLayout(layout);
        }
    }, [layout, templateData]);

    useEffect(() => {
        if (completeLayout) {
            const newMetaData = new Map();
            const newSelectedFieldsData = new Map();
            const newChartsData = new Map();

            const newLayoutData = [];

            if (completeLayout.length) {
                const reportTitle = completeLayout[0].metaData.reportTitle;
                if (reportTitle) {
                    setReportTitleField(reportTitle);
                }

                const useCustomTitle =
                    completeLayout[0].metaData.useCustomReportTitle;
                if (useCustomTitle) {
                    setUseCustomReportTitle(useCustomTitle);
                }

                completeLayout.forEach((data) => {
                    newMetaData.set(data.layoutData.i, data.metaData);
                    newSelectedFieldsData.set(
                        data.layoutData.i,
                        data.selectedFields
                    );

                    const randomChartData = generateRandomChartData(
                        data.selectedFields,
                        data.chartData
                    );
                    const chartData = { ...data.chartData };
                    chartData.data = randomChartData;
                    newChartsData.set(data.layoutData.i, chartData);

                    newLayoutData.push(data.layoutData);

                    setMetaData(newMetaData);
                    setSelectedFieldsData(newSelectedFieldsData);
                    setChartsData(newChartsData);

                    setLayoutData(newLayoutData);
                });
            } else if (templateData) {
                addMultipleNewCard(templateData);
                onSaveLayout(getCompleteLayoutData());
            }
        }
    }, [completeLayout, selectedLayout]);

    // Function to add multiple new card
    const addMultipleNewCard = async (templateData) => {
        const newMetaData = new Map();
        const newSelectedFieldsData = new Map();
        const newChartsData = new Map();
        let newLayoutData = [];
        setReportTitleField(defaultReportTitle);

        const uniqueRootLevelFields = getRootLevelFields(templateData);
        uniqueRootLevelFields.forEach((fieldData) => {
            let posX = 0;
            let posY = 0;
            let LocalMaxX = 0;
            let LocalMaxY = 0;

            if (newLayoutData.length) {
                newLayoutData.forEach((data) => {
                    if (data.y > LocalMaxY) {
                        LocalMaxY = data.y;
                    }
                });

                const filteredData = newLayoutData.filter(
                    (data) => data.y === LocalMaxY
                );
                filteredData.forEach((data) => {
                    if (data.x > LocalMaxX) {
                        LocalMaxX = data.x;
                    }
                });

                if (LocalMaxX === maxUnits) {
                    posX = 0;
                    posY = LocalMaxY + cardSize;
                } else {
                    posX = LocalMaxX + cardSize;
                    posY = LocalMaxY;
                }
            }

            const id = generateUniqueId();

            const newMeta = {
                id: id,
                name: fieldData ? toSentenceCase(fieldData) : 'Untitled Card',
                isCardNameHidden: false,
                useCustomReportTitle: false,
                reportTitle: defaultReportTitle,
            };

            const newCardData = {
                i: id,
                x: posX,
                y: posY,
                w: cardSize,
                h: cardSize,
                minW: cardSize,
                minH: minCardHeight,
            };

            newMetaData.set(id, newMeta);

            const selectedField = [
                { field: fieldData, type: DataTypes.STRING },
            ];

            newSelectedFieldsData.set(id, fieldData ? selectedField : []);

            newChartsData.set(id, {
                chart: ChartTypes.TABLE,
                useRowFormat: false,
                data: generateRandomChartData(selectedField, {
                    chart: ChartTypes.TABLE,
                    useRowFormat: false,
                }),
            });

            newLayoutData.push(newCardData);
        });
        setMetaData(newMetaData);
        setSelectedFieldsData(newSelectedFieldsData);
        setChartsData(newChartsData);
        setLayoutData(newLayoutData);
    };

    // Function to add new card
    const addNewCard = (cardName, selectedFields) => {
        let posX = 0;
        let posY = 0;
        let LocalMaxX = 0;
        let LocalMaxY = 0;

        if (layoutData.length) {
            layoutData.forEach((data) => {
                if (data.y > LocalMaxY) {
                    LocalMaxY = data.y;
                }
            });

            const filteredData = layoutData.filter(
                (data) => data.y === LocalMaxY
            );
            filteredData.forEach((data) => {
                if (data.x > LocalMaxX) {
                    LocalMaxX = data.x;
                }
            });

            if (LocalMaxX === maxUnits) {
                posX = 0;
                posY = LocalMaxY + cardSize;
            } else {
                posX = LocalMaxX + cardSize;
                posY = LocalMaxY;
            }
        }

        const id = generateUniqueId();

        const newMeta = {
            id: id,
            name: cardName ? cardName : 'Untitled Card',
            isCardNameHidden: false,
            useCustomReportTitle: useCustomReportTitle,
            reportTitle: reportTitleField,
        };

        const newCardData = {
            i: id,
            x: posX,
            y: posY,
            w: cardSize,
            h: cardSize,
            minW: cardSize,
            minH: minCardHeight,
        };

        const copyMetaData = new Map(metaData);
        copyMetaData.set(id, newMeta);
        setMetaData(copyMetaData);

        const copySelectedFieldsData = new Map(selectedFieldsData);
        copySelectedFieldsData.set(id, selectedFields ? selectedFields : []);
        setSelectedFieldsData(copySelectedFieldsData);

        const copyChartsData = new Map(chartsData);
        copyChartsData.set(id, {
            chart: ChartTypes.TABLE,
            useRowFormat: false,
            data: [],
        });
        setChartsData(copyChartsData);

        const newLayoutData = [...layoutData, newCardData];
        setLayoutData(newLayoutData);

        setNewCardId(id);

        // Flash the newly created card
        setFlash(true);
    };

    // Function to remove a card from view.
    const removeCard = (id) => {
        const copyMetaData = new Map(metaData);
        copyMetaData.delete(id);
        setMetaData(copyMetaData);

        const copySelectedFieldsData = new Map(selectedFieldsData);
        copySelectedFieldsData.delete(id);
        setSelectedFieldsData(copySelectedFieldsData);

        const copyChartsData = new Map(chartsData);
        copyChartsData.delete(id);
        setChartsData(copyChartsData);

        const newLayoutData = layoutData.filter((data) => data.i !== id);
        setLayoutData(newLayoutData);

        // Setting card reference to null to avoid unnecessary scrolling
        setNewCardId(null);
        setFlash(false);
    };

    const getSelectedFields = (activeCard) => {
        if (!activeCard) return [];

        const fieldsData = selectedFieldsData.get(activeCard.i);
        if (fieldsData) {
            return fieldsData;
        }
        return [];
    };

    const getChartData = (activeCard) => {
        if (!activeCard) return { chart: null, data: [] };

        const chartData = chartsData.get(activeCard.i);
        if (chartData) {
            return chartData;
        }
        return { chart: null, data: [] };
    };

    const getMetaData = (activeCard) => {
        const defaultMetaData = { name: '', isCardNameHidden: false };
        if (!activeCard) return defaultMetaData;

        const meta = metaData.get(activeCard.i);
        if (meta) {
            return meta;
        }
        return defaultMetaData;
    };

    const toggleCardNameView = (isHidden) => {
        if (activeCard && metaData) {
            const copyMetaData = new Map(metaData);
            const newData = copyMetaData.get(activeCard.i);
            if (newData) {
                newData.isCardNameHidden = isHidden;
            }

            copyMetaData.set(activeCard.i, newData);
            setMetaData(copyMetaData);
        }
    };

    const toggleUseRowFormat = (useRowFormat) => {
        if (activeCard) {
            // Updating chat data as useRowFormat updated.
            const existingChartData = chartsData.get(activeCard.i);
            const cardFieldsData = selectedFieldsData.get(activeCard.i);

            if (existingChartData) {
                const newChartData = { ...existingChartData };
                newChartData.useRowFormat = useRowFormat;
                const randomChartData = generateRandomChartData(
                    cardFieldsData,
                    newChartData
                );
                newChartData.data = randomChartData;

                const copyChartsData = new Map(chartsData);
                copyChartsData.set(activeCard.i, newChartData);
                setChartsData(copyChartsData);
            }
        }
    };

    const onChangeCardName = (value) => {
        if (activeCard && metaData) {
            const copyMetaData = new Map(metaData);
            const newData = copyMetaData.get(activeCard.i);
            if (newData) {
                newData.name = value;
            }

            copyMetaData.set(activeCard.i, newData);
            setMetaData(copyMetaData);
        }
    };

    const onChangeFieldType = (field, dataType) => {
        if (activeCard) {
            const copySelectedFieldsData = new Map(selectedFieldsData);
            const fieldsData = copySelectedFieldsData.get(activeCard.i);

            const foundIndex = fieldsData.findIndex(
                (data) => data.field === field
            );
            if (foundIndex > -1) {
                fieldsData[foundIndex].type = dataType;
                updateSelectedFields(fieldsData);
            }
        }
    };

    const updateSelectedFields = (updatedSelectedFields) => {
        if (selectedFieldsData && updatedSelectedFields) {
            // Updating selected fields data
            const copySelectedFieldsData = new Map(selectedFieldsData);
            copySelectedFieldsData.set(activeCard.i, updatedSelectedFields);
            setSelectedFieldsData(copySelectedFieldsData);

            // Updating chat data as selected fields updated.
            const existingChartData = chartsData.get(activeCard.i);
            const newChartData = { ...existingChartData };
            const randomChartData = generateRandomChartData(
                updatedSelectedFields,
                existingChartData
            );
            newChartData.data = randomChartData;

            const copyChartsData = new Map(chartsData);
            copyChartsData.set(activeCard.i, newChartData);
            setChartsData(copyChartsData);
        }
    };

    // Function to update the chart.
    const onUpdateChart = (updatedChartData) => {
        if (updatedChartData) {
            // Updating chat data as selected fields updated.
            const existingChartData = chartsData.get(activeCard.i);
            const newChartData = { ...existingChartData };
            newChartData.chart = updatedChartData.chart;
            if (updatedChartData.options) {
                newChartData.options = updatedChartData.options;
            }

            const copyChartsData = new Map(chartsData);
            copyChartsData.set(activeCard.i, newChartData);
            setChartsData(copyChartsData);
        }
    };

    const getCompleteLayoutData = () => {
        const completeLayoutData = [];
        layoutData.forEach((data) => {
            const meta = metaData.get(data.i);
            meta.useCustomReportTitle = useCustomReportTitle;
            meta.reportTitle = reportTitleField;
            const completeSubData = {
                metaData: meta,
                layoutData: data,
                selectedFields: selectedFieldsData.get(data.i),
                chartData: {
                    chart: chartsData.get(data.i).chart,
                    useRowFormat: chartsData.get(data.i).useRowFormat,
                    options: chartsData.get(data.i).options,
                },
            };

            completeLayoutData.push(completeSubData);
        });
        return completeLayoutData;
    };

    // console.log('###############################################');
    // console.log('Meta Data', metaData);
    // console.log('Layout Data', layoutData);
    // console.log('Selected Fields Data', selectedFieldsData);
    // console.log('chart Data', chartsData);

    const cards = React.useMemo(() => {
        return layoutData.map((layout, idx) => (
            <div
                key={layout.i}
                data-grid={{
                    x: layout.x,
                    y: layout.y,
                    w: layout.w,
                    h: layout.h,
                    minW: layout.minW ? layout.minW : cardSize,
                    minH: layout.minH ? layout.minH : minCardHeight,
                    isDraggable: true,
                    isResizable: true,
                }}
                className={`w-fit h-fit shadow rounded-xl z-10 select-none bg-backgrounds-white border cursor-move ${
                    layout.i === newCardId && flash ? 'flash' : ''
                }`}
            >
                {/* Reference div for auto scrolling to new card */}
                {layout.i === newCardId && <div ref={newCardRef} />}

                {/* Reference div for auto scrolling to dragging card  */}
                {layout.i === movingCardId && <div ref={movingCardRef} />}

                <Card
                    layout={layout}
                    metaData={metaData}
                    selectedFieldsData={selectedFieldsData}
                    chartsData={chartsData}
                    setActiveCard={setActiveCard}
                    setViewCardOptions={setViewCardOptions}
                    setShowConfirmationModal={setShowConfirmationModal}
                />
            </div>
        ));
    }, [layoutData, metaData, selectedFieldsData, chartsData]);

    const actions = [
        {
            text: 'Add Card',
            icon: faAdd,
            action: () => addNewCard(),
            hoverText: 'Add new card',
            type: 'success',
            disabled: isLoading || isSaving,
        },
        {
            text: isSaving ? (
                <div>
                    <span className='mr-2'>Saving</span>
                    <Spin
                        indicator={
                            <LoadingOutlined style={{ fontSize: 18 }} spin />
                        }
                    />
                </div>
            ) : (
                'Save Layout'
            ),
            className: 'w-32',
            icon: faSave,
            action: async () => {
                await onSaveLayout(getCompleteLayoutData());
            },
            hoverText: isSaving ? 'Saving report layout' : 'Save report layout',
            type: 'success',
            disabled: !selectedTemplate || isLoading || isSaving,
        },
    ];

    const layoutTitle = (
        <div className='flex justify-center items-center'>
            <div
                className='text-texts-secondary300 truncate'
                title='Related Template'
            >
                {selectedTemplate && selectedTemplate.filename
                    ? getFileNameWithoutExt(selectedTemplate.filename)
                    : ''}
            </div>
            <FontAwesomeIcon
                icon={faAngleRight}
                className='mx-2 text-texts-secondary300'
            />
            <div className='mr-4 truncate'>
                {selectedLayout && selectedLayout.name
                    ? getFileNameWithoutExt(selectedLayout.name)
                    : 'Layout File'}
            </div>
        </div>
    );

    if (isLoading)
        return (
            <div className='w-full h-full flex justify-center items-center'>
                <LoadingView loadingText={loadingText} inLine={false} />
            </div>
        );

    return (
        <Container
            title={layoutTitle}
            icon={faFileLines}
            iconColor={'backgrounds-settings100'}
            actions={actions}
            backgroundColor={'white'}
        >
            <div className='h-fit flex bg-backgrounds-slate justify-center p-4'>
                <div className='min-w-[1020px] min-h-[1248px] h-fit p-3 bg-backgrounds-white shadow-xl'>
                    <div className='w-full px-12'>
                        <div className='w-full flex justify-between items-center bg-backgrounds-primary100 shadow-inner font-semibold text-texts-secondary300 rounded py-1 px-2'>
                            <div className='mr-2'>Report Title</div>
                            <div className='text-backgrounds-primary500 truncate'>
                                {useCustomReportTitle
                                    ? reportTitleField
                                    : toSentenceCase(reportTitleField)}
                                <span className='ml-2 text-texts-secondary300 text-sm'>
                                    (
                                    {useCustomReportTitle
                                        ? 'Custom title'
                                        : 'Field'}
                                    )
                                </span>
                            </div>
                            <Button
                                // text={'Edit Card'}
                                icon={faPencil}
                                className={'ml-2'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setViewReportTitleOptions(true);
                                }}
                                hoverText={'Edit report title'}
                                type={'Warning'}
                            />
                        </div>
                    </div>

                    {layoutData.length ? (
                        <ReactGridLayout
                            className='layout'
                            rowHeight={45}
                            width={1000}
                            cols={12}
                            resizeHandles={['se', 'sw']}
                            containerPadding={[50, 20]}
                            onLayoutChange={(layout) => setLayoutData(layout)}
                            onDragStart={(
                                layout,
                                oldItem,
                                newItem,
                                placeholder,
                                e,
                                element
                            ) => {
                                setMovingCardId(newItem.i);
                            }}
                            onDrag={(
                                layout,
                                oldItem,
                                newItem,
                                placeholder,
                                e,
                                element
                            ) => scrollToCard(movingCardRef)}
                            onDrop={(layout, item, e) =>
                                scrollToCard(movingCardRef)
                            }
                        >
                            {cards}
                        </ReactGridLayout>
                    ) : (
                        <NoCardsView />
                    )}

                    {viewReportTitleOptions && (
                        <Modal
                            title={`Report Title Options`}
                            titleIcon={faGears}
                            iconColor={'backgrounds-settings100'}
                            onClose={() => {
                                if (!reportTitleField) {
                                    alert(
                                        'Report Title field can not be empty!'
                                    );
                                } else {
                                    setViewReportTitleOptions(false);
                                }
                            }}
                        >
                            <ReportTitleFieldsViewer
                                schema={templateData}
                                reportTitleField={reportTitleField}
                                setReportTitleField={setReportTitleField}
                                useCustomReportTitle={useCustomReportTitle}
                                setUseCustomReportTitle={
                                    setUseCustomReportTitle
                                }
                                defaultReportTitle={defaultReportTitle}
                            />
                        </Modal>
                    )}

                    {viewCardOptions && (
                        <Modal
                            title={`Card Options`}
                            titleIcon={faGears}
                            iconColor={'backgrounds-settings100'}
                            onClose={() => {
                                const meta = metaData.get(activeCard.i);
                                if (meta) {
                                    if (!meta.name) {
                                        alert(`Card Title cannot be empty!`);
                                    } else {
                                        setViewCardOptions(false);
                                    }
                                }
                            }}
                        >
                            <CardOptions
                                templateData={templateData}
                                metaData={getMetaData(activeCard)}
                                chartData={getChartData(activeCard)}
                                selectedFields={getSelectedFields(activeCard)}
                                onChangeCardName={onChangeCardName}
                                toggleCardNameView={toggleCardNameView}
                                toggleUseRowFormat={toggleUseRowFormat}
                                updateSelectedFields={updateSelectedFields}
                                onChangeFieldType={onChangeFieldType}
                                onUpdateChart={onUpdateChart}
                            />
                        </Modal>
                    )}

                    {showConfirmationModal && (
                        <ConfirmModal
                            modalText={`Are you sure you want to delete ${
                                getMetaData(activeCard).name
                            }?`}
                            onConfirm={() => {
                                removeCard(activeCard.i);
                                setShowConfirmationModal(false);
                            }}
                            onCancel={() => setShowConfirmationModal(false)}
                        />
                    )}
                </div>
            </div>
        </Container>
    );
};
