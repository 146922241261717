import React from 'react';
import {
    appLogo,
    companyLogo,
    appName,
    contactEmail,
    contactNumber,
} from '../Constants';
import Footer from '../Footer';
import { useMenuItems } from '../menuItems';
import Navbar from '../Navbar';

const RefundsAndCancellations = () => {
    const { menuItems } = useMenuItems();
    return (
        <>
            <Navbar menuItems={menuItems} />
            <div className='mt-24 mx-0 md:mx-8 lg:mx-20 p-8'>
                <h1 className='font-bold text-3xl mb-8 text-center'>
                    Refund & Cancellation Policy
                </h1>

                {/* Refunds & Cancellations Content */}
                <div className='text-texts-secondary'>
                    <p className='mb-4'>
                        At {appName}, we strive to provide a seamless and
                        satisfactory experience with our credit purchasing
                        system. This refund policy outlines the conditions under
                        which credits purchased from our platform can be
                        refunded.
                    </p>
                    <section className='mb-6'>
                        <h2 className='text-xl font-semibold mb-2'>
                            1. Credits are Non-Refundable
                        </h2>
                        <p>
                            Please note that, generally, all purchases are
                            considered final and non-refundable. We do not offer
                            refunds or credits for partially used periods,
                            except as required by applicable laws in your
                            jurisdiction and certain criteria established by our
                            company.
                        </p>
                    </section>
                    <section className='mb-6'>
                        <h2 className='text-xl font-semibold mb-2'>
                            2. Refundable Circumstances
                        </h2>
                        <p>
                            Refunds will only be granted in special
                            circumstances like legality related to state laws.
                        </p>
                    </section>
                    <section className='mb-6'>
                        <h2 className='text-xl font-semibold mb-2'>
                            3. Process for Requesting a Refund
                        </h2>
                        <p>To request a refund, follow these steps:</p>
                        <ol className='list-decimal list-inside pl-5'>
                            <li>
                                Contact our customer support team at{' '}
                                <a
                                    href={`mailto:${contactEmail}`}
                                    className='text-blue-600 hover:underline'
                                >
                                    {contactEmail}
                                </a>{' '}
                                or {contactNumber}.
                            </li>
                            <li>
                                Provide your purchase details, including the
                                transaction ID, purchase date, and the amount of
                                credits purchased.
                            </li>
                            <li>
                                Our team will review your request and respond
                                within 5-7 business days.
                            </li>
                        </ol>
                    </section>
                    <section className='mb-6'>
                        <h2 className='text-xl font-semibold mb-2'>
                            4. Refund Method
                        </h2>
                        <p>
                            Approved refunds will be processed back to the
                            original payment method used at the time of
                            purchase. Please allow 7-10 business days for the
                            refund to appear on your account, depending on your
                            financial institution.
                        </p>
                    </section>
                    <section className='mb-6'>
                        <h2 className='text-xl font-semibold mb-2'>
                            5. Disputes and Chargebacks
                        </h2>
                        <p>
                            If a chargeback is initiated on a credit purchase,
                            the credits associated with the chargeback will be
                            forfeited and your account may be subject to
                            suspension.
                        </p>
                    </section>
                    <section className='mb-6'>
                        <h2 className='text-xl font-semibold mb-2'>
                            6. Changes to This Policy
                        </h2>
                        <p>
                            {appName} reserves the right to amend this refund
                            policy at any time. Changes will be communicated via
                            email or through our website, and the effective date
                            will be updated accordingly.
                        </p>
                    </section>
                    <section className='mb-6'>
                        <h2 className='text-xl font-semibold mb-2'>
                            7. Contact Information
                        </h2>
                        <p>
                            If you have any questions or concerns about this
                            refund policy, please contact us at:
                        </p>
                        <ul className='list-disc list-inside pl-5'>
                            {contactEmail ? (
                                <li>
                                    Email:{' '}
                                    <a
                                        href={`mailto:${contactEmail}`}
                                        className='text-blue-600 hover:underline'
                                    >
                                        {contactEmail}
                                    </a>
                                </li>
                            ) : (
                                <></>
                            )}
                            {contactNumber ? (
                                <li>Phone: {contactNumber}</li>
                            ) : (
                                <></>
                            )}
                        </ul>
                    </section>{' '}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default RefundsAndCancellations;
