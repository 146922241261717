export const Currency = Object.freeze({
    USD: {
        key: 'USD',
        symbol: '$',
        suggestedAmount: [10, 100, 200, 500],
    },
    INR: {
        key: 'INR',
        symbol: '₹',
        suggestedAmount: [500, 1000, 10000, 50000],
    },
});
