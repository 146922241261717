import { isNumeric } from './isNumeric';

export const convertKeysToSimilarFormat = (data) => {
    if (Array.isArray(data)) {
        // If data is an array, loop through each element and recursively call the function
        return data.map((item) => convertKeysToSimilarFormat(item));
    } else if (typeof data === 'object' && data !== null) {
        // If data is an object, create a new object with lowercase keys and recursively call the function for its values
        const newData = {};
        for (const key in data) {
            let newKey = key;
            if (!isNumeric(key)) {
                newKey = key
                    .toLowerCase()
                    .replace(/[^a-zA-Z0-9 ]/g, ' ')
                    .trim();
            }

            const value = data[key];
            newData[newKey] = convertKeysToSimilarFormat(value);
        }
        return newData;
    }
    // If data is neither an array nor an object, return it as it is
    return data;
};
