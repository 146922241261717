import React from 'react';
import { Chart } from 'react-google-charts';

import { Button } from '../../reusableComponents';
import {
    faClose,
    faEyeSlash,
    faPencil,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartTypes, defaultChartOptions } from '../constants';
import { toSentenceCase } from '../utils';

const ChartView = ({ chartData, chartName }) => {
    const options = { ...defaultChartOptions };
    options.title = chartName;

    if (!chartData || !chartData.chart) {
        return (
            <div className='flex w-full h-4/5 justify-center items-center'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2 text-backgrounds-settings500'
                />
                <span className='text-texts-secondary300 font-semibold text-outline drop-shadow-sm'>
                    No Chart Selected
                </span>
            </div>
        );
    }

    if (!chartData || !chartData.chart || chartData.data.length === 0) {
        return (
            <div className='flex w-full h-4/5 justify-center items-center'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2 text-backgrounds-settings500'
                />
                <span className='text-texts-secondary300 font-semibold text-outline drop-shadow-sm'>
                    Graph Data Empty
                </span>
            </div>
        );
    }

    return (
        <div
            className={`w-full h-full ${
                chartData.useRowFormat ? 'row-table' : ''
            }`}
            style={{
                filter:
                    chartData.chart === ChartTypes.TABLE
                        ? 'blur(7px)'
                        : 'blur(2.5px)',
            }}
        >
            <Chart
                chartType={chartData.chart}
                width='100%'
                height='87%'
                data={chartData.data}
                options={chartData.options ? chartData.options : options}
                chartPackages={['corechart', 'controls']}
            />
        </div>
    );
};

const FieldsView = ({ selectedFields }) => {
    if (!selectedFields || selectedFields.length === 0) {
        return (
            <div className='flex w-full h-1/2 justify-center items-center absolute top-12'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2 text-backgrounds-settings500'
                />
                <span className='text-texts-secondary300 font-semibold text-outline drop-shadow-sm'>
                    No Field Selected
                </span>
            </div>
        );
    }

    return (
        <div
            className='w-full flex flex-col justify-center items-center absolute top-12 z-10 bg-transparent'
            style={{ height: '60%' }}
        >
            <div className='hover:bg-opacity-70 hover:bg-backgrounds-white hover:backdrop-blur-lg hover:shadow p-2 rounded-xl max-h-full max-w-full overflow-auto'>
                {selectedFields.map((data) => (
                    <div>
                        <span className='text-texts-secondary300 mr-2 font-semibold text-outline drop-shadow-sm'>
                            {toSentenceCase(data.field)}
                        </span>
                        <span className='text-backgrounds-primary500 font-semibold text-outline drop-shadow-sm'>
                            {data.type}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const isValidCard = (chartData, selectedFields) => {
    if (
        !chartData ||
        !chartData.chart ||
        !chartData.data ||
        chartData.data.length === 0
    ) {
        return false;
    }

    if (!selectedFields || selectedFields.length === 0) {
        return false;
    }

    return true;
};

export const Card = ({
    layout,
    metaData,
    selectedFieldsData,
    chartsData,
    setActiveCard,
    setViewCardOptions,
    setShowConfirmationModal,
}) => {
    const cardName = metaData.get(layout.i) ? metaData.get(layout.i).name : '';
    const isCardNameHidden = metaData.get(layout.i)
        ? metaData.get(layout.i).isCardNameHidden
        : false;
    const chartData = chartsData.get(layout.i);
    const selectedFields = selectedFieldsData.get(layout.i);

    return (
        <div className='w-full h-full rounded-xl'>
            {/* Card Header */}
            <div
                className={`w-full flex justify-between items-center p-2 border-b rounded-t-xl shadow-inner ${
                    isValidCard(chartData, selectedFields)
                        ? 'bg-backgrounds-primary100'
                        : 'bg-buttons-alert'
                }`}
            >
                <div className='font-semibold text-texts-secondary300 p-1 drop-shadow-sm truncate'>
                    {isCardNameHidden && (
                        <span
                            className='truncate mr-2 cursor-default'
                            title='Card title hidden in actual report'
                        >
                            <FontAwesomeIcon icon={faEyeSlash} />
                        </span>
                    )}
                    <span className='w-full truncate'>{cardName}</span>
                </div>
                <div className='flex justify-center items-center'>
                    <Button
                        // text={'Edit Card'}
                        icon={faPencil}
                        className={'mr-2'}
                        onClick={(e) => {
                            e.stopPropagation();
                            setActiveCard(layout);
                            setViewCardOptions(true);
                        }}
                        hoverText={'Edit Card'}
                        type={'Warning'}
                    />
                    <Button
                        // text={'Delete Card'}
                        icon={faClose}
                        className={''}
                        onClick={(e) => {
                            e.stopPropagation();
                            setActiveCard(layout);
                            setShowConfirmationModal(true);
                        }}
                        hoverText={'Remove card'}
                        type={'danger'}
                    />
                </div>
            </div>

            {/* Card Body */}
            <div className='w-full' style={{ height: '88%' }}>
                <ChartView chartData={chartData} chartName={cardName} />
                <FieldsView selectedFields={selectedFields} />
            </div>
        </div>
    );
};
