// Function to verify if the name is a valid file/folder name.
export const isValidName = (name, isFile) => {
    // Regular expression to check for invalid characters
    const invalidChars = /[\/:*?"<>|]/;
    // Regular expression to check for valid characters and position of periods
    const validFilePattern = /^[^ .][^\/:*?"<>|]{0,253}[^ .]$/;
    const validFolderPattern = /^[^ .][^\/:*?"<>|]{0,254}$/;

    const result = {
        isValid: true,
        error: null,
    };

    // Check for invalid characters
    if (invalidChars.test(name)) {
        result.isValid = false;
        result.error = 'Name contains invalid characters!';
        return result;
    }

    // Check length
    if (name.length < 1) {
        result.isValid = false;
        result.error = 'Name cannot be empty!';
        return result;
    }

    if (name.length > 60) {
        result.isValid = false;
        result.error = 'Name characters length cannot be more than 60.';
        return result;
    }

    // Check for valid pattern
    if (isFile) {
        if (!validFilePattern.test(name)) {
            result.isValid = false;
            result.error = 'Invalid file name format!';
            return result;
        }
    } else {
        if (!validFolderPattern.test(name)) {
            result.isValid = false;
            result.error = 'Invalid directory name format!';
            return result;
        }
    }

    return result;
};
