import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
    createModifiedHighlightData,
    getMatchedIndexData,
    getIndexLineData,
    getLineWordCount,
    isInPageRange,
    renderMark,
} from './utils';
import {
    ErrorDataComponent,
    LoadingComponent,
    LoadingPageComponent,
    NoDataComponent,
} from './reusableComponents';
import { DataTypes, safeWordRangeData } from './constant';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const pageRange = 1;
let currentWordsCountData = null;
let enableHighlightData = null;

const resetData = () => {
    currentWordsCountData = {};
    enableHighlightData = {};
    Object.keys(DataTypes).forEach((key) => {
        currentWordsCountData[DataTypes[key]] = [];
        enableHighlightData[DataTypes[key]] = [];
    });
};

// Function that highlights the pdf by modifying the text layer.
const renderMultipleHighlights = (
    lineData,
    indexData,
    totalWordsCountData,
    pageNumber,
    activeChunkPage,
    pageRange
) => {
    // console.log('LINE DATA: ', lineData);

    const lineText = lineData.str;
    const lineIndex = lineData.itemIndex;

    const keysData = Object.keys(indexData);

    for (let i = 0; i < keysData.length; i++) {
        // console.log('LINE INDEX:', lineIndex);
        // console.log('LINE TXT:', lineText);
        // console.log('-------------------------------------');

        for (let j = 0; j < totalWordsCountData[keysData[i]].length; j++) {
            if (isInPageRange(pageNumber, activeChunkPage, pageRange)) {
                const line = indexData[keysData[i]][lineIndex];
                const startWord = line ? line.split(' ')[0] : null;

                if (
                    enableHighlightData[keysData[i]][j] &&
                    currentWordsCountData[keysData[i]][j] <
                        totalWordsCountData[keysData[i]][j]
                ) {
                    currentWordsCountData[keysData[i]][j] +=
                        getLineWordCount(lineText);

                    if (
                        currentWordsCountData[keysData[i]][j] >=
                        totalWordsCountData[keysData[i]][j]
                    ) {
                        enableHighlightData[keysData[i]][j] = false;
                    }

                    return renderMark(keysData[i], lineText, null);
                }

                if (startWord) {
                    enableHighlightData[keysData[i]][j] = true;
                    currentWordsCountData[keysData[i]][j] =
                        getLineWordCount(lineText);

                    return renderMark(keysData[i], lineText, startWord);
                }
            }
        }
    }

    return lineText;
};

export const PdfViewer = ({
    pdfFile,
    fileName,
    highlightTexts,
    pageNumber,
    activeChunkPage,
    setPageNumber,
    setTotalPageNumbers,
    scale,
}) => {
    const [highlightData, setHighlightData] = useState(null);
    const [wordCountData, setWordCountData] = useState(null);
    const [lineData, setLineData] = useState(null);
    const [indexData, setIndexData] = useState(null);
    // const [completePageData, setCompletePageData] = useState(null);

    useEffect(() => {
        resetData();

        return () => {};
    }, [activeChunkPage]);

    useEffect(() => {
        if (pageNumber === activeChunkPage) {
            resetData();
        }

        return () => {};
    }, [pageNumber, activeChunkPage]);

    // Sanitize the modify new highlight data and get total word count data on highlight data change
    useEffect(() => {
        resetData();

        // console.log('ORIGINAL HIGHLIGHT DATA:\n', highlightTexts);

        const modifiedData = createModifiedHighlightData(
            highlightTexts,
            safeWordRangeData,
            true
        );

        if (modifiedData && modifiedData.highlightData) {
            setHighlightData(modifiedData.highlightData);
        }

        if (modifiedData && modifiedData.totalWordsCountData) {
            setWordCountData(modifiedData.totalWordsCountData);
        }

        return () => {};
    }, [highlightTexts]);

    // Setting line data on page change along with new text layer load.
    const setPageData = useCallback(
        (pageData) => {
            if (pageNumber && pageData) {
                const data = getIndexLineData(pageData);
                if (data) {
                    setLineData(data);
                }

                return;
            }
        },
        [pageNumber]
    );

    // Setting index data on lineData or highligh data change
    useEffect(() => {
        resetData();
        if (highlightData && lineData) {
            const completeIndexData = getMatchedIndexData(
                lineData,
                highlightData
            );
            setIndexData(completeIndexData);
        }

        return () => {};
    }, [highlightData, lineData]);

    // Rendering callback function, called for each line of document per page.
    const textLayerRenderer = useCallback(
        (lineData) => {
            if (indexData && pageNumber && activeChunkPage) {
                return renderMultipleHighlights(
                    lineData,
                    indexData,
                    wordCountData,
                    pageNumber,
                    activeChunkPage,
                    pageRange
                );
            }
        },
        [indexData, pageNumber, activeChunkPage, wordCountData]
    );

    const onItemClick = ({ pageNumber: itemPageNumber }) => {
        setPageNumber(itemPageNumber);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPageNumbers(numPages);
        setPageNumber(pageNumber || 1);
    };

    return (
        <div className='relative w-full h-full'>
            <div
                className='main-container min-w-fit flex justify-center items-start'
                style={{ minHeight: '40vh', maxHeight: '68vh' }}
            >
                <Document
                    file={pdfFile}
                    onItemClick={onItemClick}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<LoadingComponent fileName={fileName} />}
                    noData={<NoDataComponent fileName={fileName} />}
                    error={<ErrorDataComponent fileName={fileName} />}
                >
                    <Page
                        key={pageNumber}
                        pageNumber={pageNumber}
                        renderTextLayer={true}
                        renderAnnotationLayer={true}
                        renderForms={true}
                        onGetTextSuccess={setPageData}
                        customTextRenderer={textLayerRenderer}
                        scale={scale}
                        loading={
                            <LoadingPageComponent pageNumber={pageNumber} />
                        }
                    />
                </Document>
            </div>
        </div>
    );
};
