import React, { useEffect, useState } from 'react';
import { Modal } from './modal';
import { Table } from 'antd';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { DataTypes } from '../reportLayouts/constants';
import { camelToTitleCase } from '../../utils';

// Expected data
// tableColumns = [column1,column2,column3]
// tableData =  [
//                  [data1,data2,data3],
//                  [data4,data5,data6],
//                  [data7,data8,data9]
//              ]

const pageSize = 10;

export const TableModal = ({
    title,
    titleIcon,
    iconColor,
    onClose,
    tableColumns,
    tableData,
    completeData,
    pagination,
    modalClassName,
}) => {
    const [columnData, setColumnData] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        const columns = tableColumns.map((column, index) => {
            return {
                title: camelToTitleCase(column),
                dataIndex: column,
                key: column,
                sorter: (a, b) => {
                    if (
                        typeof a[column] &&
                        typeof b[column] === DataTypes.NUMBER
                    ) {
                        return a[column] - b[column];
                    }
                    return a[column]
                        .toString()
                        .localeCompare(b[column].toString());
                },
            };
        });
        setColumnData(columns);

        const dataSource = tableData.map((rowData, index) => {
            const dataObject = { key: index };
            tableColumns.forEach((column, columnIndex) => {
                dataObject[column] = rowData[columnIndex];
            });
            return dataObject;
        });
        setData(dataSource);

        return () => {};
    }, [tableData, tableColumns]);

    return (
        <Modal
            title={title ? title : 'Table Data'}
            titleIcon={titleIcon ? titleIcon : faTable}
            iconColor={iconColor ? iconColor : 'sky'}
            onClose={onClose}
            className={modalClassName ? modalClassName : ''}
        >
            <div className='w-fit'>
                {completeData === false ? (
                    <div className='text-backgrounds-primary300 p-2 text-sm'>
                        This is partial table data due to data limitation of{' '}
                        {tableData.length} rows per question
                    </div>
                ) : (
                    <></>
                )}
                <Table
                    dataSource={data}
                    columns={columnData}
                    bordered={true}
                    pagination={
                        pagination
                            ? pagination
                            : data.length <= pageSize
                            ? false
                            : { pageSize: pageSize }
                    }
                    size='small'
                />
            </div>
        </Modal>
    );
};
