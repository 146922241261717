//settingsContainer.jsx
import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import { Container, NoInteractionView } from '../reusableComponents';
import { faGear, faSave } from '@fortawesome/free-solid-svg-icons';
import { SettingsContent } from './settingsContent';
import { DefaultPromptContext } from '../../context/DefaultPromptContext';
import { PROMPT } from '../../enums';
import { getUrl } from '../pdfDisplay/utils';
import { base64ToBlob } from '../../utils';
import { validateVendorsData } from './accountSettings';

const fetchAccountDetails = async (user) => {
    try {
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/get-account-details`
        );
        const encodedToken = encodeURIComponent(user.token);
        const formData = new FormData();
        formData.append('token', user.token); // Send token as form data

        const response = await axios.post(dynamicUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: encodedToken,
            },
        });

        if (response) {
            const accountData = response.data;

            if (accountData) {
                if (
                    accountData.vendorDetails &&
                    accountData.vendorDetails.logo
                ) {
                    accountData.vendorDetails.logo = base64ToBlob(
                        accountData.vendorDetails.logo,
                        'image/png'
                    );
                }

                return accountData;
            }
        }
    } catch (err) {
        alert('Failed to get account details: ', err.message);
    }
};

const updateVendorDetails = async (user, accountDetails, setAccountDetails) => {
    try {
        const vendorDetails = accountDetails.vendorDetails;

        if (vendorDetails) {
            // Create FormData instead of sending JSON
            const formData = new FormData();

            // Append text fields to the form data
            formData.append('user_id', user.email);
            formData.append('company_name', vendorDetails.company_name);
            formData.append('contact_number', vendorDetails.contact_number);
            formData.append('contact_email', vendorDetails.contact_email);
            formData.append('company_domain', vendorDetails.company_domain);
            formData.append('copy_right_text', vendorDetails.copy_right_text);

            // If the logo is present, append the file (Blob)
            if (vendorDetails.logo) {
                const fileBlob = new Blob([vendorDetails.logo], {
                    type: vendorDetails.logo.type,
                });
                formData.append('logo', fileBlob, vendorDetails.logo.name); // Append the file as 'logo'
            }

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/update-vendor-account`
            );
            const encodedToken = encodeURIComponent(user.token);

            // Send the FormData
            const response = await axios.post(dynamicUrl, formData, {
                headers: {
                    Authorization: encodedToken,
                },
            });

            const data = response.data;

            let vendorId = null;
            if (data.vendor_id) {
                vendorId = data.vendor_id;
            } else {
                if (accountDetails.vendorDetails) {
                    vendorId = accountDetails.vendorDetails.vendor_id;
                }
            }

            const result = {
                ...vendorDetails,
                vendor_id: vendorId,
            };
            setAccountDetails({ ...accountDetails, vendorDetails: result });
        }
    } catch (err) {
        alert('Failed to update vendor details: ', err.message);
    }
};

export const SettingsContainer = ({
    templateList,
    setTemplateList,
    activeTemplate,
    setActiveTemplate,
    fetchTemplateList,
    accountSettingsRef,
    generalSettingsRef,
    chatsSettingsRef,
    extractionSettingsRef,
    setActiveSection,
    isAdmin,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [accountDetails, setAccountDetails] = useState({});
    const [modelName, setModelName] = useState('gpt-4o-mini');
    const [temperature, setTemperature] = useState(0); //for temperature
    const [maxToken, setMaxToken] = useState(0); //for max_tokens
    const [prompt, setPrompt] = useState('');
    const [promptlist, setpromptlist] = useState([]);
    const email = user.email;
    const defaultPromptValue = useContext(DefaultPromptContext);
    const defaultPrompt = {
        pid: PROMPT.DEFAULT, // Unique ID for the default prompt
        id: email,
        prname: 'Default Prompt', // Default prompt name
        prompt: defaultPromptValue, // The value from the backend
    };
    const [selectedPrompt, setSelectedPrompt] = useState(null);

    const [addnewpromptmode, setaddnewpromptmode] = useState(false);
    const [refine, setRefine] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const [promptname, setpromptname] = useState('');

    const [recheck_counter, set_recheck_counter] = useState(0);

    const [activePrompt, setActivePrompt] = useState('');
    const [promptToUpdate, setPromptToUpdate] = useState('');
    const [newPromptText, setNewPromptText] = useState('');
    const [modelsList, setModelsList] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );
    const rootRef = useRef(null);

    const [queryMethod, setQueryMethod] = useState(''); // Current selected value
    const [queryMethods, setQueryMethods] = useState([]); // List of methods

    useEffect(() => {
        const fetchQueryMethods = async () => {
            try {
                // Fetch the list of query methods
                const response = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_querying_methods`
                    )
                );
                setQueryMethods(response.data);

                // Fetch the selected query method
                const selectedResponse = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_selected_query_method`
                    )
                );
                setQueryMethod(selectedResponse.data);
            } catch (error) {
                console.error(
                    'Error fetching querying methods or selected query method:',
                    error
                );
            }
        };

        fetchQueryMethods();
    }, []);

    const updateActiveTemplate = async (activeTemplate) => {
        try {
            const response = await axios.post(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/updatetemplate`
                ),
                {
                    id: user.email,
                    csvname: activeTemplate.filename,
                }
            );
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getEmailModel = async (email) => {
        const apiUrl = getUrl(
            `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${
                process.env.REACT_APP_NODE_SERVER_PORT
            }/getModel?email=${encodeURIComponent(email)}`
        );

        try {
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Response:', result);

            if (result.model) {
                console.log(`Model for email ${email}: ${result.model}`);
                console.log(result.model);
                setModelName(result.model);
            } else {
                console.log(`Email ${email} not found`);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const fetchRefineData = async () => {
        try {
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/check_refine/?email=${email}`
                )
            );
            const data = await response.json();
            // console.log(data.refine);
            setRefine(data.refine);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRefineSubmit = async (refined) => {
        try {
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/process_data/?email=${email}&refine=${refined}`
                )
            );
            const data = await response.json();
            console.log(data.message || data.error);
            setResponseMessage(data.message || data.error);
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('An error occurred while processing the data.');
        }
    };

    const onRefineChange = (checked) => {
        setRefine(checked);
    };

    const isFormValid = () => {
        // Prompt validation
        if (addnewpromptmode) {
            if (promptname === '') {
                alert('Prompt name is not provided!');
                return false;
            }
            if (prompt === '') {
                alert('Prompt field is empty! Please enter the prompt.');
                return false;
            }
        }

        // Template validation
        if (templateList.length && !activeTemplate) {
            alert('No active template selected! Please select one.');
            return false;
        }

        if (accountDetails.vendorDetails) {
            const validData = validateVendorsData(accountDetails.vendorDetails);
            if (!validData.isValid) {
                alert(validData.error);
                return false;
            }
        }

        return true;
    };

    const handleInsertData = async () => {
        try {
            const dataToInsert = {
                id: user.email,
                prname: promptname,
                prompt: prompt,
            };

            const response = await axios.post(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/insertData`
                ),
                dataToInsert
            );

            set_recheck_counter(recheck_counter + 1);
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleFetchData = async () => {
        try {
            const response = await axios.get(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getPrompts`
                ),
                {
                    params: {
                        id: user.email,
                    },
                }
            );
            const tempPromptList = response.data;
            setpromptlist(tempPromptList); // Set the prompt list first

            // Check if `selectedPrompt` is already set
            if (selectedPrompt) {
                if (selectedPrompt.pid === PROMPT.DEFAULT) {
                    setPromptToUpdate(defaultPrompt.prompt);
                    setSelectedPrompt(defaultPrompt);
                } else {
                    const pidt = selectedPrompt.pid;
                    const selectedPromptObj = tempPromptList.find(
                        (prompt) => prompt.pid === pidt
                    );

                    if (selectedPromptObj) {
                        setPromptToUpdate(selectedPromptObj.prompt);
                        setSelectedPrompt(selectedPromptObj);
                    } else {
                        // If selectedPromptObj is not found, fallback to defaultPrompt
                        setPromptToUpdate(defaultPrompt.prompt);
                        setSelectedPrompt(defaultPrompt);
                    }
                }
            } else {
                // Fetch the active prompt from FastAPI
                const fastApiResponse = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_value/${user.email}`
                    )
                );

                if (!fastApiResponse.ok) {
                    throw new Error('Key not found');
                }

                const data = await fastApiResponse.json();
                setActivePrompt(data.value);

                if (data.value !== defaultPrompt.prompt) {
                    // Find matching prompt from the prompt list
                    const matchingPrompt = tempPromptList.find(
                        (prompt) => prompt.prompt === data.value
                    );

                    if (matchingPrompt) {
                        setSelectedPrompt(matchingPrompt);
                        setPromptToUpdate(matchingPrompt.prompt);
                        setaddnewpromptmode(false);
                    } else {
                        // If no matching prompt, fall back to default prompt
                        setSelectedPrompt(defaultPrompt);
                        setPromptToUpdate(defaultPrompt.prompt);
                        setaddnewpromptmode(false);
                    }
                } else {
                    // Fallback to default prompt
                    setSelectedPrompt(defaultPrompt);
                    setPromptToUpdate(defaultPrompt.prompt);
                    setaddnewpromptmode(false);
                }
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    function findAndSetState(objectsArray, searchString) {
        console.log('matched');

        for (let i = 0; i < objectsArray.length; i++) {
            if (objectsArray[i].prompt === searchString) {
                setSelectedPrompt(objectsArray[i]);

                console.log('matched');
                console.log(objectsArray[i]);
                return; // Exit the loop once a match is found
            }
        }
    }

    const handleGetButtonClick = async () => {
        fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_value/${user.email}`
            )
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Key not found');
                }
                return response.json();
            })
            .then((data) => {
                setActivePrompt(data.value);
                console.log(data.value);
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const fetchModels = async () => {
        try {
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getModelsArray`
                )
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setModelsList(data.array);
            console.log('Array from server:', data.array);
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const accountData = await fetchAccountDetails(user);
            if (accountData) {
                setAccountDetails(accountData);
            }

            await fetchRefineData();
            await fetchModels();
            await getEmailModel(user.email);
            await fetchTemplateList(user, setActiveTemplate, setTemplateList);
            setIsLoading(false);
        })();
        return () => {};
    }, []);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await handleFetchData();
            setIsLoading(false);
        })();
        return () => {};
    }, [recheck_counter]);

    useEffect(() => {
        if (selectedPrompt) {
            setPromptToUpdate(selectedPrompt.prompt);
        }
    }, [selectedPrompt]);

    useEffect(() => {
        handleFetchData();
    }, []);
    const handleDeletePrompt = async (pid) => {
        console.log('Deleting prompt with PID:', pid); // Add this line
        try {
            await axios.delete(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/deletePrompt?pid=${pid}`
                )
            );
            handleFetchData(); // Refresh the prompt list
            if (selectedPrompt) {
                handleUpdatePrompt(defaultPrompt.prompt);
            }
            setSelectedPrompt(defaultPrompt);
            setPromptToUpdate(defaultPrompt.prompt);
        } catch (error) {
            console.error('Error deleting prompt:', error);
        }
    };

    const handleDropdownChange = (e) => {
        setModelName(e.target.value);
    };

    const handleTemperature = (newTemperature) => {
        setTemperature(parseFloat(newTemperature));
    };

    const handleMaxTokenChange = (newMaxToken) => {
        setMaxToken(parseFloat(newMaxToken));
    };

    const handlePromptChange = (e) => {
        setPrompt(e.target.value);
    };

    const handleUpdatePrompt = async (prompt) => {
        try {
            const response = await axios.post(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/updatePrompt`
                ),
                {
                    id: user.email,
                    prompt: prompt,
                }
            );
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEditPrompt = async () => {
        console.log('handleEditPrompt called');
        try {
            if (selectedPrompt) {
                console.log('Selected Prompt:', selectedPrompt);
                console.log('New Prompt Text:', newPromptText);

                console.log('Sending request to update prompt...');
                const response = await axios.post(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/editPrompt`
                    ),
                    {
                        id: user.email,
                        prname: selectedPrompt.prname,
                        newPrompt: newPromptText,
                    }
                );
                console.log('Prompt edited successfully:', response.data);
                setSelectedPrompt(selectedPrompt);
                selectedPrompt.prompt = newPromptText;
                set_recheck_counter(recheck_counter + 1);
            } else {
                alert('No selected prompt.');
            }
        } catch (error) {
            alert(`Error editing prompt: ${error.message}`);
        }
    };

    const modelsDropdownView = modelsList.map((item, index) => {
        return <option value={item}>{item}</option>;
    });

    const updateModel = async () => {
        const apiUrl = getUrl(
            `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/insertModel`
        );
        const data = {
            email: user.email,
            model: modelName,
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Response:', result);
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const updateQueryMethod = async () => {
        try {
            const response = await axios.post(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/update_query_method`
                ),
                new URLSearchParams({ new_value: queryMethod }), // Encode data for form submission
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            console.log(response.data.message);
        } catch (err) {
            console.error('Failed to update query method');
            console.error(err);
        }
    };

    if (isLoading) {
        return <NoInteractionView viewText={'Loading ...'} />;
    }

    if (isSaving) {
        return <NoInteractionView viewText={'Saving ...'} />;
    }

    return (
        <Container
            ref={rootRef}
            title={'Settings'}
            icon={faGear}
            iconColor={'backgrounds-settings100'}
            actions={[
                {
                    text: isSmallScreen ? '' : 'Save',
                    icon: faSave,
                    hoverText: 'Save all settings changes',
                    type: 'success',
                    disabled: false,
                    action: async () => {
                        if (isFormValid()) {
                            setIsSaving(true);

                            await updateVendorDetails(
                                user,
                                accountDetails,
                                setAccountDetails
                            );

                            await updateQueryMethod();

                            if (activeTemplate) {
                                await updateActiveTemplate(activeTemplate);
                            }

                            if (addnewpromptmode) {
                                await handleInsertData();
                            } else {
                                if (selectedPrompt) {
                                    await handleUpdatePrompt(
                                        selectedPrompt.prompt
                                    );
                                    if (
                                        selectedPrompt.prompt !==
                                            promptToUpdate &&
                                        selectedPrompt.id !== PROMPT.DEFAULT
                                    ) {
                                        await handleEditPrompt();

                                        await handleUpdatePrompt(
                                            selectedPrompt.prompt
                                        );
                                    }
                                } else {
                                    // Handle the case where no prompt is selected

                                    await handleUpdatePrompt(
                                        defaultPrompt.prompt
                                    );
                                }
                            }
                            await handleRefineSubmit(refine);
                            await updateModel();

                            // Reset form
                            setPrompt('');
                            setpromptname('');
                            setaddnewpromptmode(false);
                            setIsSaving(false);
                        }
                    },
                },
            ]}
        >
            <SettingsContent
                accountDetails={accountDetails}
                setAccountDetails={setAccountDetails}
                modelName={modelName}
                handleDropdownChange={handleDropdownChange}
                refine={refine}
                onRefineChange={onRefineChange}
                temperature={temperature}
                handleTemperature={handleTemperature}
                maxToken={maxToken}
                handleMaxTokenChange={handleMaxTokenChange}
                addnewpromptmode={addnewpromptmode}
                setaddnewpromptmode={setaddnewpromptmode}
                promptlist={promptlist}
                setPrompt={setPrompt}
                promptname={promptname}
                prompt={prompt}
                defaultPrompt={defaultPrompt}
                setpromptname={setpromptname}
                selectedPrompt={selectedPrompt}
                setSelectedPrompt={setSelectedPrompt}
                handlePromptChange={handlePromptChange}
                promptToUpdate={promptToUpdate}
                setPromptToUpdate={setPromptToUpdate}
                modelsDropdownView={modelsDropdownView}
                templateList={templateList}
                activeTemplate={activeTemplate}
                setActiveTemplate={setActiveTemplate}
                fetchTemplateList={fetchTemplateList}
                accountSettingsRef={accountSettingsRef}
                generalSettingsRef={generalSettingsRef}
                chatsSettingsRef={chatsSettingsRef}
                extractionSettingsRef={extractionSettingsRef}
                setActiveSection={setActiveSection}
                setTemplateList={setTemplateList}
                handleDeletePrompt={handleDeletePrompt}
                queryMethod={queryMethod}
                setQueryMethod={setQueryMethod}
                queryMethods={queryMethods}
                isAdmin={isAdmin}
                newPromptText={newPromptText}
                setNewPromptText={setNewPromptText}
                email={email}
                rootRef={rootRef}
                defaultPromptValue={defaultPromptValue}
            />
        </Container>
    );
};
