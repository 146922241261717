import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { LoadingView } from '../../reusableComponents';
import {
    createModifiedHighlightData,
    getMatchedIndexData,
    getIndexLineData,
    renderMultipleHighlights,
} from '../utils';
import {
    ErrorDataComponent,
    LoadingComponent,
    LoadingPageComponent,
    NoDataComponent,
} from '../reusableComponents';
import { safeWordRangeData } from '../constant';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export const PdfDebugViewer = ({
    fileName,
    pdfFile,
    highlightTexts,
    pageNumber,
    setPageNumber,
    setTotalPageNumbers,
    isLoading,
    scale,
}) => {
    const [highlightData, setHighlightData] = useState(null);
    const [lineData, setLineData] = useState(null);
    const [indexData, setIndexData] = useState(null);
    // const [completePageData, setCompletePageData] = useState(null);

    // UseEffect to create a modified highlight data whenever the source data is changed.
    useEffect(() => {
        // console.log('ORIGINAL HIGHLIGHT DATA:\n', highlightTexts);

        // Creating new clean highlight data with alpha numeric cases.
        const modifiedHighlightData = createModifiedHighlightData(
            highlightTexts,
            safeWordRangeData,
            false
        );

        if (modifiedHighlightData) {
            setHighlightData(modifiedHighlightData);
        }

        return () => {};
    }, [highlightTexts]);

    // Setting line data on page change along with new text layer load.
    const setPageData = useCallback(
        (pageData) => {
            if (pageNumber && pageData) {
                const data = getIndexLineData(pageData);
                if (data) {
                    setLineData(data);
                }

                return;
            }
        },
        [pageNumber]
    );

    // Setting index data on lineData or highligh data change
    useEffect(() => {
        if (highlightData && lineData) {
            const completeIndexData = getMatchedIndexData(
                lineData,
                highlightData
            );
            setIndexData(completeIndexData);
        }

        return () => {};
    }, [highlightData, lineData]);

    // Rendering callback function, called for each line of document per page.
    const textLayerRenderer = useCallback(
        (lineData) => {
            if (indexData) {
                return renderMultipleHighlights(lineData, indexData);
            }
        },
        [indexData]
    );

    const onItemClick = ({ pageNumber: itemPageNumber }) => {
        setPageNumber(itemPageNumber);
    };

    // Function called when document is loaded successfully.
    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPageNumbers(numPages);
        setPageNumber(pageNumber || 1);
    };

    return (
        <div className='main-container min-w-fit flex justify-center items-start w-full'>
            <Document
                file={pdfFile}
                onItemClick={onItemClick}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<LoadingComponent fileName={fileName} />}
                noData={<NoDataComponent fileName={fileName} />}
                error={<ErrorDataComponent fileName={fileName} />}
            >
                {isLoading ? (
                    <div className='w-full h-[60vh] flex justify-center items-center'>
                        <LoadingView
                            loadingText={`Loading debugging data ...`}
                        />
                    </div>
                ) : (
                    <Page
                        key={pageNumber}
                        pageNumber={pageNumber}
                        renderTextLayer={true}
                        renderAnnotationLayer={true}
                        renderForms={true}
                        onGetTextSuccess={setPageData}
                        customTextRenderer={textLayerRenderer}
                        scale={scale}
                        loading={
                            <LoadingPageComponent pageNumber={pageNumber} />
                        }
                    />
                )}
            </Document>
        </div>
    );
};
