import React from 'react';
import { appLogo, companyLogo, appName, appURL } from '../Constants';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { useMenuItems } from '../menuItems';

const TermsAndConditions = () => {
    const { menuItems } = useMenuItems();
    return (
        <>
            <Navbar menuItems={menuItems} />
            <div className='mt-24 mx-0 md:mx-8 lg:mx-20 p-8'>
                <h1 className='font-bold text-3xl mb-8 text-center'>
                    Terms & Conditions
                </h1>

                {/* Terms & Conditions Content */}
                <div className='font-sans leading-relaxed'>
                    <p className='mb-4'>
                        These terms and conditions outline the rules and
                        regulations for the use of {appName}'s Website, located
                        at{' '}
                        <a
                            href={appURL}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-blue-500 underline'
                        >
                            {appURL}
                        </a>
                        .
                    </p>

                    <p className='mb-4'>
                        By accessing this website you accept these terms and
                        conditions. Do not continue to use{' '}
                        <a
                            href={appURL}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-blue-500 underline'
                        >
                            {appURL}
                        </a>{' '}
                        if you do not agree to take all of the terms and
                        conditions stated on this page.
                    </p>

                    <p className='mb-4'>
                        The following terminology applies to these Terms and
                        Conditions, Privacy Statement and Disclaimer Notice and
                        all Agreements: <strong>"Client"</strong>,{' '}
                        <strong>"You"</strong> and <strong>"Your"</strong>{' '}
                        refers to you, the person log on this website and
                        compliant to the Company’s terms and conditions.{' '}
                        <strong>"The Company"</strong>,{' '}
                        <strong>"Ourselves"</strong>, <strong>"We"</strong>,{' '}
                        <strong>"Our"</strong> and <strong>"Us"</strong>, refers
                        to our Company. <strong>"Party"</strong>,{' '}
                        <strong>"Parties"</strong>, or <strong>"Us"</strong>,
                        refers to both the Client and ourselves. All terms refer
                        to the offer, acceptance and consideration of payment
                        necessary to undertake the process of our assistance to
                        the Client in the most appropriate manner for the
                        express purpose of meeting the Client’s needs in respect
                        of provision of the Company’s stated services, in
                        accordance with and subject to, prevailing law of
                        Netherlands. Any use of the above terminology or other
                        words in the singular, plural, capitalization and/or
                        he/she or they, are taken as interchangeable and
                        therefore as referring to same.
                    </p>

                    <div className='mb-8'>
                        <h2 className='text-2xl font-semibold mb-4'>
                            Registration and Access
                        </h2>
                        <p className='mb-4'>
                            You must provide accurate and complete information
                            to register for an account to use our Services. You
                            may not share your account credentials or make your
                            account available to anyone else and are responsible
                            for all activities that occur under your account. If
                            you create an account or use the Services on behalf
                            of another person or entity, you must have the
                            authority to accept these Terms on their behalf.
                        </p>
                    </div>

                    <div className='mb-8'>
                        <h2 className='text-2xl font-semibold mb-4'>
                            Using our Services
                        </h2>
                        <h3 className='text-xl font-medium mb-2'>
                            What you can do:
                        </h3>

                        <p className='mb-4'>
                            Subject to your compliance with these Terms, you may
                            access and use our Services. In using our Services,
                            you must comply with all applicable laws, Usage
                            Policies, and any other documentation, guidelines,
                            or policies we make available to you.
                        </p>

                        <h3 className='text-xl font-medium mb-2'>
                            What you cannot do:
                        </h3>
                        <ul className='list-disc list-inside mb-4 ml-3'>
                            <li>
                                You may not use our Services for any illegal,
                                harmful, or abusive activity. For example, you
                                may not:
                            </li>
                            <li>
                                Use our Services in a way that infringes,
                                misappropriates or violates anyone’s rights.
                            </li>
                            <li>
                                Modify, copy, lease, sell or distribute any of
                                our Services.
                            </li>
                            <li>
                                Attempt to or assist anyone to reverse engineer,
                                decompile or discover the source code or
                                underlying components of our Services, including
                                our models, algorithms, or systems (except to
                                the extent this restriction is prohibited by
                                applicable law).
                            </li>
                            <li>
                                Automatically or programmatically extract data
                                or Output (defined below).
                            </li>
                            <li>
                                Represent that Output was human-generated when
                                it was not.
                            </li>
                            <li>
                                Interfere with or disrupt our Services,
                                including circumvent any rate limits or
                                restrictions or bypass any protective measures
                                or safety mitigations we put on our Services.
                            </li>
                        </ul>
                    </div>

                    <div className='mb-8'>
                        <h2 className='text-2xl font-semibold mb-4'>
                            Service Credit Terms
                        </h2>
                        <p className='mb-4'>
                            We may offer the option to prepay for certain
                            Services through the purchase of credits (“Prepaid
                            Service Credits”). Prepaid Service Credits represent
                            the amount you have paid in advance for use of the
                            respective Services. Additional terms may apply to
                            specific Services.
                        </p>
                        <p className='mb-4'>
                            We may also offer Service Credits free of charge as
                            part of a promotional program rather than for
                            purchase (“Promo Service Credits”). Promo Service
                            Credits will not be applied against any sales, use,
                            gross receipts, or similar transaction based taxes
                            that may be applicable to you.
                        </p>
                        <p className='mb-4'>
                            Service Credits are not legal tender or currency;
                            are not redeemable, refundable, or exchangeable for
                            any sum of money or monetary value; have no
                            equivalent value in fiat currency; do not act as a
                            substitute for fiat currency; and do not constitute
                            or confer upon you any personal property right.
                            Service Credits are non-transferable and may be used
                            only in connection with the applicable Service.
                        </p>
                        <p className='mb-4'>
                            Your available Service Credit balance may be
                            reviewed in your {appName} account. You are solely
                            responsible for verifying that the proper amount of
                            Service Credits has been added to or deducted from
                            your balance. Your Service Credit balance is not a
                            bank account, digital wallet, stored value account,
                            or other payment device.
                        </p>
                        <p className='mb-4'>
                            We prohibit and do not recognize any purported
                            transfers, sales, gifts, or trades of Service
                            Credits. Evidence of any attempt to use, sell, or
                            transfer Service Credits in any manner violates
                            these Terms and may result in revocation,
                            termination, or cancellation of the Service Credits
                            and/or your use of the Services without refund
                            and/or immediate suspension or termination of your
                            account.
                        </p>
                        <p className='mb-4'>
                            We reserve the right to suspend or terminate your
                            use of the Services in accordance with the
                            Agreement, including suspending or terminating your
                            Service Credit balance, in compliance with
                            applicable law.
                        </p>
                        <p className='mb-4'>
                            We may modify these Terms at any time by posting a
                            revised version on our website or by emailing the
                            email associated with your account. The modified
                            Terms will become effective upon posting or, if we
                            notify you by email, as stated in the email message.
                            By receiving or using any Service Credit after the
                            effective date of any modification to these Terms,
                            you agree to be bound by the modified Terms.
                        </p>
                    </div>

                    <h2 className='text-2xl font-semibold mb-4'>Content</h2>
                    <h3 className='text-xl font-medium mb-2'>Your content:</h3>
                    <p className='mb-4'>
                        You may provide input to the Services (“Input”), and
                        receive output from the Services based on the Input
                        (“Output”). Input and Output are collectively “Content.”
                        You are responsible for Content, including ensuring that
                        it does not violate any applicable law or these Terms.
                        You represent and warrant that you have all rights,
                        licenses, and permissions needed to provide Input to our
                        Services.
                    </p>

                    <h3 className='text-xl font-medium mb-2'>
                        Ownership of content:
                    </h3>
                    <p className='mb-4'>
                        As between you and {appName}, and to the extent
                        permitted by applicable law, you (a) retain your
                        ownership rights in Input and (b) own the Output. We
                        hereby assign to you all our right, title, and interest,
                        if any, in and to Output.
                    </p>

                    <h3 className='text-xl font-medium mb-2'>
                        Similarity of content:
                    </h3>
                    <p className='mb-4'>
                        Due to the nature of our Services and artificial
                        intelligence generally, output may not be unique and
                        other users may receive similar output from our
                        Services. Our assignment above does not extend to other
                        users’ output or any Third Party Output.
                    </p>

                    <h3 className='text-xl font-medium mb-2'>
                        Our use of content:
                    </h3>
                    <p className='mb-4'>
                        We may use Content to provide, maintain, develop, and
                        improve our Services. We may also use Content to monitor
                        and enhance the quality of our Services. We may access
                        and use Content, including Input and Output, to comply
                        with legal obligations, including responding to legal
                        processes, requests, or orders from competent
                        authorities. We may also use Content to conduct internal
                        research and to detect, prevent, or address fraud,
                        security, or technical issues. Our rights to use Content
                        as described in this section are limited to what is
                        necessary to provide and improve our Services.
                    </p>
                    <h3 className='text-xl font-medium mb-2'>Accuracy:</h3>
                    <p className='mb-4'>
                        Artificial intelligence and machine learning are rapidly
                        evolving fields of study. We are constantly working to
                        improve our Services to make them more accurate,
                        reliable, safe, and beneficial. Given the probabilistic
                        nature of machine learning, use of our Services may, in
                        some situations, result in Output that does not
                        accurately reflect real people, places, or facts.
                    </p>

                    <p className='mb-4'>
                        When you use our Services you understand and agree:
                    </p>
                    <ul className='list-disc list-inside mb-4 pl-5'>
                        <li>
                            Output may not always be accurate. You should not
                            rely on Output from our Services as a sole source of
                            truth or factual information, or as a substitute for
                            professional advice.
                        </li>
                        <li>
                            You must evaluate Output for accuracy and
                            appropriateness for your use case, including using
                            human review as appropriate, before using or sharing
                            Output from the Services.
                        </li>
                        <li>
                            You must not use any Output relating to a person for
                            any purpose that could have a legal or material
                            impact on that person, such as making credit,
                            educational, employment, housing, insurance, legal,
                            medical, or other important decisions about them.
                        </li>
                        <li>
                            Our Services may provide incomplete, incorrect, or
                            offensive Output that does not represent {appName}’s
                            views. If Output references any third party products
                            or services, it doesn’t mean the third party
                            endorses or is affiliated with {appName}.
                        </li>
                    </ul>
                    <h2 className='text-2xl font-semibold mt-6 mb-4'>
                        Our IP Rights
                    </h2>

                    <p className='mb-4'>
                        We and our affiliates own all rights, title, and
                        interest in and to the Services. You may only use our
                        name and logo in accordance with our Brand Guidelines.
                    </p>
                    <h2 className='text-2xl font-semibold mt-6 mb-4'>
                        Termination and Suspension
                    </h2>

                    <p className='mb-4'>
                        <strong>Termination.</strong> You are free to stop using
                        our Services at any time. We reserve the right to
                        suspend or terminate your access to our Services or
                        delete your account if we determine:
                    </p>
                    <ul className='list-disc list-inside mb-4 pl-6'>
                        <li>You breached these Terms or our Usage Policies.</li>
                        <li>We must do so to comply with the law.</li>
                        <li>
                            Your use of our Services could cause risk or harm to
                            {appName}, our users, or anyone else.
                        </li>
                    </ul>

                    <p className='mb-4'>
                        We also may terminate your account if it has been
                        inactive for over a year and you do not have a paid
                        account. If we do, we will provide you with advance
                        notice.
                    </p>

                    <p className='mb-4'>
                        <strong>Appeals.</strong> If you believe we have
                        suspended or terminated your account in error, you can
                        file an appeal with us by contacting our Support team.
                    </p>

                    <h2 className='text-2xl font-semibold mt-6 mb-4'>
                        Discontinuation of Services
                    </h2>

                    <p className='mb-4'>
                        We may decide to discontinue our Services, but if we do,
                        we will give you advance notice and a refund for any
                        prepaid, unused Services.
                    </p>

                    <h2 className='text-2xl font-semibold mt-6 mb-4'>
                        Disclaimer of Warranties
                    </h2>

                    <p className='mb-4'>
                        OUR SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT
                        PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS
                        MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR
                        OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM
                        ALL WARRANTIES INCLUDING, BUT NOT LIMITED TO, WARRANTIES
                        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET
                        ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE
                        OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE
                        SERVICES WILL BE UNINTERRUPTED, ACCURATE OR ERROR FREE,
                        OR THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR
                        ALTERED.
                    </p>

                    <p className='mb-4'>
                        YOU ACCEPT AND AGREE THAT ANY USE OF OUTPUTS FROM OUR
                        SERVICE IS AT YOUR SOLE RISK AND YOU WILL NOT RELY ON
                        OUTPUT AS A SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION,
                        OR AS A SUBSTITUTE FOR PROFESSIONAL ADVICE.
                    </p>

                    <h2 className='text-2xl font-semibold mt-6 mb-4'>
                        Limitation of Liability
                    </h2>

                    <p className='mb-4'>
                        NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL
                        BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                        CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES
                        FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER
                        LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
                        OF SUCH DAMAGES. OUR AGGREGATE LIABILITY UNDER THESE
                        TERMS WILL NOT EXCEED THE GREATER OF THE AMOUNT YOU PAID
                        FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE
                        12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED
                        DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY
                        ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                    </p>

                    <h2 className='text-2xl font-semibold mt-6 mb-4'>
                        Copyright Complaints
                    </h2>

                    <p className='mb-4'>
                        If you believe that your intellectual property rights
                        have been infringed, please send notice to the email
                        address below. We may delete or disable content that we
                        believe violates these Terms or is alleged to be
                        infringing and will terminate accounts of repeat
                        infringers where appropriate.
                    </p>

                    <p className='mb-4'>
                        Written claims concerning copyright infringement must
                        include the following information:
                    </p>
                    <ul className='list-disc pl-5 mb-4'>
                        <li>
                            A physical or electronic signature of the person
                            authorized to act on behalf of the owner of the
                            copyright interest
                        </li>
                        <li>
                            A description of the copyrighted work that you claim
                            has been infringed upon
                        </li>
                        <li>
                            A description of where the allegedly infringing
                            material is located on our site so we can find it
                            Your address, telephone number, and e-mail address
                        </li>
                        <li>
                            A statement by you that you have a good-faith belief
                            that the disputed use is not authorized by the
                            copyright owner, its agent, or the law
                        </li>
                        <li>
                            A statement by you that the above information in
                            your notice is accurate and, under penalty of
                            perjury, that you are the copyright owner or
                            authorized to act on the copyright owner’s behalf
                        </li>
                    </ul>

                    <h2 className='text-2xl font-semibold mt-6 mb-4'>
                        General Terms
                    </h2>

                    <h3 className='text-xl font-medium  mt-4 mb-2'>
                        Assignment
                    </h3>
                    <p className='mb-4'>
                        You may not assign or transfer any rights or obligations
                        under these Terms and any attempt to do so will be void.
                        We may assign our rights or obligations under these
                        Terms to any affiliate, subsidiary, or successor in
                        interest of any business associated with our Services.
                    </p>

                    <h3 className='text-xl font-medium  mt-4 mb-2'>
                        Changes to these Terms or our Services
                    </h3>
                    <p className='mb-4'>
                        We are continuously working to develop and improve our
                        Services. We may update these Terms or our Services
                        accordingly from time to time. For example, we may make
                        changes to these Terms or the Services due to:
                    </p>
                    <ul className='list-disc pl-5 mb-4'>
                        <li>Changes to the law or regulatory requirements.</li>
                        <li>Security or safety reasons.</li>
                        <li>Circumstances beyond our reasonable control.</li>
                        <li>
                            Changes we make in the usual course of developing
                            our Services.
                        </li>
                        <li>To adapt to new technologies.</li>
                    </ul>
                    <p className='mb-4'>
                        We will give you at least 30 days advance notice of
                        changes to these Terms that materially adversely impact
                        you either via email or an in-product notification. All
                        other changes will be effective as soon as we post them
                        to our website. If you do not agree to the changes, you
                        must stop using our Services.
                    </p>

                    <h3 className='text-xl font-medium  mt-4 mb-2'>
                        Delay in Enforcing These Terms
                    </h3>
                    <p className='mb-4'>
                        Our failure to enforce a provision is not a waiver of
                        our right to do so later. Except as provided in the
                        dispute resolution section above, if any portion of
                        these Terms is determined to be invalid or
                        unenforceable, that portion will be enforced to the
                        maximum extent permissible and it will not affect the
                        enforceability of any other terms.
                    </p>

                    <h3 className='text-xl font-medium  mt-4 mb-2'>
                        Entire Agreement
                    </h3>
                    <p className='mb-4'>
                        These Terms contain the entire agreement between you and
                        {appName} regarding the Services and, other than any
                        Service-specific terms, supersedes any prior or
                        contemporaneous agreements between you and {appName}.
                    </p>

                    <h3 className='text-xl font-medium mt-4 mb-2'>
                        Governing Law
                    </h3>
                    <p className='mb-4'>
                        Indian law will govern these Terms except for its
                        conflicts of laws principles. Except as provided in the
                        dispute resolution section above, all claims arising out
                        of or relating to these Terms will be brought
                        exclusively in the courts of competent jurisdiction in
                        New Delhi, India.
                    </p>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default TermsAndConditions;
