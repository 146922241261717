import React from 'react';
import { Article } from './article';

export const AllArticles = ({ queriesData }) => {
    const renderArticles =
        queriesData &&
        queriesData.map((data, index) => {
            return (
                <Article
                    key={index + data.query}
                    query={data.query}
                    solution={data.solution}
                />
            );
        });
    return <div className='py-3 px-4'>{renderArticles}</div>;
};
