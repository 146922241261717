export const ChartTypes = Object.freeze({
    ANNOTATION_CHART: 'AnnotationChart',
    AREA_CHART: 'AreaChart',
    BAR_CHART: 'BarChart',
    BUBBLE_CHART: 'BubbleChart',
    CALENDAR: 'Calendar',
    CANDLESTICK_CHART: 'CandlestickChart',
    COLUMN_CHART: 'ColumnChart',
    COMBO_CHART: 'ComboChart',
    DIFF_CHART: 'DiffChart',
    DONUT_CHART: 'DonutChart',
    GANTT: 'Gantt',
    GAUGE: 'Gauge',
    GEO_CHART: 'GeoChart',
    HISTOGRAM: 'Histogram',
    LINE_CHART: 'LineChart',
    LINE: 'Line',
    BAR: 'Bar',
    MAP: 'Map',
    ORG_CHART: 'OrgChart',
    PIE_CHART: 'PieChart',
    SAN_KEY: 'Sankey',
    SCATTER_CHART: 'ScatterChart',
    SCATTER: 'Scatter',
    STEPPED_AREA_CHART: 'SteppedAreaChart',
    TABLE: 'Table',
    TIMELINE: 'Timeline',
    TREE_MAP: 'TreeMap',
    WATERFALL_CHART: 'WaterfallChart',
    WORD_TREE: 'WordTree',
});

export const DataTypes = Object.freeze({
    STRING: 'string',
    NUMBER: 'number',
});

export const defaultChartOptions = Object.freeze({
    hAxis: { title: 'X axis' },
    vAxis: { title: 'Y axis' },
    width: '100%',
    height: '100%',
});

export const pageWidth = 795; // in px
export const pageHeight = 1032; // in px

export const sharedColor = '#38bdf8';
