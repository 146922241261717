import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Dropdown, Modal } from './reusableComponents';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { getUrl } from './pdfDisplay/utils';

const dropDownData = [
    'Select an issue',
    'Incorrect response',
    'Inaccurate response',
    'Info missing from chunks',
    'LLM unable to retrieve information',
];

const Flagpage = (props) => {
    const [issue, setIssue] = useState(dropDownData[0]);
    const [comment, setComment] = useState('');

    useEffect(() => {
        if (props.qid && props.questionsData) {
            const issueData = props.questionsData.get(props.qid).issue;
            const commentData = props.questionsData.get(props.qid).comment;

            if (issueData) {
                setIssue(issueData);
            }

            if (commentData) {
                setComment(commentData);
            }
        }
    }, []);

    const dropDownList = dropDownData.map((data, index) => {
        return {
            key: index,
            label: data,
            //disabled: index === 0,
            selected: index && issue === data,
            action: () => {
                if (index) {
                    setIssue(data);
                }
            },
        };
    });

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('email', props.email);
        formData.append('foldername', props.foldername);
        formData.append('query', props.query);
        formData.append('issue', issue);
        formData.append('chunks', JSON.stringify(props.chunks));
        formData.append('comment', comment);
        formData.append('response', props.response);
        formData.append('qid', props.qid);
        
        axios
            .post(
                getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/handle_flag`),
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((response) => {
                console.log(response.data);
                props.add_flag(props.qid, issue, comment);
                props.setflag(false);
            })
            .catch((error) => {
                console.error('Error making the API call:', error);
            });
    };

    return (
        <Modal
            title={'Flag'}
            titleIcon={faFlag}
            iconColor={'red'}
            onClose={() => {
                props.setflag(false);
            }}
        >
            <div className='flex flex-col justify-center items-center px-2 py-4'>
                {/* <label className='block text-slate-700 mt-2'>Issue</label> */}
                <Dropdown
                    dropDownList={dropDownList.slice(1)}
                    showButtonText={true}
                    buttonText={issue}
                    listClassName={''}
                    titleText={'Select an issue'}
                    buttonClassName={
                        'font-normal sm:w-full md:w-96 bg-backgrounds-primary100 hover:bg-backgrounds-primary100'
                    }
                    buttonHoverText={'Select an issue from dropdown'}
                />

                <textarea
                    className='mt-5 w-full sm:w-full md:w-96 rounded-xl border h-52 p-4 hover:outline-none focus:outline-backgrounds-primary200 hover:border-backgrounds-primary300 mb-4'
                    placeholder={'Enter your comment here'}
                    rows={8} // Increase the number of visible rows
                    cols={25}
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                ></textarea>

                <Button
                    onClick={() => {
                        handleSubmit();
                    }}
                    icon={faFlag}
                    type={'success'}
                    text={'Report'}
                    hoverText={'Update flag data'}
                    // disabled={comment.length === 0 || issue === dropDownData[0]}
                    disabled={comment.length === 0}
                />
            </div>
        </Modal>
    );
};

export default Flagpage;
