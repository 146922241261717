import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

export const Article = ({ query, solution }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div className='flex flex-col items-center justify-center my-3'>
            <button
                className={`w-full flex items-center justify-between font-semibold text-texts-secondary300 ${
                    isExpanded
                        ? 'bg-backgrounds-primary100 border-backgrounds-primary200 rounded-t-xl shadow-inner'
                        : 'bg-backgrounds-primary100 rounded-xl'
                } p-3 border hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200`}
                onClick={(event) => {
                    // handleClick();
                    setIsExpanded(!isExpanded);
                    event.stopPropagation();
                }}
            >
                <div>{query}</div>
                <div>
                    <FontAwesomeIcon
                        icon={isExpanded ? faCaretUp : faCaretDown}
                    />
                </div>
            </button>
            {isExpanded && (
                <div
                    className='w-full bg-backgrounds-white rounded-b-xl p-6 text-texts-secondary300 shadow-sm border'
                    dangerouslySetInnerHTML={solution}
                ></div>
            )}
        </div>
    );
};
