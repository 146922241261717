import { useRef } from 'react';
import { MENUITEMS } from '../../enums';

export const useMenuItems = () => {
    const layoutRef = useRef(null);
    const functionalitiesRef = useRef(null);
    const purchasesRef = useRef(null);
    const footerRef = useRef(null);
    const chatStepsRef = useRef(null);

    const menuItems = [
        { name: MENUITEMS.HOME, ref: layoutRef },
        { name: MENUITEMS.FUNCTIONALITIES, ref: functionalitiesRef },
        { name: MENUITEMS.WORKFLOW, ref: chatStepsRef },
        { name: MENUITEMS.PRICING, ref: purchasesRef },
        { name: MENUITEMS.CONTACTUS, ref: footerRef },
    ];

    return {
        menuItems,
        layoutRef,
        functionalitiesRef,
        purchasesRef,
        footerRef,
        chatStepsRef,
    };
};
