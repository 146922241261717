import {
    faDatabase,
    faFile,
    faFileCode,
    faFileCsv,
    faFileLines,
    faFilePdf,
    faFileText,
    faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { getFileFormat } from './getFileFormat';

export const getFileIcon = (fileName) => {
    const fileFormat = getFileFormat(fileName).toLowerCase();
    if (fileFormat === 'pdf') return faFilePdf;
    else if (fileFormat === 'doc' || fileFormat === 'docx') return faFileWord;
    else if (fileFormat === 'txt') return faFileText;
    else if (fileFormat === 'py') return faFileCode;
    else if (fileFormat === 'json') return faFileLines;
    else if (fileFormat === 'csv') return faFileCsv;
    else if (fileFormat === 'db') return faDatabase;
    else return faFile;
};
