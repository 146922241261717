import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { Button } from './reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faExclamationTriangle,
    faFolderPlus,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { isValidName } from '../utils';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';
import { getUrl } from './pdfDisplay/utils';

const Addnewrepo_postgres = (props) => {
    console.log(props);
    const reponame = props.reponame;
    const [repoDetails, setRepoDetails] = useState([]);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        hostname: '',
        port: '',
        database: '',
        user: '',
        password: '',
    });
    const active_user = JSON.parse(localStorage.getItem('current'));

    function isNameFound(searchString) {
        for (let i = 0; i < repoDetails.length; i++) {
            if (repoDetails[i].name === searchString) {
                return true;
            }
        }
        return false;
    }

    const repos = async () => {
        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getAllChatsRepos/${active_user.email}`);
            const authToken = encodeURIComponent(active_user.token);

            const headers = {
                Authorization: authToken,
                'Content-Type': 'application/json',
            };

            const response = await fetch(dynamicUrl, {
                method: 'GET',
                headers: headers,
            });

            const data = await response.json();
            setRepoDetails(data.Normal);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        repos();
    }, []);

    const { hostname, port, database, user, password } = formData;

    const onFinish = async (values) => {
        setError('');
        const dataToSend = new FormData();
        dataToSend.append('hostname', hostname);
        dataToSend.append('port', port);
        dataToSend.append('database', database);
        dataToSend.append('user', user);
        dataToSend.append('password', password);
        dataToSend.append('reponame', reponame);
        dataToSend.append('email', active_user.email);
        dataToSend.append('repotype', 'postgress');
        const duplicateName = isNameFound(reponame);

        if (duplicateName) {
            setError(
                'Repo with the same name exists! Try with a different name.'
            );
            // alert('Repo with the same name exists .try with a different name');
            return;
        }

        const isValidNameData = isValidName(reponame, false);
        if (!isValidNameData.isValid) {
            setError(isValidNameData.error);
            return;
        }

        const status = {
            message: 'Starting db repository creation',
            percentageCompletion: 0,
            process: ProcessType.REPO_CREATION,
            progress: TaskProgressType.STARTED,
            repoId: null,
            repoName: reponame,
            statusCode: 200,
            timeStamp: new Date(),
            userMail: active_user.email,
        };
        props.socket.emit('statusUpdate', status);
        const tempUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/addpostgressrepo`
        );
        // Send data to server
        axios
            .post(
                tempUrl,
                dataToSend,
                {
                    onUploadProgress: (progressEvent) => {
                        const status = {
                            message: 'Uploading files for repository creation',
                            percentageCompletion: 20,
                            process: ProcessType.REPO_CREATION,
                            progress: TaskProgressType.PROCESSING,
                            repoId: null,
                            repoName: reponame,
                            statusCode: 200,
                            timeStamp: new Date(),
                            userMail: active_user.email,
                        };

                        props.socket.emit('statusUpdate', status);
                    },
                }
            )
            .then(async (response) => {
                console.log('Server response:', response.data);
                await props.getAllReposData();
                props.setAddNewMode(false); // Assuming you want to disable add new mode on success
            })
            .catch((error) => {
                console.error('Error:', error);
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.detail
                ) {
                    console.log(error.response.data.detail); // Alert the error detail
                } else {
                    alert('An unexpected error occurred');
                }
            });

        props.setAddNewMode(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const onCancelClick = () => {
        console.log('cancel clicked');
        // You can add further actions here
    };

    const isFormComplete = () => {
        return (
            Object.values(formData).every((value) => value.trim() !== '') &&
            reponame
        );
    };

    return (
        <div>
            <Form
                name='basic'
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                layout='vertical'
            >
                <div className='grid grid-cols-3 gap-4'>
                    <div className='col-span-3 sm:col-span-3 md:col-span-2 lg:col-span-2'>
                        <Form.Item
                            label='Hostname'
                            name='hostname'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the hostname!',
                                },
                            ]}
                        >
                            <Input
                                name='hostname'
                                value={hostname}
                                onChange={handleChange}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-span-3 sm:col-span-3 md:col-span-1 lg:col-span-1'>
                        <Form.Item
                            label='Port'
                            name='port'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the port!',
                                },
                            ]}
                        >
                            <Input
                                name='port'
                                value={port}
                                onChange={handleChange}
                            />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item
                    label='Database'
                    name='database'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the database!',
                        },
                    ]}
                >
                    <Input
                        name='database'
                        value={database}
                        onChange={handleChange}
                    />
                </Form.Item>

                <Form.Item
                    label='User'
                    name='user'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the user!',
                        },
                    ]}
                >
                    <Input name='user' value={user} onChange={handleChange} />
                </Form.Item>

                <Form.Item
                    label='Password'
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the password!',
                        },
                    ]}
                >
                    <Input.Password
                        name='password'
                        value={password}
                        onChange={handleChange}
                    />
                </Form.Item>
                {error && (
                    <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className='mr-2'
                        />
                        {error}
                    </div>
                )}

                <Form.Item>
                    <div className='flex items-center justify-center mt-4'>
                        <div className='flex items-center justify-between w-full'>
                            <Button
                                type='danger'
                                icon={faCircleXmark}
                                text={'Cancel'}
                                tile={'Cancel and close the modal'}
                                className='w-1/3'
                                onClick={() => {
                                    props.setAddNewMode(false);
                                }}
                            />

                            <button
                                className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                    !isFormComplete()
                                        ? 'text-texts-secondary300 cursor-not-allowed'
                                        : 'hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                                }`}
                                type='submit'
                                title='Upload files and add new repository'
                                disabled={!isFormComplete()}
                            >
                                <FontAwesomeIcon
                                    icon={faFolderPlus}
                                    className='mr-2'
                                />
                                Add
                            </button>
                        </div>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Addnewrepo_postgres;
