export const hasDuplicateNames = (files) => {
    const uniqueElements = new Set();
    for (const file of files) {
        let fileName = '';
        const index = file.name.lastIndexOf('.');
        if (index > -1) {
            fileName = file.name.substring(0, index);
        } else {
            fileName = file.name;
        }

        if (uniqueElements.has(fileName)) {
            return true;
        } else {
            uniqueElements.add(fileName);
        }
    }
    return false;
};
