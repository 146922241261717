import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import html2pdf from 'html2pdf.js';
import { Button, Dropdown, Modal } from '../../reusableComponents';
import {
    faFileDownload,
    faFilePdf,
    faPieChart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    CompanyDetails,
    appLogo,
    appName,
    appVersion,
} from '../../../Constants';
import Toggle from '../../reusableComponents/Toggle';
import axios from 'axios';
import { getFileIcon, getFileIconColor } from '../../../utils';
import {
    convertKeysToSimilarFormat,
    convertToNumber,
    getFileNameWithoutExt,
    toSentenceCase,
} from '../utils';
import { ChartTypes, DataTypes, sharedColor } from '../constants';
import { ReportContainer } from './reportContainer';
import { getUrl } from '../../pdfDisplay/utils';

const AppData = { appLogo, appName, appVersion };
const CompanyData = { CompanyDetails };
const keyPathMap = {};
const noData = '---';
const defaultReportTitle = 'fileName';
const BREAKPOINT_WIDTH = 1024;

const buildKeyPathMap = (data, parentKeys = []) => {
    for (const property in data) {
        const currentKeys = [...parentKeys, property];
        keyPathMap[property] = currentKeys;

        if (
            typeof data[property] === 'object' &&
            !Array.isArray(data[property])
        ) {
            buildKeyPathMap(data[property], currentKeys);
        } else if (Array.isArray(data[property])) {
            for (let i = 0; i < data[property].length; i++) {
                buildKeyPathMap(data[property][i], [...currentKeys, i]);
            }
        }
    }

    // Adjust keys to remove ".0"
    const adjustedKeyPathMap = {};
    for (const key in keyPathMap) {
        const adjustedKey = key.replace(/\.0$/, '');
        adjustedKeyPathMap[adjustedKey] = keyPathMap[key];
    }

    return adjustedKeyPathMap;
};

const findKeyPath = (key, keyPathMap) => {
    return keyPathMap[key] || null;
};

const getDropDownList = (layoutsList, selectedLayout, setSelectedLayout) => {
    const dropDownList = [];

    layoutsList.forEach((layout, index) => {
        const layoutName = getFileNameWithoutExt(layout.name);
        const data = {
            key: index,
            label: layoutName,
            icon: getFileIcon(layout.name),
            iconColor: layout.isShared
                ? sharedColor
                : getFileIconColor(layout.name),
            selected: layout.name === selectedLayout.name,
            action: () => {
                setSelectedLayout(layout);
            },
        };

        dropDownList.push(data);
    });

    return dropDownList;
};

//  Function to format extracted data as template data.
const getDataAsTemplate = (templateData, extractedData) => {
    const finalData = [];

    extractedData.forEach((data) => {
        finalData.push({});
    });

    [...Object.keys(templateData)].forEach((templateKey) => {
        if (
            typeof templateData[templateKey] === DataTypes.STRING ||
            typeof templateData[templateKey] === DataTypes.NUMBER
        ) {
            // If data is single and string type/ number type
            extractedData.forEach((data, index) => {
                if (!data[templateKey]) {
                    finalData[index][templateKey] = '';
                } else if (
                    data[templateKey] &&
                    (typeof data[templateKey] === DataTypes.STRING ||
                        typeof data[templateKey] === DataTypes.NUMBER)
                ) {
                    finalData[index][templateKey] = data[templateKey];
                } else if (
                    data[templateKey] &&
                    typeof data[templateKey] === 'object'
                ) {
                    finalData[index][templateKey] = recursiveTraverse(
                        data[templateKey]
                    );
                }
            });
        } else if (
            typeof templateData[templateKey] === 'object' &&
            !Array.isArray(templateData[templateKey])
        ) {
            // If data is single and object type
            extractedData.forEach((data, index) => {
                if (!data[templateKey]) {
                    finalData[index][templateKey] = {};
                } else if (
                    data[templateKey] &&
                    typeof data[templateKey] === 'object' &&
                    !Array.isArray(data[templateKey])
                ) {
                    const subKeysData = [...Object.keys(data[templateKey])];
                    if (
                        subKeysData.length === 1 &&
                        (subKeysData[0] === '1' || subKeysData[0] === 1)
                    ) {
                        finalData[index][templateKey] =
                            data[templateKey][subKeysData[0]];
                    } else {
                        finalData[index][templateKey] = data[templateKey];
                    }
                }
            });
        } else if (
            typeof templateData[templateKey] === 'object' &&
            Array.isArray(templateData[templateKey]) &&
            (typeof templateData[templateKey][0] === DataTypes.STRING ||
                typeof templateData[templateKey][0] === DataTypes.NUMBER)
        ) {
            // If data is multiple and string/number type
            extractedData.forEach((data, index) => {
                if (!data[templateKey]) {
                    finalData[index][templateKey] = [];
                } else if (
                    data[templateKey] &&
                    typeof data[templateKey] === 'object'
                ) {
                    finalData[index][templateKey] = [];
                    if (Array.isArray(data[templateKey])) {
                        if (data[templateKey].length) {
                            data[templateKey].forEach((subData) => {
                                if (
                                    typeof subData === DataTypes.STRING ||
                                    typeof subData === DataTypes.NUMBER
                                ) {
                                    finalData[index][templateKey].push(subData);
                                } else {
                                    finalData[index][templateKey].push(
                                        recursiveTraverse(subData)
                                    );
                                }
                            });
                        }
                    } else {
                        const subKeysData = [...Object.keys(data[templateKey])];
                        if (subKeysData.length) {
                            if (
                                (subKeysData[0] === '1' ||
                                    subKeysData[0] === 1) &&
                                (typeof data[templateKey][subKeysData[0]] ===
                                    DataTypes.STRING ||
                                    typeof data[templateKey][subKeysData[0]] ===
                                        DataTypes.NUMBER)
                            ) {
                                subKeysData.forEach((key) => {
                                    const subData = data[templateKey][key];
                                    finalData[index][templateKey].push(subData);
                                });
                            }
                        }
                    }
                }
            });
        } else if (
            typeof templateData[templateKey] === 'object' &&
            Array.isArray(templateData[templateKey]) &&
            typeof templateData[templateKey][0] === 'object'
        ) {
            // If data is multiple and object type
            extractedData.forEach((data, index) => {
                if (!data[templateKey]) {
                    finalData[index][templateKey] = [];
                } else if (
                    data[templateKey] &&
                    typeof data[templateKey] === 'object'
                ) {
                    finalData[index][templateKey] = [];
                    if (Array.isArray(data[templateKey])) {
                        if (data[templateKey].length) {
                            data[templateKey].forEach((subData) => {
                                if (typeof subData === 'object') {
                                    finalData[index][templateKey].push(subData);
                                }
                            });
                        }
                    } else {
                        const subKeysData = [...Object.keys(data[templateKey])];
                        if (subKeysData.length) {
                            if (
                                (subKeysData[0] === '1' ||
                                    subKeysData[0] === 1) &&
                                typeof data[templateKey][subKeysData[0]] ===
                                    'object'
                            ) {
                                subKeysData.forEach((key) => {
                                    const subData = data[templateKey][key];
                                    finalData[index][templateKey].push(subData);
                                });
                            } else {
                                finalData[index][templateKey].push(
                                    data[templateKey]
                                );
                            }
                        }
                    }
                }
            });
        }
    });

    // console.log('Data formatted as Template', finalData);
    return finalData;
};

// Function to recursively get inside and get first single data
const recursiveTraverse = (data) => {
    if (data) {
        if (typeof data === 'string' || typeof data === 'number') {
            return data;
        } else if (Array.isArray(data)) {
            return recursiveTraverse(data[0]);
        } else if (typeof data === 'object' && data !== null) {
            const firstKey = Object.keys(data)[0];
            return recursiveTraverse(data[firstKey]);
        }
    }

    return noData;
};

//  Function to get field data recursively be it single ['data'] or multiple ['d1','d2']
const getFieldData = (path, extractedData) => {
    if (!path) {
        return recursiveTraverse(extractedData);
    }

    const remainingPath = [...path];
    const currentKey = remainingPath.shift();
    let current = extractedData[currentKey];

    if (current || current === 0) {
        if (
            remainingPath.length === 0 &&
            (typeof current === DataTypes.STRING ||
                typeof current === DataTypes.NUMBER)
        ) {
            return current;
        } else if (typeof current === 'object' && !Array.isArray(current)) {
            return getFieldData(remainingPath, current);
        } else if (typeof current === 'object' && Array.isArray(current)) {
            if (current.length) {
                const fieldData = [];
                if (remainingPath.length === 0) {
                    current.forEach((data) => {
                        if (
                            typeof data === DataTypes.STRING ||
                            typeof data === DataTypes.NUMBER
                        ) {
                            fieldData.push(data);
                        }
                    });
                    return fieldData;
                } else {
                    remainingPath.shift();
                    current.forEach((data) => {
                        const subData = getFieldData([...remainingPath], data);
                        fieldData.push(subData);
                    });
                    return fieldData;
                }
            }
        }
    }

    return noData;
};

// Function to get chart data
const generateChartData = (
    selectedFieldsData,
    extractedData,
    keyPathData,
    chartData
) => {
    // Creating graph data
    const graphData = [];
    const { chart, useRowFormat } = chartData;

    for (const extData of extractedData) {
        // Fetching actual data and grouping according to field name
        const allValues = [];
        selectedFieldsData.forEach((data) => {
            const modifiedField = data.field
                .toLowerCase()
                .replace(/[^a-zA-Z0-9 ]/g, ' ')
                .trim();
            const path = findKeyPath(modifiedField, keyPathData);
            const fieldData = getFieldData(path, extData);
            const finalFieldData = !Array.isArray(fieldData)
                ? [
                      data.type === DataTypes.NUMBER &&
                      chartData.chart !== ChartTypes.TABLE
                          ? convertToNumber(fieldData)
                          : `${fieldData}`,
                  ]
                : fieldData.map((fData) =>
                      data.type === DataTypes.NUMBER &&
                      chartData.chart !== ChartTypes.TABLE
                          ? convertToNumber(fData)
                          : `${fData}`
                  );
            allValues.push(finalFieldData);
        });

        if (chart === ChartTypes.TABLE && useRowFormat) {
            graphData.push(['', '']);
            // Forming actual data in specific format to feed to chart viewer
            selectedFieldsData.forEach((data, indexX) => {
                const combinedData = [];
                combinedData.push(toSentenceCase(data.field));
                let valueStr = '';
                allValues[indexX].forEach((value, indexY) => {
                    if (indexY !== allValues[indexX].length - 1) {
                        valueStr += value + ', ';
                    } else {
                        valueStr += value;
                    }
                });
                combinedData.push(valueStr);
                graphData.push(combinedData);
            });
        } else {
            // Forming Parameter
            const fieldData = [];
            selectedFieldsData.forEach((data) => {
                fieldData.push(toSentenceCase(data.field));
            });
            graphData.push(fieldData);

            let maxRows = 0;
            allValues.forEach((data) => {
                if (Array.isArray(data)) {
                    if (maxRows < data.length) {
                        maxRows = data.length;
                    }
                } else {
                    if (maxRows < 1) {
                        maxRows = 1;
                    }
                }
            });

            // Creating graph data
            for (let i = 0; i < maxRows; i++) {
                let rowData = [];
                allValues.forEach((data) => {
                    if (Array.isArray(data)) {
                        if (i < data.length) {
                            rowData.push(data[i]);
                        } else {
                            rowData.push(data[0]);
                        }
                    } else {
                        rowData.push(data ? data : noData);
                    }
                });
                graphData.push(rowData);
            }
        }
    }

    // console.log('Graph Data', graphData);
    return graphData;
};

// Function to get title of report based on user selected field.
const getReportTitle = (reportTitleField, extractedData, keyPathData) => {
    // Getting reportTitle
    if (reportTitleField) {
        if (reportTitleField === defaultReportTitle) {
            return null;
        }
        const modifiedField = reportTitleField
            .toLowerCase()
            .replace(/[^a-zA-Z0-9 ]/g, ' ')
            .trim();
        const path = findKeyPath(modifiedField, keyPathData);
        const fieldData = getFieldData(path, extractedData[0]);
        const finalFieldData = !Array.isArray(fieldData) ? fieldData : null;
        return finalFieldData !== noData ? finalFieldData : null;
    } else {
        return null;
    }
};

const createLayoutWithData = (layout, templateData, extractedData) => {
    const updatedExtractedData = convertKeysToSimilarFormat(extractedData);
    // console.log('updatedExtractedData', updatedExtractedData);

    const updatedTemplateData = convertKeysToSimilarFormat(templateData);
    // console.log('updatedTemplateData', updatedTemplateData);

    const keyPathData = buildKeyPathMap(updatedTemplateData);

    const dataAsTemplate = getDataAsTemplate(
        updatedTemplateData,
        updatedExtractedData
    );
    // console.log('dataAsTemplate', dataAsTemplate);

    // Creating a deep copy of layout.
    const layoutWithData = JSON.parse(JSON.stringify(layout));

    // Getting title of report
    console.log('@@@@@@@@@@', layoutWithData[0].metaData);
    const useCustomReportTitle =
        layoutWithData[0].metaData.useCustomReportTitle;
    const reportTitleField = layoutWithData[0].metaData.reportTitle;
    const reportTitle = useCustomReportTitle
        ? reportTitleField
        : getReportTitle(reportTitleField, dataAsTemplate, keyPathData);

    layoutWithData.forEach((data) => {
        const chartData = generateChartData(
            data.selectedFields,
            dataAsTemplate,
            keyPathData,
            data.chartData
        );
        data.chartData.data = chartData;
    });

    return { reportTitle: reportTitle, layoutWithData: layoutWithData };
};

const handleDownload = (pdfRef, reportHeaderName, setIsDownloading) => {
    setIsDownloading(true);
    // Define options for PDF generation
    const options = {
        margin: [12, 0], // Set pdf margin in mm
        filename: `${reportHeaderName}.pdf`, // Set filename
        image: { type: 'jpeg', quality: 1 }, // Set image type and quality
        html2canvas: {
            dpi: 192,
            y: 0,
            x: 0,
            scrollY: 0,
            scrollX: 0,
            scale: 3, // Quality of allover pdf, Increase scale for better quality,
            letterRendering: true,
            useCORS: true,
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }, // Set unit, format, and orientation
    };

    const contentDiv = pdfRef.current.innerHTML;

    setTimeout(() => {
        // Generate PDF from content
        html2pdf().from(contentDiv).set(options).save();
        setIsDownloading(false);
    }, 0);
};

export const ReportModal = ({
    extractionRepo,
    setShowReportModal,
    template,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const pdfRef = React.createRef();

    const [layouts, setLayouts] = useState([]);
    const [templateData, setTemplateData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [selectedLayout, setSelectedLayout] = useState({
        name: '',
        isShared: false,
    });
    const [enableEditing, setEnableEditing] = useState(false);
    const [layoutsList, setLayoutsList] = useState([]);
    const [extractedData, setExtractedData] = useState(null);
    const [reportHeaderName, setReportHeaderName] = useState(
        extractionRepo.name
    );
    const [reportTitleData, setReportTitleData] = useState([]);
    const [closeDropdownSignal, setCloseDropdownSignal] = useState(false);

    const timeout = setTimeout(() => {
        setCloseDropdownSignal(false);
    }, 500);

    const toggleDropdownSignal = () => {
        clearTimeout(timeout);
        setCloseDropdownSignal(true);
        setTimeout(timeout);
    };

    const getLayoutData = async (email, template) => {
        try {
            setLoadingText('Loading Layout Data...');
            setIsLoading(true);

            const layoutName = selectedLayout.name;
            const templateName = template;
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getLayoutData?email=${email}&templateName=${templateName}&layoutName=${layoutName}`);

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            setIsLoading(false);
            setLoadingText('');

            if (response) {
                const layoutData = response.data;

                if (layoutData) {
                    return layoutData;
                }
            }
        } catch (error) {
            alert(error.message);
            setIsLoading(false);
            setLoadingText('');
        }
    };

    const getLayoutsList = async (email, templateName) => {
        try {
            setLoadingText('Loading Layout List...');
            setIsLoading(true);
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getLayoutsList?email=${email}&template=${templateName}`);

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            setIsLoading(false);
            setLoadingText('');

            if (response) {
                const layoutsData = response.data.layoutsList;
                if (layoutsData) {
                    return layoutsData;
                } else {
                    return [];
                }
            }
        } catch (error) {
            setIsLoading(false);
            setLoadingText('');
            alert(error.message);
        }
    };

    const getTemplateData = async (email, templateName) => {
        try {
            setLoadingText('Loading Template Data...');
            setIsLoading(true);

            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getTemplateData?email=${email}&userEmail=${user.email}&templateName=${templateName}`);

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response && response.data.templateData) {
                const templateData = response.data.templateData;
                return templateData;
            }

            setIsLoading(false);
            setLoadingText('');
        } catch (error) {
            alert(error.response.data.error);
            setIsLoading(false);
            setLoadingText('');
        }
    };

    const getExtractedData = async (email, repoName, templateName) => {
        try {
            setLoadingText('Loading Extracted Data...');
            setIsLoading(true);

            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getExtractedData?email=${email}&repoName=${repoName}&templateName=${templateName}`);

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            setIsLoading(false);
            setLoadingText('');

            if (response) {
                const extractedData = response.data;

                if (extractedData) {
                    return extractedData;
                }
            }
        } catch (error) {
            alert(error.message);
            setIsLoading(false);
            setLoadingText('');
        }
    };

    useEffect(() => {
        if (!reportHeaderName) {
            alert("Report name field can't be empty!");
        }

        return () => {};
    }, [reportHeaderName]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const templateData = await getTemplateData(
                extractionRepo.email,
                template
            );
            const extractedData = await getExtractedData(
                extractionRepo.email,
                extractionRepo.name,
                template
            );

            const list = await getLayoutsList(user.email, template);
            let sharedList = [];
            if (
                user.email.toLowerCase() !== extractionRepo.email.toLowerCase()
            ) {
                sharedList = await getLayoutsList(
                    extractionRepo.email,
                    template
                );
                sharedList.forEach((layout) => (layout.isShared = true));
            }

            const finalLayoutList = [...sharedList, ...list];

            if (templateData) {
                setTemplateData(templateData);
            }

            if (extractedData) {
                setExtractedData(extractedData);
            }

            if (finalLayoutList) {
                setLayoutsList(finalLayoutList);
                if (finalLayoutList.length) {
                    setSelectedLayout(finalLayoutList[0]);
                }
            }

            setIsLoading(false);
            setLoadingText('');
        })();

        return () => {};
    }, [extractionRepo]);

    useEffect(() => {
        setEnableEditing(false);

        (async () => {
            if (selectedLayout && selectedLayout.name && extractedData) {
                let email = '';
                if (selectedLayout.isShared) {
                    email = extractionRepo.email;
                } else {
                    email = user.email;
                }
                const layoutData = await getLayoutData(email, template);
                const allLayoutsWithData = [];
                const reportTitles = [];

                extractedData.forEach((data) => {
                    const completeData = createLayoutWithData(
                        layoutData,
                        templateData,
                        [data]
                    );

                    reportTitles.push(completeData.reportTitle);
                    allLayoutsWithData.push(completeData.layoutWithData);
                });

                setReportTitleData(reportTitles);
                setLayouts(allLayoutsWithData);
            }
        })();

        return () => {};
    }, [selectedLayout]);

    return (
        <Modal
            title={'Report viewer'}
            titleIcon={faPieChart}
            iconColor={'lightblue'}
            onClose={() => setShowReportModal(false)}
            className={''}
            onScroll={() => {
                toggleDropdownSignal();
            }}
        >
            <div
                className={`flex flex-col items-center min-w-fit ${
                    window.innerWidth > BREAKPOINT_WIDTH
                        ? 'w-[70vw]  h-[79vh]'
                        : 'w-[100vw] h-[100vh]'
                }`}
            >
                {/* Header */}
                <div className='w-full flex items-center justify-between p-3 shadow'>
                    <div
                        className='font-semibold text-texts-secondary300 mx-1 w-52 min-w-52 overflow-hidden text-ellipsis whitespace-nowrap'
                        title={reportHeaderName}
                    >
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            className='text-buttons-alert400'
                        />
                        <span className='ml-2'>{reportHeaderName}</span>
                    </div>

                    <div>
                        {window.innerWidth > BREAKPOINT_WIDTH ? (
                            <Toggle
                                toggleText={'Enable Editing'}
                                toggleTextClassName={
                                    'font-semibold text-texts-secondary300 mr-3'
                                }
                                checked={enableEditing}
                                onChange={() =>
                                    setEnableEditing(!enableEditing)
                                }
                                disabled={
                                    layouts.length === 0 ||
                                    !selectedLayout ||
                                    isLoading ||
                                    isDownloading
                                }
                            />
                        ) : (
                            <div className='font-semibold text-texts-secondary300 mr-3'>
                                Editing not supported for small screen
                            </div>
                        )}
                    </div>

                    <div className='flex items-center justify-center ml-2 h-full'>
                        <div className='mx-3'>
                            <Dropdown
                                dropDownList={getDropDownList(
                                    layoutsList,
                                    selectedLayout,
                                    setSelectedLayout
                                )}
                                showButtonText={true}
                                buttonText={getFileNameWithoutExt(
                                    selectedLayout.name
                                )}
                                buttonTitle={'Layout'}
                                buttonHoverText={'Select Report Layout'}
                                buttonClassName={
                                    'text-backgrounds-primary400 rounded-l-xl'
                                }
                                listClassName={''}
                                disabled={
                                    isLoading || isDownloading || enableEditing
                                }
                                closeSignal={closeDropdownSignal}
                            />
                        </div>

                        <Button
                            text={
                                isDownloading ? (
                                    <div>
                                        <span className='mr-2'>
                                            Downloading
                                        </span>
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{ fontSize: 18 }}
                                                    spin
                                                />
                                            }
                                        />
                                    </div>
                                ) : (
                                    'Download Report'
                                )
                            }
                            className={'m-0 w-44'}
                            hoverText={
                                !reportHeaderName
                                    ? "report name can't be empty!"
                                    : !enableEditing
                                    ? `Download this report`
                                    : `Disable editing to download this report`
                            }
                            icon={faFileDownload}
                            disabled={
                                enableEditing ||
                                layouts.length === 0 ||
                                !selectedLayout ||
                                !reportHeaderName ||
                                isLoading ||
                                isDownloading
                            }
                            onClick={() => {
                                handleDownload(
                                    pdfRef,
                                    reportHeaderName,
                                    setIsDownloading
                                );
                            }}
                            type={'success'}
                        />
                    </div>
                </div>
                {/* Body */}
                <div
                    className='w-full h-full flex justify-center py-4 overflow-auto shadow-inner'
                    onScroll={() => {
                        toggleDropdownSignal();
                    }}
                >
                    <ReportContainer
                        extractionRepo={extractionRepo}
                        layouts={layouts}
                        layoutName={selectedLayout.name}
                        template={template}
                        reportHeaderName={reportHeaderName}
                        setReportHeaderName={setReportHeaderName}
                        reportTitleData={reportTitleData}
                        AppData={AppData}
                        CompanyData={CompanyData}
                        enableEditing={enableEditing}
                        isLoading={isLoading}
                        loadingText={loadingText}
                        pdfRef={pdfRef}
                    />
                </div>
            </div>
        </Modal>
    );
};
