import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { DragDropFile, FileListViewer } from './reusableComponents';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmModal } from './reusableComponents';
import { getUA, isMobile } from 'react-device-detect';
import { getUrl } from './pdfDisplay/utils';

const AddTemplate = ({
    templateList,
    afterUpload,
    setActiveTemplate,
    setTemplateList,
    setAddNewTemplate,
}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);
    const [shouldDisplayConfirmModal, setShouldDisplayConfirmModal] =
        useState(false);
    const user = JSON.parse(localStorage.getItem('current'));
    useEffect(() => {}, [selectedFiles]);
    function checkIfFileExists(searchString) {
        if (templateList && templateList.length) {
            for (let i = 0; i < templateList.length; i++) {
                const fileName = templateList[i].filename;

                if (fileName === searchString) {
                    // there exists a file with same name as well as extension
                    return {
                        isNameWithExtensionSame: true,
                        isNameWithoutExtensionSame: true,
                    };
                } else {
                    const fileNameParts = fileName.split('.');
                    const searchStringParts = searchString.split('.');
                    if (
                        fileNameParts.length >= 2 &&
                        searchStringParts.length >= 2
                    ) {
                        const fileNameWithoutExtension = fileNameParts
                            .slice(0, fileNameParts.length - 1)
                            .join('.');
                        const searchStringWithOutExtension = searchStringParts
                            .slice(0, searchStringParts.length - 1)
                            .join('.');

                        if (
                            fileNameWithoutExtension ===
                            searchStringWithOutExtension
                        ) {
                            // there exists a file with same name but different extension
                            return {
                                isNameWithExtensionSame: false,
                                isNameWithoutExtensionSame: true,
                            };
                        }
                    }
                }
            }
        }
        // no matching file present
        return {
            isNameWithExtensionSame: false,
            isNameWithoutExtensionSame: false,
        };
    }

    // Handle file selection
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        setError('');
        validateFiles(files);
    };

    // Handle file drop
    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
        setError('');
        validateFiles(files);

        // Programmatically set the files of the file input
        if (fileInputRef.current) {
            const dataTransfer = new DataTransfer();
            files.forEach((file) => dataTransfer.items.add(file));
            fileInputRef.current.files = dataTransfer.files;
        }
    };

    // Prevent default behavior for file drop
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Validate file types
    const validateFiles = (files) => {
        if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileName = file.name;

                if (fileName.toLowerCase().startsWith('default_')) {
                    setError('Template name can not start with default_');
                    return;
                }

                const fileNameParts = fileName.split('.');
                const fileExtension = fileNameParts[fileNameParts.length - 1];
                const fileExtensionInLowerCase = fileExtension.toLowerCase();
                const validFileNameRegex = /^[a-zA-Z0-9_.]*$/;
                const isValid = validFileNameRegex.test(fileName);
                 if (
                     fileExtensionInLowerCase !== 'csv' &&
                     fileExtensionInLowerCase !== 'py' && !isValid
                 ) {
                    setError(
                        'Only CSV and py files are allowed also File name can only contain letters, numbers, underscores, and dots.'
                    ); 
                    return;
                }
                if (
                     fileExtensionInLowerCase !== 'csv' &&
                     fileExtensionInLowerCase !== 'py'
                 ) {
                    setError('Only CSV and py files are allowed.');    
                     return;
                }

                if (!isValid) {
                    setError(
                        'File name can only contain letters, numbers, underscores, and dots.'
                    );
                    return;
                }
            }
        } else {
            setError('No File.');
            return;
        }
    };

    const uploadTemplate = async () => {
        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}`);
            const data = { id: user.email };
            const data2 = JSON.stringify(data);
            const encodedToken = encodeURIComponent(user.token);
            const formData = new FormData();

            selectedFiles.forEach((file, index) => {
                formData.append(`file`, file);
            });
            await axios.post(`${dynamicUrl}/upload222/${data2}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: encodedToken,
                },
            });
            // Handle success
            console.log('File uploaded successfully!');
            setSelectedFiles([]);
            afterUpload(user, setActiveTemplate, setTemplateList);
        } catch (error) {
            // Handle error
            console.error('Error uploading file:', error);
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (!selectedFiles || selectedFiles.length === 0) {
            setError('Please select a file.');
            return;
        }

        for (const file of selectedFiles) {
            const fileName = file.name; // Get the name of the file
            const fileContent = await readFileContent(file);
            console.log(fileContent);
            const { isNameWithExtensionSame, isNameWithoutExtensionSame } =
                checkIfFileExists(fileName);

            if (isNameWithoutExtensionSame && !isNameWithExtensionSame) {
                // display error that a template with same name but different extension already exists.
                setError(
                    'Template with same name but different extension already exists.'
                );
                return;
            }
            const validationResponse = await validateAndUploadFile(
                file,
                setError
            );

            if (validationResponse.isValid) {
                console.log('File is valid, ready to upload.');
            } else {
                console.log('File validation failed for : ', fileName);
                return;
            }
            if (isNameWithExtensionSame && isNameWithoutExtensionSame) {
                // update the template
                setShouldDisplayConfirmModal(true);
                return;
            }
        }
        await uploadTemplate();
        setAddNewTemplate(false);
    };

    //function to validate all the uploaded template
    async function validateAndUploadFile(file, setError) {
        try {
            const fileContent = await readFileContent(file); // Assume readFileContent is defined elsewhere to read the file content

            // Determine the file extension
            const fileExtension = file.name.split('.').pop().toLowerCase();
            let validationResult = [];

            // Call the appropriate validation function based on file extension
            if (fileExtension === 'csv') {
                validationResult = validateCSVSchemaContent(fileContent); // Assume validateCSVSchemaContent returns an array
            } else if (fileExtension === 'py') {
                validationResult = validatePythonContentFormat(fileContent); // Assume validatePythonContentFormat returns an array
            } else {
                setError(
                    'Unsupported file type. Only CSV and Python file are allowed.'
                );
                return { isValid: false };
            }

            // Check validation results
            if (
                validationResult.length === 1 &&
                validationResult[0] === 'Validation Successful!'
            ) {
                console.log('Validation complete');
                return { isValid: true }; // Return a positive validation result
            } else {
                // Use the validationResult array to set the error message with detailed info
                setError(
                    `Uploaded file ${file.name} does not follow the required format:\n` +
                        validationResult.join(`\n`)
                );
                return { isValid: false }; // Return a negative validation result with errors
            }
        } catch (error) {
            console.error('Error reading file:', error);
            setError('Error reading file. Please try again.');
            return { isValid: false };
        }
    }

    // Function to read file content
    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            // Read the file as text
            reader.readAsText(file);
        });
    };

    const containsSpecialCharacters = (variable) => {
        const regex = /[!@#$%^&*(),.?":{}|<>_/]/;
        return regex.test(variable);
    };

    const validateCSVSchemaContent = (content) => {
        // Determine whether the first line starts with a quote
        const firstRowFirstChar = content.trim()[0];
        const hasSpecialHeader = ['"', "'", '`'].includes(firstRowFirstChar);

        // Configure PapaParse to treat the appropriate row as headers
        const parsed = Papa.parse(content, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            beforeFirstChunk: function (chunk) {
                const rows = chunk.split(/\r\n|\r|\n/);
                const startIndex = hasSpecialHeader ? 1 : 0;
                return rows.slice(startIndex).join('\n');
            },
        });

        if (parsed.data.length === 0) {
            return ['No data in file.'];
        }

        const errors = [];
        const requiredFields = [
            'Field Name',
            'Question',
            'Verbosity',
            'Sub_Field Count',
            'Multiple',
        ];

        // Check headers
        const headers = parsed.meta.fields;
        requiredFields.forEach((field) => {
            if (!headers.includes(field)) {
                errors.push(`Missing "${field}" header.`);
            }
        });

        // Validate each data row
        parsed.data.forEach((row, index) => {
            requiredFields.forEach((field) => {
                if (row[field] == null || row[field].toString().trim() === '') {
                    errors.push(
                        `Row ${index + 1}: "${field}" cannot be empty.`
                    );
                }
            });

            // Check for special characters in "Field Name" if "Multiple" is set to 'yes'
            if (row['Multiple']?.toString().trim().toLowerCase() === 'yes') {
                const fieldName = row['Field Name']?.toString().trim();
                if (containsSpecialCharacters(fieldName)) {
                    errors.push(
                        `Row ${
                            index + 1
                        }: Field name '${fieldName}' with multiple set to true contains special characters.`
                    );
                }
            }
        });
        return errors.length > 0 ? errors : ['Validation Successful!'];
    };
    const validatePythonContentFormat = (content) => {
        // Check if content contains a class definition named "Main"
        const classRegex = /class\s+Main\s*\(\s*BaseModel\s*\):/;
        let errors = [];
        if (content.length == 0) {
            return ['No data in file.'];
        }

        if (!classRegex.test(content)) {
            errors.push("Class definition 'Main(BaseModel)' not found.");
        }

        // Extract variable definitions within the class
        const variableRegex =
            /(\w+)\s*:\s*(Optional\[\s*(?:List\[\s*\w+\s*\]|str|int|float)\s*\]|List\[\s*(?:\w+\s*(?:,\s*\w+\s*)*)?\]| str|int|float)\s*=\s*Field\s*\(\s*description\s*=\s*(?:"(.*?)"|f"(.*?)"|'(.*?)'|f'(.*?)')/g;
        const variableMatches = [...content.matchAll(variableRegex)];

        // Check for list variable names with special characters and missing descriptions
        variableMatches.forEach((match) => {
            const [
                ,
                variableName,
                ,
                description1,
                description2,
                description3,
                description4,
            ] = match;
            // Check for description field
            const description =
                description1 || description2 || description3 || description4;

            // Check if the variable has special characters and is a List
            if (
                /List/.test(match[2]) &&
                containsSpecialCharacters(variableName)
            ) {
                errors.push(
                    `List variable '${variableName}' has special characters.`
                );
            }

            // Check if description is missing
            if (!description) {
                errors.push(
                    `Variable '${variableName}' is missing a description.`
                );
            }
        });

        return errors.length > 0 ? errors : ['Validation Successful!'];
    };

    return (
        <div className='flex flex-col border p-6 bg-backgrounds-slate text-texts-secondary300 shadow rounded-xl justify-center'>
            {shouldDisplayConfirmModal && (
                <ConfirmModal
                    modalText='A Template with same name already Exists. Do you want to update the existing template?'
                    onConfirm={() => {
                        uploadTemplate();
                        setShouldDisplayConfirmModal(false);
                        setAddNewTemplate(false);
                    }}
                    onCancel={() => {
                        setSelectedFiles([]);
                        setShouldDisplayConfirmModal(false);
                        setAddNewTemplate(false);
                    }}
                />
            )}
            <div className='flex items-center w-full mb-5 font-semibold'>
                <FontAwesomeIcon
                    icon={faFileUpload}
                    className='mr-2 text-backgrounds-primary400'
                />
                <div>Upload New Template</div>
            </div>

            <form onSubmit={handleSubmit}>
                <input
                    type='file'
                    className='bg-backgrounds-white w-full p-2 rounded-xl border cursor-pointer'
                    ref={fileInputRef}
                    onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                            const fileName = file.name;
                            handleFileSelect(e); // Proceed with the file selection
                        }
                    }}
                    style={{
                        maxWidth: '80rem', // or a specific width like '20rem' based on your layout
                        textOverflow: 'ellipsis', // adds ellipsis to overflowing text
                        overflow: 'hidden', // hides overflowing text
                    }}
                />
                {!isMobile && (
                    <DragDropFile
                        onDrop={(e) => {
                            const files = e.dataTransfer.files;
                            if (files.length > 0) {
                                const fileName = files[0].name;
                                handleFileDrop(e); // Proceed with the file drop

                            }
                        }}
                        onDragOver={handleDragOver}
                        text={
                            'Drag and drop file here or click on choose file icon to upload file'
                        }
                    />
                )}
                <div className='pt-6'>
                    {error && (
                        <div className='mb-3'>
                            {error.split('\n').map((line, index) => (
                                <div
                                    key={index}
                                    className='text-buttons-alert400'
                                >
                                    {line}
                                </div>
                            ))}
                        </div>
                    )}

                    <FileListViewer selectedFiles={selectedFiles} />
                </div>
                <div className='flex items-center justify-center mt-4'>
                    <button
                        className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                            error || selectedFiles.length === 0
                                ? 'text-texts-secondary300 cursor-not-allowed'
                                : 'hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                        }`}
                        type='submit'
                        title='Upload Template'
                        disabled={error || selectedFiles.length === 0}
                    >
                        <FontAwesomeIcon icon={faFileUpload} className='mr-2' />
                        Upload
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddTemplate;
