import React from 'react';
import { Chart } from 'react-google-charts';
import { Button } from '../../reusableComponents';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ChartTypes, defaultChartOptions } from '../constants';
import '../styles';

export const ReportCard = ({
    layout,
    metaData,
    chartsData,
    enableEditing,
    removeCard,
}) => {
    const cardName = metaData.get(layout.i) ? metaData.get(layout.i).name : '';
    const isCardNameHidden = metaData.get(layout.i)
        ? metaData.get(layout.i).isCardNameHidden
        : false;
    const chartData = chartsData.get(layout.i);

    const options = { ...defaultChartOptions };
    options.title = cardName;

    if (!chartData) return <></>;

    return (
        <div
            className={`w-full h-full ${
                chartData.chart === ChartTypes.TABLE && chartData.useRowFormat
                    ? enableEditing
                        ? 'border'
                        : ''
                    : 'border'
            }`}
        >
            {!isCardNameHidden ? (
                <div
                    className='px-1 pb-2 text-sm font-semibold text-slate-500 border-b truncate'
                    style={{
                        height: '25px',
                    }}
                >
                    {cardName}
                </div>
            ) : (
                <></>
            )}

            <div
                className={`w-full ${
                    chartData.useRowFormat ? 'row-table' : ''
                }`}
                style={{
                    height: !isCardNameHidden ? 'calc(100% - (25px))' : '100%',
                }}
            >
                <Chart
                    chartType={chartData.chart}
                    width='100%'
                    height='100%'
                    data={chartData.data}
                    options={chartData.options ? chartData.options : options}
                    chartPackages={['corechart', 'controls']}
                />
            </div>

            {enableEditing && (
                <Button
                    // text={'Delete Card'}
                    icon={faClose}
                    className={'absolute top-1 right-1 z-20 h-4 w-4'}
                    onClick={(e) => {
                        e.stopPropagation();
                        removeCard(layout.i);
                    }}
                    hoverText={'Remove card'}
                    type={'danger'}
                />
            )}
        </div>
    );
};
