import React, { useState } from 'react';
import Addnewrepo_sqlite from './addnewrepo_sqlite';
import Addnewrepo_mysql from './addnewrepo_mysql';
import Addnewrepo_postgres from './addnewrepo_postgres';

import { Select } from 'antd';

const { Option } = Select;

const Maindbrepo = (props) => {
    // Define state to hold the selected database type
    const [selectedDbType, setSelectedDbType] = useState('sqlite');

    const handleDbTypeChange = (value) => {
        setSelectedDbType(value);
    };

    return (
        <div className='container mx-auto py-2'>
            <div className='flex flex-row mr-3 items-center mb-4'>
                <div className='text-texts-secondary300 mr-3 font-semibold'>
                    Select Database Type
                </div>
                <Select
                    defaultValue='sqlite'
                    style={{ width: 120 }}
                    onChange={handleDbTypeChange}
                >
                    <Option value='sqlite'>SQLite</Option>
                    <Option value='postgres'>Postgres</Option>
                    <Option value='mysql'>MySQL</Option>
                </Select>
            </div>

            {selectedDbType === 'sqlite' && (
                <div>
                    <Addnewrepo_sqlite
                        reponame={props.reponame}
                        setAddNewMode={props.setAddNewMode}
                        getAllReposData={props.getAllReposData}
                        socket={props.socket}
                    ></Addnewrepo_sqlite>
                </div>
            )}
            {selectedDbType === 'postgres' && (
                <div>
                    <Addnewrepo_postgres
                        reponame={props.reponame}
                        setAddNewMode={props.setAddNewMode}
                        getAllReposData={props.getAllReposData}
                        socket={props.socket}
                    ></Addnewrepo_postgres>
                </div>
            )}
            {selectedDbType === 'mysql' && (
                <div>
                    <Addnewrepo_mysql></Addnewrepo_mysql>
                </div>
            )}
        </div>
    );
};

export default Maindbrepo;
