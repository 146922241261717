/**
 * Converts a given string to a number if possible.
 * If the string is not a number, it searches for a number within the text.
 * If no number is found, it returns 0.
 * If the input is undefined or null, it returns 0.
 * If the input is already a number, it returns the same number.
 *
 * @param {string|number|null|undefined} input - The input to be converted.
 * @returns {number} - The converted number or 0 if no number is found.
 */
const defaultNumber = 0;

export const convertToNumber = (input) => {
    // If the input is already a number, return it
    if (typeof input === 'number') {
        return input;
    }

    // If the input is undefined or null, return 0
    if (input === undefined || input === null) {
        return 0;
    }

    // Convert input to string for further processing
    const str = String(input);

    // Check if the entire string is a valid number
    if (!isNaN(str) && str.trim() !== '') {
        // Convert the valid numeric string to a number and return it
        return Number(str);
    }

    // Search for the first number in the string (including negative and decimal numbers)
    const match = str.match(/-?\d+(\.\d+)?/);

    // If a number is found, convert it to a number and return it
    // If no number is found, return default number
    return match ? Number(match[0]) : defaultNumber;
};
