import React, { useState, useEffect } from 'react';
import { Button, InputModal, ConfirmModal } from './reusableComponents';
import axios from 'axios';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';
import { RepoType } from '../constants/repoType';
import { getUrl } from './pdfDisplay/utils';

const truncateFilename = (filename, maxLength) => {
  const extension = filename.substring(filename.lastIndexOf('.'));
  const name = filename.substring(0, filename.lastIndexOf('.'));
  
  if (filename.length <= maxLength) return filename;

  const truncationLength = maxLength - extension.length - 3; // 3 for '...'
  const frontLength = Math.ceil(truncationLength / 2);
  const backLength = Math.floor(truncationLength / 2);

  const truncatedName = `${name.substring(0, frontLength)}...${name.substring(name.length - backLength)}`;

  return `${truncatedName}${extension}`;
};

export const ExtractData = ({ templateList, currentTemplate, repoName, repoId, email, socket, onClose, fetchDownloadList, getAllReposData, isReRun = false }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [parameters, setParameters] = useState([]);
  const [showInputModal, setShowInputModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isTemplateRecentlyUpdated, setIsTemplateRecentlyUpdated] = useState(false);

  useEffect(() => {
    (async () => {
      if(currentTemplate) {
        await checkTemplateUpdate(currentTemplate.filename);
      }
    })();
  }, [currentTemplate]);

  const isTemplateDifferent = currentTemplate && selectedTemplate && selectedTemplate?.filename !== currentTemplate?.filename;
  const isTemplateUpdatedButSame = ((isTemplateRecentlyUpdated && currentTemplate?.filename === selectedTemplate?.filename) || (!selectedTemplate && currentTemplate && isTemplateRecentlyUpdated));


  const fetchParameters = async (templateName) => {
    try {
      const user = JSON.parse(localStorage.getItem('current'));
      const response = await axios.post(
          getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get-parameters`),
          {
              email: user.email,
              template_name: templateName,
          },
          {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${user.token}`,
              },
          }
      );

      const params = response.data.parameters || [];
      setParameters(params);
      return params;
    } catch (error) {
      console.error('Error fetching parameters:', error);
      return [];
    }
  };

  const handleTemplateChange = async (event) => {
    const selectedTemplateObj = templateList.find(t => t.filename === event.target.value);
    setSelectedTemplate(selectedTemplateObj);
    if (selectedTemplateObj) {
      await fetchParameters(selectedTemplateObj.filename);
    } else {
      setParameters([]);
    }
  };

  const handleConfirmTemplateChange = async () => {
    setShowConfirmModal(false);
    onClose();
    await handleExtraction();
    await createNewDbRepo();
  };

  const handleCancelTemplateChange = () => {
    setShowConfirmModal(false);
  };

  const createNewDbRepo = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('current'));
      const newRepoName = `${repoName}_extracted_db`;

      const formData = new FormData();
      formData.append('reponame', newRepoName);
      formData.append('email', email);
      formData.append('repotype', RepoType.SQLLITE);
      formData.append('origin_id', repoId);
      formData.append('use_existing_db', 'true');

      const encodedToken = encodeURIComponent(user.token);
      const dynamicUrl = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/add_sqlite_repo`);

      await axios.post(dynamicUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: encodedToken,
        },
      })

      await getAllReposData();
    } catch (error) {
      console.error('Error creating new DB repo:', error);
    }
  };

  const handleExtraction = async (inputValues = {}) => {
    if (selectedTemplate || (currentTemplate && isTemplateRecentlyUpdated) || (isReRun && currentTemplate)) {
        try {
            const user = JSON.parse(localStorage.getItem('current'));
            const status = {
            message: `Starting extraction process on ${repoName}`,
            percentageCompletion: 0,
            process: ProcessType.EXTRACTION,
            progress: TaskProgressType.STARTED,
            repoId: repoId,
            repoName: repoName,
            statusCode: 200,
            timeStamp: new Date(),
            userMail: user.email,
        };
        socket.emit('statusUpdate', status);
        const formData = new FormData();
        formData.append('email', email);
        formData.append('repo_name', repoName);
        formData.append('repoid', repoId);
        formData.append('template_name', selectedTemplate?.filename || currentTemplate?.filename);
        formData.append('user_email', user.email);
        formData.append('db_extraction', '1');
        formData.append('parameters', JSON.stringify(inputValues));

        const response = await fetch(
          getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/extract-information`),
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setIsTemplateRecentlyUpdated(false);
        await fetchDownloadList(repoName, repoId, user.email);
      } catch (error) {
        console.error('Error starting extraction:', error);
      }
    }
  };

  const checkTemplateUpdate = async (templateName) => {
    try {
      const user = JSON.parse(localStorage.getItem('current'));
      const response = await fetch(
        getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/checkTemplateUpdate?templateName=${templateName}&userId=${user.email}&repoId=${repoId}`),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setIsTemplateRecentlyUpdated(result.isTemplateRecentlyUpdated);
    } catch (error) {
      console.error('Error checking template update:', error);
      setIsTemplateRecentlyUpdated(false);
    }
  };

  const handleRunExtraction = async () => {
    const hasParameters = parameters.length > 0;
  
    if (isReRun || (!isTemplateDifferent && !isTemplateUpdatedButSame)) {
      if (hasParameters) {
        setShowInputModal(true);
      } else {
        onClose();
        await handleExtraction();
        await createNewDbRepo();
      }
    } else if (isTemplateDifferent || isTemplateUpdatedButSame) {
      setShowConfirmModal(true);
    }
  };

  const handleInputModalSubmit = async (inputValues) => {
    setShowInputModal(false);
    await handleExtraction(inputValues);
    await createNewDbRepo();
  };


  return (
    <div className="p-4 max-w-md mx-auto">
      {!isReRun && (
        <div className="mb-4">
          <label htmlFor="templateSelect" className="block text-sm font-medium text-gray-700 mb-1">
            Select Template
          </label>
          <div className="relative">
            <select
              id="templateSelect"
              className="block w-full p-2 mt-1 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300"
              value={selectedTemplate ? selectedTemplate.filename : (currentTemplate ? currentTemplate.filename : '')}
              onChange={handleTemplateChange}
            >
              <option disabled={true} value="">
                Select a Template
              </option>
              {templateList && templateList.map((template, index) => (
                <option key={index} value={template.filename}>
                  {truncateFilename(template.filename, 40)}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <Button 
        onClick={()=>{handleRunExtraction()}}
        disabled={
          (!isReRun && !isTemplateRecentlyUpdated && currentTemplate?.filename === selectedTemplate?.filename) || 
          (!isReRun && !selectedTemplate && currentTemplate && !isTemplateRecentlyUpdated)
        }
        text={isReRun ? "Re-run Extraction" : "Run Extraction"}
        type="success"
        className="w-full p-2"
      />
      {showConfirmModal && (
        <ConfirmModal
          modalText={isTemplateUpdatedButSame ? "Template updated since previous extraction. Re-running will erase existing data from database. Are you sure you want to continue?" : "Extracting with a new template will erase existing data from database. Are you sure you want to continue?"}
          onConfirm={handleConfirmTemplateChange}
          onCancel={handleCancelTemplateChange}
        />
      )}
      {showInputModal && (
        <InputModal
          parameters={parameters}
          onSubmit={handleInputModalSubmit}
          onCancel={() => setShowInputModal(false)}
        />
      )}
    </div>
  );
};