import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from './reusableComponents';
import { Brand } from './brand';
import { PoweredByInfo } from './powereByComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { isEmailValid } from '../utils/validateEmail';
import { getUrl } from './pdfDisplay/utils';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);

    const [sendingMail, setSendingMail] = useState(false);
    const navigate = useNavigate();

    const handleSend = async (e) => {
        e.preventDefault();

        if (!isEmailValid(email)) {
            alert('Invalid email address!');
            setEmailValid(false);
            return;
        } else {
            setEmailValid(true);
        }

        setSendingMail(true);

        try {
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/register`
            );
            const res = await fetch(dynamicUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!res.ok) {
                const errorData = await res.json();
                throw new Error(errorData.error || 'Failed to send email.');
            }

            alert('Password reset link sent successfully!');
            navigate('/confirmation');
        } catch (error) {
            console.error('Error sending password reset link:', error);
            alert(error.message || 'An error occurred. Please try again.');
        } finally {
            setSendingMail(false);
        }
    };

    return (
        <div className='flex items-center justify-center min-h-screen bg-gradient-primary'>
            <div className='w-full max-w-[500px] flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                <div className='mb-8 w-full lg:w-3/5 mx-auto'>
                    <Brand iconClassName='w-20 h-20 mr-3' />
                </div>
                <h2 className='text-xl font-semibold mb-4 text-center text-texts-secondary'>
                    Reset Password
                </h2>
                <div className='text-sm mb-4 text-center text-texts-secondary300'>
                    Enter the email address associated with your account and
                    we'll send you a link to reset your password.
                </div>
                <form
                    onSubmit={handleSend}
                    className='flex flex-col text-sm w-full'
                >
                    <Input
                        className={`mb-5 rounded-xl border p-3 w-full hover:outline-none focus:outline-backgrounds-primary200 hover:border-backgrounds-primary200 shadow-sm ${
                            !emailValid && 'border-red-500'
                        }`}
                        type='email'
                        placeholder='Email Address'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button
                        className='flex w-full font-semibold items-center justify-center text-texts-primary bg-buttons-primary border border-1 shadow-inner hover:bg-gradient-buttons hover:border-buttons-primary hover:text-texts-primary p-3 mt-2 rounded-lg'
                        type='submit'
                        disabled={sendingMail}
                    >
                        {sendingMail ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className='animate-spin mr-1.5'
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faPaperPlane}
                                className='mr-1.5'
                            />
                        )}
                        <span className='drop-shadow-sm'>Send</span>
                    </button>
                </form>
                <div className='mt-5 p-1 text-center text-sm font-semibold text-texts-tertiary'>
                    <Link to='/login'>Back to Login</Link>
                </div>
                <div className='w-full flex justify-center items-center mt-8'>
                    <PoweredByInfo />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
