import React, { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router';
import { getUrl } from './pdfDisplay/utils';
// import { handleLogout } from './userDetailAndAction';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const { login, logout } = useAuth();
    const user = JSON.parse(localStorage.getItem('current'));

    useEffect(() => {
        const checkEmail = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/verify_mail?token=${token}`);
            const response = await fetch(dynamicUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                let res = await response.json();
                login(res);
                navigate('/chats');
            } else {
                logout();
                navigate('/login');
            }
        };
        checkEmail();
    }, []);

    return <></>;
};

export default VerifyEmail;
