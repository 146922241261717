import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFolder,
    faArrowRight,
    faComments,
    faHandPointLeft,
    faArrowDown,
    faHandPointUp,
} from '@fortawesome/free-solid-svg-icons';

const BeforeSelect = () => {
    const isSmallScreen = window.innerWidth < 1024;

    return (
        <div className='bg-backgrounds-slate w-full h-full rounded-xl'>
            {!isSmallScreen ? (
                <div className='w-full h-full'>
                    <div className='flex h-4/6 items-center justify-center text-texts-secondary300'>
                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <FontAwesomeIcon
                                icon={faFolder}
                                className='w-[8vw] h-[8vw] drop-shadow text-icons-primary100'
                            />
                            <div>Select Repository</div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className='w-[5vw] h-[5vw] m-[3vw] drop-shadow text-icons-tertiary100'
                        />
                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <FontAwesomeIcon
                                icon={faComments}
                                className='w-[8vw] h-[8vw] drop-shadow text-icons-primary100'
                            />
                            <div>Start Chatting</div>
                        </div>
                    </div>

                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-8'>
                        <FontAwesomeIcon
                            icon={faHandPointLeft}
                            className='mr-3 text-icons-primary'
                        />
                        <div>
                            Select a repository from the repositories section to
                            start chatting
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col items-center'>
                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-20 pl-2 pr-2'>
                        <FontAwesomeIcon
                            icon={faHandPointUp}
                            className='mr-3 text-icons-primary'
                        />
                        <div className='hidden md:block'>
                            Select a repository from the repositories section to
                            start chatting
                        </div>
                        <div className='block md:hidden'>
                            Select a repository from the repositories section to
                            start chatting
                        </div>
                    </div>

                    <div className='flex flex-col items-center justify-center h-4/6 text-texts-secondary300 mt-20'>
                        <div className='flex flex-col items-center justify-center mb-2 drop-shadow'>
                            <FontAwesomeIcon
                                icon={faFolder}
                                className='w-20 h-20 md:w-24 md:h-24 text-icons-tertiary'
                            />
                            <div className='md:ml-3 text-base md:text-lg'>
                                Select Repository
                            </div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowDown}
                            className='w-10 h-10 md:w-12 md:h-12 my-2 drop-shadow text-icons-tertiary100'
                        />
                        <div className='flex flex-col items-center justify-center mb-2 drop-shadow'>
                            <FontAwesomeIcon
                                icon={faComments}
                                className='w-20 h-20 md:w-24 md:h-24 text-icons-primary100'
                            />
                            <div className='md:ml-3 text-base md:text-lg'>
                                Start Chatting
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BeforeSelect;
