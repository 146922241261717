const DataType = Object.freeze({
    STRING: 'string',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
});

const MODE = Object.freeze({
    QNA: 'qna',
});

const PROMPT = Object.freeze({
    DEFAULT: 'default',
});

const IMAGES = Object.freeze({
    CHAT_STEP_1: 'Step1.png',
    CHAT_STEP_2: 'Step2.png',
    CHAT_STEP_3: 'Step3.png',
});

const MENUITEMS = Object.freeze({
    HOME: 'Home',
    FUNCTIONALITIES: 'Functionalities',
    WORKFLOW: 'Workflow',
    PRICING: 'Pricing',
    CONTACTUS: 'Contact Us',
});

const PLATFORM = Object.freeze({
    ONE_DRIVE: 'OneDrive',
    GOOGLE_DRIVE: 'GoogleDrive',
});

module.exports = { DataType, MODE, IMAGES, PROMPT, MENUITEMS, PLATFORM };
