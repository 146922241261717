import React from 'react';
import { Progress } from 'antd';

const conicColors = {
    '0%': '#f87171',
    '33%': '#ffe58f',
    '66%': '#7dd3fc',
    '100%': '#49de80',
};

export const ProgressBar = ({
    type,
    percentage,
    status,
    size,
    allowStrokeColor,
}) => {
    return (
        <Progress
            type={type}
            percent={percentage}
            strokeColor={allowStrokeColor ? conicColors : null}
            size={size}
            status={status}
        />
    );
};
