import React from 'react';
import {
    appLogo,
    companyLogo,
    appName,
    contactEmail,
    contactNumber,
} from '../Constants';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { useMenuItems } from '../menuItems';

const PrivacyPolicy = () => {
    const { menuItems } = useMenuItems();
    return (
        <>
            <Navbar menuItems={menuItems} />
            <div className='mt-24 mx-0 md:mx-8 lg:mx-20 p-8'>
                <h1 className='font-bold text-3xl mb-8 text-center'>
                    Privacy Policy
                </h1>

                {/* Privacy Policy Content */}
                <div className='text-texts-secondary'>
                    <p className='mb-4'>
                        The following terminology applies to these Terms and
                        Conditions, Privacy Statement and Disclaimer Notice and
                        all Agreements: "Client", "You" and "Your" refers to
                        you, the person log on this website and compliant to the
                        Company’s terms and conditions. "The Company",
                        "Ourselves", "We", "Our" and "Us", refers to our
                        Company. "Party", "Parties", or "Us", refers to both the
                        Client and ourselves. This Privacy Policy describes our
                        practices with respect to Personal Information we
                        collect from or about you when you use our website,
                        applications, and services (collectively, “Services”).
                        This Privacy Policy does not apply to content that we
                        process on behalf of customers of our business
                        offerings, such as our API. Our use of that data is
                        governed by our customer agreements covering access to
                        and use of those offerings.
                    </p>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        Personal Information We Collect
                    </h3>
                    <p className='mb-4'>
                        We collect personal information relating to you
                        (“Personal Information”) as follows:
                    </p>
                    <strong>Personal Information You Provide:</strong> We
                    collect Personal Information if you create an account to use
                    our Services or communicate with us as follows:
                    <ul className='list-disc list-inside mb-4'>
                        <li className='mb-2'>
                            <strong>Account Information:</strong> When you
                            create an account with us, we will collect
                            information associated with your account, including
                            your name, contact information, account credentials,
                            payment card information, and transaction history,
                            (collectively, “Account Information”).
                        </li>
                        <li className='mb-2'>
                            <strong>User Content:</strong> When you use our
                            Services, we collect Personal Information that is
                            included in the input, file uploads, or feedback
                            that you provide to our Services (“Content”).
                        </li>
                        <li className='mb-2'>
                            <strong>Communication Information:</strong> If you
                            communicate with us, we collect your name, contact
                            information, and the contents of any messages you
                            send (“Communication Information”).
                        </li>
                        <li>
                            <strong>Other Information You Provide:</strong> We
                            collect other information that you may provide to
                            us, such as when you participate in our events or
                            surveys or provide us with information to establish
                            your identity (collectively, “Other Information You
                            Provide”).
                        </li>
                    </ul>
                    <strong>
                        Personal Information We Receive Automatically from Your
                        Use of the Services:
                    </strong>{' '}
                    When you visit, use, or interact with the Services, we
                    receive the following information about your visit, use, or
                    interactions (“Technical Information”):
                    <ul className='list-disc list-inside mb-4'>
                        <li className='mb-2'>
                            <strong>Log Data:</strong> Information that your
                            browser or device automatically sends when you use
                            our Services. Log data includes your Internet
                            Protocol address, browser type and settings, the
                            date and time of your request, and how you interact
                            with our Services.
                        </li>
                        <li className='mb-2'>
                            <strong>Usage Data:</strong> We may automatically
                            collect information about your use of the Services,
                            such as the types of content that you view or engage
                            with, the features you use and the actions you take,
                            as well as your time zone, country, the dates and
                            times of access, user agent and version, type of
                            computer or mobile device, and your computer
                            connection.
                        </li>
                        <li className='mb-2'>
                            <strong>Device Information:</strong> Includes name
                            of the device, operating system, device identifiers,
                            and browser you are using. Information collected may
                            depend on the type of device you use and its
                            settings.
                        </li>
                        <li className='mb-2'>
                            <strong>Cookies:</strong> We use cookies to operate
                            and administer our Services, and improve your
                            experience. A “cookie” is a piece of information
                            sent to your browser by a website you visit. You can
                            set your browser to accept all cookies, to reject
                            all cookies, or to notify you whenever a cookie is
                            offered so that you can decide each time whether to
                            accept it. However, refusing a cookie may in some
                            cases preclude you from using, or negatively affect
                            the display or function of, a website or certain
                            areas or features of a website.
                        </li>
                        <li>
                            <strong>Analytics:</strong> We may use a variety of
                            online analytics products that use cookies to help
                            us analyze how users use our Services and enhance
                            your experience when you use the Services.
                        </li>
                    </ul>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        How We Use Personal Information
                    </h3>
                    <p className='mb-4'>
                        We may use Personal Information for the following
                        purposes:
                    </p>
                    <ul className='list-disc list-inside mb-4'>
                        <li className='mb-2'>
                            To provide, administer, maintain and/or analyze the
                            Services;
                        </li>
                        <li className='mb-2'>
                            To improve our Services and conduct research;
                        </li>
                        <li className='mb-2'>
                            To communicate with you, including to send you
                            information about our Services and events;
                        </li>
                        <li className='mb-2'>
                            To develop new programs and services;
                        </li>
                        <li className='mb-2'>
                            To prevent fraud, criminal activity, or misuses of
                            our Services, and to protect the security of our IT
                            systems, architecture, and networks;
                        </li>
                        <li className='mb-2'>
                            To carry out business transfers; and
                        </li>
                        <li>
                            To comply with legal obligations and legal process
                            and to protect our rights, privacy, safety, or
                            property, and/or that of our affiliates, you, or
                            other third parties.
                        </li>
                    </ul>
                    <p className='font-semibold mt-4 mb-1'>
                        Aggregated or De-Identified Information
                    </p>
                    <p className='mb-4'>
                        We may aggregate or de-identify Personal Information so
                        that it may no longer be used to identify you and use
                        such information to analyze the effectiveness of our
                        Services, to improve and add features to our Services,
                        to conduct research and for other similar purposes. In
                        addition, from time to time, we may analyze the general
                        behavior and characteristics of users of our Services
                        and share aggregated information like general user
                        statistics with third parties, publish such aggregated
                        information or make such aggregated information
                        generally available. We may collect aggregated
                        information through the Services, through cookies, and
                        through other means described in this Privacy Policy. We
                        will maintain and use de-identified information in
                        anonymous or de-identified form and we will not attempt
                        to reidentify the information, unless required by law.
                    </p>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        Disclosure of Personal Information
                    </h3>
                    <p className='mb-4'>
                        In certain circumstances we may provide your Personal
                        Information to third parties without further notice to
                        you, unless required by the law:
                    </p>
                    <ul className='list-disc list-inside mb-4'>
                        <li className='mb-2'>
                            <strong>Vendors and Service Providers:</strong> To
                            assist us in meeting business operations needs and
                            to perform certain services and functions, we may
                            provide Personal Information to vendors and service
                            providers, including providers of hosting services,
                            customer service vendors, cloud services, email
                            communication software, web analytics services, and
                            other information technology providers, among
                            others. Pursuant to our instructions, these parties
                            will access, process, or store Personal Information
                            only in the course of performing their duties to us.
                        </li>
                        <li className='mb-2'>
                            <strong>Business Transfers:</strong> If we are
                            involved in strategic transactions, reorganization,
                            bankruptcy, receivership, or transition of service
                            to another provider (collectively, a “Transaction”),
                            your Personal Information and other information may
                            be disclosed in the diligence process with
                            counterparties and others assisting with the
                            Transaction and transferred to a successor or
                            affiliate as part of that Transaction along with
                            other assets.
                        </li>
                        <li className='mb-2'>
                            <strong>Legal Requirements:</strong> We may share
                            your Personal Information, including information
                            about your interaction with our Services, with
                            government authorities, industry peers, or other
                            third parties (i) if required to do so by law or in
                            the good faith belief that such action is necessary
                            to comply with a legal obligation, (ii) to protect
                            and defend our rights or property, (iii) if we
                            determine, in our sole discretion, that there is a
                            violation of our terms, policies, or the law; (iv)
                            to detect or prevent fraud or other illegal
                            activity; (v) to protect the safety, security, and
                            integrity of our products, employees, or users, or
                            the public, or (vi) to protect against legal
                            liability.
                        </li>
                        <li className='mb-2'>
                            <strong>Affiliates:</strong> We may disclose
                            Personal Information to our affiliates, meaning an
                            entity that controls, is controlled by, or is under
                            common control with us, for purposes consistent with
                            this Privacy Policy.
                        </li>
                    </ul>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        Your Rights
                    </h3>
                    <p className='mb-4'>
                        Depending on location, individuals may have certain
                        statutory rights in relation to their Personal
                        Information. For example, you may have the right to:
                    </p>
                    <ul className='list-disc list-inside mb-4'>
                        <li className='mb-2'>
                            <strong>Access:</strong> Access your Personal
                            Information and information relating to how it is
                            processed.
                        </li>
                        <li className='mb-2'>
                            <strong>Deletion:</strong> Delete your Personal
                            Information from our records.
                        </li>
                        <li className='mb-2'>
                            <strong>Rectification:</strong> Rectify or update
                            your Personal Information.
                        </li>
                        <li>
                            <strong>Data Portability:</strong> Transfer your
                            Personal Information to a third party (right to data
                            portability).
                        </li>
                    </ul>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        Link to Other Websites
                    </h3>
                    <p className='mb-4'>
                        The Service may contain links to other websites not
                        operated or controlled by {appName}, including social
                        media services (“Third Party Sites”). The information
                        that you share with Third Party Sites will be governed
                        by the specific privacy policies and terms of service of
                        the Third Party Sites and not by this Privacy Policy. By
                        providing these links we do not imply that we endorse or
                        have reviewed these sites. Please contact the Third
                        Party Sites directly for information on their privacy
                        practices and policies.
                    </p>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        Security and Retention
                    </h3>
                    <p className='mb-4'>
                        We implement commercially reasonable technical,
                        administrative, and organizational measures to protect
                        Personal Information both online and offline from loss,
                        misuse, and unauthorized access, disclosure, alteration,
                        or destruction. However, no Internet or email
                        transmission is ever fully secure or error free. In
                        particular, email sent to or from us may not be secure.
                        Therefore, you should take special care in deciding what
                        information you send to us via the Service or email. In
                        addition, we are not responsible for circumvention of
                        any privacy settings or security measures contained on
                        the Service, or third-party websites.
                    </p>
                    <p>
                        We’ll retain your Personal Information for only as long
                        as we need in order to provide our Service to you, or
                        for other legitimate business purposes such as resolving
                        disputes, safety and security reasons, or complying with
                        our legal obligations. How long we retain Personal
                        Information will depend on a number of factors, such as
                        the amount, nature, and sensitivity of the information,
                        the potential risk of harm from unauthorized use or
                        disclosure, our purpose for processing the information,
                        and any legal requirements.
                    </p>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        International Users
                    </h3>
                    <p className='mb-4'>
                        By using our Service, you understand and acknowledge
                        that your Personal Information will be processed and
                        stored in our facilities and servers in India and may be
                        disclosed to our service providers and affiliates in
                        other jurisdictions.
                    </p>
                    <p className='mb-4'>
                        <strong>Legal basis for processing:</strong> Our legal
                        bases for processing your Personal Information include:
                    </p>
                    <ul className='list-disc pl-5 mb-4'>
                        <li>
                            <strong>Performance of a contract:</strong> When we
                            provide and maintain our Services. When we process
                            Account Information, Content, and Technical
                            Information solely to provide our Services to you,
                            this information is necessary to be able to provide
                            our Services. If you do not provide this
                            information, we may not be able to provide our
                            Services to you.
                        </li>
                        <li>
                            <strong>Compliance with legal obligations:</strong>{' '}
                            When we use your Personal Information to comply with
                            applicable law or when we protect our or our
                            affiliates’, users’, or third parties’ rights,
                            safety, and property.
                        </li>
                    </ul>
                    <p>
                        <strong>Data transfers:</strong> Where required, we will
                        use appropriate safeguards for transferring Personal
                        Information outside of certain countries. We will only
                        transfer Personal Information pursuant to a legally
                        valid transfer mechanism.
                    </p>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        Changes to the Privacy Policy
                    </h3>
                    <p>
                        We may update this Privacy Policy from time to time.
                        When we do, we will post an updated version on this
                        page, unless another type of notice is required by
                        applicable law.
                    </p>
                    <h3 className='text-xl font-semibold mt-4 mb-2'>
                        How to Contact Us
                    </h3>
                    <p>
                        Please contact support if you have any questions or
                        concerns not already addressed in this Privacy Policy.
                    </p>
                    {contactEmail ? (
                        <div className='mt-4'>
                            <p className='font-medium'>Email:</p>
                            <a
                                href={`mailto:${contactEmail}`}
                                className='text-blue-600 hover:underline'
                            >
                                {contactEmail}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {contactNumber ? (
                        <div className='mt-2'>
                            <p className='font-medium'>Phone:</p>
                            <p className='text-gray-700'>{contactNumber}</p>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
