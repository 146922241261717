import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Select, Pagination, message } from "antd";
import { Pie, Bar } from "react-chartjs-2";
import "chart.js/auto";
import "antd/dist/reset.css";
import {
    faDownload,
    faExchangeAlt
} from '@fortawesome/free-solid-svg-icons';
import { Button, LoadingView } from './reusableComponents';
import { getUrl } from './pdfDisplay/utils';
import { SUBSCRIPTION_PLANS } from '../utils/planUtils';
const { Option } = Select;

// Formats a date string into a localized short format (e.g., "Jan 15, 2024")
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
};

// Formats a date range into a compact string (e.g., "Jan'24 - Mar'24")
const formatDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  
  const startMonth = start.toLocaleString('en-US', { month: 'short' });
  const startYear = start.getFullYear().toString().slice(2);
  const endMonth = end.toLocaleString('en-US', { month: 'short' });
  const endYear = end.getFullYear().toString().slice(2);
  
  return `${startMonth}'${startYear} - ${endMonth}'${endYear}`;
};

const chartOptions = {
  plugins: {
    legend: {
      display: false, // This disables the legend
    },
  },
};

const SubscriptionDashboard = ({setManageSubscriptionModal}) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [daily_data, setDailyData] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  // const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const itemsPerPage = 30;

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      setLoadingSubscriptions(true);
      try {
        const user = JSON.parse(localStorage.getItem('current'));
        if (!user?.email) {
          throw new Error('User email not found');
        }
        // Activate pending subscription
        await fetch(getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/activate-pending-subscription/${user.email}`));

        // Fetch current plan
        const response = await fetch(
          getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/current-plan/${user.email}`)
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch current plan: ${response.status}`);
        }
        
        const data = await response.json();
        const transformedData = {
          ...data,
          plan_name: data.name || SUBSCRIPTION_PLANS.FREE
        };
        
        setSubscriptions([transformedData]);
        setSelectedSubscription(transformedData);
        setDailyData(transformedData.daily_data || []);
      } catch (error) {
        console.error('Error fetching subscription data:', error.message);
        message.error('Failed to load subscription data');
        // Set default values
        const defaultData = {
          plan_name: SUBSCRIPTION_PLANS.FREE,
          pages_processed: 0,
          total_pages_allowed: 0,
          daily_data: []
        };
        setSubscriptions([defaultData]);
        setSelectedSubscription(defaultData);
        setDailyData([]);
      } finally {
        setLoadingSubscriptions(false);
      }
    };

    fetchSubscriptionData();
  }, []);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      if (!selectedSubscription?.id || selectedSubscription.plan_name === SUBSCRIPTION_PLANS.FREE) return;
      
      setLoadingInvoice(true);
      try {
        const user = JSON.parse(localStorage.getItem('current'));
        if (!user?.email) {
          throw new Error('User email not found');
        }

        const formData = new FormData();
        formData.append('email', user.email);
        formData.append('plan_id', selectedSubscription.id);
        formData.append('start_date', selectedSubscription.start_date);

        const response = await fetch(
          getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/invoice`),
          {
            method: 'POST',
            body: formData
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch invoice: ${response.status}`);
        }

        const data = await response.json();
        setInvoiceData(data || {});
      } catch (error) {
        console.error('Error fetching invoice data:', error.message);
        message.error('Failed to load invoice data');
        setInvoiceData({});
      } finally {
        setLoadingInvoice(false);
      }
    };

    fetchInvoiceData();
  }, [selectedSubscription]);

  const handleDownloadInvoice = async (id) => {
    try {
      if (!id) {
        message.warning('No invoice available for download');
        return;
      }

      const user = JSON.parse(localStorage.getItem('current'));
      if (!user?.token) {
        throw new Error('Authentication token not found');
      }

      const dynamicUrl = getUrl(
        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/download/${id.replace(/\.pdf$/, '')}`
      );
      const authToken = encodeURIComponent(user.token);

      const response = await axios.get(dynamicUrl, {
        headers: {
          Authorization: authToken,
        },
        responseType: 'blob',
      });

      if (response.status === 200) {
        const blob = response.data;
        const blobUrl = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', `${id}`);
        tempLink.setAttribute('target', '_blank');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobUrl);
        message.success('Invoice downloaded successfully');
      } else {
        throw new Error(`Failed to download file: ${response.status}`);
      }
    } catch (err) {
      console.error('Error downloading invoice:', err);
      message.error('Failed to download invoice');
    }
  };

  const handleDateChange = (value) => {
    try {
      const subscription = subscriptions.find((sub) => sub.id === value);
      if (!subscription) {
        throw new Error('Selected subscription not found');
      }
      setSelectedSubscription(subscription);
      setDailyData(subscription.daily_data || []);
    } catch (error) {
      console.error('Error changing subscription date:', error);
      message.error('Failed to update subscription data');
    }
  };

  // Pie Chart Data
  const pieData = {
    labels: ["Pages Processed", "Pages Left"],
    
    datasets: [
      {
        data: [
          selectedSubscription?.pages_processed,
          selectedSubscription?.total_pages_allowed -
            selectedSubscription?.pages_processed,
        ],
        backgroundColor: ["#47aaf6", "#e0e5e7"],
      },
    ],
  };

  // Bar Chart Data
  const barData = {
    labels: daily_data
      ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      ?.map((item) => {
        const date = new Date(item?.day);
        return date.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric' 
        });
      }),
    datasets: [
      {
        label: "Pages Processed",
        data: daily_data
          ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          ?.map((item) => item?.pages_processed),
        backgroundColor: "#03A9F4",
      },
    ],
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loadingSubscriptions) {
    return <LoadingView loadingText="Loading subscriptions" />;
  }

  if (!selectedSubscription) {
    return <LoadingView loadingText="Loading subscription data" />;
  }

  return (
    <div className="px-6 space-y-6 bg-gray-50 min-h-screen">
      <div className="w-full flex">
        {selectedSubscription?.plan_name !== SUBSCRIPTION_PLANS.FREE && (
          <div className="flex items-center space-x-4">
            <label className="text-lg font-medium text-backgrounds-primary300">
              Subscription:
            </label>
            <Select
              placeholder="Select Date Range"
              onChange={handleDateChange}
              defaultValue={selectedSubscription.id}
              className="w-fit"
            >
              {subscriptions.map((sub) => (
                <Option key={sub.id} value={sub.id}>
                  {formatDateRange(sub.start_date, sub.end_date)}
                </Option>
              ))}
            </Select>
          </div>
        )}
        <div className='ml-auto justify-end flex gap-2'>
          {/* <Button
            className='h-8 mr-2 px-2 text-backgrounds-primary300'
            hoverText='Top Up Pages'
            text='Top Up Pages'
            icon={faPlus}
            type='success'
            onClick={() => setShowTopUpModal(true)}
          /> */}
          <Button
            className='h-8 mr-2 px-2 text-backgrounds-primary300'
            hoverText='Manage Subscription'
            text='Manage Subscription'
            icon={faExchangeAlt}
            type='success'
            onClick={() => setManageSubscriptionModal(true)}
          />
        </div>
      </div>

      {/* TODO: Add Top Up Modal */}
      {/* <Modal
        open={showTopUpModal}
        onCancel={() => setShowTopUpModal(false)}
        footer={null}
        width={400}
        centered
      >
        <TopUpPages onClose={() => setShowTopUpModal(false)} />
      </Modal> */}

      {/* Visualization Section */}
      <div className="flex flex-col  space-y-6 ">
        {/* Pie Chart */}
        <div className="flex  w-full bg-white shadow-md rounded-lg p-4"> 
        <div className="w-1/5 bg-white ">
        

          <Pie data={pieData} options={chartOptions} />
          <h2 className="w-full flex items-center justify-center text-sm font-semibold text-gray-400 mt-2">
  {`Plan Usage: ${((selectedSubscription?.pages_processed / selectedSubscription?.total_pages_allowed) * 100).toFixed(2)}%`}
</h2>
        </div>
        <div className="w-2/5 ml-auto  my-auto  ">
        {/* <h2 className="text-xl font-semibold text-gray-800 mb-4">Plan Details</h2> */}
  <div className="space-y-4">
    <div className="flex justify-between items-center">
      <div className="w-full flex justify-center text-blue-600 text-xl  font-semibold">{selectedSubscription.plan_name}</div>
    </div>

    {selectedSubscription?.plan_name !== SUBSCRIPTION_PLANS.FREE && (
      <>
        <div className="flex justify-between items-center">
          <span className="text-gray-600 font-medium">Active Till</span>
          <span className="text-blue-600 font-medium mr-2 px-2">
            {formatDate(selectedSubscription?.end_date)}
          </span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-gray-600 font-medium">Download Invoice</span>
          <Button
            className={`h-8 mr-2 px-2  text-backgrounds-primary300 `}
            hoverText={'Download Invoice'}
            text={''}
            icon={faDownload}
            type={'success'}
            onClick={() => handleDownloadInvoice(invoiceData?.invoice_id)}
          />
        </div>
      </>
    )}

    {/* Queries */}
    <div className="flex justify-between items-center mt-1">
      <span className="text-gray-600 font-medium">Queries</span>
      <span className="text-blue-600 font-medium mr-2 px-2 ">{selectedSubscription?.queries_count}</span>
    </div>

    {/* Daily Average Usage */}
    <div className="flex justify-between items-center mt-4 py-2 pb-4 ">
      <span className="text-gray-600 font-medium">Daily Average Usage (pages/day)</span>
      <span className="text-blue-600 font-medium mr-2 px-2">
        {(() => {
          const startDate = new Date(selectedSubscription?.start_date);
          const today = new Date();
          // Calculate days including today (add 1 to include today)
          const daysDiff = Math.max(1, Math.floor((today - startDate) / (1000 * 60 * 60 * 24)) + 1);
          
          return Math.floor(selectedSubscription?.pages_processed / daysDiff);
        })()}
      </span>
    </div>
  </div>


          

        </div>
        </div>

        {/* Bar Chart */}
        <div className="w-full bg-white shadow-md rounded-lg p-4">
          <Bar data={barData} options={chartOptions} />
          <div className="mt-4 flex justify-center">
            <Pagination
              current={currentPage}
              total={daily_data?.length || 0}
              pageSize={itemsPerPage}
              onChange={handlePageChange}
              showSizeChanger={false}
              simple
            />
          </div>
        </div>
      </div>
      {loadingInvoice && <LoadingView loadingText="Loading invoice data" />}
    </div>
  );
};

export default SubscriptionDashboard;
