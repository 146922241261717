/**
 * Converts a camelCase, kebab-case, or snake_case string to sentence case.
 * @param {string} str - The input string to convert.
 * @returns {string} - The converted sentence case string.
 */
export const toSentenceCase = (str) => {
    if (!str) return str;

    // Replace kebab-case and snake_case with spaces
    let sentence = str.replace(/[-_]/g, ' ');

    // Replace camelCase with spaces
    sentence = sentence.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Convert the first character to uppercase and the rest to lowercase
    sentence =
        sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();

    return sentence;
};
