// Don't change the order of object as there is precedence order (Chapters > Tables > Chunks)
export const DataTypes = Object.freeze({
    CHAPTERS: 'chapters',
    TABLES: 'tables',
    CHUNKS: 'chunks',
});

// Safe words length to match with each line to find if it is a start line.
export const safeWordRangeData = Object.freeze({
    chapters: 10,
    tables: 3,
    chunks: 10,
});

// Acceptable char errors % while matching each line with start text to find if it is an actual start line.
export const acceptableErrorsPercentage = Object.freeze({
    chapters: 5,
    tables: 30,
    chunks: 5,
});
