import React from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';

import { UserDetailAndAction } from '../userDetailAndAction';
import { Brand } from '../brand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../reusableComponents';
import { faCaretLeft, faGear } from '@fortawesome/free-solid-svg-icons';
import { useGlobalCredits } from '../GlobalStateContext';

export const SettingsIndex = ({
    settingSections,
    activeSection,
    setActiveSection,
    scrollToSettings,
    closeSidebar,
}) => {
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const BREAKPOINT_WIDTH = 1024;
    return settingSections.map((item, index) => (
        <div className='w-full text-texts-secondary300' key={index}>
            <button
                className={`w-full flex items-center justify-between ${
                    activeSection === item.name
                        ? 'bg-backgrounds-primary100 shadow-inner'
                        : ''
                } rounded-xl my-2 px-3 py-1 border border-transparent hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-texts-secondary300`}
                onClick={(event) => {
                    setActiveSection(item.name);
                    scrollToSettings(item.ref);
                    if (transactionMode) {
                        setTransactionMode(!transactionMode);
                    }
                    event.stopPropagation();
                    if (window.innerWidth < BREAKPOINT_WIDTH) {
                        closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                    }
                }}
                title={item.hoverText}
            >
                <div className='flex items-center justify-center truncate max-w-prose'>
                    <FontAwesomeIcon
                        icon={item.icon}
                        // color={'lightblue'}
                        className='mr-2 drop-shadow-sm text-icons-primary'
                    />
                    <div className='truncate'>{item.name}</div>
                </div>
            </button>
        </div>
    ));
};

export const SettingsSideBar = ({
    settingSections,
    activeSection,
    setActiveSection,
    setIsLeftViewExpanded,
    scrollToSettings,
    isSidebarOpen,
    closeSidebar,
    setIsSidebarOpen,
}) => {
    const BREAKPOINT_WIDTH = 1024;
    return (
        <div
            className={`z-0 h-full w-full transition-all duration-700 ease-in-out bg-transparent p-1 m-0 rounded-xl`}
        >
            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header border-b-2 border-b-backgrounds-white'
                    style={{
                        minHeight: '8rem',
                        height: '8rem',
                        maxHeight: '8rem',
                    }}
                >
                    <div className='pane-content w-full h-full pb-2 px-1'>
                        <div className='w-full h-full flex flex-col justify-between items-center text-texts-secondary300'>
                            <Brand />

                            <div className='w-full flex items-center justify-between'>
                                <div className='w-full flex items-center truncate'>
                                    <FontAwesomeIcon
                                        icon={faGear}
                                        className='mr-2 drop-shadow-sm text-icons-primary'
                                    />
                                    <div className='font-semibold truncate'>
                                        Setting Sections
                                    </div>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        icon={faCaretLeft}
                                        className={'w-9 h-9'}
                                        onClick={() => {
                                            if (
                                                window.innerWidth >
                                                BREAKPOINT_WIDTH
                                            ) {
                                                // Adjust the breakpoint as per your design
                                                setIsLeftViewExpanded(false);
                                            } else {
                                                closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                            }
                                        }}
                                        hoverText={
                                            'Collapse and hide repositories view'
                                        }
                                        type={'warning'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className='pane-content p-1'>
                        <div className='h-full w-full overflow-auto'>
                            <SettingsIndex
                                settingSections={settingSections}
                                activeSection={activeSection}
                                setActiveSection={setActiveSection}
                                scrollToSettings={scrollToSettings}
                                closeSidebar={closeSidebar}
                            />
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement
                    className='footer border-t-2 border-t-backgrounds-white flex flex-col justify-end'
                    style={{
                        minHeight: '9.5rem',
                        height: '9.5rem',
                        maxHeight: '9.5rem',
                        bottom: 5,
                        position:
                            window.innerWidth > BREAKPOINT_WIDTH
                                ? 'static'
                                : 'fixed',
                        width:
                            window.innerWidth > BREAKPOINT_WIDTH
                                ? 'auto'
                                : '100%',
                    }}
                >
                    <div className='pane-content w-full h-full'>
                        <UserDetailAndAction />
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};
