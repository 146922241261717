import { ChartTypes, DataTypes } from '../constants';
import { toSentenceCase } from './convertTextToSentenceCase';
import { getIncreasinglyRandomInt } from './getIncreasinglyRandomInt';
import { generateRandomWord } from './getRandomWord';

// Function to generate random chart data based on selected fields
export const generateRandomChartData = (selectedFieldsData, chartData) => {
    const randomGraphData = [];
    const { chart, useRowFormat } = chartData;

    if (chart === ChartTypes.TABLE && useRowFormat) {
        randomGraphData.push(['', '']);
        selectedFieldsData.forEach((data) => {
            const rowData = [];
            const modifiedFieldName = toSentenceCase(data.field);
            rowData.push(modifiedFieldName);
            // Setting values
            const getNextRandomInt = getIncreasinglyRandomInt(1, 100);
            if (data.type === DataTypes.STRING) {
                rowData.push(generateRandomWord(10));
            } else if (data.type === DataTypes.NUMBER) {
                rowData.push(getNextRandomInt());
            }

            if (rowData.length) {
                randomGraphData.push(rowData);
            }
        });
    } else {
        // Parameter
        const fieldData = [];
        selectedFieldsData.forEach((data) => {
            const modifiedFieldName = toSentenceCase(data.field);
            fieldData.push(modifiedFieldName);
        });
        if (fieldData.length) {
            randomGraphData.push(fieldData);
        }

        // Values
        const getNextRandomInt = getIncreasinglyRandomInt(1, 100);
        for (let i = 0; i < 4; i++) {
            const values = [];
            selectedFieldsData.forEach((data) => {
                if (data.type === DataTypes.STRING) {
                    values.push(generateRandomWord(6));
                } else if (data.type === DataTypes.NUMBER) {
                    values.push(getNextRandomInt());
                }
            });
            if (values.length) {
                randomGraphData.push(values);
            }
        }
    }

    return randomGraphData;
};
