import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, forwardRef } from 'react';
import { companyNumber, companyEmail } from '../../Constants';
import { getUrl } from '../../components/pdfDisplay/utils';
import { contactEmail } from './Constants';

const Footer = forwardRef((props, ref) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    function isValidEmail(email) {
        // Regular expression for basic email validation
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }
    const handleSubmitEmail = async () => {
        try {
            if (!email || !isValidEmail(email)) {
                alert('Invalid Email');
                setLoading(false);
                return;
            }
            const formData = {
                email: email,
            };

            setLoading(true);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/submit-mail`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // Authorization: encodedToken,
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (response.ok) {
                const result = await response.json();
                console.log('API response:', result);
                setLoading(false);
                // alert('Query Send ');
            } else {
                console.error(
                    'API error:',
                    response.status,
                    response.statusText
                );
                setLoading(false);
                alert('Failed To Send ');
            }
        } catch (error) {
            setLoading(false);
            alert('Failed To Send ');
            console.error('API error:', error.message);
        }
    };
    return (
        <footer
            ref={ref}
            className='pt-8 pb-2 px-4 md:px-[10%] relative bg-backgrounds-primary100  mt-8'
        >
            <div className='container mx-auto flex flex-col md:flex-row md:justify-between'>
                <div className='flex-shrink-0 mb-8 md:mb-0 md:pr-4 md:w-1/2'>
                    {/*<div className='flex items-center mb-8 ml-5'>
                        <img
                            src={process.env.PUBLIC_URL + '/mtree-logo.png'}
                            alt='MTree Software Pvt. Ltd.'
                            className='h-40 mr-4'
                        />
    </div>*/}
                    <div className='text-texts-secondary font-bold mb-4 md:mb-10'>
                        MTree Software Pvt. Ltd.
                    </div>
                    <div className='mb-7'>
                        <p className='text-texts-secondary font-bold mb-2'>
                            Send us your query at:
                        </p>

                        {/* Phone section */}
                        {companyNumber ? (
                            <div className='flex items-center pb-2'>
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    className='w-4 h-4 text-icons-tertiary200 mr-3'
                                />
                                <a
                                    href={`tel:${companyNumber}`}
                                    className='text-texts-secondary300 text-md space-y-2'
                                >
                                    {companyNumber}
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}

                        {/* Email section */}
                        <div className='flex items-center mb-4'>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className='w-4 h-4 text-icons-tertiary200 mr-3'
                            />
                            <a
                                href={`mailto:${contactEmail}`}
                                className='text-texts-secondary300 text-md space-y-2'
                            >
                                {contactEmail}
                            </a>
                        </div>
                    </div>
                </div>

                <div className='flex-end pl-4'>
                    <div className='flex justify-between'>
                        <div className='mr-8 mb-4'>
                            <div className='text-texts-secondary font-bold mb-4'>
                                Company
                            </div>
                            <ul className='space-y-2 text-texts-secondary'>
                                <li>
                                    <a href='/'>Home</a>
                                </li>
                                <li>
                                    <a href='https://www.mtree.co.in/about-us.html'>
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.mtree.co.in/services.html'>
                                        Services
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.mtree.co.in/case-studies.html'>
                                        Case Studies
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.mtree.co.in/career.html'>
                                        Career
                                    </a>
                                </li>
                                <li>
                                    <a href='/contactUs'>Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className='mr-8 ml-4'>
                            <div className='text-texts-secondary font-bold mb-4'>
                                Terms & Policies
                            </div>
                            <ul className='space-y-2 text-texts-secondary'>
                                <li>
                                    <a href='/terms-and-conditions'>
                                        Terms and Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href='/privacy-policy'>Privacy Policy</a>
                                </li>
                                <li>
                                    <a href='/brand-guidelines'>
                                        Brand guidelines
                                    </a>
                                </li>
                                <li>
                                    <a href='/refund-and-cancellations'>
                                        Refund and Cancellation Policies
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' bottom-0 left-[10%] right-[10%] py-2 text-center border-t border-gray-400 size-full pt-2'>
                <div className='flex justify-between'>
                    <p className='text-texts-secondary inline-block'>
                        © {new Date().getFullYear()} MTree Software Pvt. Ltd.
                        All Rights Reserved.
                    </p>
                    <p className='text-texts-secondary inline-block'>
                        Terms and Conditions Applied
                    </p>
                </div>
            </div>
        </footer>
    );
});

export default Footer;
