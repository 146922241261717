import {
    faFileCircleExclamation,
    faFolder,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PLATFORM } from '../../enums';

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const front = text.substring(0, Math.ceil(maxLength / 2));
    const end = text.substring(text.length - Math.floor(maxLength / 2));
    return `${front}...${end}`;
};

export const FolderListViewer = ({ selectedFolder, platform }) => {
    const MAX_TEXT_LENGTH = 60;
    return (
        <div className='border rounded-xl bg-backgrounds-white'>
            <div className='font-semibold border-b py-2 px-3 rounded-t-xl'>
                <FontAwesomeIcon
                    icon={faFolder}
                    className='mr-2 text-texts-secondary300'
                />
                Selected Folder
            </div>
            <ul className='text-texts-secondary300 p-3 max-h-28 overflow-auto'>
                {selectedFolder ? (
                    // selectedFolder.map((file, index) => (
                    //     <li key={index}>
                    //         {index + 1}.{' '}
                    //         {truncateText(file.name, MAX_TEXT_LENGTH)}
                    //     </li>
                    <li>
                        {platform === PLATFORM.ONE_DRIVE
                            ? truncateText(
                                  selectedFolder.value[0]?.name,
                                  MAX_TEXT_LENGTH
                              )
                            : truncateText(
                                  selectedFolder.name,
                                  MAX_TEXT_LENGTH
                              )}
                    </li>
                ) : (
                    <div className='flex justify-center items-center text-texts-secondary300'>
                        <FontAwesomeIcon
                            icon={faFileCircleExclamation}
                            className='mr-2 text-icons-secondary300'
                        />
                        No folder selected
                    </div>
                )}
            </ul>
        </div>
    );
};
