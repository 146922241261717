import React, { useEffect, useRef, useState } from 'react';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import { ReportViewer } from './reportViewer';
import { getFileNameWithoutExt } from '../utils';
import { pageHeight } from '../constants';
import { ReportHeader } from './reportHeader';
import { Input } from '../../reusableComponents';

const adjustmentValue = 1;
const maxReportTitleLength = 30;

const SingleReportViewer = ({
    index,
    reportTitle,
    updateReportTitle,
    reportHeaderName,
    AppData,
    CompanyData,
    templateName,
    layoutName,
    layout,
    enableEditing,
    setReportHeaderName,
}) => {
    const parentDivRef = useRef(null);
    const childDivRef = useRef(null);
    const [childDivHeight, setChildDivHeight] = useState(0);
    const [lines, setLines] = useState([]);

    // Function to add lines dynamically based on div height
    const updateLines = (divHeight) => {
        const currentHeight = divHeight;
        const lineHeight = pageHeight; // Height of each line

        if (currentHeight) {
            const linesToAdd = Math.ceil(currentHeight / lineHeight);

            let newLines = [];
            for (let i = 0; i < linesToAdd; i++) {
                const height = (i + 1) * lineHeight - adjustmentValue;
                newLines.push(
                    <div
                        key={i}
                        className='line'
                        style={{
                            top: `${height}px`,
                            height: `${adjustmentValue}px`,
                        }}
                    >
                        <div className='w-full flex justify-between text-xs text-texts-secondary300 absolute top-[-22px]'>
                            <span className='cursor-help' title='Page End'>
                                P.E
                            </span>
                            <span className='cursor-help' title='Page End'>
                                P.E
                            </span>
                        </div>
                    </div>
                );
            }

            setLines(newLines);
        }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const newHeight = entries[0].contentRect.height;
            setChildDivHeight(newHeight);
        });

        const targetDiv = childDivRef ? childDivRef.current : null;
        if (targetDiv) {
            resizeObserver.observe(targetDiv);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [childDivRef]);

    useEffect(() => {
        if (parentDivRef && parentDivRef.current) {
            if (childDivHeight <= pageHeight) {
                parentDivRef.current.style.height = `${pageHeight}px`;
            } else {
                const numberOfPages = Math.ceil(childDivHeight / pageHeight);
                const newHeight = numberOfPages * pageHeight;
                parentDivRef.current.style.height = `${newHeight}px`;
            }

            updateLines(childDivHeight);
        }

        return () => {};
    }, [childDivHeight]);

    return (
        <div
            key={`parentDiv${index}`}
            className={`w-full overflow-hidden`}
            ref={parentDivRef}
        >
            <div
                key={`childDiv${index}`}
                className={`w-full h-fit line-div overflow-hidden`}
                ref={childDivRef}
            >
                {/* Combined report header */}
                {index === 0 ? (
                    <ReportHeader
                        reportHeaderName={reportHeaderName}
                        AppData={AppData}
                        CompanyData={CompanyData}
                        templateName={templateName}
                        layoutName={layoutName}
                        enableEditing={enableEditing}
                        setReportHeaderName={setReportHeaderName}
                    />
                ) : (
                    <></>
                )}

                <div className='w-full pb-2 px-12 pt-2'>
                    <div
                        className={`w-full flex justify-center align-center bg-backgrounds-primary100 shadow-inner font-semibold text-slate-600 ${
                            enableEditing ? 'py-0' : 'pb-2'
                        }`}
                    >
                        {enableEditing ? (
                            <span className='w-fit py-1'>
                                <Input
                                    value={reportTitle}
                                    type={'text'}
                                    className={
                                        'w-[300px] h-6 text-texts-secondary300'
                                    }
                                    onChange={(e) => {
                                        updateReportTitle(
                                            e.target.value,
                                            index
                                        );
                                    }}
                                    maxLength={maxReportTitleLength}
                                    hoverText={'Edit report title'}
                                />
                            </span>
                        ) : (
                            <span>{reportTitle}</span>
                        )}
                    </div>
                </div>
                <ReportViewer layout={layout} enableEditing={enableEditing} />

                {/* Visible line markers to control report overflow from pages */}
                {lines}
            </div>
        </div>
    );
};

export const CombinedReportsViewer = ({
    layouts,
    enableEditing,
    fileList,
    templateName,
    AppData,
    CompanyData,
    layoutName,
    reportHeaderName,
    setReportHeaderName,
    reportTitleData,
}) => {
    const [reportTitles, setReportTitles] = useState([]);

    useEffect(() => {
        const newReportTitles = [];
        layouts.forEach((layout, index) => {
            const reportTitle = reportTitleData[index]
                ? reportTitleData[index]
                : fileList.length && fileList[index] && fileList[index].name
                ? `${getFileNameWithoutExt(fileList[index].name)} Report`
                : `Report ${index + 1}`;
            newReportTitles.push(reportTitle);
        });

        setReportTitles(newReportTitles);
        return () => {};
    }, [fileList, layouts]);

    const updateReportTitle = (newReportTitle, index) => {
        const updatedReportTitles = [...reportTitles];
        updatedReportTitles[index] = newReportTitle;
        setReportTitles(updatedReportTitles);
    };

    return layouts.map((layout, index) => {
        return (
            <SingleReportViewer
                index={index}
                reportTitle={reportTitles[index]}
                updateReportTitle={updateReportTitle}
                layout={layout}
                reportHeaderName={reportHeaderName}
                AppData={AppData}
                CompanyData={CompanyData}
                templateName={templateName}
                layoutName={layoutName}
                setReportHeaderName={setReportHeaderName}
                enableEditing={enableEditing}
            />
        );
    });
};
