//settingsContent.jsx
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faComments,
    faFileExport,
    faGears,
    faListDots,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faTable,
    faDownload,
    faFolderPlus,
    faTrashAlt,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Button, ConfirmModal, Modal } from '../reusableComponents';
import AddTemplate from '../addTemplate';
import { SettingSections } from './settingsView';
import { PROMPT } from '../../enums';
import { getUrl } from '../pdfDisplay/utils';
import { AccountSettingsComponent } from './accountSettings';

export const SettingsContent = ({
    accountDetails,
    setAccountDetails,
    modelName,
    handleDropdownChange,
    refine,
    onRefineChange,
    // temperature,
    // handleTemperature,
    // maxToken,
    // handleMaxTokenChange,
    addnewpromptmode,
    setaddnewpromptmode,
    promptlist,
    prompt,
    defaultPrompt,
    promptname,
    setPrompt,
    setpromptname,
    setSelectedPrompt,
    selectedPrompt,
    handlePromptChange,
    promptToUpdate,
    setPromptToUpdate,
    modelsDropdownView,
    templateList,
    activeTemplate,
    setActiveTemplate,
    fetchTemplateList,
    accountSettingsRef,
    generalSettingsRef,
    chatsSettingsRef,
    extractionSettingsRef,
    setTemplateList,
    handleDeletePrompt,
    queryMethod,
    setQueryMethod,
    queryMethods,
    isAdmin,
    setNewPromptText,
    activeSection,
    setActiveSection,
    rootRef,
    defaultPromptValue,
}) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [addNewTemplate, setAddNewTemplate] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('current'));
    const [isDeleting, setIsDeleting] = useState(false); //for template deletion
    const [isConfirmTemplateModalOpen, setIsConfirmTemplateModalOpen] =
        useState(false);
    const [templateToDelete, setTemplateToDelete] = useState(null);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1024px)'); // Define your breakpoint here
        const handleResize = (e) => {
            setIsSmallScreen(e.matches);
        };

        // Initial check
        setIsSmallScreen(mediaQuery.matches);

        // Listen for changes in screen size
        mediaQuery.addEventListener('change', handleResize);

        // Cleanup
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };
    }, []);

    const handlePromptRadioChange = (event) => {
        const selectedpid = event.target.value;
        // console.log('SELECTED PID:', selectedpid);
        // console.log('DEFAULT PROMPT: ', defaultPrompt.prompt);
        if (selectedpid === PROMPT.DEFAULT) {
            setSelectedPrompt(defaultPrompt);
            setPromptToUpdate(defaultPrompt.prompt);
        } else {
            const selectedPromptObj = promptlist.find(
                (prompt) => prompt.pid === parseInt(selectedpid)
            );

            if (
                selectedPrompt &&
                selectedPrompt.pid === parseInt(selectedpid)
            ) {
                // If the same prompt is clicked again, unselect it
                setPromptToUpdate(defaultPrompt.prompt);
                setSelectedPrompt(defaultPrompt);
            } else {
                setPromptToUpdate(selectedPromptObj.prompt);
                setSelectedPrompt(selectedPromptObj);
            }
        }
    };

    const handleTemplateDownload = async (templateName) => {
        try {
            setIsDownloading(true);
            const user = JSON.parse(localStorage.getItem('current'));
            const url = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/download-template?templateName=${templateName}`
            );
            const authToken = encodeURIComponent(user.token); // Replace with your actual authentication token

            const headers = {
                Authorization: authToken,
            };

            const response = await fetch(url, {
                method: 'GET',
                headers: headers,
            });

            // if error is send in response
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error);
            }

            const blob = await response.blob();

            const urlObj = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlObj;
            a.download = templateName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (err) {
            console.log(err);
            const errorMessage =
                err.message || 'Error occured while downloading template';
            alert(errorMessage);
        } finally {
            setIsDownloading(false);
        }
    };

    const handleTemplateDelete = async (templateName) => {
        setIsDeleting(true);
        try {
            const user = JSON.parse(localStorage.getItem('current'));
            const authToken = encodeURIComponent(user.token);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/deletetemplate`
                ),
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authToken,
                    },
                    body: JSON.stringify({
                        filename: templateName,
                        userId: user.email,
                    }),
                }
            );
            const result = await response.json();
            if (response.ok) {
                // Update the templateList state to remove the deleted template
                setTemplateList((prevTemplates) => {
                    const updatedTemplates = prevTemplates.filter(
                        (template) => template.filename !== templateName
                    );
                    // Check if the deleted template is the active template
                    if (
                        activeTemplate &&
                        activeTemplate.filename === templateName
                    ) {
                        // Find the first template starting with "default"
                        const defaultTemplate = updatedTemplates.find(
                            (template) =>
                                template.filename.startsWith('default')
                        );
                        // Set the first default template as the active template if it exists
                        if (defaultTemplate) {
                            setActiveTemplate(defaultTemplate);
                            // Optionally, save this template as well if needed
                            // This could be an API call or any other logic to save the active template
                        } else {
                            // If no default template exists, set active template to null
                            setActiveTemplate(null);
                        }
                    }
                    return updatedTemplates;
                });
            } else {
                console.error('Error deleting template:', result.error);
            }
        } catch (error) {
            console.error('Error deleting template:', error);
        } finally {
            setIsDeleting(false);
            setIsConfirmTemplateModalOpen(false);
        }
    };

    const displayprompt = () => {
        return (
            <>
                {/* Default Prompt always rendered first */}
                <div
                    className='mr-3'
                    onClick={() => {
                        setaddnewpromptmode(false);
                        setSelectedPrompt(defaultPrompt);
                    }}
                >
                    <div
                        className={`flex flex-wrap justify-center items-center py-2 my-1 mx-0.5 px-3 rounded-xl border cursor-pointer hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 ${
                            selectedPrompt &&
                            selectedPrompt.pid === PROMPT.DEFAULT
                                ? 'shadow-inner bg-backgrounds-primary100 border-backgrounds-primary200'
                                : 'bg-backgrounds-slate'
                        } `}
                    >
                        <input
                            type='radio'
                            name='promptRadio'
                            value='default'
                            checked={
                                selectedPrompt &&
                                selectedPrompt.pid === PROMPT.DEFAULT
                            }
                            onChange={handlePromptRadioChange}
                            className='mr-2 cursor-pointer'
                        />
                        <label className='cursor-pointer'>
                            {defaultPrompt.prname}
                        </label>
                    </div>
                </div>

                {/* Render prompts from the list */}
                {promptlist &&
                    promptlist.length > 0 &&
                    promptlist.map((prompt, index) => (
                        <div
                            key={index}
                            className='mr-3'
                            onClick={() => {
                                if (
                                    selectedPrompt &&
                                    selectedPrompt.pid === prompt.pid
                                ) {
                                    // If the same prompt is clicked again, unselect it
                                    setaddnewpromptmode(false);
                                    setSelectedPrompt(defaultPrompt);
                                } else {
                                    setaddnewpromptmode(false);
                                    setSelectedPrompt(prompt);
                                }
                            }}
                        >
                            <div
                                className={`flex flex-wrap justify-center items-center py-2 my-1 mx-0.5 px-3 rounded-xl border cursor-pointer hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 ${
                                    selectedPrompt &&
                                    selectedPrompt.pid === prompt.pid
                                        ? 'shadow-inner bg-backgrounds-primary100 border-backgrounds-primary200'
                                        : 'bg-backgrounds-slate'
                                } `}
                            >
                                <input
                                    type='radio'
                                    name='promptRadio'
                                    value={prompt.pid}
                                    checked={
                                        selectedPrompt &&
                                        selectedPrompt.pid === prompt.pid
                                    }
                                    onChange={handlePromptRadioChange}
                                    className='mr-2 cursor-pointer'
                                />
                                <label className='cursor-pointer'>
                                    {prompt.prname}
                                </label>
                            </div>
                        </div>
                    ))}
            </>
        );
    };

    const handleTemplateRadioChange = (event) => {
        const selectedTemplate = event.target.value;
        const selectedTemplateObj = templateList.find(
            (template) => template.filename === selectedTemplate
        );
        setActiveTemplate(selectedTemplateObj);
    };

    const truncateTemplateName = (name, maxLength = 25) => {
        const dotIndex = name.lastIndexOf('.');
        if (dotIndex === -1) {
            return name.length > maxLength
                ? `${name.substring(0, maxLength)}...`
                : name;
        }
        const extension = name.substring(dotIndex);
        const nameWithoutExtension = name.substring(0, dotIndex);
        const totalLength = maxLength - extension.length - 3; // 3 for '...'

        return nameWithoutExtension.length > totalLength
            ? `${nameWithoutExtension.substring(0, totalLength)}...${extension}`
            : name;
    };

    const displayTemplate = templateList.map((template, index) => {
        const truncatedName = truncateTemplateName(template.filename);
        return (
            <div
                key={index}
                className={`flex items-center justify-between p-3 border rounded-xl hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 ${
                    activeTemplate &&
                    activeTemplate.filename === template.filename
                        ? 'shadow-inner bg-backgrounds-primary100 border-backgrounds-primary200'
                        : 'bg-backgrounds-slate'
                }`}
                style={{ flex: '1 0 30%' }}
                onClick={() => setActiveTemplate(template)}
            >
                <input
                    type='radio'
                    name='templateRadio'
                    value={template.filename}
                    checked={
                        activeTemplate &&
                        activeTemplate.filename === template.filename
                    }
                    onChange={handleTemplateRadioChange}
                    className='mr-2 cursor-pointer'
                />
                <label
                    className='cursor-pointer truncate flex-grow mx-2 truncate'
                    title={template.filename}
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: 'calc(100% - 80px)',
                    }}
                >
                    {truncatedName}
                </label>
                <div className='flex items-center space-x-2'>
                    <Button
                        className='p-2 transition-transform transform'
                        hoverText='Download Template'
                        icon={faDownload}
                        disabled={isDownloading}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTemplateDownload(template.filename);
                        }}
                        type='success'
                    />
                    <Button
                        className='p-2 transition-transform transform'
                        hoverText={
                            template.filename.startsWith('default')
                                ? 'Cannot delete default templates.'
                                : 'Delete Template'
                        }
                        icon={faTrash}
                        disabled={
                            isDeleting ||
                            template.filename.startsWith('default')
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            setTemplateToDelete(template);
                            setIsConfirmTemplateModalOpen(true);
                        }}
                        type='danger'
                    />
                </div>
            </div>
        );
    });

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Check which section is in view and update active section
                        if (entry.target === accountSettingsRef.current) {
                            setActiveSection(SettingSections.ACCOUNT);
                        } else if (
                            entry.target === generalSettingsRef.current
                        ) {
                            setActiveSection(SettingSections.GENERAL);
                        } else if (entry.target === chatsSettingsRef.current) {
                            setActiveSection(SettingSections.CHATS);
                        } else if (
                            entry.target === extractionSettingsRef.current
                        ) {
                            setActiveSection(SettingSections.EXTRACTION);
                        }
                    }
                });
            },
            {
                root: rootRef.current, // Set root to the specific element

                threshold: 1.0,
            }
        );

        // Observe each section
        if (accountSettingsRef.current) {
            observer.observe(accountSettingsRef.current);
        }
        if (generalSettingsRef.current) {
            observer.observe(generalSettingsRef.current);
        }
        if (chatsSettingsRef.current) {
            observer.observe(chatsSettingsRef.current);
        }
        if (extractionSettingsRef.current) {
            observer.observe(extractionSettingsRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            if (accountSettingsRef.current) {
                observer.unobserve(accountSettingsRef.current);
            }
            if (generalSettingsRef.current) {
                observer.unobserve(generalSettingsRef.current);
            }
            if (chatsSettingsRef.current) {
                observer.unobserve(chatsSettingsRef.current);
            }
            if (extractionSettingsRef.current) {
                observer.unobserve(extractionSettingsRef.current);
            }
        };
    }, [
        rootRef,
        accountSettingsRef,
        generalSettingsRef,
        chatsSettingsRef,
        extractionSettingsRef,
        setActiveSection,
    ]);

    return (
        <div className='flex flex-col w-full justify-center text-texts-secondary'>
            <div ref={accountSettingsRef}>
                <AccountSettingsComponent
                    user={user}
                    accountDetails={accountDetails}
                    setAccountDetails={setAccountDetails}
                    SettingSections={SettingSections}
                    isSmallScreen={isSmallScreen}
                />
            </div>

            <div className='mb-6'>
                <div
                    ref={generalSettingsRef}
                    className='flex items-center font-semibold mb-6 py-3 px-4 text-texts-secondary300 uppercase bg-backgrounds-primary100 truncate'
                >
                    <FontAwesomeIcon icon={faGears} className='mr-2' />
                    <div className='font-semibold text-texts-secondary300 uppercase truncate'>
                        {SettingSections.GENERAL}
                    </div>
                </div>

                <div className='px-6 relative'>
                    <div className='flex items-center mb-6'>
                        <div className='w-1/4 font-semibold text-texts-secondary300'>
                            <label htmlFor='modelName'>Select Model Name</label>
                        </div>

                        <div className='w-full md:w-1/4'>
                            <div className='relative'>
                                <select
                                    id='modelName'
                                    className='block w-auto md:w-30 p-2 mt-1 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                    value={modelName}
                                    onChange={handleDropdownChange}
                                >
                                    <option disabled={true} value={null}>
                                        Select a Model
                                    </option>
                                    {modelsDropdownView}
                                </select>
                            </div>
                        </div>
                    </div>

                    {isAdmin && (
                        <div className='flex items-center mb-6'>
                            <div className='w-1/4 font-semibold text-texts-secondary300'>
                                <label htmlFor='queryMethod'>
                                    Querying Method
                                </label>
                            </div>
                            <div className='w-full md:w-1/4'>
                                <div className='relative'>
                                    <select
                                        id='queryMethod'
                                        className='block w-auto md:w-30 p-2 mt-1 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                        value={queryMethod}
                                        onChange={(e) =>
                                            setQueryMethod(e.target.value)
                                        } // Update queryMethod state on change
                                    >
                                        <option disabled={true} value={null}>
                                            Select a Querying Method
                                        </option>
                                        {queryMethods.map((method) => (
                                            <option key={method} value={method}>
                                                {method}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* <Toggle
                        toggleText={'Refine chain'}
                        className={'mb-6'}
                        checked={refine}
                        onChange={onRefineChange}
                    /> */}

                    {/* <div className='flex items-center mb-6'>
                        <div className='w-1/4'>
                            <label htmlFor='temperature'>Temperature</label>
                        </div>

                        <div className='flex'>
                            <Slider
                                className='w-64 mr-5'
                                min={0}
                                max={1}
                                step={0.01}
                                onChange={handleTemperature}
                                value={
                                    typeof temperature === 'number'
                                        ? temperature
                                        : 0
                                }
                            />

                            <input
                                type='number'
                                steps={0.01}
                                value={
                                    typeof temperature === 'number'
                                        ? temperature
                                        : 0
                                }
                                onChange={handleMaxTokenChange}
                                className='p-2 w-20 border bg-backgrounds-slate rounded-xl hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                            />
                        </div>
                    </div> */}

                    {/* <div className='flex items-center'>
                        <div className='w-1/4'>
                            <label htmlFor='maxToken'>Max Tokens</label>
                        </div>

                        <div className='flex'>
                            <Slider
                                className='w-64 mr-5'
                                min={0}
                                max={2000}
                                step={1}
                                onChange={handleMaxTokenChange}
                                value={
                                    typeof maxToken === 'number' ? maxToken : 0
                                }
                            />

                            <input
                                type='number'
                                steps={1}
                                value={maxToken ? maxToken : 0}
                                onChange={handleMaxTokenChange}
                                className='p-2 w-20 border bg-backgrounds-slate rounded-xl hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                            />
                        </div>
                    </div> */}
                </div>
            </div>

            <div className='mb-6'>
                <div
                    ref={chatsSettingsRef}
                    className='flex items-center font-semibold my-6 py-3 px-4 text-texts-secondary300 uppercase bg-backgrounds-primary100 truncate'
                >
                    <FontAwesomeIcon icon={faComments} className='mr-2' />
                    <div className='font-semibold text-texts-secondary300 uppercase truncate'>
                        {SettingSections.CHATS}
                    </div>
                </div>

                <div className='px-6'>
                    <div className='flex items-center justify-start font-semibold mb-6 text-texts-secondary300'>
                        Choose a prompt | Create a new prompt | Edit existing
                        prompt
                    </div>

                    <div className='border rounded-xl'>
                        <div className='w-full flex justify-between items-center border-b p-3 bg-backgrounds-slate rounded-t-xl'>
                            <div className='mx-1 flex items-center'>
                                <FontAwesomeIcon
                                    icon={faQuoteLeft}
                                    className='text-backgrounds-primary500 mr-2'
                                />
                                <FontAwesomeIcon
                                    icon={faQuoteRight}
                                    className='text-backgrounds-primary500'
                                />
                                <div className='text-texts-secondary300 ml-2 font-semibold'>
                                    Prompts
                                </div>
                            </div>

                            <div className='w-3/4 flex items-center justify-end'>
                                <Button
                                    text={'New'}
                                    className={'mr-1'}
                                    hoverText={'Add new prompt'}
                                    icon={faPlus}
                                    disabled={addnewpromptmode}
                                    onClick={() => setaddnewpromptmode(true)}
                                    type={'success'}
                                />
                            </div>
                        </div>

                        <div className='flex flex-wrap p-3'>
                            {displayprompt()}
                        </div>

                        <div className='p-3 rounded-b-xl bg-backgrounds-slate border-t'>
                            {addnewpromptmode ? (
                                <>
                                    <input
                                        className='w-full border p-3 rounded-xl mb-3 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                        type='text'
                                        placeholder='New prompt name'
                                        value={promptname}
                                        onChange={(e) =>
                                            setpromptname(e.target.value)
                                        }
                                    />
                                    <textarea
                                        className='w-full rounded-xl h-52 p-4 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                        placeholder='Enter your custom prompt here...'
                                        rows={8}
                                        cols={20}
                                        value={prompt}
                                        onChange={(e) => handlePromptChange(e)}
                                    ></textarea>
                                </>
                            ) : (
                                <>
                                    <div className='w-full p-1 rounded-xl mb-2 text-texts-secondary300'>
                                        <div className='font-semibold'>
                                            {selectedPrompt &&
                                                selectedPrompt.prname}
                                        </div>
                                    </div>
                                    {!addnewpromptmode && selectedPrompt && (
                                        <textarea
                                            className={`w-full rounded-xl h-52 p-4 hover:outline-none focus:outline-backgrounds-primary200 border ${
                                                selectedPrompt.pid ===
                                                PROMPT.DEFAULT
                                                    ? 'bg-gray-200 cursor-not-allowed'
                                                    : 'hover:border-backgrounds-primary300'
                                            }`}
                                            rows={8}
                                            cols={20}
                                            value={
                                                selectedPrompt.prompt
                                                    ? promptToUpdate
                                                    : defaultPromptValue
                                            }
                                            onChange={(e) => {
                                                setPromptToUpdate(
                                                    e.target.value
                                                );
                                                setNewPromptText(
                                                    e.target.value
                                                );
                                            }}
                                            readOnly={
                                                selectedPrompt.pid ===
                                                PROMPT.DEFAULT
                                            } // Makes textarea non-editable
                                            title={
                                                selectedPrompt.pid ===
                                                PROMPT.DEFAULT
                                                    ? 'Default prompt cannot be edited'
                                                    : ''
                                            }
                                        ></textarea>
                                    )}
                                    {!addnewpromptmode && selectedPrompt && (
                                        <Button
                                            text={'Delete'}
                                            className={'mr-1'}
                                            hoverText={
                                                selectedPrompt.pid ===
                                                PROMPT.DEFAULT
                                                    ? 'Default prompt cannot be deleted'
                                                    : 'Delete this prompt'
                                            }
                                            icon={faTrashAlt}
                                            type={'danger'}
                                            onClick={() => {
                                                if (
                                                    selectedPrompt.pid !==
                                                    PROMPT.DEFAULT
                                                ) {
                                                    setIsModalOpen(true);
                                                }
                                            }}
                                            disabled={
                                                selectedPrompt.pid ===
                                                PROMPT.DEFAULT
                                            } // Disables the button if default prompt
                                        />
                                    )}
                                </>
                            )}

                            {addnewpromptmode && (
                                <div className='flex justify-end items-center pt=2 pb-1'>
                                    <Button
                                        text={'Cancel'}
                                        className={'mr-1'}
                                        hoverText={'Cancel new prompt creation'}
                                        icon={faCircleXmark}
                                        onClick={() => {
                                            setaddnewpromptmode(false);
                                            setPrompt('');
                                            setpromptname('');
                                        }}
                                        type={'danger'}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <ConfirmModal
                    modalText='Are you sure you want to delete this prompt?'
                    onConfirm={() => {
                        handleDeletePrompt(selectedPrompt.pid);
                        setIsModalOpen(false);
                    }}
                    onCancel={() => {
                        setIsModalOpen(false);
                    }}
                />
            )}

            <div className='mb-6'>
                <div
                    ref={extractionSettingsRef}
                    className='flex items-center font-semibold my-6 py-3 px-4 text-texts-secondary300 uppercase bg-backgrounds-primary100 truncate'
                >
                    <FontAwesomeIcon icon={faFileExport} className='mr-2' />
                    <div className='font-semibold text-texts-secondary300 uppercase truncate'>
                        {SettingSections.EXTRACTION}
                    </div>
                </div>

                <div className='w-full h-full mt-3 px-6'>
                    <div className=' flex items-center justify-start font-semibold mb-6 text-texts-secondary300'>
                        Choose a Template | Upload new template for Information
                        Extraction
                    </div>

                    <div className='border rounded-xl mb-8'>
                        <div className='w-full flex justify-between items-center border-b p-3 bg-backgrounds-slate rounded-t-xl'>
                            <div className='mx-1 flex items-center'>
                                <FontAwesomeIcon
                                    icon={faTable}
                                    className='text-backgrounds-primary400 mr-2'
                                />
                                <div className='text-texts-secondary300 font-semibold'>
                                    Choose Template
                                </div>
                            </div>
                            <div className='w-3/4 flex items-center justify-end'>
                                <Button
                                    text={'Add Template'}
                                    className={'mr-1'}
                                    hoverText={'Add new Template'}
                                    icon={faPlus}
                                    disabled={addNewTemplate}
                                    onClick={() => setAddNewTemplate('true')}
                                    type={'success'}
                                />
                            </div>
                        </div>
                        <div className='flex flex-wrap max-w-full  p-3'>
                            {displayTemplate && displayTemplate.length ? (
                                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                                    {displayTemplate}
                                </div>
                            ) : (
                                <div className='w-full text-center font-semibold text-texts-secondary300 py-3'>
                                    <FontAwesomeIcon
                                        icon={faListDots}
                                        className='mr-2'
                                    />
                                    No Template Data
                                </div>
                            )}
                        </div>
                    </div>

                    {isConfirmTemplateModalOpen && (
                        <ConfirmModal
                            modalText={`Are you sure you want to delete the template "${templateToDelete?.filename}"?`}
                            onConfirm={() =>
                                handleTemplateDelete(templateToDelete.filename)
                            }
                            onCancel={() => {
                                setTemplateToDelete(null);
                                setIsConfirmTemplateModalOpen(false);
                            }}
                        />
                    )}

                    <div className='w-full h-full'>
                        {addNewTemplate && (
                            <Modal
                                title={'Add New Template'}
                                titleIcon={faFolderPlus}
                                iconColor={'lightblue'}
                                onClose={() => setAddNewTemplate(false)}
                            >
                                <AddTemplate
                                    afterUpload={fetchTemplateList}
                                    templateList={templateList}
                                    setActiveTemplate={setActiveTemplate}
                                    setTemplateList={setTemplateList}
                                    setAddNewTemplate={setAddNewTemplate}
                                />
                            </Modal>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
