import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { NotificationsView } from './notificationsView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../reusableComponents';

export const NotificationBell = ({
    viewNotifications,
    setViewNotifications,
    newNotification,
    setNewNotification,
    messages,
    socket,
}) => {
    const [showNotifications, setShowNotifications] = useState(false);

    useEffect(() => {
        if (viewNotifications && newNotification) {
            setShowNotifications(true);

            setNewNotification(false);
            setViewNotifications(false);
        }
    }, [viewNotifications, newNotification]);

    return (
        <div className='relative'>
            {newNotification && !showNotifications ? (
                <div className='absolute -top-1.5 right-2 animate-pulse z-20 scale-95'>
                    <FontAwesomeIcon icon={faCircle} color='orange' />
                </div>
            ) : (
                <></>
            )}

            <Popover
                content={
                    <NotificationsView
                        socket={socket}
                        notifications={messages}
                        showNotifications={showNotifications}
                    />
                }
                title={
                    <div className='text-texts-secondary300 font-semibold text-base px-2 py-1'>
                        <FontAwesomeIcon
                            icon={faBell}
                            //color='#4ade80'
                            className='mr-2 text-icons-primary'
                        />
                        Notifications
                    </div>
                }
                trigger='click'
                open={showNotifications}
                onOpenChange={(showNotifications) => {
                    setShowNotifications(showNotifications);
                    setNewNotification(null);
                }}
                placement='bottomLeft'
                fresh={true}
            >
                <Button
                    className={`w-9 h-9 mx-3`}
                    onClick={() => {
                        setNewNotification(null);
                    }}
                    hoverText={
                        newNotification
                            ? 'New notifications available'
                            : 'Notifications'
                    }
                    icon={faBell}
                    type={'success'}
                />
            </Popover>
        </div>
    );
};
