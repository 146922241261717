import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PdfDebugViewer } from './pdfDebugViewer';
import { PdfActions } from '../pdfActions';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { DebugLegendsSection } from './debugLegendsSection';
import { DataTypes } from '../constant';
import { getFileFormat, getFileIcon, getFileIconColor } from '../../../utils';
import { Button } from '../../reusableComponents';
import {
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';
import { getUrl } from '../utils';

const scaleSteps = 0.1;
const minScale = 0.5;
const maxScale = 3;

const getLengthData = (debugData) => {
    const lengthData = {};
    Object.keys(DataTypes).forEach((key) => {
        lengthData[DataTypes[key]] = debugData[DataTypes[key]]
            ? debugData[DataTypes[key]].length
            : 0;
    });
    return lengthData;
};

const fetchDebugData = async (repoId, fileNameData, pageNumber) => {
    try {
        // Getting file extension
        const fileExtension = getFileFormat(fileNameData);

        // Getting file name without extension
        const fileName = fileNameData.replace(`.${fileExtension}`, '');

        const response = await fetch(
            getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/debug/${repoId}/${fileName}/${pageNumber}`)
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const debugData = await response.json();
        return debugData;
    } catch (error) {
        console.error('Error fetching debugData:', error.message);
        return null;
    }
};

export const PdfDebugContainer = ({
    repoId,
    email,
    folderName,
    fileName,
    mode,
    isAdmin,
}) => {
    const [debugData, setDebugData] = useState(null);
    const [lengthData, setLengthData] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [activePageNumber, setActivePageNumber] = useState(1);
    const [totalPageNumbers, setTotalPageNumbers] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [scale, setScale] = useState(window.innerWidth < 1024 ? 0.6 : 1);

    useEffect(() => {
        const fileExtension = getFileFormat(fileName);
        const pdfFileName = fileName.replace(fileExtension, 'pdf');
        const path = `${email}/${folderName}/${pdfFileName}`;
        const encodedPath = encodeURIComponent(path);
        const pdfFileUrl = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_pdf/?file_path=${encodedPath}&mode=${mode}`);

        setPdfUrl(pdfFileUrl);
    }, [email, folderName, fileName]);

    useEffect(() => {
        if (isAdmin) {
            (async () => {
                setIsLoading(true);
                const data = await fetchDebugData(
                    repoId,
                    fileName,
                    activePageNumber
                );
                if (data) {
                    setDebugData(data);
                    setLengthData(getLengthData(data));
                }
                setIsLoading(false);
            })();
        }

        return () => {};
    }, [activePageNumber, fileName, repoId]);

    return (
        <ReflexContainer
            orientation='horizontal'
            style={{ minWidth: '20rem', width: '50vw', height: '79vh' }}
        >
            <ReflexElement
                className='header border-b'
                style={{
                    minHeight: '3.5rem',
                    height: '3.5rem',
                    maxHeight: '3.5rem',
                }}
            >
                <div className='pane-content w-full h-full p-2'>
                    <div className='flex h-full items-center my-0 mx-2'>
                        <div className='w-1/2 my-1 text-texts-secondary300 truncate'>
                            {fileName ? (
                                <div className='flex items-center'>
                                    <FontAwesomeIcon
                                        icon={getFileIcon(fileName)}
                                        color={getFileIconColor(fileName)}
                                        className='mr-2 drop-shadow'
                                    />
                                    <div className='overflow-ellipsis overflow-hidden font-semibold'>
                                        {fileName}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className='w-1/2'>
                            <PdfActions
                                pageNumber={activePageNumber}
                                totalPageNumbers={totalPageNumbers}
                                setPageNumber={setActivePageNumber}
                            />
                        </div>
                    </div>
                </div>
            </ReflexElement>

            <ReflexElement flex={1}>
                <div className='pane-content'>
                    <PdfDebugViewer
                        fileName={fileName}
                        pdfFile={pdfUrl}
                        highlightTexts={isAdmin ? debugData : null}
                        pageNumber={activePageNumber}
                        setPageNumber={setActivePageNumber}
                        setTotalPageNumbers={setTotalPageNumbers}
                        isLoading={isLoading}
                        scale={scale}
                    />
                </div>
            </ReflexElement>

            <ReflexElement
                className='footer border-t'
                style={{
                    minHeight: '4rem',
                    height: '4rem',
                    maxHeight: '4rem',
                }}
            >
                <div className='pane-content w-full h-full'>
                    <div
                        className={`flex ${
                            isAdmin ? 'justify-between' : 'justify-end'
                        } items-center w-full h-full px-4`}
                    >
                        {isAdmin && (
                            <div className='w-fit h-full'>
                                <DebugLegendsSection
                                    lengthData={lengthData}
                                    isAdmin={isAdmin}
                                />
                            </div>
                        )}
                        {/* Scale actions */}
                        <div className='w-fit flex items-center rounded-full ml-2 border'>
                            <Button
                                icon={faMagnifyingGlassMinus}
                                type={'warning'}
                                onClick={() => {
                                    const newScale = parseFloat(
                                        parseFloat(scale) - scaleSteps
                                    ).toFixed(1);
                                    setScale(newScale);
                                }}
                                hoverText={
                                    'Decrease the viewing scale of document'
                                }
                                disabled={scale <= minScale}
                            />
                            <div className='w-6 flex justify-center text-sm mx-1 text-texts-secondary300 font-semibold text-nowrap font-semibold'>
                                {scale}x
                            </div>
                            <Button
                                icon={faMagnifyingGlassPlus}
                                type={'warning'}
                                onClick={() => {
                                    const newScale = parseFloat(
                                        parseFloat(scale) + scaleSteps
                                    ).toFixed(1);
                                    setScale(newScale);
                                }}
                                hoverText={
                                    'Increase the viewing scale of document'
                                }
                                disabled={scale >= maxScale}
                            />
                        </div>
                    </div>
                </div>
            </ReflexElement>
        </ReflexContainer>
    );
};
