import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Fileupload from './Fileupload';
import Flagpage from './Flagpage';
import Evaluate from './evaluate';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { PdfContainer } from './pdfDisplay';
import { ChatContainer } from './chatSection';
import { ConfirmModal } from './reusableComponents';
import { NoInteractionView } from './reusableComponents';
import { UserAccessOptions } from '../constants/userAccessOptions';
import { useGlobalCredits } from './GlobalStateContext';
import { RepoType } from '../constants/repoType';
import { ChatFormatType } from '../constants/chatFormatType';
import { ErrorType } from '../constants/errorType';
import { getUrl } from './pdfDisplay/utils';
import { ChartTypes } from './reportLayouts/constants';
import { generateUniqueId, isValidJsonString } from '../utils';
const MainArea = (props) => {
    const { repo_to_chat, fine_tuned_mode, fileToChat, isAdmin } = props;
    const { credits, setCredits } = useGlobalCredits();
    const [query, setQuery] = useState('');
    const [output, setOutput] = useState('');
    const [docs, setdocs] = useState([]);
    const [response, setresponse] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const [currentqna, setcurrentqna] = useState([]);

    const [currentqnc, setcurrentqnc] = useState([]);

    const [batchmode, setbatchmode] = useState(false);
    const [batchwitheval, set_batchwitheval] = useState(true);
    const [previos_ques_at, set_previous_ques_at] = useState(0);

    const [flag_mode, set_flag_mode] = useState(false);
    const [flag_index, set_flag_index] = useState(-1);

    const [finetunelist, set_finetunelist] = useState([]);
    const user = JSON.parse(localStorage.getItem('current'));
    const [finetunedata, setfinetunedata] = useState(null);
    const [s_repo, sets_repo] = useState({});

    const [questionsData, setQuestionsData] = useState(new Map());
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [fineTuningVerify, setFineTuningVerify] = useState(false);
    const [filesData, setFilesData] = useState(null);
    const [abortController, setAbortController] = useState(null);

    const authToken = encodeURIComponent(user.token); // Replace with your actual authentication token

    const BREAKPOINT_WIDTH = 1024;

    useEffect(() => {
        const headers = {
            Authorization: authToken,
            'Content-Type': 'application/json',
        };

        // Make GET request to fetch user credits
        axios
            .get(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getCredits`
                ),
                {
                    headers: headers,
                }
            )
            .then((response) => {
                setCredits(response.data.credits);
            })
            .catch((error) => {
                console.error('Error fetching user credits:', error);
            });
    }, [isGenerating]);

    const handleFetchData = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getfinetune`
                ),
                {
                    params: {
                        id: user.email,
                    },
                }
            );

            set_finetunelist(response.data);
            const dataArray = response.data;
            const searchKey = 'foldername';
            const searchValue = repo_to_chat.foldername;
            const foundObject = dataArray.find(
                (obj) => obj[searchKey] === searchValue
            );
            const tvalue = foundObject ? foundObject : -1;
            setfinetunedata(tvalue);
            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
        }
    };

    const getRepoFilesData = async (repoId) => {
        try {
            setIsLoading(true);
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_alldocs/${repoId}`
            );

            const encodedToken = encodeURIComponent(user.token);
            const response = await fetch(dynamicUrl, {
                method: 'GET', // or 'POST' or other HTTP method
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            const data = await response.json();
            setFilesData(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const handleData = async (repoid, chatonly) => {
            await getRepoFilesData(repoid);
            // await handleFetchData();
            await handleGetQnAHistory(
                user.email,
                repo_to_chat.name,
                repo_to_chat.repoid,
                chatonly
            );
        };

        questionsData.clear();
        setQuestionsData(questionsData);
        setcurrentqna([]);
        setcurrentqnc([]);
        setActiveQuestion(null);

        let chatonly = false;
        if (repo_to_chat.shared_type === UserAccessOptions.CHAT) {
            chatonly = true;
        }

        handleData(repo_to_chat.repoid, chatonly);
        sets_repo(repo_to_chat);
        setQuery('');
    }, [fine_tuned_mode, repo_to_chat, fileToChat]);

    const addNewFlag = (key, issue, comment) => {
        const data = questionsData.get(key);
        if (data) {
            data.issue = issue;
            data.comment = comment;
            data.flagged = true;
            questionsData.set(key, data);
        }
        setQuestionsData(questionsData);
    };

    const handleFineTune = async () => {
        if (query === '') {
            alert('empty query');
            return;
        }

        setIsLoading(true);
        const response = await fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/fine_tune_chat`
            ),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `model_id=${finetunedata.modelid}&query=${query}&email=${user.email}&foldername=${repo_to_chat.foldername}`,
            }
        );

        const data = await response.json();

        if (data.output) {
            setOutput(data.output);

            const parsedData = JSON.parse(data.output);

            const temp_qna = {
                question: query,
                answer: parsedData.content,
                qid: parsedData.qid,
            };
            currentqna.unshift(temp_qna);
            setQuery('');

            setIsLoading(false);
        } else if (data.error) {
            alert(data.error);
            setIsLoading(false);
        }
    };

    const handleGetQnAHistory = (email, folderName, repoid, chatonly) => {
        setIsLoading(true);
        fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_data_qna/?email=${email}&foldername=${folderName}&repoid=${repoid}&chatonly=${chatonly}`
            )
        )
            .then((response) => response.json())
            .then((historyData) => {
                historyData
                    .sort(function (a, b) {
                        return new Date(a.timestamp) - new Date(b.timestamp);
                    })
                    .forEach((data, index) => {
                        const isMultiple =
                            data.response.includes('|END OF ANSWER|');

                        const questionData = {
                            qid: data.chat_id,
                            question: data.query,
                            answer: isMultiple
                                ? data.response.split('|END OF ANSWER|')
                                : data.response,
                            timestamp: new Date(
                                data.timestamp
                            ).toLocaleString(),
                            chunksData: [],
                            flagged: !!data.issue,
                            issue: data.issue,
                            comment: data.comment,
                            fileToChat: data.file_to_chat,
                            sqlQuery: data.sql_query,
                            queries: data.queries,
                        };
                        questionsData.set(data.chat_id, questionData);
                        if (index === historyData.length - 1) {
                            const qna = {
                                question: data.query,
                                answer: isMultiple
                                    ? data.response.split('|END OF ANSWER|')
                                    : data.response,
                                qid: data.chat_id,
                            };
                            setcurrentqna([qna]);
                            const qnc = {
                                question: data.query,
                                docs: [],
                                qid: data.chat_id,
                            };
                            setcurrentqnc([qnc]);
                            set_previous_ques_at(0);
                        }
                    });

                setQuestionsData(questionsData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    };

    const findActualFileName = (pdfName) => {
        const foundData = filesData.find((data) => {
            const index = data.name.lastIndexOf('.');
            const pdfIndex = pdfName.lastIndexOf('.');
            if (index > -1 && pdfIndex > -1) {
                if (
                    data.name.substring(0, index) ===
                    pdfName.substring(0, pdfIndex)
                )
                    return true;
            } else {
                if (data.name.substring(0, index) === pdfName) return true;
            }
            return false;
        });

        return foundData ? foundData.name : pdfName;
    };

    const handlesqlltequestions = async (regenerate, query2) => {
        try {
            await getRepoFilesData(repo_to_chat.repoid);

            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/runqna_sqllite`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        email: repo_to_chat.email,
                        foldername: repo_to_chat.name,
                        query: query2,
                        regenerate: regenerate,
                        repoid: repo_to_chat.repoid,
                        user: user.email,
                    }).toString(),
                }
            );

            const data = await response.json();
            if (data.output) {
                setOutput(data.output);

                const parsedData = JSON.parse(data.output);
                const docs2 = [];
                const responseString = parsedData.response;
                const qid = parsedData.qid;

                const questionData = {
                    qid: qid,
                    question: query2,
                    answer: responseString,
                    timestamp: new Date().toLocaleString(),
                    chunksData: [],
                    sqlQuery: parsedData ? parsedData.sql_query : '',
                    html: true,
                };

                docs2.forEach((pageContent, index) => {
                    const chunkData = {
                        email: repo_to_chat.email,
                        folderName: repo_to_chat.name,
                        fileName: parsedData.metadata[index].pdf_name,
                        actualFileName: findActualFileName(
                            parsedData.metadata[index].pdf_name
                        ),
                        pageNumber: parsedData.metadata[index].page_number,
                        pageContent: pageContent.page_content,
                    };
                    questionData.chunksData.push(chunkData);
                });

                questionsData.set(qid, questionData);
                setQuestionsData(questionsData);

                setActiveQuestion(questionData);

                setdocs(docs2);
                setresponse(responseString);

                const qna = {
                    question: query2,
                    answer: responseString,
                    qid: qid,
                };
                setcurrentqna([qna]);
                const qnc = { question: query2, docs: docs2, qid: qid };
                setcurrentqnc([qnc]);
                set_previous_ques_at(0);
                setQuery('');
            } else if (data.error || data.detail) {
                setIsGenerating(false);
                alert(`Error: ${data.error || data.detail}`);
            }
        } catch (error) {
            // console.error('Error:', error);
            alert(
                `Server cannot be reached. Please try again later.${error.message}`
            );
        } finally {
            setIsGenerating(false);
        }
    };

    const handlepostgressquestions = async (regenerate, query2) => {
        try {
            await getRepoFilesData(repo_to_chat.repoid);

            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/runqna_postgress`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        email: repo_to_chat.email,
                        foldername: repo_to_chat.name,
                        query: query2,
                        regenerate: regenerate,
                        repoid: repo_to_chat.repoid,
                        user: user.email,
                    }).toString(),
                }
            );

            const data = await response.json();
            if (data.output) {
                setOutput(data.output);

                const parsedData = JSON.parse(data.output);
                const docs2 = [];
                const responseString = parsedData.response;
                const qid = parsedData.qid;

                const questionData = {
                    qid: qid,
                    question: query2,
                    answer: responseString,
                    timestamp: new Date().toLocaleString(),
                    chunksData: [],
                    sqlQuery: parsedData ? parsedData.sql_query : '',
                    html: true,
                };

                docs2.forEach((pageContent, index) => {
                    const chunkData = {
                        email: repo_to_chat.email,
                        folderName: repo_to_chat.name,
                        fileName: parsedData.metadata[index].pdf_name,
                        actualFileName: findActualFileName(
                            parsedData.metadata[index].pdf_name
                        ),
                        pageNumber: parsedData.metadata[index].page_number,
                        pageContent: pageContent.page_content,
                    };
                    questionData.chunksData.push(chunkData);
                });

                questionsData.set(qid, questionData);
                setQuestionsData(questionsData);

                setActiveQuestion(questionData);

                setdocs(docs2);
                setresponse(responseString);

                const qna = {
                    question: query2,
                    answer: responseString,
                    qid: qid,
                };
                setcurrentqna([qna]);
                const qnc = { question: query2, docs: docs2, qid: qid };
                setcurrentqnc([qnc]);
                set_previous_ques_at(0);
                setQuery('');
            } else if (data.error || data.detail) {
                setIsGenerating(false);
                alert(`Error: ${data.error || data.detail}`);
            }
        } catch (error) {
            // console.error('Error:', error);

            alert(
                `Server cannot be reached. Please try again later.${error.message}`
            );
        } finally {
            setIsGenerating(false);
        }
    };

    const handleSubmit = async (regenerate) => {
        if (query === '' && regenerate === false) {
            alert('Question cannot be empty');
            return;
        }

        let query2 = regenerate ? currentqna[0].question : query;

        //e.preventDefault();
        setIsGenerating(true);

        if (repo_to_chat.repotype === 'sqllite') {
            handlesqlltequestions(regenerate, query2);
            return;
        }

        if (repo_to_chat.repotype === 'postgress') {
            handlepostgressquestions(regenerate, query2);
            return;
        }

        // Create a new AbortController
        const controller = new AbortController();
        setAbortController(controller); // Save it in state

        try {
            await getRepoFilesData(repo_to_chat.repoid);

            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/runqna9`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        email: repo_to_chat.email,
                        foldername: repo_to_chat.name,
                        query: query2,
                        regenerate: regenerate,
                        repoid: repo_to_chat.repoid,
                        user: user.email,
                        file_to_chat: fileToChat,
                    }).toString(),
                    signal: controller.signal, // Pass the signal
                }
            );

            const data = await response.json();
            if (data.output) {
                setOutput(data.output);

                const parsedData = JSON.parse(data.output);
                const docs2 = JSON.parse(parsedData.docs);
                const responseString = parsedData.response;
                const qid = parsedData.qid;
                const questionData = {
                    qid: qid,
                    question: query2,
                    answer: responseString,
                    timestamp: new Date().toLocaleString(),
                    fileToChat: parsedData.file_to_chat,
                    chunksData: [],
                    queries: [],
                };

                // Fetch queries from the second API (`/get_queries`)
                try {
                    const queriesResponse = await fetch(
                        getUrl(
                            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_queries?chat_id=${qid}`
                        )
                    );

                    const queriesData = await queriesResponse.json();
                    if (queriesData) {
                        questionData.queries = JSON.parse(queriesData); // Add queries to `questionData`
                    }
                } catch (queriesError) {
                    console.log('Error occurred:', queriesError);
                    console.log('Stack Trace:', queriesError.stack);
                    console.log(
                        'No multiple queries for this chat.',
                        queriesError
                    );
                }

                docs2.forEach((pageContent, index) => {
                    const chunkData = {
                        email: repo_to_chat.email,
                        folderName: parsedData.metadata[index].foldername,
                        fileName: parsedData.metadata[index].pdf_name,
                        actualFileName: findActualFileName(
                            parsedData.metadata[index].pdf_name
                        ),
                        pageNumber: parsedData.metadata[index].page_number,
                        pageContent: pageContent.page_content,
                    };
                    questionData.chunksData.push(chunkData);
                });

                questionsData.set(qid, questionData);
                setQuestionsData(questionsData);
                setActiveQuestion(questionData);

                setdocs(docs2);
                setresponse(responseString);

                const qna = {
                    question: query2,
                    answer: responseString,
                    qid: qid,
                };
                setcurrentqna([qna]);

                const qnc = { question: query2, docs: docs2, qid: qid };
                setcurrentqnc([qnc]);

                set_previous_ques_at(0);
                setQuery('');
            } else if (data.error || data.detail) {
                setIsGenerating(false);
                alert(`Error: ${data.error || data.detail}`);
            }
        } catch (error) {
            console.log('Error occurred:', error);
            console.log('Stack Trace:', error);
            if (error.name === ErrorType.ABORT_ERROR) {
                console.log(
                    'Request changed due to repository change while processing'
                );
            }
        } finally {
            setIsGenerating(false);
        }
    };

    // Effect to handle repository change
    useEffect(() => {
        if (abortController) {
            // Abort the previous request
            abortController.abort();
        }
        // Clear the controller after aborting
        setAbortController(null);
    }, [repo_to_chat.repoid]); // Run this effect whenever repo changes

    const handleSubmitMultiple = async (regenerate) => {
        if (query === '' && regenerate === false) {
            alert("Question can't be empty");
            return;
        }

        let query2 = regenerate ? currentqna[0].question : query;

        setIsGenerating(true);

        // Create a new AbortController
        const controller = new AbortController();
        setAbortController(controller); // Save it in state

        try {
            await getRepoFilesData(repo_to_chat.repoid);

            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/runqna_multiple`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        email: repo_to_chat.email,
                        foldername: repo_to_chat.name,
                        query: query2,
                        regenerate: regenerate,
                        repoid: repo_to_chat.repoid,
                        user: user.email,
                        file_to_chat: fileToChat,
                    }).toString(),
                    signal: controller.signal, // Pass the signal to fetch
                }
            );

            const data = await response.json();
            if (data.output) {
                setOutput(data.output);

                const parsedData = JSON.parse(data.output);
                const docs2 = JSON.parse(parsedData.docs);
                const responseString = parsedData.response;
                const qid = parsedData.qid;

                const questionData = {
                    qid: qid,
                    question: query2,
                    answer: responseString,
                    timestamp: new Date().toLocaleString(),
                    chunksData: [],
                    type: ChatFormatType.MULTIPLE,
                };

                docs2.forEach((pageContent, index) => {
                    const chunkData = {
                        email: repo_to_chat.email,
                        folderName: repo_to_chat.name,
                        fileName: parsedData.metadata[index].pdf_name,
                        actualFileName: findActualFileName(
                            parsedData.metadata[index].pdf_name
                        ),
                        pageNumber: parsedData.metadata[index].page_number,
                        pageContent: pageContent.page_content,
                    };
                    questionData.chunksData.push(chunkData);
                });

                questionsData.set(qid, questionData);
                setQuestionsData(questionsData);
                setActiveQuestion(questionData);

                setdocs(docs2);
                setresponse(responseString);

                const qna = {
                    question: query2,
                    answer: responseString,
                    qid: qid,
                    type: ChatFormatType.MULTIPLE,
                };
                setcurrentqna([qna]);

                const qnc = { question: query2, docs: docs2, qid: qid };
                setcurrentqnc([qnc]);

                set_previous_ques_at(0);
                setQuery('');
            } else if (data.error || data.detail) {
                setIsGenerating(false);
                alert(`Error: ${data.error || data.detail}`);
            }
        } catch (error) {
            if (error.name === ErrorType.ABORT_ERROR) {
                console.log('Request was aborted due to repository change');
            } else {
                alert(
                    `Server cannot be reached. Please try again later. ${error.message}`
                );
            }
        } finally {
            setIsGenerating(false);
        }
    };

    const handleRegenerate = async (e) => {
        e.preventDefault();
        setIsGenerating(true);
        await getRepoFilesData(repo_to_chat.repoid);
        const response = await fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/regenerate`
            ),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: repo_to_chat.email,
                    foldername: repo_to_chat.foldername,
                    query: currentqna[previos_ques_at].question,
                }).toString(),
            }
        );

        const data = await response.json();
        if (data.output) {
            setOutput(data.output);

            const parsedData = JSON.parse(data.output);
            const docs2 = JSON.parse(parsedData.docs);
            const response2 = parsedData.response;
            const qid = parsedData.qid;

            const questionData = {
                qid: qid,
                question: currentqna[previos_ques_at].question,
                answer: response2,
                timestamp: new Date().toLocaleString(),
                chunksData: [],
                fileToChat: parsedData.file_to_chat,
            };

            docs2.forEach((pageContent, index) => {
                const chunkData = {
                    email: repo_to_chat.email,
                    folderName: repo_to_chat.foldername,
                    fileName: parsedData.metadata[index].pdf_name,
                    actualFileName: findActualFileName(
                        parsedData.metadata[index].pdf_name
                    ),
                    pageNumber: parsedData.metadata[index].page_number,
                    pageContent: pageContent.page_content,
                };
                questionData.chunksData.push(chunkData);
            });

            questionsData.set(qid, questionData);
            setQuestionsData(questionsData);

            setActiveQuestion(questionData);

            setdocs(docs2);
            setresponse(response2);

            const temp_qna = {
                question: currentqna[previos_ques_at].question,
                answer: response2,
                qid: qid,
            };
            currentqna.unshift(temp_qna);
            const temp_qnc = {
                question: currentqna[previos_ques_at].question,
                docs: docs2,
                qid: qid,
            };
            currentqnc.unshift(temp_qnc);
            set_previous_ques_at(0);
            setQuery('');

            setIsGenerating(false);
        } else if (data.error || data.detail) {
            setIsGenerating(false);
            alert(`Error: ${data.error || data.detail}`);
        }
    };

    function removeOuterParts(str) {
        const start = str.indexOf('<');
        const end = str.lastIndexOf('>');

        if (start === -1 || end === -1 || start >= end) {
            return str;
        }

        return str.substring(start + 1, end);
    }

    const handleCustomFormat = async (e) => {
        e.preventDefault();

        if (query === '') {
            alert('empty query');
            return;
        }
        setIsGenerating(true);

        const temp_query = `the previous question was {${currentqna[previos_ques_at]?.question}} and the answer was {${currentqna[previos_ques_at]?.answer}} can you please apply {${query}} on the answer`;
        const ques = `${currentqna[previos_ques_at]?.question} and ${query}`;
        const chunk = `${currentqna[previos_ques_at]?.answer}`;

        const response = await fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/formatans`
            ),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: repo_to_chat.email,
                    foldername: repo_to_chat.name,
                    query: temp_query,
                    ques: ques,
                    chunk: chunk,
                    file_to_chat: fileToChat,
                }).toString(),
            }
        );

        const dateNow = new Date().toLocaleString();

        const data = await response.json();
        if (data.output) {
            setOutput(data.output);

            const parsedData = JSON.parse(data.output);

            const response2 = parsedData.response;
            const qid = parsedData.qid;
            const docs2 = parsedData.docs ? JSON.parse(parsedData.docs) : [];

            setresponse(response2);

            const previousQid = currentqna[previos_ques_at]?.qid;
            const chunksData = questionsData.get(previousQid).chunksData;

            const questionData = {
                qid: qid,
                question: currentqna[previos_ques_at].question,
                answer: response2,
                timestamp: dateNow,
                chunksData: chunksData,
                fileToChat: parsedData.file_to_chat,
                type: ChatFormatType.CUSTOM,
            };

            docs2.forEach((pageContent, index) => {
                const chunkData = {
                    email: repo_to_chat.email,
                    folderName: repo_to_chat.name,
                    fileName: parsedData.metadata[index].pdf_name,
                    actualFileName: findActualFileName(
                        parsedData.metadata[index].pdf_name
                    ),
                    pageNumber: parsedData.metadata[index].page_number,
                    pageContent: pageContent.page_content,
                };
                questionData.chunksData.push(chunkData);
            });

            questionsData.set(qid, questionData);
            setQuestionsData(questionsData);

            setActiveQuestion(questionData);

            setQuery('');

            setIsGenerating(false);
        } else if (data.error || data.detail) {
            setIsGenerating(false);
            alert(`Error: ${data.error || data.detail}`);
        }
    };

    const handleChartFormat = async (chatData, isDatabase, chartType) => {
        try {
            let answer = chatData.answer;
            if (isValidJsonString(answer)) {
                answer = JSON.parse(answer);
            } else {
                answer = chatData.answer;
            }

            if (
                answer.type === ChatFormatType.TABLE &&
                (isDatabase === RepoType.SQLLITE ||
                    isDatabase === RepoType.POSTGRESS)
            ) {
                const payload = answer.payload;
                const columnData = payload.columns;
                const data = payload.data;

                const dateNow = new Date().toLocaleString();

                const chartOptions = {
                    title: `${columnData[0]} vs ${columnData[1]} chart`,
                    hAxis: {
                        title: columnData[0],
                    },
                    vAxis: {
                        title: columnData[1],
                    },
                };

                const defaultChart = chartType
                    ? chartType
                    : ChartTypes.BAR_CHART;

                const chartData = {
                    columns: columnData,
                    data: data,
                    chartType: defaultChart,
                    options: chartOptions,
                };

                const qid = generateUniqueId();
                const questionData = {
                    qid: qid,
                    question: currentqna[previos_ques_at].question,
                    answer: chartData,
                    timestamp: dateNow,
                    chunksData: [],
                    type: ChatFormatType.CHART,
                    fileToChat: null,
                };

                questionsData.set(qid, questionData);
                setQuestionsData(questionsData);
                setActiveQuestion(questionData);
            } else {
                setIsGenerating(true);

                const charts = [
                    'PieChart',
                    'BarChart',
                    'ColumnChart',
                    'LineChart',
                    'AreaChart',
                ];

                const temp_query = `the previous question was {${currentqna[previos_ques_at]?.question}} and the answer was {${currentqna[previos_ques_at]?.answer}} can you please convert the given answer into tabular format and return tabular data in following json object and array format mentioned below. Also these are some charts ${charts} you need to select the best chart name according to your answer as I am using React Google Chart to represent data. The tabular data can have N number of columns and M number of rows.
                {
                    columns:[column1, column2, column3 ... columnN],
                    data:[
                            [data1ForRow1Column1, data2ForRow1Column2, data3ForRow1Column3 ... data4ForRow1ColumnN],
                            [data5ForRow2Column1, data6ForRow2Column2, data7ForRow2Column3 ... data8ForRow1ColumnN],
                            .
                            .
                            .
                            [data5ForRowMColumn1, data6ForRowMColumn2, data7ForRowMColumn3 ... data8ForRowMColumnN],
                        ],
                    chartType:"Most suitable chart name from provided above only that can represent the data generated by you"
                    options:{
                        title: "Most suitable title as per the answer or question",
                        hAxis: {
                            title: "Most suitable label on chart X axis based on answer",
                        },
                        vAxis: {
                            title: "Most suitable label on chart Y axis based on answer",
                        },
                    }
                }

                In case of no data for any column, place '---' string in case of a string data and 0 in case of numerical data. If any column has same data type and if a string data is there in any column, make sure that column is the first column. Try to convert rest of the column into numerical format if possible.


                In case of no chart can be represented for the data then return following json object format mentioned below
                {data:"No chart possible for this data representation."}


                Please do not add any additional data or key except for the above mention format or else there will be huge penalty.`;

                const ques = `${currentqna[previos_ques_at]?.question}`;
                const chunk = `${currentqna[previos_ques_at]?.answer}`;

                const response = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/formatans`
                    ),
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: new URLSearchParams({
                            email: repo_to_chat.email,
                            foldername: repo_to_chat.name,
                            query: temp_query,
                            ques: ques,
                            chunk: chunk,
                            fileToChat: fileToChat,
                        }).toString(),
                    }
                );

                const dateNow = new Date().toLocaleString();

                const data = await response.json();
                if (data.output) {
                    setOutput(data.output);
                    const parsedData = JSON.parse(data.output);
                    const response2 = JSON.parse(parsedData.response);
                    const qid = parsedData.qid;
                    const docs2 = parsedData.docs
                        ? JSON.parse(parsedData.docs)
                        : [];

                    setresponse(response2);

                    const previousQid = currentqna[previos_ques_at]?.qid;
                    const chunksData =
                        questionsData.get(previousQid).chunksData;
                    const questionData = {
                        qid: qid,
                        question: currentqna[previos_ques_at].question,
                        answer: response2,
                        timestamp: dateNow,
                        chunksData: chunksData,
                        type: ChatFormatType.CHART,
                        fileToChat: parsedData.fileToChat,
                    };
                    docs2.forEach((pageContent, index) => {
                        const chunkData = {
                            email: repo_to_chat.email,
                            folderName: repo_to_chat.name,
                            fileName: parsedData.metadata[index].pdf_name,
                            actualFileName: findActualFileName(
                                parsedData.metadata[index].pdf_name
                            ),
                            pageNumber: parsedData.metadata[index].page_number,
                            pageContent: pageContent.page_content,
                        };
                        questionData.chunksData.push(chunkData);
                    });

                    questionsData.set(qid, questionData);
                    setQuestionsData(questionsData);

                    setActiveQuestion(questionData);
                    setQuery('');
                } else if (data.error || data.detail) {
                    throw new Error(data.error || data.detail);
                }
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setTimeout(() => {
                setIsGenerating(false);
            }, 100);
        }
    };

    const handleTableFormat = async (e) => {
        if (e) {
            e.preventDefault();
        }

        setIsGenerating(true);

        const temp_query = `the previous question was {${currentqna[previos_ques_at]?.question}} and the answer was {${currentqna[previos_ques_at]?.answer}} can you please convert the given answer into tabular format and return tabular data in following json object and array format mentioned below. The tabular data can have N number of columns and any number of data for each column.
        {
            tableHead:[column1,column2,column3 ... columnN],
            tableData:[
                {
                    column1:data1ForColumn1,
                    column2:data2ForColumn2,
                    column3:data3ForColumn3
                    .
                    .
                    .
                    columnN:dataNForColumnN
                },
                {
                    column1:data4ForColumn1,
                    column2:data5ForColumn2,
                    column3:data6ForColumn3
                    .
                    .
                    .
                    columnN:dataMForColumnN
                }
            ]
        }
        In case of no data for any column, place '---' string for it. Please do not add any additional data or key except for the above mention format or else there will be huge penalty.`;
        const ques = `${currentqna[previos_ques_at]?.question} `;
        const chunk = `${currentqna[previos_ques_at]?.answer}`;

        const response = await fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/formatans`
            ),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: repo_to_chat.email,
                    foldername: repo_to_chat.name,
                    query: temp_query,
                    ques: ques,
                    chunk: chunk,
                    file_to_chat: fileToChat,
                }).toString(),
            }
        );

        const dateNow = new Date().toLocaleString();

        const data = await response.json();
        if (data.output) {
            setOutput(data.output);
            const parsedData = JSON.parse(data.output);
            const response2 = JSON.parse(parsedData.response);
            const qid = parsedData.qid;
            const docs2 = parsedData.docs ? JSON.parse(parsedData.docs) : [];

            setresponse(response2);

            const previousQid = currentqna[previos_ques_at]?.qid;
            const chunksData = questionsData.get(previousQid).chunksData;
            const questionData = {
                qid: qid,
                question: currentqna[previos_ques_at].question,
                answer: response2,
                timestamp: dateNow,
                chunksData: chunksData,
                type: ChatFormatType.TABLE,
                fileToChat: parsedData.file_to_chat,
            };
            docs2.forEach((pageContent, index) => {
                const chunkData = {
                    email: repo_to_chat.email,
                    folderName: repo_to_chat.name,
                    fileName: parsedData.metadata[index].pdf_name,
                    actualFileName: findActualFileName(
                        parsedData.metadata[index].pdf_name
                    ),
                    pageNumber: parsedData.metadata[index].page_number,
                    pageContent: pageContent.page_content,
                };
                questionData.chunksData.push(chunkData);
            });

            questionsData.set(qid, questionData);
            setQuestionsData(questionsData);

            setActiveQuestion(questionData);

            setQuery('');

            setIsGenerating(false);
        } else if (data.error || data.detail) {
            setIsGenerating(false);
            alert(`Error: ${data.error || data.detail}`);
        }
    };

    const handleListFormat = async (e) => {
        if (e) {
            e.preventDefault();
        }

        setIsGenerating(true);

        const temp_query = `the previous question was {${currentqna[previos_ques_at]?.question}} and the answer was {${currentqna[previos_ques_at]?.answer}} can you please convert the given answer into into list format and return the data in below mentioned json object and array format. The listData can have N number of subData and subData is of sting type so format the subData to string type accordingly.
        {
            listData:[subData1,subData2,subData3 ... subDataN]
        }
        Please do not add any additional data or key except for the above mention format or else there will be huge penalty`;

        const ques = `${currentqna[previos_ques_at]?.question} `;
        const chunk = `${currentqna[previos_ques_at]?.answer}`;

        const response = await fetch(
            getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/formatans`
            ),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: repo_to_chat.email,
                    foldername: repo_to_chat.name,
                    query: temp_query,
                    ques: ques,
                    chunk: chunk,
                    file_to_chat: fileToChat,
                }).toString(),
            }
        );

        const dateNow = new Date().toLocaleString();

        const data = await response.json();
        if (data.output) {
            setOutput(data.output);

            const parsedData = JSON.parse(data.output);

            const response2 = JSON.parse(parsedData.response);
            const qid = parsedData.qid;
            const docs2 = parsedData.docs ? JSON.parse(parsedData.docs) : [];

            setresponse(response2);

            const previousQid = currentqna[previos_ques_at]?.qid;
            const chunksData = questionsData.get(previousQid).chunksData;

            const questionData = {
                qid: qid,
                question: currentqna[previos_ques_at].question,
                answer: response2,
                timestamp: dateNow,
                chunksData: chunksData,
                type: ChatFormatType.LIST,
                fileToChat: parsedData.file_to_chat,
            };

            docs2.forEach((pageContent, index) => {
                const chunkData = {
                    email: repo_to_chat.email,
                    folderName: repo_to_chat.name,
                    fileName: parsedData.metadata[index].pdf_name,
                    actualFileName: findActualFileName(
                        parsedData.metadata[index].pdf_name
                    ),
                    pageNumber: parsedData.metadata[index].page_number,
                    pageContent: pageContent.page_content,
                };
                questionData.chunksData.push(chunkData);
            });

            questionsData.set(qid, questionData);
            setQuestionsData(questionsData);

            setActiveQuestion(questionData);

            setQuery('');

            setIsGenerating(false);
        } else if (data.error || data.detail) {
            setIsGenerating(false);

            alert(`Error: ${data.error || data.detail}`);
        }
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Reset togglePdfVisibility here
            props.togglePdfVisibility();
        };

        // Add event listener for beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array to ensure this effect runs only once on mount

    useEffect(() => {
        // Reset both states to false when the component mounts or updates
        props.setShowPdf(false);
        props.setOnButton(false);
    }, []);

    return (
        <div className='h-full w-full bg-backgrounds-secondary'>
            {batchmode &&
                (batchwitheval ? (
                    <div className='w-full h-full flex justify-center items-center rounded-xl bg-backgrounds-secondary border'>
                        <div className='flex items-center justify-center h-full w-5/6'>
                            <Evaluate
                                repo_to_chat={s_repo}
                                fine_tuned_mode={fine_tuned_mode}
                                finetunedata={finetunedata}
                                onCancel={() => {
                                    setbatchmode(false);
                                    set_batchwitheval(false);
                                }}
                                cancelText={'Back to Chat'}
                                cancelIcon={faComments}
                            ></Evaluate>
                        </div>
                    </div>
                ) : (
                    <div className='w-full h-full flex justify-center items-center rounded-xl bg-backgrounds-secondary border'>
                        <div className='flex items-center justify-center h-full w-5/6'>
                            <Fileupload
                                repo_to_chat={s_repo}
                                fine_tuned_mode={fine_tuned_mode}
                                finetunedata={finetunedata}
                                onCancel={() => {
                                    setbatchmode(false);
                                    set_batchwitheval(false);
                                }}
                                cancelText={'Back to Chat'}
                                cancelIcon={faComments}
                            ></Fileupload>
                        </div>
                    </div>
                ))}

            {!batchmode && (
                <>
                    {isLoading && (
                        <NoInteractionView viewText={'Loading ...'} />
                    )}
                    <div className='flex items-center justify-center px-0 z-0 h-[86vh]'>
                        <div className='w-full h-full'>
                            {activeQuestion &&
                            (repo_to_chat.repotype == null ||
                                repo_to_chat.repotype === RepoType.VISION ||
                                repo_to_chat.repotype === RepoType.GLOBAL) ? (
                                <ReflexContainer orientation='vertical'>
                                    {!props.showPdf && (
                                        <ReflexElement className='left-pane'>
                                            <div className='pane-content pr-0.5 w-full h-full bg-transparent'>
                                                <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                                    <ChatContainer
                                                        activeQuestion={
                                                            activeQuestion
                                                        }
                                                        isGenerating={
                                                            isGenerating
                                                        }
                                                        setIsGenerating={
                                                            setIsGenerating
                                                        }
                                                        questionsData={
                                                            questionsData
                                                        }
                                                        flagMode={flag_mode}
                                                        query={query}
                                                        currentQnA={currentqna}
                                                        fineTunedMode={
                                                            fine_tuned_mode
                                                        }
                                                        fileToChat={fileToChat}
                                                        repoName={
                                                            repo_to_chat.name
                                                        }
                                                        user={user}
                                                        setFlagMode={
                                                            set_flag_mode
                                                        }
                                                        setFlagIndex={
                                                            set_flag_index
                                                        }
                                                        setQuery={setQuery}
                                                        handleChartFormat={
                                                            handleChartFormat
                                                        }
                                                        handleTableFormat={
                                                            handleTableFormat
                                                        }
                                                        handleListFormat={
                                                            handleListFormat
                                                        }
                                                        handleCustomFormat={
                                                            handleCustomFormat
                                                        }
                                                        handleFineTune={
                                                            handleFineTune
                                                        }
                                                        handleSubmit={
                                                            handleSubmit
                                                        }
                                                        handleSubmitMultiple={
                                                            handleSubmitMultiple
                                                        }
                                                        handleRegenerate={
                                                            handleRegenerate
                                                        }
                                                        setBatchMode={
                                                            setbatchmode
                                                        }
                                                        setBatchWithEval={
                                                            set_batchwitheval
                                                        }
                                                        setFineTuningVerify={
                                                            setFineTuningVerify
                                                        }
                                                        isAdmin={isAdmin}
                                                        isDatabase={
                                                            repo_to_chat.repotype
                                                        }
                                                        togglePdfVisibility={
                                                            props.togglePdfVisibility
                                                        }
                                                        setActiveQuestion={
                                                            setActiveQuestion
                                                        }
                                                        setQuestionsData={
                                                            setQuestionsData
                                                        }
                                                        setcurrentqna={
                                                            setcurrentqna
                                                        }
                                                        setcurrentqnc={
                                                            setcurrentqnc
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </ReflexElement>
                                    )}

                                    {!props.isSmallScreen && (
                                        <ReflexSplitter
                                            style={{
                                                border: 0,
                                                background: 'transparent',
                                                width: '6px',
                                                zIndex: 0,
                                            }}
                                        />
                                    )}

                                    {/* Render PDF view based on showPdf */}
                                    {(props.showPdf ||
                                        props.onButton ||
                                        window.innerWidth >
                                            BREAKPOINT_WIDTH) && (
                                        <ReflexElement className='right-pane'>
                                            <div className='pane-content pl-0.5 w-full h-full bg-transparent'>
                                                <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                                    <PdfContainer
                                                        activeQuestion={
                                                            activeQuestion
                                                        }
                                                        togglePdfVisibility={
                                                            props.togglePdfVisibility
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </ReflexElement>
                                    )}
                                </ReflexContainer>
                            ) : (
                                <ReflexContainer orientation='vertical'>
                                    <ReflexElement className='middle-pane'>
                                        <div className='pane-content w-full h-full bg-transparent'>
                                            <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                                <ChatContainer
                                                    activeQuestion={
                                                        activeQuestion
                                                    }
                                                    fileToChat={fileToChat}
                                                    isGenerating={isGenerating}
                                                    setIsGenerating={
                                                        setIsGenerating
                                                    }
                                                    questionsData={
                                                        questionsData
                                                    }
                                                    flagMode={flag_mode}
                                                    query={query}
                                                    currentQnA={currentqna}
                                                    fineTunedMode={
                                                        fine_tuned_mode
                                                    }
                                                    repoName={repo_to_chat.name}
                                                    user={user}
                                                    setFlagMode={set_flag_mode}
                                                    setFlagIndex={
                                                        set_flag_index
                                                    }
                                                    setQuery={setQuery}
                                                    handleChartFormat={
                                                        handleChartFormat
                                                    }
                                                    handleTableFormat={
                                                        handleTableFormat
                                                    }
                                                    handleListFormat={
                                                        handleListFormat
                                                    }
                                                    handleCustomFormat={
                                                        handleCustomFormat
                                                    }
                                                    handleFineTune={
                                                        handleFineTune
                                                    }
                                                    handleSubmit={handleSubmit}
                                                    handleSubmitMultiple={
                                                        handleSubmitMultiple
                                                    }
                                                    handleRegenerate={
                                                        handleRegenerate
                                                    }
                                                    setBatchMode={setbatchmode}
                                                    setBatchWithEval={
                                                        set_batchwitheval
                                                    }
                                                    setFineTuningVerify={
                                                        setFineTuningVerify
                                                    }
                                                    isAdmin={isAdmin}
                                                    isDatabase={
                                                        repo_to_chat.repotype
                                                    }
                                                    togglePdfVisibility={
                                                        props.togglePdfVisibility
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </ReflexElement>
                                </ReflexContainer>
                            )}
                        </div>
                    </div>
                </>
            )}

            {fineTuningVerify && (
                <ConfirmModal
                    modalText={'Start fine tuning ?'}
                    onConfirm={() => {
                        alert('Feature is under development. Get back soon!!');
                        setFineTuningVerify(false);
                    }}
                    onCancel={() => setFineTuningVerify(false)}
                />
            )}

            {flag_mode && (
                <Flagpage
                    questionsData={questionsData}
                    setflag={set_flag_mode}
                    add_flag={addNewFlag}
                    query={questionsData.get(flag_index).question}
                    response={questionsData.get(flag_index).answer}
                    email={repo_to_chat.email}
                    foldername={repo_to_chat.foldername}
                    qid={flag_index}
                />
            )}
        </div>
    );
};

export default MainArea;
